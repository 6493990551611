<template>
  <div>
    <div class="page-header">
      <div><h2 class="main-content-title tx-24 mg-b-5">Contacts</h2>
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="#" @click="$router.go(-1)">Назад</a></li>
          <li class="breadcrumb-item active" aria-current="page">Contacts Details</li>
        </ol>
      </div>
      <div class="d-flex">
      </div>
    </div>
    <div class="row row-sm">
      <div class="col-lg-12 col-md-12">
        <div class="card custom-card main-content-body-profile">
          <div class="tab-content">
            <div class="main-content-body tab-pane p-4 border-top-0 active" id="about">
              <div class="card-body p-0 border p-0 rounded-10">
                <div class="p-4"><h4 class="tx-15 text-uppercase mb-3">Message</h4>
                  <p class="m-b-5">
                    {{contact.text}}
                  </p>
                  <div class="m-t-30">
                    <div class="border-top"></div>
                    <div class="p-4"><label class="main-content-label tx-13 mg-b-20">Contact</label>
                      <div class="d-sm-flex">
                        <div class="mg-sm-r-20 mg-b-10">
                          <div class="main-profile-contact-list">
                            <div class="media">
                              <div class="media-icon bg-success-transparent text-success"><i
                                class="icon ion-android-clock"></i>
                              </div>
                              <div class="media-body"><span>Name</span>
                                <div>
                                  {{contact.name}}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="mg-sm-r-20 mg-b-10">
                          <div class="main-profile-contact-list">
                            <div class="media">
                              <div class="media-icon bg-primary-transparent text-primary"><i
                                class="icon ion-md-phone-portrait"></i></div>
                              <div class="media-body"><span>Phone Number</span>
                                <div>
                                  <a :href="'tel:'+contact.phone_number">{{contact.phone_number}}</a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="mg-sm-r-20 mg-b-10">
                          <div class="main-profile-contact-list">
                            <div class="media">
                              <div class="media-icon bg-info-transparent text-info"><i
                                class="icon ion-logo-google"></i>
                              </div>
                              <div class="media-body"><span>Email</span>
                                <div>
                                  <a :href="'mailto:'+contact.email">{{contact.email}}</a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="border-top"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'ContactDetails',
  data () {
    return {}
  },
  computed: {
    ...mapState({
      contact: state => state.contacts.contact
    })
  },
  created () {
    this.getContact(this.$route.params.id).catch(() => {
      this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
    })
  },
  methods: {
    ...mapActions({
      getContact: 'contacts/getContact'
    })
  }
}
</script>
