var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"page-header"},[_c('div',[_c('h2',{staticClass:"main-content-title tx-24 mg-b-5"},[_vm._v(_vm._s(_vm.isCreate ? 'Добавить' : 'Редактировать')+" новость")]),_c('ol',{staticClass:"breadcrumb"},[_c('li',{staticClass:"breadcrumb-item"},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("Назад")])]),_c('li',{staticClass:"breadcrumb-item active",attrs:{"aria-current":"page"}},[_vm._v(_vm._s(_vm.isCreate ? 'Добавить' : 'Редактировать')+" новость ")])])]),_c('div',{staticClass:"d-flex"})]),_c('div',{staticClass:"row row-sm justify-content-center"},[_c('div',{staticClass:"col-xl-6 col-lg-8 col-md-12"},[_c('div',{staticClass:"card custom-card"},[_c('div',{staticClass:"card-body"},[_vm._m(0),( _vm.showNews === true)?_c('div',[_c('ValidationObserver',{ref:"newsAddForm",staticClass:"d-flex flex-column",attrs:{"id":"addNewsForm","tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.formSubmit()}}},[_c('b-tabs',{attrs:{"content-class":"mt-3"}},[_vm._l((_vm.languages),function(language,i){return _c('b-tab',{key:i,attrs:{"title":language.code}},[_c('ValidationProvider',{staticClass:"form-group",attrs:{"rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
return [_c('label',{staticClass:"form-label",attrs:{"for":'title[' + language.code + ']'}},[_vm._v("Название")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.title[language.code]),expression:"formData.title[language.code]"}],staticClass:"form-control",class:_vm.checkValidation(classes, language.code),attrs:{"id":'title[' + language.code + ']',"name":'title[' + language.code + ']',"placeholder":"Название","type":"text"},domProps:{"value":(_vm.formData.title[language.code])},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData.title, language.code, $event.target.value)}}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"form-group",attrs:{"rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
return [_c('label',{staticClass:"form-label",attrs:{"for":'shortDescriptions[' + language.code + ']'}},[_vm._v("Короткое описание")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.short_description[language.code]),expression:"formData.short_description[language.code]"}],staticClass:"form-control",class:_vm.checkValidation(classes, language.code),attrs:{"pattern":"^(\\s{0,}|a^)([^\\s]+(\\s|$)){1,45}(\\s{0,}|a^)$","name":'short_description[' + language.code + ']',"id":'shortDescriptions[' + language.code + ']',"placeholder":"Короткое описание"},domProps:{"value":(_vm.formData.short_description[language.code])},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData.short_description, language.code, $event.target.value)},function($event){return _vm.toCountWord($event.target.value)}]}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"form-group",attrs:{"rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
return [_c('label',{staticClass:"form-label",attrs:{"for":'description[' + language.code + ']'}},[_vm._v("Описание")]),_c('ckeditor',{class:_vm.checkValidation(classes, language.code),attrs:{"id":'description[' + language.code + ']',"config":_vm.ckEditorConfig},model:{value:(_vm.formData.description[language.code]),callback:function ($$v) {_vm.$set(_vm.formData.description, language.code, $$v)},expression:"formData.description[language.code]"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"form-group",attrs:{"rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
return [_c('label',{staticClass:"form-label",attrs:{"for":'seo_title[' + language.code + ']'}},[_vm._v("Seo Title:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.seo_title[language.code]),expression:"formData.seo_title[language.code]"}],staticClass:"form-control",class:_vm.checkValidation(classes, language.code),attrs:{"id":'seo_title[' + language.code + ']',"maxlength":"80","name":'seo_title[' + language.code + ']',"placeholder":"Seo Title","type":"text"},domProps:{"value":(_vm.formData.seo_title[language.code])},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData.seo_title, language.code, $event.target.value)}}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"form-group",attrs:{"rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
return [_c('label',{staticClass:"form-label",attrs:{"for":'seo_description[' + language.code + ']'}},[_vm._v("Seo Description:")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.seo_description[language.code]),expression:"formData.seo_description[language.code]"}],staticClass:"form-control",class:_vm.checkValidation(classes, language.code),attrs:{"maxlength":"180","name":'seo_description[' + language.code + ']',"id":'seo_description[' + language.code + ']',"placeholder":"Seo Description"},domProps:{"value":(_vm.formData.seo_description[language.code])},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData.seo_description, language.code, $event.target.value)}}})]}}],null,true)})],1)}),_c('FileUpload',{attrs:{"accept":"image/png,image/jpeg,image/jpg","label":"Фото:","name":"image","uploadType":"oneImage","type":"image","validation":true,"filesData":_vm.formData.uploadedFiles}}),_c('ValidationProvider',{staticClass:"form-group",attrs:{"rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
return [_c('label',{staticClass:"form-label",attrs:{"for":"image_alt"}},[_vm._v("Image alt:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.image_alt),expression:"formData.image_alt"}],staticClass:"form-control",class:classes,attrs:{"id":"image_alt","name":"image_alt","placeholder":"Image alt","type":"text"},domProps:{"value":(_vm.formData.image_alt)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "image_alt", $event.target.value)}}})]}}],null,false,851484248)}),_c('div',{staticClass:"row mg-y-10 "},[_c('div',{staticClass:"col-lg-3 mg-t-20 mg-lg-t-0"},[_c('label',{staticClass:"radiobox"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.videoType),expression:"videoType"}],attrs:{"checked":"","name":"video_type","type":"radio","value":"video_iframe"},domProps:{"checked":_vm._q(_vm.videoType,"video_iframe")},on:{"change":function($event){_vm.videoType="video_iframe"}}}),_c('span',[_vm._v("Добавить iframe")])])]),_c('div',{staticClass:"col-lg-3"},[_c('label',{staticClass:"radiobox"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.videoType),expression:"videoType"}],attrs:{"name":"video_type","type":"radio","value":"video"},domProps:{"checked":_vm._q(_vm.videoType,"video")},on:{"change":function($event){_vm.videoType="video"}}}),_c('span',[_vm._v("Загрузить видео")])])])]),(_vm.videoType === 'video_iframe')?_c('ValidationProvider',{staticClass:"form-group",attrs:{"tag":"div","rules":{ regex: /(?:<iframe[^>]*)(?:(?:\/>)|(?:>.*?<\/iframe>))/ }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
return [_c('label',{staticClass:"form-label",attrs:{"for":"video_iframe"}},[_vm._v("Iframe видео:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.video_iframe),expression:"formData.video_iframe"}],staticClass:"form-control",class:classes,attrs:{"id":"video_iframe","name":"video_iframe","placeholder":"Iframe видео","type":"text"},domProps:{"value":(_vm.formData.video_iframe)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "video_iframe", $event.target.value)}}})]}}],null,false,3524091171)}):_c('ValidationProvider',{staticClass:"form-group",attrs:{"tag":"div"}},[_c('div',[_c('label',{staticClass:"form-label"},[_vm._v("Видео:")])]),_c('div',{staticClass:"custom-file"},[_c('input',{staticClass:"custom-file-input",attrs:{"name":"video","accept":".mp4, .webm, .ogg","type":"file","id":"inputGroupFile01","aria-describedby":"inputGroupFileAddon01"},on:{"change":function($event){return _vm.uploadCoverFile($event.target.files)}}}),(_vm.uploadedVideoFile && _vm.uploadedVideoFile.name)?_c('label',{staticClass:"custom-file-label",attrs:{"for":"inputGroupFile01"}},[_vm._v(_vm._s(_vm.isEdit && !_vm.uploadedVideoFile.name ? _vm.uploadedVideoFile : _vm.uploadedVideoFile.name))]):_c('label',{staticClass:"custom-file-label",attrs:{"for":"inputGroupFile01"}},[_vm._v("Выбрать файл")])])])],2),_c('button',{staticClass:"btn ripple btn-main-primary",class:_vm.buttonDisable ? 'button-readonly' : '',attrs:{"disabled":_vm.buttonDisable}},[_vm._v(_vm._s(_vm.isCreate?'Сохранить':'Обновить')+" ")])],1)],1):_vm._e()])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h6',{staticClass:"main-content-label mb-1"},[_vm._v("Новость")])])}]

export { render, staticRenderFns }