import { http } from '../../../api'

const taskCategorize = {
  namespaced: true,
  state: {
    task: null
  },
  actions: {
    createTask: ({ commit }, params) => {
      return http.post('/task-categorize', params).then(res => {
        return res.data
      })
    },
    getTask: ({ commit }, params) => {
      return http.get('/task-categorize/' + params).then(res => {
        commit('setTask', res.data)
      })
    },
    updateTask: ({ commit }, params) => {
      return http.post('/task-categorize/' + params.id, params.data).then(res => {
      })
    },
    deleteCategory: ({ commit }, params) => {
      return http.post('task-categorize/delete-category', params).then(res => {
      })
    },
    deleteCategoryItem: ({ commit }, params) => {
      return http.post('task-categorize/delete-category-item', params).then(res => {
      })
    }
  },
  mutations: {
    setTask (state, data) {
      state.task = data
    }
  },
  getters: {
    task: state => state.task
  }
}

export default taskCategorize
