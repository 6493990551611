import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

import SignIn from '../components/pages/Auth/SignIn'
import ForgotPassword from '../components/pages/Auth/ForgotPassword'
import Dashboard from '../components/pages/Dashboard'
import News from '../components/pages/News'
import Settings from '../components/pages/Settings'
import Subscribers from '../components/pages/Subscribers'
import EmailTemplates from '../components/pages/EmailTemplates'
import EmailTemplateAdd from '../components/pages/EmailTemplates/add'
import Currency from '../components/pages/Currency'
import CurrencyAdd from '../components/pages/Currency/add'
import AddNews from '../components/pages/News/add'
import Videos from '../components/pages/Videos'
import AddVideos from '../components/pages/Videos/add'
import DynamicPage from '../components/pages/DynamicPage'
import DynamicPageAdd from '../components/pages/DynamicPage/add'
import DynamicPageText from '../components/pages/DynamicPageText'
import DynamicPageTextAdd from '../components/pages/DynamicPageText/add'
import Faq from '../components/pages/Faq'
import AddFaq from '../components/pages/Faq/add'
import FaqCategory from '../components/pages/FaqCategory'
import AddFaqCategory from '../components/pages/FaqCategory/add'
import Application from '../components/pages/Application'
import SupportCategory from '../components/pages/SupportCategory'
import SupportCategoryAdd from '../components/pages/SupportCategory/add'
import Supports from '../components/pages/Supports'
import Language from '../components/pages/Language'
import AddLanguage from '../components/pages/Language/add.vue'
import User from '../components/pages/User'
import UserEdit from '../components/pages/User/edit'
import Review from '../components/pages/Review'
import ReviewEdit from '../components/pages/Review/edit.vue'
import TeachingLangCategories from '../components/pages/TeachingLangCategories'
import Contacts from '../components/pages/Contacts'
import ContactsDetails from '../components/pages/Contacts/details'
import TeachingLangCategory from '../components/pages/TeachingLangCategories/add.vue'
import Interesting from '../components/pages/Interesting/index'
import InterestingAdd from '../components/pages/Interesting/add.vue'
import InterestingCategory from '../components/pages/InterestingCategory'
import InterestingCategoryAdd from '../components/pages/InterestingCategory/add'
import Admins from '../components/pages/Admins'
import AddAdmin from '../components/pages/Admins/add'
import Courses from '../components/pages/Courses'
import CourseDetail from '../components/pages/Courses/courseDetail'
import ChatExpert from '../components/pages/Courses/ChatExpert'
import CoursesAdd from '../components/pages/Courses/add'
import CourseTariff from '../components/pages/CourseTariff'
import CourseTariffAdd from '../components/pages/CourseTariff/add'
import CourseModuleAdd from '../components/pages/CourseModule/add'
import CourseLevel from '../components/pages/CourseLevel'
import CourseLevelAdd from '../components/pages/CourseLevel/add'
import LessonAdd from '../components/pages/Lesson/add'
import TaskTest from '../components/pages/Tasks/TaskTest'
import TaskMissingWords from '../components/pages/Tasks/TaskMissingWords'
import TaskPickUpTranslation from '../components/pages/Tasks/TaskPickUpTranslation'
import TaskFieldOfDreams from '../components/pages/Tasks/TaskFieldOfDreams'
import TaskRememberFind from '../components/pages/Tasks/TaskRememberFind'
import TaskGallows from '../components/pages/Tasks/TaskGallows'
import TaskDeckCards from '../components/pages/Tasks/TaskDeckCards'
import TaskWheelFortune from '../components/pages/Tasks/TaskWheelFortune'
import TaskSuggestedFromWords from '../components/pages/Tasks/TaskSuggestedFromWords'
import TaskSpeakText from '../components/pages/Tasks/TaskSpeakText'
import TaskCrossword from '../components/pages/Tasks/TaskCrossword'
import TaskCategorize from '../components/pages/Tasks/TaskCategorize'
import TaskType from '../components/pages/Tasks/TaskType'
import TaskTypos from '../components/pages/Tasks/TaskTypos'
import LessonBlockAdd from '../components/pages/LessonBlockAdd'
import PaymentMethod from '../components/pages/PaymentMethod'
import PaymentMethodAdd from '../components/pages/PaymentMethod/add'
import Books from '../components/pages/Books'
import BooksAdd from '../components/pages/Books/add'
import Encyclopedia from '../components/pages/Encyclopedia/index'
import EncyclopediaAdd from '../components/pages/Encyclopedia/add'
import OtherMaterials from '../components/pages/OtherMaterials'
import OtherMaterialsAdd from '../components/pages/OtherMaterials/add'
import TeachingVideos from '../components/pages/TeachingVideos'
import TeachingVideosAdd from '../components/pages/TeachingVideos/add'
import TeachingAudio from '../components/pages/TeachingAudio'
import TeachingAudioAdd from '../components/pages/TeachingAudio/add'
import CourseTest from '../components/pages/CourseTest'
import CourseModuleTest from '../components/pages/CourseModuleTest'
import CourseVideo from '../components/pages/CourseVideo'
import CourseTestTaskType from '../components/pages/CourseTestTaskType'
import CourseModuleTestTaskType from '../components/pages/CourseModuleTestTaskType'
import CourseTaskType from '../components/pages/CourseTaskType'
import ExpertEdit from '../components/pages/User/ExpertEdit'
import MailDelivery from '../components/pages/MailDelivery'
import Phones from '../components/pages/Phones'
import PhonesAdd from '../components/pages/Phones/add'
import Coupons from '../components/pages/Coupons'
import CouponsAdd from '../components/pages/Coupons/add'
import CouponsStatistics from '../components/pages/Coupons/Statistics'
import NotFound from '../components/pages/404'

import AuthLayout from '../components/layouts/AuthLayout'
import IndexLayout from '../components/layouts/IndexLayout'
import middlewarePipeline from './middlewarePipeline'

import guest from './middleware/guest'
import auth from './middleware/auth'

Vue.use(VueRouter)

const index = new VueRouter({
  routes: [
    {
      path: '/auth',
      component: AuthLayout,
      name: 'auth',
      redirect: '/auth/sign-in',
      meta: {
        middleware: [
          guest
        ]
      },
      children: [
        {
          path: 'sign-in',
          name: 'SignIn',
          component: SignIn,
          meta: {
            middleware: [
              guest
            ]
          }
        },
        {
          path: 'forgot-password',
          name: 'ForgotPassword',
          component: ForgotPassword,
          meta: {
            middleware: [
              guest
            ]
          }
        }
      ]
    },
    {
      path: '/',
      component: IndexLayout,
      meta: {
        middleware: [
          auth
        ]
      },
      redirect: '/dashboard',
      children: [
        {
          path: '/dashboard',
          name: 'dashboard',
          component: Dashboard,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/news',
          name: 'news',
          component: News,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/news/:id?',
          name: 'add_news',
          component: AddNews,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/settings',
          name: 'Settings',
          component: Settings,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/email-template',
          name: 'email_template',
          component: EmailTemplates,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/email-template/:id',
          name: 'email_template_add',
          component: EmailTemplateAdd,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/phones',
          name: 'phones',
          component: Phones,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/phones/:id',
          name: 'phones.add',
          component: PhonesAdd,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/coupons',
          name: 'coupons',
          component: Coupons,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/coupons/:id',
          name: 'coupons.add',
          component: CouponsAdd,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/coupons/:id/statistics',
          name: 'coupons.statistics',
          component: CouponsStatistics,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/subscribers',
          name: 'Subscribers',
          component: Subscribers,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/payment-method',
          name: 'PaymentMethod',
          component: PaymentMethod,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/payment-method/:id',
          name: 'PaymentMethodAdd',
          component: PaymentMethodAdd,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/currency',
          name: 'Currency',
          component: Currency,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/currency/:id',
          name: 'CurrencyAdd',
          component: CurrencyAdd,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/videos',
          name: 'videos',
          component: Videos,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/video/:id?',
          name: 'add_video',
          component: AddVideos,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/dynamic-page',
          name: 'dynamic_page',
          component: DynamicPage,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/dynamic-page/:id?',
          name: 'dynamic_page_add',
          component: DynamicPageAdd,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/dynamic-page-text',
          name: 'dynamic_page_text',
          component: DynamicPageText,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/dynamic-page-text/:id?',
          name: 'dynamic_page_text_add',
          component: DynamicPageTextAdd,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/faq',
          name: 'faq',
          component: Faq,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/faq/:id',
          name: 'faq_store',
          component: AddFaq,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/faq-category',
          name: 'faq_category',
          component: FaqCategory,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/faq-category/:id',
          name: 'faq_category_store',
          component: AddFaqCategory,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/faq-application',
          name: 'Application',
          component: Application,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/support-tickets',
          name: 'support-tickets',
          component: Supports,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/support-category',
          name: 'support-category',
          component: SupportCategory,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/support-category/:id',
          name: 'support-category_add',
          component: SupportCategoryAdd,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/user',
          name: 'user',
          component: User,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/user/:id',
          name: 'UserEdit',
          component: UserEdit,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/experts',
          name: 'Experts',
          component: User,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/expert/:id',
          name: 'ExpertEdit',
          component: ExpertEdit,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/reviews',
          name: 'review',
          component: Review,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/review/edit/:id',
          name: 'reviewEdit',
          component: ReviewEdit,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/teaching-lang-categories',
          name: 'teachingLangCategories',
          component: TeachingLangCategories,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/interesting',
          name: 'interesting',
          component: Interesting,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/interesting-categories',
          name: 'InterestingCategory',
          component: InterestingCategory,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/interesting-category/:id',
          name: 'InterestingCategoryAdd',
          component: InterestingCategoryAdd,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/contacts',
          name: 'contacts',
          component: Contacts,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/contacts/:id',
          name: 'contacts_details',
          component: ContactsDetails,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/interesting/:id',
          name: 'interesting_add',
          component: InterestingAdd,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/teaching-lang-category/:id',
          name: 'teachingLangCategory',
          component: TeachingLangCategory,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/language',
          name: 'language',
          component: Language,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/language/:id',
          name: 'language_add',
          component: AddLanguage,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/admins',
          name: 'admins',
          component: Admins,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/admin/:id',
          name: 'AddAdmin',
          component: AddAdmin,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/courses',
          name: 'Courses',
          component: Courses,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/courses/:id/detail',
          name: 'CourseDetail',
          component: CourseDetail,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/courses/:id/:userCourseId/chat-expert/:userId',
          name: 'ChatExpert',
          component: ChatExpert,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/courses/:id',
          name: 'CoursesAdd',
          component: CoursesAdd,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/course-tariff',
          name: 'CourseTariff',
          component: CourseTariff,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/course-tariff/:id',
          name: 'CourseTariffAdd',
          component: CourseTariffAdd,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/course-module/:courseId/:id',
          name: 'CourseModuleAdd',
          component: CourseModuleAdd,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/course-level',
          name: 'CourseLevel',
          component: CourseLevel,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/course-level/:id',
          name: 'CourseLevelAdd',
          component: CourseLevelAdd,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/lesson/:courseId/:moduleId/:id',
          name: 'LessonAdd',
          component: LessonAdd,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/task-type/:courseId/:moduleId/:lessonId/:lessonBlockId/lesson-type',
          name: 'TaskType',
          component: TaskType,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/course-test/:courseId/:testId',
          name: 'CourseTest',
          component: CourseTest,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/course-test/:courseId/:testId/task-type',
          name: 'CourseTestTaskType',
          component: CourseTestTaskType,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/course/:courseId/task-type',
          name: 'CourseTaskType',
          component: CourseTaskType,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/course-module-test/:courseId/:moduleId/:testId',
          name: 'CourseModuleTest',
          component: CourseModuleTest,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/course-module-test/:courseId/:moduleId/:testId/task-type',
          name: 'CourseModuleTestTaskType',
          component: CourseModuleTestTaskType,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/course-video/:courseId/:videoId',
          name: 'CourseVideo',
          component: CourseVideo,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/task/:courseId/:moduleId/:lessonId/:lessonBlockId/task-type/1/:id',
          name: 'TaskTest',
          component: TaskTest,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/task/:courseId/:moduleId/:lessonId/:lessonBlockId/task-type/2/:id',
          name: 'TaskMissingWords',
          component: TaskMissingWords,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/task/:courseId/:moduleId/:lessonId/:lessonBlockId/task-type/3/:id',
          name: 'TaskPickUpTranslation',
          component: TaskPickUpTranslation,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/task/:courseId/:moduleId/:lessonId/:lessonBlockId/task-type/4/:id',
          name: 'TaskCategorize',
          component: TaskCategorize,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/task/:courseId/:moduleId/:lessonId/:lessonBlockId/task-type/6/:id',
          name: 'TaskSpeakText',
          component: TaskSpeakText,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/task/:courseId/:moduleId/:lessonId/:lessonBlockId/task-type/7/:id',
          name: 'TaskCrossword',
          component: TaskCrossword,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/task/:courseId/:moduleId/:lessonId/:lessonBlockId/task-type/8/:id',
          name: 'TaskFieldOfDreams',
          component: TaskFieldOfDreams,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/task/:courseId/:moduleId/:lessonId/:lessonBlockId/task-type/9/:id',
          name: 'TaskGallows',
          component: TaskGallows,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/task/:courseId/:moduleId/:lessonId/:lessonBlockId/task-type/10/:id',
          name: 'TaskDeckCards',
          component: TaskDeckCards,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/task/:courseId/:moduleId/:lessonId/:lessonBlockId/task-type/11/:id',
          name: 'TaskWheelFortune',
          component: TaskWheelFortune,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/task/:courseId/:moduleId/:lessonId/:lessonBlockId/task-type/12/:id',
          name: 'TaskRememberFind',
          component: TaskRememberFind,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/task/:courseId/:moduleId/:lessonId/:lessonBlockId/task-type/13/:id',
          name: 'TaskSuggestedFromWords',
          component: TaskSuggestedFromWords,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/task/:courseId/:moduleId/:lessonId/:lessonBlockId/task-type/14/:id',
          name: 'TaskTypos',
          component: TaskTypos,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        // coues-test
        {
          path: '/task/:courseId/:testId/task-type/1/:id',
          name: 'TaskTest',
          component: TaskTest,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/task/:courseId/:testId/task-type/2/:id',
          name: 'TaskMissingWords',
          component: TaskMissingWords,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/task/:courseId/:testId/task-type/3/:id',
          name: 'TaskPickUpTranslation',
          component: TaskPickUpTranslation,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/task/:courseId/:testId/task-type/4/:id',
          name: 'TaskCategorize',
          component: TaskCategorize,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/task/:courseId/:testId/task-type/6/:id',
          name: 'TaskSpeakText',
          component: TaskSpeakText,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/task/:courseId/:testId/task-type/7/:id',
          name: 'TaskCrossword',
          component: TaskCrossword,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/task/:courseId/:testId/task-type/8/:id',
          name: 'TaskFieldOfDreams',
          component: TaskFieldOfDreams,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/task/:courseId/:testId/task-type/9/:id',
          name: 'TaskGallows',
          component: TaskGallows,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/task/:courseId/:testId/task-type/10/:id',
          name: 'TaskDeckCards',
          component: TaskDeckCards,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/task/:courseId/:testId/task-type/11/:id',
          name: 'TaskWheelFortune',
          component: TaskWheelFortune,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/task/:courseId/:testId/task-type/12/:id',
          name: 'TaskRememberFind',
          component: TaskRememberFind,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        // cours module test
        {
          path: '/task/:courseId/:moduleId:moduleTestId/task-type/1/:id',
          name: 'TaskTest',
          component: TaskTest,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/task/:courseId/:moduleId/:moduleTestId/task-type/2/:id',
          name: 'TaskMissingWords',
          component: TaskMissingWords,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/task/:courseId/:moduleId/:moduleTestId/task-type/3/:id',
          name: 'TaskPickUpTranslation',
          component: TaskPickUpTranslation,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/task/:courseId/:moduleId/:moduleTestId/task-type/4/:id',
          name: 'TaskCategorize',
          component: TaskCategorize,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/task/:courseId/:moduleId/:moduleTestId/task-type/6/:id',
          name: 'TaskSpeakText',
          component: TaskSpeakText,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/task/:courseId/:moduleId/:moduleTestId/task-type/7/:id',
          name: 'TaskCrossword',
          component: TaskCrossword,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/task/:courseId/:moduleId/:moduleTestId/task-type/8/:id',
          name: 'TaskFieldOfDreams',
          component: TaskFieldOfDreams,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/task/:courseId/:moduleId/:moduleTestId/task-type/9/:id',
          name: 'TaskGallows',
          component: TaskGallows,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/task/:courseId/:moduleId/:moduleTestId/task-type/10/:id',
          name: 'TaskDeckCards',
          component: TaskDeckCards,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/task/:courseId/:moduleId/:moduleTestId/task-type/11/:id',
          name: 'TaskWheelFortune',
          component: TaskWheelFortune,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/task/:courseId/:moduleId/:moduleTestId/task-type/12/:id',
          name: 'TaskRememberFind',
          component: TaskRememberFind,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/lesson-block/:courseId/:moduleId/:lessonId/:id',
          name: 'LessonBlockAdd',
          component: LessonBlockAdd,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/books',
          name: 'Books',
          component: Books,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/books/:id',
          name: 'BooksAdd',
          component: BooksAdd,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/encyclopedia',
          name: 'Encyclopedia',
          component: Encyclopedia,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/encyclopedia/:id',
          name: 'Encyclopedia',
          component: EncyclopediaAdd,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/other-materials',
          name: 'OtherMaterials',
          component: OtherMaterials,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/other-materials/:id',
          name: 'OtherMaterialsAdd',
          component: OtherMaterialsAdd,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/teaching-videos',
          name: 'TeachingVideos',
          component: TeachingVideos,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/teaching-videos/:id',
          name: 'TeachingVideosAdd',
          component: TeachingVideosAdd,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/teaching-audios',
          name: 'TeachingAudio',
          component: TeachingAudio,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/teaching-audios/:id',
          name: 'TeachingAudioAdd',
          component: TeachingAudioAdd,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
          path: '/mail-delivery',
          name: 'MailDelivery',
          component: MailDelivery,
          meta: {
            middleware: [
              auth
            ]
          }
        }
      ]
    },
    {
      path: '*',
      component: NotFound,
      name: 'not-found'
    }
  ],
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  mode: 'history',
  linkActiveClass: 'active'
})

index.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next()
  }
  const middleware = to.meta.middleware

  const context = {
    to,
    from,
    next,
    store
  }
  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1)
  })
})

export default index
