<template>
  <div>
    <div class="page-header">
      <div>
        <h2 class="main-content-title tx-24 mg-b-5">Язык обучения</h2>
      </div>
      <div class="d-flex">
        <div class="justify-content-center">
          <router-link to="/teaching-lang-category/add" type="button" class="btn btn-primary my-2 btn-icon-text">
            <i class="fe fe-plus-square mr-2"></i> Добавить язык
          </router-link>
        </div>
      </div>
    </div>
    <div class="row row-sm">
      <div class="col-lg-12">
        <div class="card custom-card">
          <div class="card-body">
            <div>
              <h6 class="main-content-label mb-1">Список</h6>
            </div>
            <div class="table-responsive">
              <b-table striped hover :items="langCategoryList" :fields="tableFields">
                <template #cell(status)="row">
                  <span class="badge text-white" :class="{'bg-success': row.item.status === 'active', 'bg-danger': row.item.status !== 'active'}">{{row.item.status}}</span>
                </template>
                <template #cell(flag)="row">
                  <img class="flag-img" :src="row.item.flag"/>
                </template>
                <template #cell(color)="row">
                  <span class="badge w-25 px-5 py-3 d-inline-block" v-bind:style="{ backgroundImage: row.item.color }">
                  </span>
                </template>
                <template #cell(actions)="row">
                  <router-link :to="`/teaching-lang-category/${row.item.id}`" class="btn btn-outline-primary">
                    <i class="fe fe-edit"></i>
                  </router-link>
                  <button v-if="row.item.status === 'active'" @click="deleteCategory(row.item.id)" type="button" class="btn btn-outline-danger ml-2">
                    <i class="ti-close"></i>
                  </button>
                  <div v-else class="d-inline-block">
                    <button @click="restoreCategory(row.item.id)" type="button" class="btn btn-outline-success ml-2">
                      <i class="fe fe-rotate-ccw"></i>
                    </button>
                    <button @click="deleteCategory(row.item.id)" type="button" class="btn btn-outline-danger ml-2">
                      <i class="fe fe-trash-2"></i>
                    </button>
                  </div>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Swal from 'sweetalert2'

export default {
  name: 'teachingLangCategories',
  data () {
    return {

    }
  },
  computed: {
    ...mapState({
      langCategoryList: state => state.teachingLanguage.langCategoryList
    }),
    tableFields () {
      return [
        {
          key: 'id',
          label: 'Id',
          sortable: false
        },
        {
          key: 'name.' + this.$i18n.locale,
          label: 'Название',
          sortable: false
        },
        {
          key: 'flag',
          label: 'Флаг',
          sortable: false
        },
        {
          key: 'color',
          label: 'Основной цвет',
          sortable: false
        },
        {
          key: 'key',
          label: 'Ключ',
          sortable: false
        },
        {
          key: 'status',
          label: 'Статус',
          sortable: false
        },
        {
          key: 'actions',
          label: 'Действие',
          sortable: false
        }
      ]
    }
  },
  created () {
    this.getLanguageCategoryList().catch(() => {
      this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
    })
  },
  methods: {
    ...mapActions({
      getLanguageCategoryList: 'teachingLanguage/getLanguageCategoryList',
      deleteTeachingLanguage: 'teachingLanguage/deleteTeachingLanguage',
      restoreTeachingLanguage: 'teachingLanguage/restoreTeachingLanguage'
    }),
    restoreCategory (id) {
      Swal.fire({
        text: this.$t('message.warning.restore'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#748C41',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('buttons.yes'),
        cancelButtonText: this.$t('buttons.no')
      }).then((result) => {
        if (result.value) {
          this.restoreTeachingLanguage(id).then(res => {
            this.$toasted.success(this.$t('messages.success.teachingLanguageUpdated')).goAway(1500)
          }).catch(() => {
            this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
          })
        }
      })
    },
    deleteCategory (id) {
      Swal.fire({
        text: this.$t('messages.warning.delete'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#748C41',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('buttons.yes'),
        cancelButtonText: this.$t('buttons.no')
      }).then((result) => {
        if (result.value) {
          this.deleteTeachingLanguage(id).then(res => {
            this.$toasted.success(this.$t('messages.success.languageDeleted')).goAway(1500)
          }).catch(() => {
            this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
          })
        }
      })
    }
  }
}
</script>
<style>
  .flag-img {
    width: 30px;
    height: 30px;
    border-radius: 100%
  }
</style>
