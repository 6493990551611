import { http } from '../../../api'

const videos = {
  namespaced: true,
  state: {
    videosList: [],
    video: null
  },
  actions: {
    getAllVideos: ({ commit }, params) => {
      let url = '/video'
      if (params) {
        const q = new URLSearchParams(params)
        url = url + '?' + q.toString()
      }
      return http.get(url).then(res => {
        commit('setVideosList', res.data.data)
        return res.data
      })
    },
    createVideos: ({ commit }, params) => {
      return http.post('/video', params, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(res => {
        return res.data
      })
    },
    deleteVideo: ({ commit }, params) => {
      return http.delete('/video/' + params.id).then(res => {
        if (res.data.deleteType === 'forceDelete') {
          commit('setRemoveVideoList', params.id)
        } else {
          commit('setUpdateVideoStatus', { id: params.id, status: 'inactive' })
        }
      })
    },
    restoreVideo: ({ commit }, params) => {
      return http.get('/video/' + params.id + '/restore').then(res => {
        commit('setUpdateVideoStatus', { id: params.id, status: 'active' })
      })
    },
    getVideo: ({ commit }, params) => {
      return http.get('/video/' + params.id).then(res => {
        commit('setVideo', res.data.data)
      })
    },
    updateVideo: ({ commit }, params) => {
      return http.post('/video/' + params.id, params.data).then(res => {
      })
    }
  },
  mutations: {
    setVideosList (state, data) {
      state.videosList = data
    },
    setRemoveVideoList (state, id) {
      state.videosList = state.videosList.filter(item => item.id !== id)
    },
    setUpdateVideoStatus (state, data) {
      state.videosList = state.videosList.map(item => {
        if (item.id === data.id) {
          item.status = data.status
        }
        return item
      })
    },
    setVideo (state, data) {
      state.video = data
    }
  },
  getters: {
    videosList: state => state.videosList,
    video: state => state.video
  }
}

export default videos
