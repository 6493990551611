<template>
  <TaskBase
  help="Запишите в поле слово, которое должен отгадать экзаменуемый."
  :task-type-id="8"
  :create="createTask"
  :update="updateTask"
  :after-validation="afterValidation"
  :data="formData"
  >
    <FormCard>
      <b-tabs content-class="mt-3">
        <div class="form-group">
          <label for="field_of_dreams[word]" class="form-label">Слово:</label>
          <input id="field_of_dreams[word]" :disabled="formData.field_of_dreams.select_dictionary" class="form-control" name="field_of_dreams[word]" v-model="formData.field_of_dreams.word" placeholder="Word" type="text">
        </div>
      </b-tabs>
    </FormCard>
  </TaskBase>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import FileUpload from '../../../components/FileUpload'
import FormCard from "../../../components/FormCard";
import VideoForm from '../../../components/VideoForm'
import CkeditorMixin from '../../../../mixins/CkeditorMixin'
import TaskMixin from '../../../../mixins/TaskMixin'
import Breadcrumbs from '../../../components/Breadcrumbs'
import AudioMixin from '../../../../mixins/AudioMixin'
import TaskBase from '../../../components/tasks/TaskBase'
export default {
  name: 'TaskFieldOfDreams',
  mixins: [CkeditorMixin, TaskMixin, AudioMixin],
  data () {
    return {
      buttonDisable: false,
      amountNumbers: [],
      formData: {
        name: {},
        description: {},
        field_of_dreams: {
          word: '',
          prompt: {},
          select_dictionary: false
        }
      },
      charactersList: ['2-4', '4-6', '6-8', '8-10', '10-12'],
      videoType: 'video_iframe',
      fileErrorMessage: '',
      uploadedVideoFile: null,
      isCreate: false,
      taskId: null,
      gallery: [],
    }
  },
  components: {
    TaskBase,
    Breadcrumbs,
    VideoForm,
    FormCard,
    FileUpload
  },
  computed: {
    ...mapState({
      languages: state => state.language.languages,
      task: state => state.taskFieldDreams.task,
      taskType: state => state.taskType.taskType
    })
  },
  created () {
    this.taskId = this.$route.params.id
    if (this.taskId === 'add') {
      this.isCreate = true
    } else {
      this.isCreate = false
      this.getTask(this.taskId).then(res => {
        this.formData = this.task.task
        this.formData.field_of_dreams = this.task.word
        if (this.formData.field_of_dreams.prompt === null) {
          this.formData.field_of_dreams.prompt = {}
        }
        this.amountNumbers = this.task.word.amount_numbers
        if (this.formData.field_of_dreams.select_dictionary) {
          this.formData.field_of_dreams.select_dictionary = true
        } else {
          this.formData.field_of_dreams.select_dictionary = false
        }
      })
    }
  },
  methods: {
    ...mapActions({
      createTask: 'taskFieldDreams/createTask',
      updateTask: 'taskFieldDreams/updateTask',
      getTask: 'taskFieldDreams/getTask',
      getTaskType: 'taskType/getTaskType'
    }),
    selectCharacters (amountNumbers) {
      this.amountNumbers = amountNumbers
    },
    customLabel ({ name, desc }) {
      return `${name[this.$i18n.locale]}`
    },
    afterValidation(formData){
      formData.append('select_dictionary', this.formData.field_of_dreams.select_dictionary)
      if (this.formData.field_of_dreams.select_dictionary) {
        formData.append('field_of_dreams[amount_numbers]', this.amountNumbers)
      }
    },
  }
}
</script>
