import { http } from '../../../api'

const courseVideo = {
  namespaced: true,
  state: {
    courseVideosList: [],
    courseVideo: {}
  },
  actions: {
    getCourseVideoListByCourse: ({ commit }, params) => {
      return http.get('/course-video/' + params + '/list').then(res => {
        commit('setCourseVideosList', res.data.data)
        return res.data
      })
    },
    getCourseVideo: ({ commit }, id) => {
      return http.get('/course-video/' + id).then(res => {
        commit('setCourseVideo', res.data.data)
        return res.data
      })
    },
    createCourseVideo: ({ commit }, params) => {
      return http.post('/course-video', params).then(res => {
        return res.data
      })
    },
    updateCourseVideo: ({ commit }, params) => {
      return http.post('/course-video/' + params.id, params.data).then(res => {
        return res.data
      })
    },
    deleteVideo: ({ commit }, id) => {
      return http.delete('/course-video/' + id).then(res => {
        if (res.data.deleteType === 'forceDelete') {
          commit('removeCourseVideo', id)
        } else {
          commit('updateCourseVideoStatus', { id: id, status: 'inactive' })
        }
      })
    },
    restoreVideo: ({ commit }, id) => {
      return http.get('/course-video/' + id + '/restore').then(res => {
        commit('updateCourseVideoStatus', { id: id, status: 'active' })
      })
    }
  },
  mutations: {
    setCourseVideo (state, data) {
      state.courseVideo = data
    },
    setCourseVideosList (state, data) {
      state.courseVideosList = data
    },
    updateCourseVideoStatus (state, data) {
      state.courseVideosList = state.courseVideosList.map(module => {
        if (module.id === data.id) {
          module.status = data.status
        }
        return module
      })
    },
    removeCourseVideo (state, id) {
      state.courseVideosList = state.courseVideosList.filter(module => module.id !== id)
    }
  },
  getters: {
    courseVideosList: state => state.courseVideosList,
    courseVideo: state => state.courseVideo
  }
}

export default courseVideo
