import { http } from '../../../api'

const teachingAudio = {
  namespaced: true,
  state: {
    teachingAudiosList: [],
    teachingAudio: null
  },
  actions: {
    getAllTeachingAudios: ({ commit }, params) => {
      let url = '/teaching-audio'
      if (params) {
        const q = new URLSearchParams(params)
        url = url + '?' + q.toString()
      }
      return http.get(url).then(res => {
        commit('setAudiosList', res.data.data)
        return res.data
      })
    },
    createTeachingAudio: ({ commit }, params) => {
      return http.post('/teaching-audio', params).then(res => {
        return res.data
      })
    },
    deleteTeachingAudio: ({ commit }, params) => {
      return http.delete('/teaching-audio/' + params.id).then(res => {
        if (res.data.deleteType === 'forceDelete') {
          commit('setAudioAfterDelete', params.id)
        } else {
          commit('setUpdateAudioStatus', { id: params.id, status: 'inactive' })
        }
      })
    },
    restoreTeachingAudio: ({ commit }, params) => {
      return http.get('/teaching-audio/' + params.id + '/restore').then(res => {
        commit('setUpdateAudioStatus', { id: params.id, status: 'active' })
      })
    },
    getTeachingAudio: ({ commit }, params) => {
      return http.get('/teaching-audio/' + params.id).then(res => {
        commit('setAudio', res.data.data)
      })
    },
    updateTeachingAudio: ({ commit }, params) => {
      return http.post('/teaching-audio/' + params.id, params.data).then(res => {
      })
    }
  },
  mutations: {
    setUpdateAudioStatus (state, data) {
      state.teachingAudiosList = state.teachingAudiosList.map(item => {
        if (item.id === data.id) {
          item.status = data.status
        }
        return item
      })
    },
    setAudiosList (state, data) {
      state.teachingAudiosList = data
    },
    setAudioAfterDelete (state, id) {
      state.teachingAudiosList = state.teachingAudiosList.filter(item => item.id !== id)
    },
    setAudio (state, data) {
      state.teachingAudio = data
    }
  },
  getters: {
    teachingAudiosList: state => state.teachingAudiosList,
    teachingAudio: state => state.teachingAudio
  }
}

export default teachingAudio
