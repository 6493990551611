import { http } from '../../../api'

const taskTest = {
  namespaced: true,
  state: {
    task: null
  },
  actions: {
    createTask: ({ commit }, params) => {
      return http.post('/task-question', params).then(res => {
        return res.data
      })
    },
    getTask: ({ commit }, params) => {
      return http.get('/task-question/' + params).then(res => {
        commit('setTask', res.data)
      })
    },
    updateTask: ({ commit }, params) => {
      return http.post('/task-question/' + params.id, params.data).then(res => {
      })
    },
    deleteFileTask: ({ commit }, id) => {
      return http.delete('/task-question/' + id + '/delete-file')
    },
    deleteAnswers: ({ commit }, params) => {
      return http.post('/task-question/delete-answers', params).then(res => {
      })
    },
    deleteQuestions: ({ commit }, params) => {
      return http.post('/task-question/delete-questions', params).then(res => {
      })
    }
  },
  mutations: {
    setTask (state, data) {
      state.task = data
    }
  },
  getters: {
    task: state => state.task
  }
}

export default taskTest
