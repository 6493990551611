import { http } from '../../../api'

const courseTest = {
  namespaced: true,
  state: {
    courseTestsList: [],
    courseTest: [],
    testTasksList: []
  },
  actions: {
    getCourseTestsListByCourse: ({ commit }, params) => {
      return http.get('/course-test/' + params + '/list').then(res => {
        commit('setCourseTestsList', res.data.data)
        return res.data
      })
    },
    getTestTasksList: ({ commit }, params) => {
      return http.get('/course-test/' + params + '/task-list').then(res => {
        commit('setTestTasksList', res.data.data)
        return res.data
      })
    },
    getCourseTest: ({ commit }, id) => {
      return http.get('/course-test/' + id).then(res => {
        commit('setCourseTest', res.data.data)
        return res.data
      })
    },
    createCourseTest: ({ commit }, params) => {
      return http.post('/course-test', params).then(res => {
        return res.data
      })
    },
    updateCourseTest: ({ commit }, params) => {
      return http.put('/course-test/' + params.id, params.data).then(res => {
        return res.data
      })
    },
    deleteTest: ({ commit }, id) => {
      return http.delete('/course-test/' + id).then(res => {
        if (res.data.deleteType === 'forceDelete') {
          commit('removeCourseTest', id)
        } else {
          commit('updateCourseTestStatus', { id: id, status: 'inactive' })
        }
      })
    },
    restoreTest: ({ commit }, id) => {
      return http.get('/course-test/' + id + '/restore').then(res => {
        commit('updateCourseTestStatus', { id: id, status: 'active' })
      })
    },
    deleteTestTask: ({ commit }, params) => {
      return http.delete('/task/' + params).then(res => {
        if (res.data.deleteType === 'forceDelete') {
          commit('setTaskAfterDelete', params)
        } else {
          commit('setUpdateTaskStatus', { id: params, status: 'inactive' })
        }
      })
    },
    restoreTestTask: ({ commit }, params) => {
      return http.get('/task/' + params + '/restore').then(res => {
        commit('setUpdateTaskStatus', { id: params, status: 'active' })
      })
    }
  },
  mutations: {
    setUpdateTaskStatus (state, data) {
      state.testTasksList = state.testTasksList.map(item => {
        if (item.id === data.id) {
          item.status = data.status
        }
        return item
      })
    },
    setTaskAfterDelete (state, id) {
      state.testTasksList = state.testTasksList.filter(item => item.id !== id)
    },
    setCourseTest (state, data) {
      state.courseTest = data
    },
    setTestTasksList (state, data) {
      state.testTasksList = data
    },
    setCourseTestsList (state, data) {
      state.courseTestsList = data
    },
    updateCourseTestStatus (state, data) {
      state.courseTestsList = state.courseTestsList.map(module => {
        if (module.id === data.id) {
          module.status = data.status
        }
        return module
      })
    },
    removeCourseTest (state, id) {
      state.courseTestsList = state.courseTestsList.filter(module => module.id !== id)
    }
  },
  getters: {
    courseTestsList: state => state.courseTestsList,
    courseTest: state => state.courseTest
  }
}

export default courseTest
