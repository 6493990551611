<template>
  <div>
    <div class="page-header">
      <div>
        <h2 class="main-content-title tx-24 mg-b-5">Детали курса</h2>
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="#" @click="$router.go(-1)">Назад</a></li>
          <li class="breadcrumb-item active" aria-current="page">{{ course.name[$i18n.locale] }}</li>
        </ol>
      </div>
      <div class="d-flex">
        <div class="justify-content-center">
          <router-link :to="'/courses/' + $route.params.id" type="button" class="btn btn-primary my-2 btn-icon-text">
            Редактировать курс
          </router-link>
        </div>
      </div>
    </div>
    <div class="row row-sm">
      <div class="col-lg-12">
        <div class="card custom-card">
          <div class="card-body">
            <div v-if="course">
              <b-tabs content-class="mt-3">
                <b-tab title="Детали курса" active>
                  <div class="d-flex flex-column">
                    <div class="d-flex mt-1">
                      <p class="font-weight-bold mr-2">Название:</p>{{ course.name[$i18n.locale] }}
                    </div>
                    <div class="d-flex mt-1">
                      <p class="font-weight-bold mr-2">Описание:</p>
                      <div v-html="course.description[$i18n.locale]"></div>
                    </div>
                    <div class="d-flex mt-1">
                      <p class="font-weight-bold mr-2">Это бесплатно:</p>{{ course.is_free ? 'Да' : 'Нет' }}
                    </div>
                    <div class="d-flex mt-1">
                      <p class="font-weight-bold mr-2">Уровень:</p>{{ course.course_level.name[$i18n.locale] }}
                    </div>
                    <div class="d-flex mt-1">
                      <p class="font-weight-bold mr-2">Тариф:</p>
                      <div class="d-flex flex-column">
                        <span v-for="(tariff, i) in course.tariffs" :key="i">{{ tariff.name[$i18n.locale] }}</span>
                      </div>
                    </div>
                    <div class="d-flex mt-1">
                      <p class="font-weight-bold mr-2">Язык обучения:</p>
                      {{ course.teaching_language.name[$i18n.locale] }}
                    </div>
                    <div class="d-flex mt-1">
                      <p class="font-weight-bold mr-2">Изображения:</p>
                      <img width="100px" height="100px" :src="course.image_path" alt="course-logo"/>
                    </div>
                    <div class="d-flex mt-1">
                      <p class="font-weight-bold mr-2">Анонс:</p>{{ course.announcement.title[$i18n.locale] }}
                    </div>
                  </div>
                </b-tab>
                <b-tab title="Модули">
                  <div class="accordion" role="tablist" v-if="course.course_modules && course.course_modules.length">
                    <b-card no-body class="mb-1" v-for="(module, index) in course.course_modules" :key="index">
                      <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button block v-b-toggle="'accordion-' + index" variant="dark">Module {{ index + 1 }}:
                          {{ module.name[$i18n.locale] }}
                        </b-button>
                      </b-card-header>
                      <b-collapse :id="'accordion-' + index" accordion="accordion-module" role="tabpanel">
                        <b-card-body>
                          <div class="d-flex flex-column">
                            <div class="d-flex mt-1">
                              <p class="font-weight-bold mr-2">Название:</p>{{ module.name[$i18n.locale] }}
                            </div>
                            <div class="d-flex mt-1">
                              <p class="font-weight-bold mr-2">Описание:</p>
                              <div v-html="module.description[$i18n.locale]"></div>
                            </div>
                          </div>
                          <div class="accordion" role="tablist" v-if="module && module.lessons.length">
                            <b-card no-body class="mb-1" v-for="(lesson, i) in module.lessons" :key="i">
                              <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block v-b-toggle="'accordion-lesson-' + i" variant="info">Lesson {{ i + 1 }}:
                                  {{ lesson.name[$i18n.locale] }}
                                </b-button>
                              </b-card-header>
                              <b-collapse :id="'accordion-lesson-' + i" accordion="accordion-lesson" role="tabpanel">
                                <b-card-body>
                                  <div class="d-flex flex-column">
                                    <div class="d-flex mt-1">
                                      <p class="font-weight-bold mr-2">Название:</p>{{ lesson.name[$i18n.locale] }}
                                    </div>
                                    <div class="d-flex mt-1">
                                      <p class="font-weight-bold mr-2">Описание:</p>
                                      <div v-html="lesson.description[$i18n.locale]"></div>
                                    </div>
                                    <div class="d-flex mt-1">
                                      <p class="font-weight-bold mr-2">Это бесплатно:</p>
                                      {{ lesson.is_free ? 'Да' : 'Нет' }}
                                    </div>
                                  </div>
                                  <div class="accordion" role="tablist" v-if="lesson && lesson.lesson_blocks.length">
                                    <b-card no-body class="mb-1" v-for="(lesson_block, i) in lesson.lesson_blocks"
                                            :key="i">
                                      <b-card-header header-tag="header" class="p-1" role="tab">
                                        <b-button block v-b-toggle="'accordion-lesson-block' + i" variant="success">
                                          Lesson Block {{ i + 1 }}: {{ lesson_block.name[$i18n.locale] }}
                                        </b-button>
                                      </b-card-header>
                                      <b-collapse :id="'accordion-lesson-block' + i" accordion="accordion-lesson"
                                                  role="tabpanel">
                                        <b-card-body>
                                          <div class="d-flex flex-column">
                                            <div class="d-flex mt-1">
                                              <p class="font-weight-bold mr-2">Название:</p>
                                              {{ lesson_block.name[$i18n.locale] }}
                                            </div>
                                            <div class="d-flex mt-1">
                                              <p class="font-weight-bold mr-2">Описание:</p>
                                              <div v-html="lesson_block.description[$i18n.locale]"></div>
                                            </div>
                                          </div>
                                        </b-card-body>
                                      </b-collapse>
                                    </b-card>
                                  </div>
                                </b-card-body>
                              </b-collapse>
                            </b-card>
                          </div>
                          <div v-else class="w-100 text-center">Нет уроков</div>
                        </b-card-body>
                      </b-collapse>
                    </b-card>
                  </div>
                  <div v-else class="w-100 text-center">Нет модулей</div>
                </b-tab>
                <b-tab title="Покупатели" active>
                  <div class="table-responsive">
                    <b-table v-if="course.users && course.users.length" striped hover :items="course.users"
                             :fields="usersTableFields">
                      <template #cell(avatar_path)="row">
                        <img v-if="row.item.avatar_path" class="img-thumbnail table-image" :src="row.item.avatar_path"
                             alt="avatar">
                        <img v-else class="img-thumbnail table-image" src="../../../assets/img/users/default.png"
                             alt="avatar">
                      </template>
                      <template #cell(first_name)="row">
                        <div>{{ row.item.first_name }} {{ row.item.last_name }}</div>
                      </template>
                      <template #cell(email_verified_at)="row">
                        <div>{{ row.item.email_verified_at ? 'Да' : 'Нет' }}</div>
                      </template>
                      <template #cell(actions)="row">
                        <router-link
                          :to="'/courses/'+$route.params.id +'/' + row.item.pivot.id + '/chat-expert/' + row.item.id"
                          class="btn btn-outline-primary mr-2 mt-1 mb-1">
                          <i class="fe fe-message-square"></i>
                        </router-link>
                        <button @click="resetProgress(row.item.id)" type="button"
                                class="btn btn-outline-warning mr-2 mt-1 mb-1">
                          <i class="ti-reload"></i>
                        </button>
                        <button @click="reset(row.item.id)" type="button"
                                class="btn btn-outline-danger mr-2 mt-1 mb-1">
                          <i class="ti-trash"></i>
                        </button>
                      </template>
                    </b-table>
                    <p class="text-danger text-center" v-else>Не найдено покупателей для этого курса.</p>
                    <div class="d-flex justify-content-end  mt-3">
                      <div class="mr-3">
                        <b-form-select
                          @change="changePaginationPerPage"
                          style="width: 80px"
                          v-model="paginationPerPage"
                          :options="paginationOptions"
                        >
                        </b-form-select>
                      </div>
                      <div>
                        <b-pagination
                          class="m-0"
                          @change="changePagination"
                          v-model="paginationCurrentPage"
                          :total-rows="pagination.count"
                          :per-page="paginationPerPage"
                          first-text="<<"
                          prev-text="<"
                          next-text=">"
                          last-text=">>"
                        ></b-pagination>
                      </div>
                    </div>
                  </div>
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal ref="userChatModal" hide-footer centered title="Chat">
      <div class="d-block text-center">
        <div class="card custom-card mb-0">
          <div class="pt-0">
            <div class="main-content-body main-content-body-chat">
              <div class="main-chat-header pt-3" v-if="selectedUser && selectedUser !== null">
                <div class="main-img-user online">
                  <img v-if="selectedUser.avatar_path" class="img-thumbnail table-image" :src="selectedUser.avatar_path"
                       alt="avatar">
                  <img v-else class="img-thumbnail table-image" src="../../../assets/img/users/default.png"
                       alt="avatar">
                </div>
                <div class="main-chat-msg-name">
                  <h6 v-if="selectedUser">{{ selectedUser.first_name + ' ' + selectedUser.last_name }}</h6>
                </div>
                <nav class="nav">
                  <a class="nav-link" href="" data-toggle="dropdown" role="button" aria-haspopup="true"
                     aria-expanded="false">
                    <i class="fe fe-more-horizontal"></i>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right shadow">
                    <a class="dropdown-item" href="#"><i class="fe fe-phone-call mr-1"></i> Телефонный звонок</a>
                    <a class="dropdown-item" href="#"><i class="fe fe-video mr-1"></i> Видеозвонок</a>
                  </div>
                  <a class="nav-link" data-toggle="tooltip" href="" title="" data-original-title="Phone Call">
                    <i class="fe fe-phone-call"></i>
                  </a>
                  <a class="nav-link" data-toggle="tooltip" href="" title="" data-original-title="Video Call">
                    <i class="fe fe-video"></i>
                  </a>
                </nav>
              </div><!-- main-chat-header -->
              <div class="main-chat-body ps--active-y" id="ChatBody">
                <video-chat :user="selectedUser"/>
                <div class="content-inner" v-chat-scroll>
                  <div class="media" :class="message.sender_is_user ? '' : 'flex-row-reverse' "
                       v-for="(message, index) in userChatMessages" :key="index">
                    <div class="main-img-user online" v-if="message.sender_is_user">
                      <img v-if="message.user && message.user.avatar" :src="message.user.avatar_path" alt="avatar">
                      <img v-else src="../../../assets/img/users/default.png" alt="avatar">
                    </div>
                    <div class="main-img-user online" v-else>
                      <img v-if="message.admin && message.admin.avatar" :src="message.admin.avatar_path" alt="avatar">
                      <img v-else src="../../../assets/img/users/default.png" alt="avatar">
                    </div>
                    <div class="media-body">
                      <div class="main-msg-wrapper">{{ message.message }}</div>
                      <div><span>{{ message.created_at | moment('DD.MM.YYYY | HH:mm') }}</span></div>
                      <div v-if="message.sender_is_user"></div>
                      <div v-else></div>
                    </div>
                  </div>
                </div>
              </div>
              <form class="main-chat-footer" @submit.prevent="sendUserChatForm()">
                <textarea v-model="userChatFormData.message" class="form-control"
                          placeholder="Type your message here..." type="text"></textarea>
                <button class="main-msg-send btn btn-outline-light" type="submit" :disabled="disableButtonChatForm"><i
                  class="far fa-paper-plane"></i></button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import VideoChat from '../../components/VideoChat'
import ToastMixin from '../../../mixins/ToastMixin'

export default {
  name: 'CourseDetail',
  mixins: [ToastMixin],
  computed: {
    ...mapState({
      course: state => state.course.course,
      users: state => state.user.users,
      userPagination: state => state.user.userPagination
    }),
    usersTableFields () {
      return [
        {
          key: 'id',
          label: 'Id',
          sortable: true
        },
        {
          key: 'avatar_path',
          label: 'Фото',
          sortable: false
        },
        {
          key: 'first_name',
          label: 'Имя',
          sortable: true
        },
        {
          key: 'email',
          label: 'Почта',
          sortable: true
        },
        {
          key: 'email_verified_at',
          label: 'Подтверждение почты',
          sortable: true
        },
        {
          key: 'login',
          label: 'Логин',
          sortable: true
        },
        {
          key: 'phone',
          label: 'Телефон',
          sortable: true
        },
        {
          key: 'birthday',
          label: 'Дата рождения',
          sortable: true
        },
        {
          key: 'gender',
          label: 'Пол',
          sortable: true
        },
        {
          key: 'actions',
          label: 'Действие',
          sortable: false
        }
      ]
    }
  },
  components: {
    VideoChat
  },
  data () {
    return {
      paginationOptions: [
        {
          value: 6,
          text: '6'
        },
        {
          value: 10,
          text: '10'
        },
        {
          value: 30,
          text: '30'
        },
        {
          value: 100,
          text: '100'
        }
      ],
      paginationCurrentPage: 1,
      paginationPerPage: 6,
      userChatMessages: [],
      userChatFormData: {},
      disableButtonChatForm: false,
      selectedUser: null,
      pagination: {}
    }
  },
  created () {
    this.getCourse({ id: this.$route.params.id }).then(res => {
    })
  },
  methods: {
    ...mapActions({
      getCourse: 'course/getCourse',
      getMessages: 'chatExpert/getMessages',
      sendMessage: 'chatExpert/sendMessage',
      cancelBuy: 'course/cancelBuy',
      resetCourse: 'user/resetProgress'
    }),
    resetProgress(user_id){
      this.ToastShow('Вы точно хотите удалить прогресс?', 'warning', () => {
        const params = {
          user_id,
          course_id: this.$route.params.id
        }

        this.resetCourse(params).then(res => {
          this.$toasted.success(res.message).goAway(1500)
        }).catch((e) => {
          this.$toasted.error('Что-то пошло не так, повторите позже').goAway(1500)
        })
      })
    },
    reset (user_id) {
      this.ToastShow('Вы точно хотите отменить покупку курса?', 'warning', () => {
        const params = {
          user_id,
          course_id: this.$route.params.id
        }

        this.cancelBuy(params).then(res => {
          this.$toasted.success(res.message).goAway(1500)
        }).catch((e) => {
          this.$toasted.error('Что-то пошло не так, повторите позже').goAway(1500)
        })
      })
    },
    changePaginationPerPage (value) {
      this.getUsersList({
        page: this.paginationCurrentPage,
        count: value
      })
    },
    openUserChatModal (user) {
      this.selectedUser = user
      this.$refs.userChatModal.show()
      this.getMessages({
        course_id: this.$route.params.id,
        user_id: user.id
      }).then(res => {
        setTimeout(() => {
          const chatContainer = document.getElementById('ChatBody')
          chatContainer.scrollTop = chatContainer.scrollHeight
        }, 500)
        this.userChatMessages = res.data
        this.listenForChatExpertNewMessage(user.pivot.id)
      })
    },
    hideUserChatModal () {
      this.$refs.userChatModal.hide()
    },
    listenForChatExpertNewMessage (userCourseID) {
      window.Echo.join(`chat-expert.${userCourseID}`)
        .here((users) => {
          console.log(users)
        })
        .joining((user) => {
          console.log(user)
        })
        .leaving((user) => {
          console.log(user)
        })
        .listen('ChatExpertMessageSent', (e) => {
          this.userChatMessages.push(e.data)
        })
    },
    sendUserChatForm () {
      if (this.userChatFormData.message !== '') {
        this.disableButtonChatForm = true
        this.userChatFormData.course_id = this.$route.params.id
        this.userChatFormData.user_id = this.selectedUser.id
        this.sendMessage(this.userChatFormData).then(res => {
          this.userChatFormData = {}
          this.userChatMessages.push(res.data)
        }).catch(() => {
          this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
        }).finally(() => {
          this.disableButtonChatForm = false
        })
      }
    },
    changePagination (page) {
      this.getUsersList({
        page: page,
        count: this.paginationPerPage
      })
    }
  }
}
</script>

<style>
p {
  margin: 0;
}
</style>
