import { http } from '../../../api'

const announcement = {
  namespaced: true,
  state: {
    announcementList: [],
    announcement: null
  },
  actions: {
    getAllAnnouncement: ({ commit }, params) => {
      let url = '/announcement'
      if (params) {
        const q = new URLSearchParams(params)
        url = url + '?' + q.toString()
      }
      return http.get(url).then(res => {
        commit('setAnnouncementList', res.data.data)
        return res.data
      })
    },
    createAnnouncement: ({ commit }, params) => {
      return http.post('/announcement', params).then(res => {
        return res.data
      })
    },
    updateAnnouncement: ({ commit }, params) => {
      return http.post('/announcement/' + params.id + '/update', params.data).then(res => {
        return res.data
      })
    }
  },
  mutations: {
    setAnnouncementList (state, data) {
      state.announcementList = data
    }
  },
  getters: {
    announcementList: state => state.announcementList,
    announcement: state => state.announcement
  }
}

export default announcement
