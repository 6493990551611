<template>
  <div>
    <div class="page-header">
      <div>
        <h2 class="main-content-title tx-24 mg-b-5">{{isCreate ? 'Добавить' : 'Редактировать'}} FAQ категорию</h2>
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="#" @click="$router.go(-1)">Назад</a></li>
          <li class="breadcrumb-item active" aria-current="page">{{isCreate ? 'Добавить' : 'Редактировать'}} FAQ категорию</li>
        </ol>
      </div>
      <div class="d-flex">
      </div>
    </div>
    <div class="row row-sm justify-content-center">
      <div class="col-lg-6 col-md-12">
        <div class="card custom-card">
          <div class="card-body">
            <div>
              <h6 class="main-content-label mb-1">FAQ категория</h6>
            </div>
            <div>
              <ValidationObserver tag="form" ref="faqCategoryForm" class="d-flex flex-column" @submit.prevent="formSubmit()">
                <b-tabs content-class="mt-3">
                  <b-tab :title="language.code" v-for="(language, i) in languages" :key="i">
                      <ValidationProvider v-slot="{ classes }" rules="required" tag="div" class="form-group">
                        <input class="form-control" v-model="formData.name[language.code]" :class="classes" placeholder="Название" type="text">
                      </ValidationProvider>
                      <button class="btn ripple btn-main-primary">{{isCreate?'Сохранить':'Обновить'}}</button>
                  </b-tab>
                </b-tabs>
              </ValidationObserver>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'FaqCategoryAdd',
  data () {
    return {
      formData: {
        name: {}
      },
      faqCategoryId: '',
      isCreate: false
    }
  },
  created () {
    this.getLanguages().catch(() => {
      this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
    })
    this.faqCategoryId = this.$route.params.id
    if (this.faqCategoryId === 'add') {
      this.isCreate = true
    } else {
      this.getFaqCategory(this.faqCategoryId).then(res => {
        this.formData = res.data
      })
    }
  },
  computed: {
    ...mapState({
      languages: state => state.language.languages
    })
  },
  methods: {
    ...mapActions({
      createFaqCategory: 'faqCategory/create',
      updateFaqCategory: 'faqCategory/update',
      getLanguages: 'language/getLanguages',
      getFaqCategory: 'faqCategory/getFaqCategory'
    }),
    formSubmit () {
      this.$refs.faqCategoryForm.validate().then(success => {
        if (success) {
          if (this.isCreate) {
            this.createFaqCategory(this.formData).then(() => {
              this.$router.push('/faq-category')
            }).catch(() => {
              this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
            })
          } else {
            this.updateFaqCategory({ data: this.formData, id: this.faqCategoryId }).then(() => {
              this.$router.push('/faq-category')
            }).catch(() => {
              this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
            })
          }
        } else {
          this.$toasted.error(this.$t('messages.error.validationError')).goAway(1500)
        }
      })
    }
  }
}
</script>
