<template>
  <div>
    <div class="page-header">
      <div>
        <h2 class="main-content-title tx-24 mg-b-5">Купоны</h2>
      </div>
      <div class="d-flex">
        <div class="justify-content-center">
          <router-link to="/coupons/add" type="button" class="btn btn-primary my-2 btn-icon-text">
            <i class="fe fe-plus-square mr-2"></i> Добавить
          </router-link>
        </div>
      </div>
    </div>
    <div class="row row-sm">
      <div class="col-lg-12">
        <div class="card custom-card">
          <div class="card-body">
            <div>
              <h6 class="main-content-label mb-1">Список</h6>
            </div>
            <div class="table-responsive">
              <b-table striped hover :items="coupons" :fields="tableFields">
                <template #cell(actions)="row">
                  <div style="min-width: 150px">
                    <router-link :to="`/coupons/${row.item.id}`" class="btn btn-outline-primary">
                      <i class="fe fe-edit"></i>
                    </router-link>
                    <router-link  :to="`/coupons/${row.item.id}/statistics?name=${row.item.name}`" class="btn btn-outline-primary ml-2">
                      <i class="fe fe-file-text"></i>
                    </router-link>
                    <button @click="Delete(row.item.id)" type="button"
                            class="btn btn-outline-danger ml-2">
                      <i class="ti-close"></i>
                    </button>
                  </div>

                </template >
              </b-table>
              <div class="d-flex justify-content-end  mt-3" v-if="pagination && pagination.last_page > 1">
                <div>
                  <b-pagination
                    class="m-0"
                    @change="getCoupons"
                    v-model="paginationCurrentPage"
                    :total-rows="pagination.total"
                    :per-page="pagination.per_page"
                    first-text="<<"
                    prev-text="<"
                    next-text=">"
                    last-text=">>"
                  ></b-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Swal from 'sweetalert2'

export default {
  name: 'Coupons',
  computed: {
    ...mapGetters({
      coupons: 'coupon/coupons',
    }),
    tableFields () {
      return [
        {
          key: 'id',
          label: 'Id',
          sortable: true
        },
        {
          key: 'name',
          label: 'Название',
          sortable: true
        },
        {
          key: 'code',
          label: 'Промо код',
          sortable: true
        },
        {
          key: 'is_percent',
          label: 'Тип операции',
          sortable: true,
          formatter: (value, key, item) => {
            return item.is_percent ? 'Процент' : 'Вычитание суммы'
          }
        },
        {
          key: 'price',
          label: 'Сумма',
          sortable: true
        },
        {
          key: 'start',
          label: 'Дата начала работы купона',
          sortable: true
        },
        {
          key: 'end',
          label: 'Дата окончания купона',
          sortable: true
        },
        {
          key: 'count',
          label: 'Количество применений купона',
          sortable: true
        },
        {
          key: 'count_user',
          label: 'Количество применений купона одним пользователем',
          sortable: true
        },
        {
          key: 'is_active',
          label: 'Статус',
          sortable: true,
          formatter: (value, key, item) => {
            return item.is_active ? 'Включен' : 'Отключен'
          }
        },
        {
          key: 'actions',
          label: 'Действие',
          sortable: false
        }
      ]
    }
  },
  data () {
    return {
      paginationCurrentPage: 1,
      pagination: null,
    }
  },
  created () {
    this.getCoupons({ page: this.paginationCurrentPage })
      .then(res => {
        this.pagination = res.meta
      })
      .catch(() => {
      this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
    })
  },
  methods: {
    ...mapActions({
      getCoupons: 'coupon/get',
      delete: 'coupon/delete',
    }),
    Delete (id) {
      Swal.fire({
        text: this.$t('messages.warning.delete'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#748C41',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('buttons.yes'),
        cancelButtonText: this.$t('buttons.no')
      }).then((result) => {
        if (result.value) {
          this.delete(id).then(res => {
            this.$toasted.success('Успешно удален').goAway(1500)
          }).catch(() => {
            this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
          })
        }
      })
    }
  }
}
</script>
<style>

</style>
