<template>
  <div>
    <div class="page-header">
      <Breadcrumbs name="аудио обучения"/>
      <div class="d-flex">
      </div>
    </div>
    <div class="row row-sm justify-content-center">
      <div class="col-xl-6 col-lg-8 col-md-12">
        <div class="card custom-card">
          <div class="card-body">
            <div>
              <h6 class="main-content-label mb-1">Аудио обучения</h6>
            </div>
            <div v-if="showVideo === true">
              <ValidationObserver ref="teachingAudioAddForm" id="teachingAudioAddForm" tag="form"
                                  class="d-flex flex-column" @submit.prevent="formSubmit()">
                <b-tabs content-class="mt-3">
                  <b-tab :title="language.code" v-for="(language, i) in languages" :key="i">
                    <ValidationProvider v-slot="{ classes }" rules="required|max:120" tag="div" class="form-group">
                      <label :for="'name[' + language.code + ']'" class="form-label">Название:</label>
                      <input :id="'name[' + language.code + ']'" class="form-control"
                             :name="'name[' + language.code + ']'" v-model="formData.name[language.code]"
                             :class="checkValidation(classes, language.code)" placeholder="Название" type="text">
                    </ValidationProvider>
                    <ValidationProvider v-slot="{ classes }" rules="max:500" tag="div" class="form-group">
                      <label :for="'descriptions[' + language.code + ']'" class="form-label">Описание:</label>
                      <textarea class="form-control" :name="'description[' + language.code + ']'"
                                :id="'descriptions[' + language.code + ']'" placeholder="Description"
                                :class="checkValidation(classes, language.code)"
                                v-model="formData.description[language.code]"></textarea>
                    </ValidationProvider>
                  </b-tab>
                  <FileUpload :is-multiple="false" uploadType="audio" label="Аудио:" name="audio" accept="audio/*"
                              :validation="true" :filesData="formData.audio"/>
                  <ValidationProvider name="Курс" v-slot="{ classes, errors }" rules="required" tag="div" class="form-group">
                    <label for="course_id" class="form-label">Курс:</label>
                    <b-select
                      :class="classes"
                      id="course_id"
                      value-field="id"
                      :text-field="`name.${$i18n.locale}`"
                      :options="courses"
                      name="course_id"
                      v-model="formData.course_id"
                    />
                    <div class="invalid-feedback">{{errors[0]}}</div>
                  </ValidationProvider>
                  <span class="text-danger" v-if="fileErrorMessage">{{ fileErrorMessage }}</span>
                </b-tabs>
                <button class="btn ripple btn-main-primary mt-2" :disabled="buttonDisable"
                        :class="buttonDisable ? 'button-readonly' : ''"><ButtonName/>
                </button>
              </ValidationObserver>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import FileUpload from '../../components/FileUpload'
import Breadcrumbs from '../../components/Breadcrumbs'
import ButtonName from '../../components/ButtonName'

export default {
  name: 'TeachingAudioAdd',
  data () {
    return {
      showVideo: false,
      isEdit: false,
      buttonDisable: false,
      formData: {
        name: {},
        description: {},
        audio: null
      },
      isCreate: false,
      teachingAudioId: '',
      uploadedVideoFile: null,
      fileErrorMessage: ''
    }
  },
  components: {
    ButtonName,
    Breadcrumbs,
    FileUpload
  },
  computed: {
    ...mapState({
      languages: state => state.language.languages,
      teachingAudio: state => state.teachingAudio.teachingAudio,
    }),
    ...mapGetters({
      courses: 'course/activeCoursesList'
    })
  },
  created () {
    this.teachingAudioId = this.$route.params.id
    if (this.teachingAudioId === 'add') {
      this.isEdit = false
      this.isCreate = true
      this.showVideo = true
    } else {
      this.isEdit = true
      this.getTeachingAudio({ id: this.teachingAudioId }).then(res => {
        this.showVideo = true
        this.formData = this.teachingAudio
      })
    }
    this.getCourses()
  },
  methods: {
    ...mapActions({
      createTeachingAudio: 'teachingAudio/createTeachingAudio',
      getTeachingAudio: 'teachingAudio/getTeachingAudio',
      updateTeachingAudio: 'teachingAudio/updateTeachingAudio',
      getCourses: 'course/getActiveCoursesList'
    }),
    checkValidation (data) {
      return data
    },
    formSubmit () {
      this.$refs.teachingAudioAddForm.validate().then(success => {
        if (success) {
          const form = document.getElementById('teachingAudioAddForm')
          const formData = new FormData(form)
          this.buttonDisable = true

          if (this.isCreate === true) {
            this.createTeachingAudio(formData).then(() => {
              this.$toasted.success(this.$t('messages.success.courseCreated')).goAway(1500)
              this.$router.push('/teaching-audios')
            }).catch(() => {
              this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
            }).finally(() => {
              this.buttonDisable = false
            })
          } else {
            this.updateTeachingAudio({
              data: formData,
              id: this.$route.params.id
            }).then(res => {
              this.$toasted.success(this.$t('messages.success.courseUpdated')).goAway(1500)
              this.$router.push('/teaching-audios')
            }).catch(() => {
              this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
            }).finally(() => {
              this.buttonDisable = false
            })
          }
        } else {
          this.$toasted.error(this.$t('messages.error.validationError')).goAway(1500)
        }
      })
    }
  }
}
</script>
