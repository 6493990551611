<template>
  <TaskBase
    help="Расположите слова и/или изображениля правильно, а система сама перемешает их в случайном порядке для экзаменуемого."
    :data="formData"
    :task-type-id="4"
    :after-validation="afterValidation"
    :before-update-request="beforeUpdateRequest"
    :update="updateTask"
    :create="createTask"
  >
    <div class="row row-sm justify-content-center" v-if="types">
      <div class="col-md-12">
        <div class="card custom-card">
          <div class="card-body">
            <div class=" mb-4">
              <div>
                <h6 class="main-content-label mb-3">Категории</h6>
              </div>
              <div class="form-group">
                <label for="type"> Тип</label>
                <b-form-select
                  v-model="type_id"
                  :options="types"
                  name="type_id"
                  required
                  value-field="id"
                  text-field="name"
                  id="type"
                >
                </b-form-select>
              </div>

              <div class="d-flex overflow-x-auto" v-if="!isCreate && formData.categorize || isCreate">
                <div v-for="(category, index) in formData.categorize.category" :key="category.id"
                     class="category-images position-relative">
                  <input hidden :value="category.id" :name="'categorize[category][' + index + '][id]'">
                  <ValidationProvider v-slot="{ classes }" tag="div" class="form-group">
                    <input class="form-control" :name="'categorize[category][' + index + '][name]'" :class="classes"
                           v-model="category.name" placeholder="" type="text">
                  </ValidationProvider>
                  <span class="p-2 text-danger pointer font-weight-bold position-absolute t-0 r-0"
                        style="width: 32px;" v-if="formData.categorize.category.length > 2"
                        @click="deleteTaskCategory(category, index)"><i class="ti-close"></i></span>
                  <div>
                    <div class="sub-title-height">
                      <h6 class="main-content-label mb-3" v-if="index === 0">Category items</h6>
                    </div>
                    <div>
                      <div v-for="(item, i) in category.category_items" :key="i"
                           class="category-images position-relative">
                        <input hidden :value="item.id"
                               :name="'categorize[category][' + index + '][category_items][' + i + '][id]'">
                        <FileUpload v-if="type === 'images' && (item.image || item.image === null)"
                                    uploadType="oneImageType" :is-multiple="true"
                                    :name="'categorize[category][' + index + '][category_items][' + i + '][image]'"
                                    accept="image/png,image/jpeg,image/jpg" :validation="true"
                                    :filesData="item.image"/>
                        <ValidationProvider v-slot="{ classes }" tag="div" class="form-group">
                          <input
                            v-if="type === 'text'"
                            class="form-control"
                            :name="'categorize[category][' + index + '][category_items][' + i + '][name]'"
                            :class="classes" maxlength="30" v-model="item.name" placeholder="" type="text">
                        </ValidationProvider>
                        <span class="p-2 text-danger pointer font-weight-bold position-absolute t-0 r-0"
                              style="width: 32px;"
                              v-if="category.category_items.length > 1 && index === formData.categorize.category.length - 1"
                              @click="deleteTaskCategoryItem(item, i)"><i class="ti-close"></i></span>
                      </div>
                    </div>
                    <button type="button" class="btn btn-outline-primary btn-add"
                            v-if="index === formData.categorize.category.length - 1" @click="addCategoryItem"><i
                      class="fas fa-plus"></i></button>
                  </div>
                </div>
                <button type="button" class="btn btn-outline-primary btn-add" @click="addCategory"><i
                  class="fas fa-plus"></i></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </TaskBase>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import FileUpload from '../../../components/FileUpload'
import FormCard from '../../../components/FormCard'
import VideoForm from '../../../components/VideoForm'
import CkeditorMixin from '../../../../mixins/CkeditorMixin'
import TaskMixin from '../../../../mixins/TaskMixin'
import Breadcrumbs from '../../../components/Breadcrumbs'
import AudioMixin from '../../../../mixins/AudioMixin'
import TaskBase from '../../../components/tasks/TaskBase'

export default {
  name: 'TaskCategorize',
  mixins: [CkeditorMixin, TaskMixin, AudioMixin],
  data () {
    return {
      removeCategorize: [],
      removeCategorizeItem: [],
      formData: {
        name: {},
        description: {},
        categorize: {
          prompt: {},
          category: [],
        }
      },
      gallery: [],
      videoType: 'video_iframe',
      fileErrorMessage: '',
      uploadedVideoFile: null,
      taskId: null,
      type_id: null,
      selectType: null,
    }
  },
  components: {
    TaskBase,
    Breadcrumbs,
    VideoForm,
    FormCard,
    FileUpload
  },
  computed: {
    ...mapState({
      task: state => state.taskCategorize.task,
    }),
    ...mapGetters({
      types: 'type/types'
    }),

    type () {
      if (!this.type_id) {
        return ''
      }
      return this.types.filter(type => type.id === this.type_id)[0].name
    }
  },
  async created () {
    for (let i = 0; i < 2; i++) {
      this.formData.categorize.category.push({
        name: '',
        image: null,
        category_items: [
          {
            id: null,
            name: '',
            image: null
          },
          {
            id: null,
            name: '',
            image: null
          }
        ]
      })
    }
    this.taskId = this.$route.params.id
    await this.getTypes('App\\Models\\TaskCategorize')

    if (this.taskId === 'add') {
      this.isCreate = true
      this.type_id = this.types[0].id
    } else {
      this.isCreate = false
      this.getTask(this.taskId).then(res => {
        this.formData = { ...this.formData, ...this.task.task }
        this.type_id = this.task.categorize.type_id
        this.formData.categorize.category = this.task.categorize.categories
        this.formData.categorize.prompt = this.task.categorize.prompt
      })
    }
  },
  methods: {
    ...mapActions({
      createTask: 'taskCategorize/createTask',
      updateTask: 'taskCategorize/updateTask',
      deleteCategory: 'taskCategorize/deleteCategory',
      deleteCategoryItem: 'taskCategorize/deleteCategoryItem',
      getTask: 'taskCategorize/getTask',
      getTypes: 'type/get'
    }),
    addCategory () {
      const itemArrLength = this.formData.categorize.category[0].category_items.length
      const arr = []
      for (let i = 0; i < itemArrLength; i++) {
        arr.push({
          id: null,
          name: '',
          image: null
        })
      }
      this.formData.categorize.category.push({
        id: null,
        name: '',
        image: null,
        category_items: arr
      })
    },
    addCategoryItem () {
      for (let i = 0; i < this.formData.categorize.category.length; i++) {
        this.formData.categorize.category[i].category_items.push({
          name: '',
          image: null,
          id: null,
        })
      }
    },
    deleteTaskCategory (category, index) {
      if (category.id) {
        this.formData.categorize.category = this.formData.categorize.category.filter(cat => cat.id !== category.id)
        this.removeCategorize.push(category.id)
      } else {
        this.formData.categorize.category = this.formData.categorize.category.filter((item, i) => i !== index)
      }
    },
    deleteTaskCategoryItem (categoryItem, index) {
      if (categoryItem.id) {
        for (let i = 0; i < this.formData.categorize.category.length; i++) {
          this.removeCategorizeItem.push(this.formData.categorize.category[i].category_items[index].id)
          this.formData.categorize.category[i].category_items = this.formData.categorize.category[i].category_items.filter((item, ind) => ind !== index)
        }
      } else {
        for (let i = 0; i < this.formData.categorize.category.length; i++) {
          this.formData.categorize.category[i].category_items = this.formData.categorize.category[i].category_items.filter((item, ind) => ind !== index)
        }
      }
    },
    afterValidation(formData){
      formData.append('task_type_id', 4)

      if (this.removeCategorize.length) {
        this.deleteCategory({ category_id: this.removeCategorize })
      }

      if (this.removeCategorizeItem.length) {
        this.deleteCategoryItem({ category_item_id: this.removeCategorizeItem })
      }
    },
    beforeUpdateRequest(formData){
      formData.append('categorize_id', this.task.categorize.id)
    },
  },
}
</script>

<style lang="scss">
.category-images {
  min-width: 130px;

  .category-images {
    width: auto;

    .uploaded-files {
      position: absolute;
      top: 0;
    }
  }

  .form-group {
    width: 100px;
  }

  .upload {
    min-height: 100px;
    max-width: 100px;
    z-index: 2;
    background-color: transparent;
    margin-bottom: 0;
  }

  .choose {
    display: none;
  }

  .add {
    width: 50px;
    height: 50px;
    margin-bottom: 0;
  }

  .sub-title-height {
    height: 20px;

    .main-content-label {
      font-size: 12px;
    }
  }

  .uploaded-files {
    position: absolute;
    top: 5px;

    .item {
      border: none;
      border-radius: 10px;
      padding: 0;
      overflow: hidden;
    }
  }
}

.btn-add {
  height: min-content;
  float: right;
}
</style>
