<template>
  <div>
    <div class="page-header">
      <breadcrumbs name="блок урока"/>
      <div class="d-flex">
      </div>
    </div>
    <div class="row row-sm justify-content-center">
      <div class="col-lg-6 col-md-12">
        <div class="card custom-card">
          <div class="card-body">
            <div>
              <h6 class="main-content-label mb-1">Блок урока</h6>
            </div>
            <div>
              <ValidationObserver tag="form" ref="lessonBlockForm" id="lessonBlockForm" class="d-flex flex-column">
                <b-tabs content-class="mt-3">
                  <b-tab :title="language.code" v-for="(language, i) in languages" :key="i">
                    <ValidationProvider v-slot="{ classes }" rules="required" tag="div" class="form-group">
                      <label :for="'name[' + language.code + ']'" class="form-label">Название блока:</label>
                      <input :id="'name[' + language.code + ']'" class="form-control"
                             :name="'name[' + language.code + ']'" v-model="formData.name[language.code]"
                             :class="checkValidation(classes, language.code)" placeholder="Название" type="text">
                    </ValidationProvider>
                    <ValidationProvider v-slot="{ classes }" tag="div" class="form-group">
                      <label :for="'description[' + language.code + ']'" class="form-label">Описание блока:</label>
                      <ckeditor
                        tag-name="textarea"
                        :config="ckEditorConfig"
                        @namespaceloaded="onNamespaceLoaded"
                        :id="'description[' + language.code + ']'"
                        placeholder="Описание"
                        :class="checkValidation(classes, language.code)"
                        v-model="formData.description[language.code]">
                      </ckeditor>
                    </ValidationProvider>
                  </b-tab>
                  <FileUpload v-if="(formData.gallery && !isCreate) || isCreate" page="lessonBlock" :is-multiple="true"
                              uploadType="images" label="Фото:" name="gallery[]"
                              accept="image/png,image/jpeg,image/jpg" :validation="true" :filesData="formData.gallery"/>

                  <FileUpload v-if="audioShow" :is-multiple="false"
                              page="lessonBlock"
                              uploadType="audio"
                              label="Аудио:" name="audio" accept="audio/*" :validation="true"
                              :filesData="audio.path" :audio-id="audio.id"
                              @fileChange="audioChange"
                  />

                  <div class="row">
                    <template>
                      <div v-for="(video, index) in videos" :key="index" class="col-md-11">
                        <VideoForm
                          :radio-name="`video_type[${index}]`"
                          :is-edit="!isCreate"
                          :iframe-name="`iframe[${index}]`"
                          :video-name="`video[${index}]`"
                          :video-type.sync="video.type"
                          :video-file.sync="video.uploadedVideoFile"
                          :video-iframe.sync="video.videoIframe"
                        />
                        <input hidden name="videoID[]" :value="video.id">
                      </div>
                      <div class="col-md-1 pt-4 d-flex justify-content-center">
                        <button type="button" class="btn btn-outline-danger btn-add mt-5" v-if="index > 0"
                                @click="deleteFile(index, video.id)"><i
                          class="fas fa-trash"></i>
                        </button>
                      </div>
                    </template>
                    <div class="col-md-12 d-flex justify-content-end">
                      <button type="button" class="btn btn-outline-primary btn-add" @click="addVideo"><i
                        class="fas fa-plus"></i>
                      </button>
                    </div>

                  </div>
                  <div class="form-group mt-5" v-if="encyclopediaList">
                    <multiselect
                      v-model="formData.encyclopediaList"
                      :options="encyclopediaList"
                      :multiple="true"
                      label="word"
                      :loading="isLoading"
                      track-by="id"
                      @search-change="asyncFind"
                    />
                  </div>
                  <div class="form-group ml-4">
                    <input type="checkbox" name="show" v-model="formData.show" value="1" class="form-check-input"
                           id="show">
                    <label class="form-check-label" for="show">выводить пользователю</label>
                  </div>
                </b-tabs>
              </ValidationObserver>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row row-sm justify-content-center">
      <div class="col-xl-6 col-lg-8 col-md-12">
        <div class="card custom-card">
          <div class="card-body">
            <div class="d-flex justify-content-between align-items-center">
              <h6 class="main-content-label mb-1">Задания</h6>
              <button type="button" @click="formSubmit('taskType')" class="btn ripple btn-main-primary mt-2"
                      :disabled="buttonDisable" :class="buttonDisable ? 'button-readonly' : ''">Добавить задание
              </button>
            </div>
            <draggable v-model="tasks" @change="sortTasks">
              <transition-group>
                <div class="add-course-item row" v-for="task in tasks" :key="task.id">
                  <div class="name col-md-5">{{ task.name[$i18n.locale] }}</div>
                  <span class="col-md-4">Класс задания: {{ task.task_type_name }}</span>
                  <div class="col-md-3 text-right">
                    <router-link
                      :to="'/task/' + $route.params.courseId + '/' + $route.params.moduleId + '/' + $route.params.lessonId + '/' + formData.id + '/task-type/' + (task.task_type_id === 5 ? 2 : task.task_type_id) + '/' + task.id + (task.task_type_id === 5 ? '?type=5' : '')"
                      class="btn btn-outline-primary">
                      <i class="fe fe-edit"></i>
                    </router-link>
                    <button v-if="task.status !== 'active'" @click="restoreTask(task.id)" type="button"
                            class="btn btn-outline-success ml-2">
                      <i class="fe fe-rotate-ccw"></i>
                    </button>
                    <button @click="deleteTask(task.id)" type="button" class="btn btn-outline-danger ml-2">
                      <i class="fe fe-trash-2"></i>
                    </button>
                  </div>
                </div>
              </transition-group>
            </draggable>
          </div>
        </div>
      </div>
    </div>
    <div class="row row-sm justify-content-center">
      <div class="col-xl-6 col-lg-8 col-md-12">
        <div class="card custom-card">
          <div class="card-body">
            <button class="btn ripple btn-main-primary w-100" type="button" @click="formSubmit('lessonBlock')"
                    :disabled="buttonDisable" :class="buttonDisable ? 'button-readonly' : ''">
              <button-name/>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import FileUpload from '../../components/FileUpload'
import Swal from 'sweetalert2'
import VideoForm from '../../components/VideoForm'
import CkeditorMixin from '../../../mixins/CkeditorMixin'
import TaskCRUDMixin from '../../../mixins/TaskCRUDMixin'
import Breadcrumbs from '../../components/Breadcrumbs'
import ButtonName from '../../components/ButtonName'
import draggable from 'vuedraggable'
import AudioMixin from '../../../mixins/AudioMixin'

export default {
  name: 'LessonBlockAdd',
  mixins: [CkeditorMixin, TaskCRUDMixin, AudioMixin],
  components: {
    ButtonName,
    Breadcrumbs,
    FileUpload,
    VideoForm,
    draggable
  },
  data () {
    return {
      formData: {
        name: {},
        description: {}
      },
      isLoading: false,
      audio: {
        path: null,
        id: null
      },
      buttonDisable: false,
      LessonBlockId: '',
      isCreate: false,
      videos: [{
        type: 'video_iframe',
        uploadedVideoFile: null,
        videoIframe: null,
        id: null
      }],
      audioShow: false,
      tasks: []
    }
  },
  created () {
    this.$set(this.formData.description, this.$i18n.locale, null)
    this.LessonBlockId = this.$route.params.id
    this.getTasksByLessonBlock(this.LessonBlockId).then(() => {
      this.tasks = this.tasksList
    })
    this.getCourse({ id: this.$route.params.courseId })
    if (this.LessonBlockId === 'add') {
      this.isCreate = true
      this.audioShow = true
    } else {
      const videos = []
      this.getLessonBlock(this.LessonBlockId).then(() => {
        this.formData = this.lessonBlock
        this.formData.files.forEach(file => {
          if (file.type === 'video') {
            const videoObject = {
              type: 'video',
              uploadedVideoFile: null,
              videoIframe: null,
              id: file.id
            }
            if (file.extension === 'iframe') {
              videoObject.type = 'video_iframe'
              videoObject.videoIframe = file.path
            } else {
              videoObject.uploadedVideoFile = file.path
            }
            videos.push(videoObject)
          } else {
            this.audio.path = file.path
            this.audio.id = file.id
          }
        })
        if (videos.length) {
          this.videos = videos
        }
        this.audioShow = true
      })
    }
  },
  computed: {
    ...mapState({
      languages: state => state.language.languages,
      lessonBlock: state => state.lessonBlock.lessonBlock,
      tasksList: state => state.task.tasksList
    }),
    ...mapGetters({
      encyclopediaList: 'encyclopedia/encyclopediaList',
      course: 'course/course'
    })
  },
  methods: {
    ...mapActions({
      createLessonBlock: 'lessonBlock/create',
      updateLessonBlock: 'lessonBlock/updateLessonBlock',
      getLanguages: 'language/getLanguages',
      getLessonBlock: 'lessonBlock/getLessonBlock',
      getTasksByLessonBlock: 'task/getTasksByLessonBlock',
      deleteFileLessonBlock: 'lessonBlock/deleteFileLessonBlock',
      getEncyclopedia: 'encyclopedia/getAll',
      getCourse: 'course/getCourse',
      sort: 'task/sort'
    }),
    addVideo () {
      this.videos.push({
        type: 'video_iframe',
        uploadedVideoFile: null,
        videoIframe: null,
        id: null
      })
    },
    sortTasks () {
      this.sort({ tasks: this.tasks })
    },
    asyncFind (query) {
      this.isLoading = true
      this.getEncyclopedia({
        code: this.course.teaching_language.code,
        query
      }).then(res => {
        this.isLoading = false
      })
    },
    deleteFile (index, id = null) {
      Swal.fire({
        text: this.$t('messages.warning.delete'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#748C41',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('buttons.yes'),
        cancelButtonText: this.$t('buttons.no')
      }).then((result) => {
        if (result.value) {
          if (id) {
            this.deleteFileLessonBlock(id).then(res => {
              this.videos.splice(index, 1)
            })
          } else {
            this.videos.splice(index, 1)
          }
        }
      })
    },
    deleteVideo (index) {
      this.videos.splice(index, 1)
    },
    checkValidation (data) {
      return data
    },
    formSubmit (type) {
      this.$refs.lessonBlockForm.validate().then(success => {
        if (success) {
          const form = document.getElementById('lessonBlockForm')
          const formData = new FormData(form)

          if (this.audio.length) {
            formData.set('audio', this.audio[0])
          }

          formData.append('lesson_id', this.$route.params.lessonId)

          if (this.formData.encyclopediaList) {
            this.formData.encyclopediaList.forEach((item, index) => {
              formData.append(`encyclopedia[${index}]`, item.id)
            })
          }

          if (!Object.keys(this.formData.description).length) {
            this.languages.map(language => {
              this.$set(this.formData.description, language.code, '')
            })
          }

          this.languages.map(language => {
            formData.append('description[' + language.code + ']', this.formData.description[language.code])
          })

          this.buttonDisable = true
          if (this.isCreate) {
            this.createLessonBlock(formData).then(res => {
              if (type === 'lessonBlock') {
                this.$router.push('/lesson/' + this.$route.params.courseId + '/' + this.$route.params.moduleId + '/' + this.$route.params.lessonId)
              } else {
                this.$router.push('/task-type/' + this.$route.params.courseId + '/' + this.$route.params.moduleId + '/' + this.$route.params.lessonId + '/' + res.data.id + '/lesson-type')
              }
            }).catch(() => {
              this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
            }).finally(() => {
              this.buttonDisable = false
            })
          } else {
            this.updateLessonBlock({
              data: formData,
              id: this.LessonBlockId
            }).then(res => {
              if (type === 'lessonBlock') {
                this.$router.push('/lesson/' + this.$route.params.courseId + '/' + this.$route.params.moduleId + '/' + this.$route.params.lessonId)
              } else {
                this.$router.push('/task-type/' + this.$route.params.courseId + '/' + this.$route.params.moduleId + '/' + this.$route.params.lessonId + '/' + res.data.id + '/lesson-type')
              }
            }).catch(() => {
              this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
            }).finally(() => {
              this.buttonDisable = false
            })
          }
        } else {
          this.$toasted.error(this.$t('messages.error.validationError')).goAway(1500)
        }
      })
    }
  }
}
</script>
