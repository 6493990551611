import { http } from '../../../api'

const review = {
  namespaced: true,
  state: {
    reviews: [],
    pagination: {},
    review: {}
  },
  actions: {
    getAllReviews: ({ commit }, params) => {
      const q = new URLSearchParams(params)
      return http.get('/review?' + q.toString()).then(res => {
        commit('setReviewsList', res.data)
      })
    },
    updateReview: ({ commit }, params) => {
      return http.put('/review/' + params.id, params.data).then(res => {
      })
    },
    getReview: ({ commit }, id) => {
      return http.get('/review/' + id).then(res => {
        commit('setReview', res.data.data)
      })
    },
    restore: ({ commit }, params) => {
      return http.get('/review/' + params.id + '/restore').then(res => {
      })
    },
    setStatus: ({ commit }, params) => {
      return http.get('/review/' + params.id + '/' + params.statusId).then(res => {
      })
    },
    deleteReview: ({ commit }, params) => {
      return http.delete('review/' + params.id).then(res => {
        if (res.data.deleteType === 'forceDelete') {
          commit('setReviewListAfterDelete', params.id)
        } else if (res.data.deleteType === 'delete') {
          commit('updateStatus', { id: params.id, status: 'inactive' })
        }
      })
    },
    answerReview: ({ commit }, params) => {
      return http.post('/review/' + params.id + '/answer-review', params.data).then(res => {
        commit('setAnswerReview', res.data.data)
      })
    }
  },
  mutations: {
    setReviewsList (state, data) {
      state.reviews = data.data
      state.pagination = data.pagination
    },
    setAnswerReview (state, data) {
      state.reviews = state.reviews.map(item => {
        if (item.id === data.id) {
          return data
        } else {
          return item
        }
      })
    },
    setReview (state, data) {
      state.review = data
    },
    setReviewListAfterDelete (state, id) {
      state.reviews = state.reviews.filter(item => item.id !== id)
    },
    updateStatus (state, data) {
      state.reviews = state.reviews.map(review => {
        if (review.id === data.id) {
          review.status = data.status
        }
        return review
      })
    }
  },
  getters: {
    reviews: state => state.reviews,
    pagination: state => state.pagination,
    review: state => state.review
  }
}

export default review
