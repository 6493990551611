import { http } from '../../../api'

const dynamicPageText = {
  namespaced: true,
  state: {
    dynamicPageTextList: [],
    dynamicPageText: null
  },
  actions: {
    getAllDynamicPageTexts: ({ commit }, params) => {
      let url = '/dynamic-page-text'
      if (params) {
        const q = new URLSearchParams(params)
        url = url + '?' + q.toString()
      }
      return http.get(url).then(res => {
        commit('setDynamicPageTextsList', res.data.data)
        return res.data
      })
    },
    createDynamicPageText: ({ commit }, params) => {
      return http.post('/dynamic-page-text', params).then(res => {
        return res.data
      })
    },
    deleteDynamicPageText: ({ commit }, params) => {
      return http.delete('/dynamic-page-text/' + params.id).then(res => {
        if (res.data.deleteType === 'forceDelete') {
          commit('setDynamicPageTextsListAfterDelete', params.id)
        } else {
          commit('updateDynamicPageTextStatus', { id: params.id, status: 'inactive' })
        }
      })
    },
    restoreDynamicPageText: ({ commit }, params) => {
      return http.get('/dynamic-page-text/' + params.id + '/restore').then(res => {
        commit('updateDynamicPageTextStatus', { id: params.id, status: 'active' })
      })
    },
    getDynamicPageText: ({ commit }, params) => {
      return http.get('/dynamic-page-text/' + params.id).then(res => {
        commit('setDynamicPageText', res.data.data)
      })
    },
    setCurrentDynamicPageText: ({ commit }, params) => {
      return http.get('dynamic-page-text/' + params.id + '/set-current').then(res => {
        commit('setCurrent', params.id)
      })
    },
    updateDynamicPageText: ({ commit }, params) => {
      return http.put('/dynamic-page-text/' + params.id, params.data).then(res => {
      })
    }
  },
  mutations: {
    setDynamicPageTextsList (state, data) {
      state.dynamicPageTextList = data
    },
    setDynamicPageTextsListAfterDelete (state, id) {
      state.dynamicPageTextList = state.dynamicPageTextList.filter(item => item.id !== id)
    },
    updateDynamicPageTextStatus (state, data) {
      state.dynamicPageTextList = state.dynamicPageTextList.map(item => {
        if (item.id === data.id) {
          item.status = data.status
        }
        return item
      })
    },
    setCurrent (state, id) {
      state.dynamicPageTextList = state.dynamicPageTextList.map(item => {
        if (item.id === id) {
          item.is_current = 1
          return item
        }
        item.is_current = 0
        return item
      })
    },
    setDynamicPageText (state, data) {
      state.dynamicPageText = data
    }
  },
  getters: {
    dynamicPageTextList: state => state.dynamicPageTextList,
    dynamicPageText: state => state.dynamicPageText
  }
}

export default dynamicPageText
