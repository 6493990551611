import { http } from '../../../api'

const interesting = {
  namespaced: true,
  state: {
    interestingArticles: [],
    interestingCategoriesList: [],
    interestingArticle: null,
    interestingCategory: null,
    icons: []
  },
  actions: {
    createInterestingArticle: ({ commit }, data) => {
      return http.post('/interesting', data).then(res => {
      })
    },
    deleteFileInteresting: ({ commit }, id) => {
      return http.delete('/interesting/' + id + '/delete-file')
    },
    getInterestingArticle: ({ commit }, id) => {
      return http.get('/interesting/' + id).then(res => {
        console.log('state', res)
        commit('setInterestingArticle', res.data.data)
        return res.data.data
      })
    },
    deleteInterestingArticle: ({ commit }, id) => {
      return http.delete('/interesting/' + id).then(res => {
        if (res.data.deleteType === 'forceDelete') {
          commit('removeInterestingArticle', id)
        } else {
          commit('updateInterestingArticleStatus', {
            id: id,
            status: 'inactive'
          })
        }
      })
    },
    updateInterestingArticle: ({ commit }, data) => {
      return http.post('interesting/' + data.id, data.data)
    },
    getAllInterestingArticles: ({ commit }, params) => {
      let url = '/interesting'
      if (params) {
        const q = new URLSearchParams(params)
        url = url + '?' + q.toString()
      }
      return http.get(url).then(res => {
        commit('setInterestingArticles', res.data.data)
        return res.data
      })
    },
    restoreInterestingArticle: ({ commit }, id) => {
      return http.get('/interesting/' + id + '/restore').then(res => {
        commit('updateInterestingArticleStatus', {
          id: id,
          status: 'active'
        })
      })
    },
    getAllInterestingCategories: ({ commit }, params) => {
      let url = '/category-interesting'
      if (params) {
        const q = new URLSearchParams(params)
        url = url + '?' + q.toString()
      }
      return http.get(url).then(res => {
        commit('setInterestingCategories', res.data.data)
        return res.data
      })
    },
    deleteInterestingCategory: ({ commit }, id) => {
      return http.delete('/category-interesting/' + id).then(res => {
        if (res.data.deleteType === 'forceDelete') {
          commit('removeInterestingCategory', id)
        } else {
          commit('updateInterestingCategoryStatus', {
            id: id,
            status: 'inactive'
          })
        }
      })
    },
    restoreInterestingCategory: ({ commit }, id) => {
      return http.get('/category-interesting/' + id + '/restore').then(res => {
        commit('updateInterestingCategoryStatus', {
          id: id,
          status: 'active'
        })
      })
    },
    sortPositionInterestingCategory: ({ commit }, params) => {
      return http.post('/category-interesting/update-sort', params.data).then(res => {
        return res
      })
    },
    getInterestingCategory: ({ commit }, id) => {
      return http.get('/category-interesting/' + id).then(res => {
        commit('setInterestingCategory', res.data.data)
      })
    },
    createInterestingCategory: ({ commit }, data) => {
      return http.post('/category-interesting', data).then(res => {
      })
    },
    updateInterestingCategory: ({ commit }, data) => {
      return http.put('/category-interesting/' + data.id, data.data)
    },
    getIcons ({ commit }) {
      return http.get('interesting/icons').then(res => {
        commit('setIcons', res.data)
        return res
      })
    }
  },
  mutations: {
    setInterestingArticles (state, data) {
      state.interestingArticles = data
    },
    setInterestingCategories (state, data) {
      state.interestingCategoriesList = data
    },
    setInterestingArticle (state, data) {
      state.interestingArticle = data
    },
    setInterestingCategory (state, data) {
      state.interestingCategory = data
    },
    removeInterestingArticle (state, id) {
      state.interestingArticles = state.interestingArticles.filter(item => item.id !== id)
    },
    removeInterestingCategory (state, id) {
      state.interestingCategoriesList = state.interestingCategoriesList.filter(item => item.id !== id)
    },
    updateInterestingArticleStatus (state, data) {
      state.interestingArticles = state.interestingArticles.map(item => {
        if (item.id === data.id) {
          item.status = data.status
        }
        return item
      })
    },
    updateInterestingCategoryStatus (state, data) {
      state.interestingCategoriesList = state.interestingCategoriesList.map(item => {
        if (item.id === data.id) {
          item.status = data.status
        }
        return item
      })
    },
    setIcons (state, data) {
      state.icons = data
    }
  },
  getters: {
    interestingArticles: state => state.interestingArticles,
    interestingArticle: state => state.interestingArticle,
    interestingCategoriesList: state => state.interestingCategoriesList,
    interestingCategory: state => state.interestingCategory,
    icons: state => state.icons
  }
}

export default interesting
