import { http } from '../../../api'

const admin = {
  namespaced: true,
  state: {
    types:[],
  },
  actions: {
    get({commit}, type){
      return http.get('/types', {params:{type}}).then(res => {
        commit('setTypes', res.data)
      })
    },
  },
  mutations: {
    setTypes (state, data) {
      state.types = data
    },
  },
  getters: {
    types: state => state.types,
  }
}

export default admin
