<template>
  <div>
    <div class="page-header">
      <div>
        <h2 class="main-content-title tx-24 mg-b-5">{{ isCreate ? 'Добавить' : 'Редактировать' }} FAQ</h2>
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="#" @click="$router.go(-1)">Назад</a></li>
          <li class="breadcrumb-item active" aria-current="page">{{ isCreate ? 'Добавить' : 'Редактировать' }} FAQ</li>
        </ol>
      </div>
      <div class="d-flex">
      </div>
    </div>
    <div class="row row-sm justify-content-center">
      <div class="col-lg-6 col-md-12">
        <div class="card custom-card">
          <div class="card-body">
            <div>
              <h6 class="main-content-label mb-1">FAQ</h6>
            </div>
            <div>
              <ValidationObserver v-if="faqCategories" tag="form" ref="faqForm" class="d-flex flex-column"
                                  @submit.prevent="formSubmit()">
                <b-tabs content-class="mt-3">
                  <b-tab :title="language.code" v-for="(language, i) in languages" :key="i">
                    <ValidationProvider v-slot="{ classes }" rules="required" tag="div" class="form-group">
                      <label for="question">Вопрос</label>
                      <input class="form-control" v-model="formData.question[language.code]" :class="classes"
                             :placeholder="'Вопрос'" type="text">
                    </ValidationProvider>
                    <ValidationProvider v-slot="{ classes }" rules="required" tag="div" class="form-group">
                      <label :id="'answer[' + language.code + ']'">Ответ</label>
                      <ckeditor :class="checkValidation(classes, language.code)"
                                @namespaceloaded="onNamespaceLoaded"
                                v-model="formData.answer[language.code]" :id="'answer[' + language.code + ']'"
                                :config="ckEditorConfig"></ckeditor>
                    </ValidationProvider>
                  </b-tab>
                  <ValidationProvider rules="required" tag="div" class="form-group">
                    <label for="category">Категория</label>
                    <multiselect v-model="formData.categories" :multiple="true" placeholder="Выбрать категорию"
                                 label="title" track-by="id" id="category" :options="faqCategories" :custom-label="customLabel">
                      <template slot="singleLabel" slot-scope="props">
                        {{ props.option.name[$i18n.locale] }}
                      </template>
                      <template slot="option" slot-scope="props">
                        <span class="option__name">{{ props.option.name[$i18n.locale] }}</span>
                      </template>
                    </multiselect>
                  </ValidationProvider>
                </b-tabs>
                <button class="btn ripple btn-main-primary">{{isCreate?'Сохранить':'Обновить'}}</button>
              </ValidationObserver>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import CkeditorMixin from '../../../mixins/CkeditorMixin'

export default {
  name: 'AddFaq',
  mixins: [CkeditorMixin],
  data () {
    return {
      formData: {
        question: {},
        answer: {},
        categories: []
      },
      faqId: '',
      isCreate: false
    }
  },
  created () {
    this.getFaqCategories().catch(() => {
      this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
    })
    this.faqId = this.$route.params.id
    if (this.faqId === 'add') {
      this.isCreate = true
    } else {
      this.getFaq(this.faqId).then(res => {
        this.formData = res.data
      })
    }
  },
  computed: {
    ...mapState({
      languages: state => state.language.languages,
      faq: state => state.faq.faq,
      faqCategories: state => state.faqCategory.faqCategories
    })
  },
  methods: {
    ...mapActions({
      getFaqCategories: 'faqCategory/getFaqCategories',
      createFaq: 'faq/create',
      update: 'faq/update',
      getFaq: 'faq/getFaq'
    }),
    checkValidation (data, code) {
      return data
    },
    customLabel ({
      name,
      desc
    }) {
      return `${name[this.$i18n.locale]}`
    },
    formSubmit () {
      this.$refs.faqForm.validate().then(success => {
        if (success) {
          if (this.isCreate) {
            this.createFaq(this.formData).then(() => {
              this.$router.push('/faq')
            }).catch(() => {
              this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
            })
          } else {
            this.update({
              data: this.formData,
              id: this.faqId
            }).then(() => {
              this.$router.push('/faq')
            }).catch(() => {
              this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
            })
          }
        }
      })
    }
  }
}
</script>
