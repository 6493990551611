import { http } from '../../../api'

const subscribe = {
  namespaced: true,
  state: {
    subscribers: [],
    pagination: {}
  },
  actions: {
    getSubscribers: ({ commit }, params) => {
      const q = new URLSearchParams(params)
      return http.get('/subscribe?' + q.toString()).then(res => {
        commit('setSubscribers', res.data)
      })
    },
    delete: ({ commit }, id) => {
      return http.delete('/subscribe/' + id).then(res => {
        if (res.data.deleteType === 'forceDelete') {
          commit('removeSubscriber', id)
        } else {
          commit('updateSubscriberStatus', { id: id, status: 'inactive' })
        }
      })
    },
    restore: ({ commit }, id) => {
      return http.get('/subscribe/' + id + '/restore').then(res => {
        commit('updateSubscriberStatus', { id: id, status: 'active' })
      })
    },
    sendMessages({commit}, params){
      return http.post('/subscribe/sendMessages', params).then(res => {
      })
    },
  },
  mutations: {
    setSubscribers (state, data) {
      state.subscribers = data.data
      state.pagination = data.pagination
    },
    updateSubscriberStatus (state, data) {
      state.subscribers = state.subscribers.map(language => {
        if (language.id === data.id) {
          language.status = data.status
        }
        return language
      })
    },
    removeSubscriber (state, id) {
      state.subscribers = state.subscribers.filter(language => language.id !== id)
    }
  },
  getters: {
    subscribers: state => state.subscribers,
    pagination: state => state.pagination
  }
}

export default subscribe
