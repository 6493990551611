import { http } from '../../../api'

const dashboard = {
  namespaced: true,
  state: {
    countUsers: {},
    newUsers: [],
    totalUsers: 0
  },
  actions: {
    getDashboardData: ({ commit }, params) => {
      return http.get('/dashboard', params).then(res => {
        commit('setDashboardData', res.data)
      })
    }
  },
  mutations: {
    setDashboardData (state, data) {
      state.newUsers = data.users
      state.totalUsers = data.total_users
      state.countUsers = data.count_users
    }
  },
  getters: {
    newUsers: state => state.newUsers,
    totalUsers: state => state.totalUsers,
    countUsers: state => state.countUsers
  }
}

export default dashboard
