import Vue from 'vue'
import './scss/style.scss'
import App from './App.vue'
import i18n from './localization/i18n'
import router from './router/index'
import store from './store'
import { ValidationProvider, ValidationObserver, configure, extend, localize } from 'vee-validate'
import en from 'vee-validate/dist/locale/en.json'
import ru from 'vee-validate/dist/locale/ru.json'
import * as rules from 'vee-validate/dist/rules'
import { BootstrapVue } from 'bootstrap-vue'
import Toasted from 'vue-toasted'
import Multiselect from 'vue-multiselect'
import CKEditor from 'ckeditor4-vue'
import InputTag from 'vue-input-tag'
import ClickOutside from 'vue-click-outside'
import VJsoneditor from 'v-jsoneditor/src/index'
import VueChatScroll from 'vue-chat-scroll'
import VueEcho from 'vue-echo-laravel'
import Pusher from 'pusher-js'
import { http } from './api'


Vue.use(VueEcho, {
  broadcaster: 'pusher',
  key: process.env.VUE_APP_PUSHER_KEY,
  cluster: process.env.VUE_APP_PUSHER_CLUSTER,
  encrypted: true,
  authorizer: (channel, options) => {
    return {
      authorize: (socketId, callback) => {
        http.post(process.env.VUE_APP_SOCKET_AUTH, {
          socket_id: socketId,
          channel_name: channel.name
        })
          .then(response => {
            callback(false, response.data);
          })
          .catch(error => {
            callback(true, error);
          });
      }
    };
  },
})

Vue.use(VueChatScroll)
Vue.use(VJsoneditor)
Vue.directive('click-outside', ClickOutside)

Vue.use(require('vue-moment'))
Vue.use(CKEditor)

Vue.component('input-tag', InputTag)

Vue.component('multiselect', Multiselect)

Vue.use(BootstrapVue)

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
localize({
  en,
  ru
})
localize('ru')
configure({
  classes: {
    invalid: 'is-invalid'
  }
})
// install rules and localization
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule])
})
extend('positive', value => {
  if (value.slice(0, 24) >= 'https://www.youtube.com/') {
    return true
  }
})

Vue.use(Toasted, {
  type: ['success', 'error']
})

Vue.config.productionTip = false

const vue = new Vue({
  i18n,
  store,
  render: h => h(App),
  router: router
}).$mount('#app')
window.$echo = vue.$echo
