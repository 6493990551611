<template>
  <div>
    <div class="page-header">
      <breadcrumbs name="задание" />
      <div class="d-flex"></div>
    </div>
    <ValidationObserver tag="form" ref="taskAddForm" id="taskAddForm" class="d-flex flex-column">
      <div class="row row-sm justify-content-center">
        <div class="col-md-12">
          <div class="card custom-card">
            <div class="card-body">
              <div>
                <h6 class="main-content-label mb-3">
                  {{ taskType.name && taskType.name[$i18n.locale] }}
                </h6>
              </div>
              <div>
                <div class="d-flex flex-column">
                  <b-tabs content-class="mt-3">
                    <b-tab :title="language.code" v-for="(language, i) in languages" :key="i">
                      <ValidationProvider v-slot="{ classes }" rules="required" tag="div" class="form-group">
                        <label :for="'name[' + language.code + ']'" class="form-label">Название:</label>
                        <input
                          :id="'name[' + language.code + ']'"
                          class="form-control"
                          :name="'name[' + language.code + ']'"
                          v-model="formData.name[language.code]"
                          :class="checkValidation(classes, language.code)"
                          placeholder="Название"
                          type="text"
                        />
                      </ValidationProvider>
                      <ValidationProvider v-slot="{ classes }" tag="div" class="form-group">
                        <label :for="'description[' + language.code + ']'" class="form-label">Описание:</label>
                        <ckeditor
                          @namespaceloaded="onNamespaceLoaded"
                          :config="ckEditorConfig"
                          :name="'description[' + language.code + ']'"
                          :id="'description[' + language.code + ']'"
                          placeholder="Описание"
                          :class="checkValidation(classes, language.code)"
                          v-model="formData.description[language.code]"
                        ></ckeditor>
                      </ValidationProvider>
                    </b-tab>
                    <FileUpload
                      v-if="(formData.gallery && !isCreate) || isCreate"
                      page="taskTest"
                      :is-multiple="true"
                      uploadType="images"
                      label="Галерея:"
                      @fileChange="value => (gallery = value)"
                      accept="image/png,image/jpeg,image/jpg"
                      :validation="true"
                      :filesData="formData.gallery"
                    />
                    <FileUpload
                      :is-multiple="false"
                      uploadType="audio"
                      label="Аудио:"
                      name="audio"
                      accept="audio/*"
                      :validation="true"
                      :init-enable="true"
                      @fileChange="audioChange"
                      :filesData="formData.audio"
                    />
                    <VideoForm
                      :is-edit="!isCreate"
                      iframe-name="video_iframe"
                      video-name="video"
                      :video-type.sync="videoType"
                      :video-file.sync="uploadedVideoFile"
                      :video-iframe.sync="formData.video_iframe"
                    />
                  </b-tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row row-sm justify-content-center" v-if="help.length">
        <div class="col-md-12">
          <div class="card custom-card">
            <div class="card-body bg-dark text-white" v-html="help"></div>
          </div>
        </div>
      </div>
      <slot />
    </ValidationObserver>
    <slot name="submit" :formSubmit="formSubmit">
      <FormCard body-class="d-flex">
        <button
          class="btn ripple btn-main-primary m-2 w-100"
          type="button"
          @click="formSubmit('task')"
          :disabled="buttonDisable"
          :class="buttonDisable ? 'button-readonly' : ''"
        >
          Сохранить задание
        </button>
        <button
          class="btn ripple btn-main-primary m-2 w-100"
          type="button"
          @click="formSubmit('course')"
          :disabled="buttonDisable"
          :class="buttonDisable ? 'button-readonly' : ''"
        >
          Сохранить курс
        </button>
      </FormCard>
    </slot>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import FileUpload from '../FileUpload'
import FormCard from '../FormCard'
import VideoForm from '../VideoForm'
import CkeditorMixin from '../../../mixins/CkeditorMixin'
import TaskMixin from '../../../mixins/TaskMixin'
import Breadcrumbs from '../Breadcrumbs'
import AudioMixin from '../../../mixins/AudioMixin'

export default {
  name: 'TaskBase',
  props: {
    taskTypeId: {
      type: Number
    },
    help: {
      type: String,
      default: ''
    },
    data: {
      type: Object
    },
    afterValidation: {
      type: Function,
      default: () => {}
    },
    beforeCreateRequest: {
      type: Function,
      default: () => {}
    },
    beforeUpdateRequest: {
      type: Function,
      default: () => {}
    },
    update: {
      type: Function,
      default: () => {}
    },
    create: {
      type: Function,
      default: () => {}
    },
    validation: {
      type: Function,
      default: () => true
    }
  },
  mixins: [CkeditorMixin, TaskMixin, AudioMixin],
  data() {
    return {
      buttonDisable: false,
      formData: this.data,
      gallery: [],
      videoType: 'video_iframe',
      fileErrorMessage: '',
      uploadedVideoFile: null,
      isCreate: false,
      taskId: null
    }
  },
  components: {
    Breadcrumbs,
    VideoForm,
    FormCard,
    FileUpload
  },
  computed: {
    ...mapState({
      languages: state => state.language.languages,
      taskType: state => state.taskType.taskType
    })
  },
  async created() {
    this.taskId = this.$route.params.id

    this.getTaskType(this.taskTypeId).catch(() => {
      this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
    })

    if (this.taskId === 'add') {
      this.isCreate = true
    } else {
      this.isCreate = false
    }
  },
  watch: {
    data(newVal) {
      this.formData = newVal
      console.log(newVal)
      if (this.formData.video_iframe || !this.formData.video) {
        this.videoType = 'video_iframe'
      } else {
        this.videoType = 'video'
        this.uploadedVideoFile = this.formData.video
      }
    }
  },
  methods: {
    ...mapActions({
      getTaskType: 'taskType/getTaskType'
    }),

    formSubmit(type) {
      this.$refs.taskAddForm.validate().then(success => {
        if (success && this.validation()) {
          const formData = this.preData()
          formData.append('task_type_id', this.taskTypeId)

          this.afterValidation(formData)
          if (this.isCreate) {
            this.beforeCreateRequest(formData)
            this.create(formData)
              .then(() => {
                this.afterRequest(type)
              })
              .catch(() => {
                this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
              })
              .finally(() => {
                this.buttonDisable = false
              })
          } else {
            this.beforeUpdateRequest(formData)
            this.update({
              data: formData,
              id: this.taskId
            })
              .then(() => {
                this.afterRequest(type)
              })
              .catch(err => {
                console.error(err)
                console.error(err.message)
                this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
              })
              .finally(() => {
                this.buttonDisable = false
              })
          }
        } else {
          this.$toasted.error(this.$t('messages.error.validationError')).goAway(1500)
        }
      })
    },
    checkValidation(data) {
      return data
    },
    createIframeVideo() {
      this.fileErrorMessage = ''
    },
    uploadCoverFile(file) {
      this.fileErrorMessage = ''
      this.uploadedVideoFile = file[0]
    }
  }
}
</script>

<style lang="scss"></style>
