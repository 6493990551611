<template>
  <TaskBase
    :data="formData"
    :task-type-id="7"
    :after-validation="afterValidation"
    :before-update-request="beforeUpdateRequest"
    :update="updateTask"
    :create="createTask"
    help="Расположите словав полях для ввода, а система составит их них кроссворд. Ванжно! Чем больше одинаковых символов будет в словах, тем лучше будет результат."
    ref="taskBase"
  >
    <div v-if="crosswordStyle">
      <Crossword :show="true" :wordArray="wordArray" @newWordArray="newWordArray"/>
      <div class="mt-4">
        <ValidationProvider v-slot="{ classes }" rules="required" tag="div"
                            class="form-group d-flex align-items-center justify-content-between"
                            v-for="(item, index) in rightWords" :key="'question-' + index">
          <span>{{ item.word }}</span>
          <input class="form-control w-75" :class="classes" v-model="item.question" placeholder="Question" type="text">
        </ValidationProvider>
      </div>
    </div>

    <FormCard>
      <template #content>
        <div class="card-body" v-show="!crosswordStyle">
          <b-tabs content-class="mt-3">
            <ValidationProvider v-slot="{ classes }" :rules="{ required: true, regex: /^.{2,}$/ }" tag="div"
                                class="form-group d-flex align-items-center" v-for="(item, index) in formData.words"
                                :key="'words-' + index">
              <input ref="inputs" class="form-control" :class="classes" v-model="item.word"
                     placeholder="Word or Fragment" type="text">
              <span class="p-2 text-danger pointer" style="width: 32px;"><i v-if="formData.words.length > 5"
                                                                            @click="deleteCrosswordWord(item, index)"
                                                                            class="ti-close"></i></span>
            </ValidationProvider>
            <div class="text-primary pointer" @click="addWord">Add word/fragment</div>
          </b-tabs>
        </div>
      </template>
    </FormCard>

    <template v-slot:submit="props">
      <FormCard>
        <template #content>
          <div class="card-body" v-show="crosswordStyle">
            <button class="btn ripple btn-dark m-2 w-100" type="button" @click="crosswordStyle = false"
                    :disabled="buttonDisable" :class="buttonDisable ? 'button-readonly' : ''">Назад
            </button>
            <div class="d-flex">
              <button class="btn ripple btn-main-primary m-2 w-100" type="button" @click="props.formSubmit('task')"
                      :disabled="buttonDisable" :class="buttonDisable ? 'button-readonly' : ''">Сохранить задание
              </button>
              <button class="btn ripple btn-main-primary m-2 w-100" type="button" @click="props.formSubmit('course')"
                      :disabled="buttonDisable" :class="buttonDisable ? 'button-readonly' : ''">Сохранить курс
              </button>
            </div>
          </div>
          <div class="card-body d-flex" v-if="!crosswordStyle">
            <button class="btn ripple btn-main-primary m-2 w-100" type="button" @click="selectCrossword"
                    :disabled="buttonDisable" :class="buttonDisable ? 'button-readonly' : ''">Next
            </button>
          </div>
        </template>
      </FormCard>
    </template>
  </TaskBase>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import FileUpload from '../../../components/FileUpload'
import Crossword from '../../../components/crossword'
import FormCard from '../../../components/FormCard'
import VideoForm from '../../../components/VideoForm'
import CkeditorMixin from '../../../../mixins/CkeditorMixin'
import TaskMixin from '../../../../mixins/TaskMixin'
import Breadcrumbs from '../../../components/Breadcrumbs'
import AudioMixin from '../../../../mixins/AudioMixin'
import TaskBase from '../../../components/tasks/TaskBase'

export default {
  name: 'TaskCrossword',
  mixins: [CkeditorMixin, TaskMixin, AudioMixin],
  data () {
    return {
      buttonDisable: false,
      wordArray: [],
      crosswordStyle: false,
      rightWords: [],
      removeCrosswordWords: [],
      formData: {
        name: {},
        description: {},
        prompt: {},
        words: []
      },
      gallery: [],
      videoType: 'video_iframe',
      fileErrorMessage: '',
      uploadedVideoFile: null,
      isCreate: false,
      taskId: null
    }
  },
  components: {
    TaskBase,
    Breadcrumbs,
    VideoForm,
    FormCard,
    FileUpload,
    Crossword
  },
  computed: {
    ...mapState({
      languages: state => state.language.languages,
      task: state => state.taskCrossword.task,
      taskType: state => state.taskType.taskType
    })
  },
  created () {
    for (let i = 0; i < 2; i++) {
      this.formData.words.push({
        word: ''
      })
    }
    this.taskId = this.$route.params.id

    if (this.taskId === 'add') {
      this.isCreate = true
    } else {
      this.isCreate = false
      this.getTask(this.taskId).then(res => {
        this.task.task.words = []
        this.formData = { ...this.task.task }
        this.formData.prompt = this.task.crossword.prompt
        this.formData.words = [...this.task.crossword.words]
      })
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.$refs.inputs.forEach(input => {
        input.addEventListener('input', e => {
          e.target.value = e.target.value.replace(/^\s+/, '').replace(/\s+$/, '')
        })
      })
    })
  },
  methods: {
    ...mapActions({
      createTask: 'taskCrossword/createTask',
      updateTask: 'taskCrossword/updateTask',
      deleteWord: 'taskCrossword/deleteWord',
      getTask: 'taskCrossword/getTask',
      getTaskType: 'taskType/getTaskType'
    }),
    selectCrossword () {
      this.wordArray = []
      this.rightWords = []
      this.formData.words.map(item => {
        this.wordArray.push(item.word)
        this.rightWords.push(item)
      })
      this.crosswordStyle = true
    },
    addWord () {
      this.formData.words.push({ word: '' })
    },
    deleteCrosswordWord (word, index) {
      if (word.id) {
        this.formData.words = this.formData.words.filter(item => item.id !== word.id)
        this.removeCrosswordWords.push(word.id)
      } else {
        this.formData.words = this.formData.words.filter((item, i) => i !== index)
      }
    },
    newWordArray (arr) {
      if (this.rightWords) {
        for (let i = 0; i < this.rightWords.length; i++) {
          if (arr.includes(this.rightWords[i].word)) {
            this.rightWords.splice(i, 1)
          }
        }
      }
      console.log(this.formData.words)
    },
    afterValidation (formData) {
      if (this.removeCrosswordWords.length) {
        this.deleteWord({ words_id: this.removeCrosswordWords })
      }

      if (this.rightWords && this.rightWords.length) {
        this.rightWords.map((item, index) => {
          if (item.word) {
            formData.append('words[' + index + '][word]', item.word)
          }
          if (item.question) {
            formData.append('words[' + index + '][question]', item.question)
          }
        })
      }
    },
    beforeUpdateRequest (formData) {
      this.formData.words.map((item, index) => {
        formData.set('words[' + index + '][word]', item.word)
        formData.append('words[' + index + '][id]', item.id)
      })
    },
  }
}
</script>
