import { http } from '../../../api'

const teachingVideo = {
  namespaced: true,
  state: {
    teachingVideosList: [],
    teachingVideo: null
  },
  actions: {
    getAllTeachingVideos: ({ commit }, params) => {
      let url = '/teaching-video'
      if (params) {
        const q = new URLSearchParams(params)
        url = url + '?' + q.toString()
      }
      return http.get(url).then(res => {
        commit('setVideosList', res.data.data)
        return res.data
      })
    },
    createTeachingVideo: ({ commit }, params) => {
      return http.post('/teaching-video', params, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(res => {
        return res.data
      })
    },
    deleteTeachingVideo: ({ commit }, params) => {
      return http.delete('/teaching-video/' + params.id).then(res => {
        if (res.data.deleteType === 'forceDelete') {
          commit('setVideoAfterDelete', params.id)
        } else {
          commit('setUpdateVideoStatus', { id: params.id, status: 'inactive' })
        }
      })
    },
    restoreTeachingVideo: ({ commit }, params) => {
      return http.get('/teaching-video/' + params.id + '/restore').then(res => {
        commit('setUpdateVideoStatus', { id: params.id, status: 'active' })
      })
    },
    getTeachingVideo: ({ commit }, params) => {
      return http.get('/teaching-video/' + params.id).then(res => {
        commit('setVideo', res.data.data)
      })
    },
    updateTeachingVideo: ({ commit }, params) => {
      return http.post('/teaching-video/' + params.id, params.data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(res => {
        return res.data
      })
    }
  },
  mutations: {
    setUpdateVideoStatus (state, data) {
      state.teachingVideosList = state.teachingVideosList.map(item => {
        if (item.id === data.id) {
          item.status = data.status
        }
        return item
      })
    },
    setVideosList (state, data) {
      state.teachingVideosList = data
    },
    setVideoAfterDelete (state, id) {
      state.teachingVideosList = state.teachingVideosList.filter(item => item.id !== id)
    },
    setVideo (state, data) {
      state.teachingVideo = data
    }
  },
  getters: {
    teachingVideosList: state => state.teachingVideosList,
    teachingVideo: state => state.teachingVideo
  }
}

export default teachingVideo
