import { http } from '../../../api'

const currency = {
  namespaced: true,
  state: {
    currencyList: [],
    currency: null
  },
  actions: {
    getAllCurrency: ({ commit }, params) => {
      let url = '/currency'
      if (params) {
        const q = new URLSearchParams(params)
        url = url + '?' + q.toString()
      }
      return http.get(url).then(res => {
        commit('setCurrencyList', res.data.data)
        return res.data
      })
    },
    createCurrency: ({ commit }, params) => {
      return http.post('/currency', params).then(res => {
        return res.data
      })
    },
    deleteCurrency: ({ commit }, params) => {
      return http.delete('/currency/' + params.id).then(res => {
        if (res.data.deleteType === 'forceDelete') {
          commit('setRemoveCurrencyList', params.id)
        } else {
          commit('setUpdateCurrencyStatus', { id: params.id, status: 'inactive' })
        }
      })
    },
    restoreCurrency: ({ commit }, params) => {
      return http.get('/currency/' + params.id + '/restore').then(res => {
        commit('setUpdateCurrencyStatus', { id: params.id, status: 'active' })
      })
    },
    getCurrency: ({ commit }, params) => {
      return http.get('/currency/' + params.id).then(res => {
        commit('setCurrency', res.data.data)
      })
    },
    updateCurrency: ({ commit }, params) => {
      return http.put('/currency/' + params.id, params.data).then(res => {
      })
    },
    updateCurrenciesExchangeRate: ({ commit }) => {
      return http.get('/currency/update-exchange-rate').then(res => {
      })
    },
    updateCurrenciMainStatus: ({ commit }, params) => {
      return http.get('currency/' + params + '/set-main').then(res => {
      })
    }
  },
  mutations: {
    setCurrencyList (state, data) {
      state.currencyList = data
    },
    setRemoveCurrencyList (state, id) {
      state.currencyList = state.currencyList.filter(item => item.id !== id)
    },
    setUpdateCurrencyStatus (state, data) {
      state.currencyList = state.currencyList.map(item => {
        if (item.id === data.id) {
          item.status = data.status
        }
        return item
      })
    },
    setCurrency (state, data) {
      state.currency = data
    }
  },
  getters: {
    currencyList: state => state.currencyList,
    currency: state => state.currency
  }
}

export default currency
