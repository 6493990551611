import { http } from '../../../api'

const paymentMethod = {
  namespaced: true,
  state: {
    paymentMethod: {},
    paymentMethods: []
  },
  actions: {
    getPaymentMethods: ({ commit }) => {
      return http.get('/payment-method').then(res => {
        commit('setPaymentMethods', res.data.data)
        return res.data
      })
    },
    getPaymentMethod: ({ commit }, id) => {
      return http.get('/payment-method/' + id).then(res => {
        commit('setPaymentMethod', res.data.data)
        return res.data
      })
    },
    create: ({ commit }, params) => {
      return http.post('/payment-method', params).then(res => {
        return res
      })
    },
    update: ({ commit }, params) => {
      return http.post('/payment-method/' + params.id, params.data).then(res => {
        return res
      })
    },
    restore: ({ commit }, id) => {
      return http.get('/payment-method/' + id + '/restore').then(res => {
        commit('updatePaymentMethodStatus', { id: id, status: 'active' })
      })
    },
    deletePaymentMethod: ({ commit }, id) => {
      return http.delete('/payment-method/' + id).then(res => {
        if (res.data.deleteType === 'forceDelete') {
          commit('removePaymentMethod', id)
        } else {
          commit('updatePaymentMethodStatus', { id: id, status: 'inactive' })
        }
      })
    },
    sortPositionPaymentMethod: ({ commit }, params) => {
      return http.post('/payment-method/update-sort', params.data).then(res => {
        return res
      })
    }
  },
  mutations: {
    setPaymentMethods (state, data) {
      state.paymentMethods = data
    },
    updatePaymentMethodStatus (state, data) {
      state.paymentMethods = state.paymentMethods.map(paymentMethod => {
        if (paymentMethod.id === data.id) {
          paymentMethod.status = data.status
        }
        return paymentMethod
      })
    },
    removePaymentMethod (state, id) {
      state.paymentMethods = state.paymentMethods.filter(faq => faq.id !== id)
    },
    setPaymentMethod (state, data) {
      state.paymentMethod = data
    }
  },
  getters: {
    paymentMethod: state => state.paymentMethod,
    paymentMethods: state => state.paymentMethods
  }
}

export default paymentMethod
