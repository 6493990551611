<template>
  <div>
    <div class="page-header">
      <div>
        <h2 class="main-content-title tx-24 mg-b-5">{{isCreate ? 'Add Dynamic Page' : 'Edit Dynamic Page'}}</h2>
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="#" @click="$router.go(-1)">Назад</a></li>
          <li class="breadcrumb-item active" aria-current="page">{{isCreate ? 'Add Dynamic Page' : 'Edit Dynamic Page'}}</li>
        </ol>
      </div>
      <div class="d-flex">
      </div>
    </div>
    <div class="row row-sm justify-content-center">
      <div class="col-xl-6 col-lg-8 col-md-12">
        <div class="card custom-card">
          <div class="card-body">
            <div>
              <h6 class="main-content-label mb-1">Dynamic Page</h6>
            </div>
            <div v-if="showVideo === true">
              <ValidationObserver ref="dynamicPageAddForm" id="addDynamicPageForm" tag="form" class="d-flex flex-column" @submit.prevent="formSubmit()">
                <b-tabs content-class="mt-3">
                  <b-tab :title="language.code" v-for="(language, i) in languages" :key="i">
                    <ValidationProvider v-slot="{ classes }" rules="required" tag="div" class="form-group">
                      <label :for="'title[' + language.code + ']'" class="form-label">Title:</label>
                      <input :id="'title[' + language.code + ']'" class="form-control" :name="'title[' + language.code + ']'" v-model="formData.title[language.code]" :class="checkValidation(classes, language.code)" placeholder="title" type="text">
                    </ValidationProvider>
                  </b-tab>
                </b-tabs>
                <ValidationProvider v-slot="{ classes }" rules="required" tag="div" class="form-group">
                  <label for="key" class="form-label">Key:</label>
                  <input id="key" class="form-control" name="key" v-model="formData.key" :class="classes" placeholder="Key" type="text">
                </ValidationProvider>
                <button class="btn ripple btn-main-primary mt-2" :disabled="buttonDisable" :class="buttonDisable ? 'button-readonly' : ''">Submit</button>
              </ValidationObserver>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'DynamicPageAdd',
  data () {
    return {
      showVideo: false,
      isEdit: false,
      buttonDisable: false,
      formData: {
        title: {}
      },
      isCreate: false,
      dynamicPageId: ''
    }
  },
  computed: {
    ...mapState({
      languages: state => state.language.languages,
      dynamicPage: state => state.dynamicPage.dynamicPage
    })
  },
  created () {
    this.dynamicPageId = this.$route.params.id
    if (this.dynamicPageId === 'add') {
      this.isEdit = false
      this.isCreate = true
      this.showVideo = true
    } else {
      this.isEdit = true
      this.getDynamicPage({ id: this.dynamicPageId }).then(res => {
        this.showVideo = true
        this.formData = this.dynamicPage
      })
    }
  },
  methods: {
    ...mapActions({
      createDynamicPage: 'dynamicPage/createDynamicPage',
      getDynamicPage: 'dynamicPage/getDynamicPage',
      updateDynamicPage: 'dynamicPage/updateDynamicPage'

    }),
    checkValidation (data) {
      return data
    },
    formSubmit () {
      this.$refs.dynamicPageAddForm.validate().then(success => {
        if (success) {
          this.buttonDisable = true

          if (this.isCreate === true) {
            this.createDynamicPage(this.formData).then(() => {
              this.$toasted.success(this.$t('messages.success.videoCreated')).goAway(1500)
              this.$router.push('/dynamic-page')
            }).catch(() => {
              this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
            }).finally(() => {
              this.buttonDisable = false
            })
          } else {
            this.updateDynamicPage({ data: this.formData, id: this.$route.params.id }).then(res => {
              this.$toasted.success(this.$t('messages.success.videoUpdated')).goAway(1500)
              this.$router.push('/dynamic-page')
            }).catch(() => {
              this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
            }).finally(() => {
              this.buttonDisable = false
            })
          }
        } else {
          this.$toasted.error(this.$t('messages.error.validationError')).goAway(1500)
        }
      })
    }
  }
}
</script>
