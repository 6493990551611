<template>
  <div>
    <div class="page-header">
      <div>
        <h2 class="main-content-title tx-24 mg-b-5">Видео материалы</h2>
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="#" @click="$router.go(-1)">Назад</a></li>
          <li class="breadcrumb-item active" aria-current="page">Видео</li>
        </ol>
      </div>
      <div class="d-flex">
      </div>
    </div>
    <div class="row row-sm justify-content-center">
      <div class="col-xl-6 col-lg-8 col-md-12">
        <div class="card custom-card">
          <div class="card-body">
            <div>
              <h6 class="main-content-label mb-1">Видео</h6>
            </div>
            <div>
              <ValidationObserver  ref="testAddForm" id="testAddForm" tag="form" class="d-flex flex-column">
                <b-tabs content-class="mt-3">
                  <b-tab :title="language.code" v-for="(language, i) in languages" :key="i">
                    <ValidationProvider v-slot="{ classes }" rules="required" tag="div" class="form-group">
                      <label :for="'name[' + language.code + ']'" class="form-label">Название:</label>
                      <input :id="'name[' + language.code + ']'" class="form-control" :name="'name[' + language.code + ']'" v-model="formData.name[language.code]" :class="checkValidation(classes, language.code)" placeholder="Название" type="text">
                    </ValidationProvider>
                    <ValidationProvider v-slot="{ classes }" rules="required" tag="div" class="form-group">
                      <label :for="'description[' + language.code + ']'" class="form-label">Описание:</label>
                      <textarea class="form-control" :name="'description[' + language.code + ']'" :id="'description[' + language.code + ']'" placeholder="Описание" :class="checkValidation(classes, language.code)" v-model="formData.description[language.code]"></textarea>
                    </ValidationProvider>
                  </b-tab>
                </b-tabs>
                <FileUpload :init-enable="true" accept="image/png,image/jpeg,image/jpg" label="Картинка:" name="image" uploadType="oneImage" type="image" :filesData="formData.image" />
                <div class="row mg-y-10 ">
                  <div class="col-lg-3 mg-t-20 mg-lg-t-0">
                    <label class="radiobox">
                      <input checked="" name="video_type" type="radio" v-model="videoType" value="video_iframe"> <span>Добавить видео в фрейме</span>
                    </label>
                  </div>
                  <div class="col-lg-3">
                    <label class="radiobox">
                      <input name="video_type" v-model="videoType" type="radio" value="video"> <span>Загрузить видео</span>
                    </label>
                  </div>
                </div>
                <ValidationProvider :rules="{ required: true, regex: /(?:<iframe[^>]*)(?:(?:\/>)|(?:>.*?<\/iframe>))/ }" tag="div" v-slot="{ classes }" v-if="videoType === 'video_iframe'" class="form-group">
                  <label for="video_iframe" class="form-label">Видео в фрейме:</label>
                  <input id="video_iframe" class="form-control" name="video_iframe" @input="createIframeVideo" v-model="formData.video_iframe" placeholder="Видео в фрейме" :class="classes" type="text">
                </ValidationProvider>
                <ValidationProvider v-else tag="div" class="form-group">
                  <div>
                    <label class="form-label">Видео:</label>
                  </div>
                  <div class="custom-file">
                    <input name="video" accept=".mp4, .webm, .ogg" type="file" class="custom-file-input" @change="uploadCoverFile($event.target.files)" id="inputGroupFile01" aria-describedby="inputGroupFileAddon01">
                    <label class="custom-file-label" for="inputGroupFile01" v-if="uploadedVideoFile">{{!isCreate && !uploadedVideoFile.name  ? uploadedVideoFile : uploadedVideoFile.name}}</label>
                    <label class="custom-file-label" for="inputGroupFile01" v-else>Выбрать файл</label>
                  </div>
                </ValidationProvider>
                <span class="text-danger" v-if="fileErrorMessage">{{fileErrorMessage}}</span>
              </ValidationObserver>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row row-sm justify-content-center">
      <div class="col-xl-6 col-lg-8 col-md-12">
        <div class="card custom-card">
          <div class="card-body">
            <button class="btn ripple btn-main-primary w-100" @click="formSubmit()" :disabled="buttonDisable" :class="buttonDisable ? 'button-readonly' : ''">Сохранить</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import FileUpload from '../../components/FileUpload'
import ButtonName from '../../components/ButtonName'
export default {
  name: 'CourseVideo',
  data () {
    return {
      videoType: 'video_iframe',
      fileErrorMessage: '',
      formData: {
        name: {},
        description: {}
      },
      buttonDisable: false,
      isCreate: false,
      uploadedVideoFile: ''
    }
  },
  components: {
    ButtonName,
    FileUpload
  },
  computed: {
    ...mapState({
      languages: state => state.language.languages,
      courseVideo: state => state.courseVideo.courseVideo
    })
  },
  created () {
    if (this.$route.params.videoId === 'add') {
      this.isCreate = true
    } else {
      this.isCreate = false
      this.getCourseVideo(this.$route.params.videoId).then(() => {
        this.formData = this.courseVideo
        if (this.formData.video_iframe) {
          this.videoType = 'video_iframe'
        } else {
          this.videoType = 'video'
          this.uploadedVideoFile = this.formData.video
        }
        if (this.formData.image) {
          this.formData.image = [this.formData.image]
        }
      })
    }
  },
  methods: {
    ...mapActions({
      createCourseVideo: 'courseVideo/createCourseVideo',
      updateCourseVideo: 'courseVideo/updateCourseVideo',
      getCourseVideo: 'courseVideo/getCourseVideo'
    }),
    checkValidation (data) {
      return data
    },
    formSubmit () {
      console.log(this.formData.image)
      this.$refs.testAddForm.validate().then(success => {
        if (success) {
          this.buttonDisable = true
          const form = document.getElementById('testAddForm')
          const formData = new FormData(form)
          formData.append('course_id', this.$route.params.courseId)
          if (this.videoType === 'video_iframe') {
            formData.delete('video')
          } else {
            if (typeof this.uploadedVideoFile !== 'string') {
              formData.delete('video')
              formData.append('video', this.uploadedVideoFile)
            } else {
              formData.delete('video')
            }
            formData.delete('video_iframe')
          }
          const image = formData.get('image')
          if (!image.name) {
            formData.delete('image')
          }
          if (this.isCreate === true) {
            this.createCourseVideo(formData).then(res => {
              this.$toasted.success(this.$t('messages.success.courseCreated')).goAway(1500)
              this.$router.push('/courses/' + this.$route.params.courseId)
            }).catch(() => {
              this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
            }).finally(() => {
              this.buttonDisable = false
            })
          } else {
            this.updateCourseVideo({ data: formData, id: this.$route.params.videoId }).then(res => {
              this.$toasted.success(this.$t('messages.success.courseUpdated')).goAway(1500)
              this.$router.push('/courses/' + this.$route.params.courseId)
            }).catch(() => {
              this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
            }).finally(() => {
              this.buttonDisable = false
            })
          }
        } else {
          this.$toasted.error(this.$t('messages.error.validationError')).goAway(1500)
        }
      })
    },
    createIframeVideo () {
      this.fileErrorMessage = ''
    },
    uploadCoverFile (file) {
      this.fileErrorMessage = ''
      const uploadFile = file[0]
      this.uploadedVideoFile = uploadFile
    }
  }
}
</script>
