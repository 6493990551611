var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"page-header"},[_c('div',[_c('h2',{staticClass:"main-content-title tx-24 mg-b-5"},[_vm._v("Edit Review")]),_c('ol',{staticClass:"breadcrumb"},[_c('li',{staticClass:"breadcrumb-item"},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("Назад")])]),_c('li',{staticClass:"breadcrumb-item active",attrs:{"aria-current":"page"}},[_vm._v("Edit Review")])])]),_c('div',{staticClass:"d-flex"})]),_c('div',{staticClass:"row row-sm justify-content-center"},[_c('div',{staticClass:"col-lg-6 col-md-12"},[_c('div',{staticClass:"card custom-card"},[_c('div',{staticClass:"card-body"},[_vm._m(0),_c('div',[(_vm.showReview)?_c('ValidationObserver',{ref:"userReview",staticClass:"d-flex flex-column",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.formSubmit()}}},[_c('b-tabs',{attrs:{"content-class":"mt-3"}},[_c('b-tab',[_c('ValidationProvider',{staticClass:"form-group",attrs:{"rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.full_name),expression:"formData.full_name"}],staticClass:"form-control",class:classes,attrs:{"placeholder":"User full name","type":"text"},domProps:{"value":(_vm.formData.full_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "full_name", $event.target.value)}}})]}}],null,false,130080595)}),_c('ValidationProvider',{staticClass:"form-group",attrs:{"rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.email),expression:"formData.email"}],staticClass:"form-control",class:classes,attrs:{"placeholder":"Email","type":"text"},domProps:{"value":(_vm.formData.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "email", $event.target.value)}}})]}}],null,false,3446567290)}),_c('ValidationProvider',{staticClass:"form-group",attrs:{"rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.text),expression:"formData.text"}],staticClass:"form-control",class:classes,attrs:{"placeholder":"Text","type":"text"},domProps:{"value":(_vm.formData.text)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "text", $event.target.value)}}})]}}],null,false,1965433227)}),_c('ValidationProvider',{staticClass:"form-group",attrs:{"rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.rating),expression:"formData.rating"}],staticClass:"form-control",class:classes,attrs:{"placeholder":"Rating (0-5)","max":"5","min":"0","type":"number"},domProps:{"value":(_vm.formData.rating)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "rating", $event.target.value)}}})]}}],null,false,1755218621)})],1)],1),_c('button',{staticClass:"btn ripple btn-main-primary",attrs:{"type":"submit"}},[_vm._v("Submit")])],1):_vm._e()],1)])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h6',{staticClass:"main-content-label mb-1"},[_vm._v("Review")])])}]

export { render, staticRenderFns }