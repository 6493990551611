<template>
  <div>
    <div class="page-header">
      <div>
        <h2 class="main-content-title tx-24 mg-b-5">Администраторы</h2>
      </div>
      <div class="d-flex">
        <div class="justify-content-center">
          <router-link to="/admin/add" type="button" class="btn btn-primary my-2 btn-icon-text">
            <i class="fe fe-plus-square mr-2"></i> Добавить администратора
          </router-link>
        </div>
      </div>
    </div>
    <div class="row row-sm">
      <div class="col-lg-12">
        <div class="card custom-card">
          <div class="card-body">
            <div>
              <h6 class="main-content-label mb-1">Список администраторов</h6>
            </div>
            <div class="table-responsive">
              <b-table striped hover :items="admins" :fields="tableFields">
                <template #cell(avatar)="row">
                  <img v-if="row.item.avatar" class="img-thumbnail table-image" :src="row.item.avatar" alt="avatar">
                  <img v-else class="img-thumbnail table-image" src="../../../assets/img/users/default.png" alt="avatar">
                </template>
                <template #cell(actions)="row">
                  <router-link :to="`admin/${row.item.id}`" class="btn btn-outline-primary mr-2 mt-1 mb-1">
                    <i class="fe fe-edit"></i>
                  </router-link>
                  <button v-if="row.item.status === 'active'" @click="removeAdmin(row.item.id)" type="button" class="btn btn-outline-danger mr-2 mt-1 mb-1">
                    <i class="ti-close"></i>
                  </button>
                  <div v-else class="d-inline-block">
                    <button @click="restoreAdmins(row.item.id)" type="button" class="btn btn-outline-success mr-2 mt-1 mb-1">
                      <i class="fe fe-rotate-ccw"></i>
                    </button>
                    <button @click="removeAdmin(row.item.id)" type="button" class="btn btn-outline-danger mr-2 mt-1 mb-1">
                      <i class="fe fe-trash-2"></i>
                    </button>
                  </div>
                </template>
              </b-table>
              <div class="d-flex justify-content-end  mt-3" v-if="pagination && pagination.count > 1">
                <div class="mr-3">
                  <b-form-select
                          @change="changePaginationPerPage"
                          style="width: 80px"
                          v-model="paginationPerPage"
                          :options="paginationOptions"
                  >
                  </b-form-select>
                </div>
                <div>
                  <b-pagination
                          class="m-0"
                          @change="changePagination"
                          v-model="paginationCurrentPage"
                          :total-rows="pagination.count"
                          :per-page="paginationPerPage"
                          first-text="<<"
                          prev-text="<"
                          next-text=">"
                          last-text=">>"
                  ></b-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Swal from 'sweetalert2'

export default {
  name: 'Admins',
  data () {
    return {
      paginationOptions: [
        { value: 6, text: '6' },
        { value: 10, text: '10' },
        { value: 30, text: '30' },
        { value: 100, text: '100' }
      ],
      paginationCurrentPage: 1,
      paginationPerPage: 6
    }
  },
  computed: {
    ...mapState({
      admins: state => state.admin.admins,
      pagination: state => state.admin.pagination
    }),
    tableFields () {
      return [
        {
          key: 'id',
          label: 'Id',
          sortable: true
        },
        {
          label: 'Аватар',
          key: 'avatar',
          sortable: false
        },
        {
          key: 'name',
          label: 'Имя',
          sortable: true
        },
        {
          key: 'email',
          label: 'Почта',
          sortable: true
        },
        {
          key: 'phone',
          label: 'Телефон',
          sortable: true
        },
        {
          key: 'role.name',
          label: 'Роль',
          sortable: true
        },
        {
          key: 'actions',
          label: 'Действие',
          sortable: false
        }
      ]
    }
  },
  created () {
    this.getAdminsList({})
  },
  methods: {
    ...mapActions({
      getAdmins: 'admin/getAdmins',
      deleteAdmin: 'admin/deleteAdmin',
      restoreAdmin: 'admin/restoreAdmin'
    }),
    getAdminsList (params) {
      this.getAdmins(params).then(res => {
      }).catch(() => {
        this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
      })
    },
    changePaginationPerPage (value) {
      this.getAdminsList({ page: this.paginationCurrentPage, count: value })
    },
    changePagination (page) {
      this.getAdminsList({ page: page, count: this.paginationPerPage })
    },
    removeAdmin (id) {
      Swal.fire({
        text: this.$t('messages.warning.delete'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#748C41',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('buttons.yes'),
        cancelButtonText: this.$t('buttons.no')
      }).then((result) => {
        if (result.value) {
          this.deleteAdmin({ id }).then(res => {
          }).catch(() => {
            this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
          })
        }
      })
    },
    restoreAdmins (id) {
      Swal.fire({
        text: this.$t('message.warning.restore'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#748C41',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('buttons.yes'),
        cancelButtonText: this.$t('buttons.no')
      }).then((result) => {
        if (result.value) {
          this.restoreAdmin({ id }).then(res => {
            this.$toasted.success(this.$t('messages.success.languageDeleted')).goAway(1500)
          }).catch(() => {
            this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
          })
        }
      })
    }
  }
}
</script>
