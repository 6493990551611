<template>
  <div>
    <div class="page-header">
      <div>
        <h2 class="main-content-title tx-24 mg-b-5">Test</h2>
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="#" @click="$router.go(-1)">Назад</a></li>
          <li class="breadcrumb-item active" aria-current="page">Test</li>
        </ol>
      </div>
      <div class="d-flex">
      </div>
    </div>
    <div class="row row-sm justify-content-center">
      <div class="col-xl-6 col-lg-8 col-md-12">
        <div class="card custom-card">
          <div class="card-body">
            <div>
              <h6 class="main-content-label mb-1">Test</h6>
            </div>
            <div>
              <ValidationObserver  ref="testAddForm" id="testAddForm" class="d-flex flex-column">
                <b-tabs content-class="mt-3">
                  <b-tab :title="language.code" v-for="(language, i) in languages" :key="i">
                    <ValidationProvider v-slot="{ classes }" rules="required" tag="div" class="form-group">
                      <label :for="'title[' + language.code + ']'" class="form-label">Title Test:</label>
                      <input :id="'title[' + language.code + ']'" class="form-control" :name="'title[' + language.code + ']'" v-model="formData.title[language.code]" :class="checkValidation(classes, language.code)" placeholder="Title" type="text">
                    </ValidationProvider>
                  </b-tab>
                </b-tabs>
              </ValidationObserver>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row row-sm justify-content-center">
      <div class="col-xl-6 col-lg-8 col-md-12">
        <div class="card custom-card">
          <div class="card-body">
            <div class="d-flex justify-content-between align-items-center">
              <h6 class="main-content-label mb-1">Tasks</h6>
              <button type="button" @click="formSubmit('task')" class="btn ripple btn-main-primary mt-2" :disabled="buttonDisable" :class="buttonDisable ? 'button-readonly' : ''">Add Task</button>
            </div>
            <div v-if="testTasksList && testTasksList.length">
              <div class="add-course-item" v-for="(task, index) in testTasksList" :key="index">
                <div class="name">{{task.name[$i18n.locale]}}</div>
                <div>
                  <router-link :to="'/task/' + $route.params.courseId + '/' + $route.params.testId + '/' + 'task-type' + '/' + task.task_type_id + '/' + task.id" class="btn btn-outline-primary mr-2 mt-1 mb-1">
                    <i class="fe fe-edit"></i>
                  </router-link>
                  <button v-if="task.status === 'active'" @click="deleteTask(task.id)" type="button" class="btn btn-outline-danger ml-2">
                    <i class="ti-close"></i>
                  </button>
                  <div v-else class="d-inline-block">
                    <button @click="restoreTask(task.id)" type="button" class="btn btn-outline-success ml-2">
                      <i class="fe fe-rotate-ccw"></i>
                    </button>
                    <button @click="restoreTask(task.id)" type="button" class="btn btn-outline-danger ml-2">
                      <i class="fe fe-trash-2"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row row-sm justify-content-center">
      <div class="col-xl-6 col-lg-8 col-md-12">
        <div class="card custom-card">
          <div class="card-body">
            <button class="btn ripple btn-main-primary w-100" @click="formSubmit('test')" :disabled="buttonDisable" :class="buttonDisable ? 'button-readonly' : ''">Submit</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Swal from 'sweetalert2'
export default {
  name: 'CourseTest',
  data () {
    return {
      formData: {
        title: {}
      },
      buttonDisable: false,
      isCreate: false
    }
  },
  computed: {
    ...mapState({
      languages: state => state.language.languages,
      courseModuleTest: state => state.courseModuleTest.courseModuleTest,
      testTasksList: state => state.courseModuleTest.testTasksList
    })
  },
  created () {
    this.getTestTasksList(this.$route.params.testId).then(() => {})
    if (this.$route.params.testId === 'add') {
      this.isCreate = true
    } else {
      this.isCreate = false
      this.getCourseModuleTest(this.$route.params.testId).then(() => {
        this.formData = this.courseModuleTest
      })
    }
  },
  methods: {
    ...mapActions({
      createCourseModuleTest: 'courseModuleTest/createCourseModuleTest',
      updateCourseModuleTest: 'courseModuleTest/updateCourseModuleTest',
      getTestTasksList: 'courseModuleTest/getTestTasksList',
      getCourseModuleTest: 'courseModuleTest/getCourseModuleTest',
      deleteTestTask: 'courseModuleTest/deleteTestTask',
      restoreTestTask: 'courseModuleTest/restoreTestTask'
    }),
    checkValidation (data) {
      return data
    },
    formSubmit (type) {
      this.$refs.testAddForm.validate().then(success => {
        if (success) {
          this.buttonDisable = true
          this.formData.course_module_id = this.$route.params.moduleId
          this.formData.course_id = this.$route.params.courseId
          if (this.isCreate === true) {
            this.createCourseModuleTest(this.formData).then(res => {
              this.$toasted.success(this.$t('messages.success.courseCreated')).goAway(1500)
              if (type === 'test') {
                this.$router.push('/course-module/' + this.$route.params.courseId + '/' + 'add')
              } else {
                this.$router.push('/course-module-test/' + this.$route.params.courseId + '/' + this.$route.params.moduleId + '/' + res.data.id + '/task-type')
              }
            }).catch(() => {
              this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
            }).finally(() => {
              this.buttonDisable = false
            })
          } else {
            this.updateCourseModuleTest({ data: this.formData, id: this.$route.params.testId }).then(res => {
              this.$toasted.success(this.$t('messages.success.courseUpdated')).goAway(1500)
              if (type === 'test') {
                this.$router.push('/course-module/' + this.$route.params.courseId + '/' + 'add')
              } else {
                this.$router.push('/course-module-test/' + this.$route.params.courseId + '/' + this.$route.params.moduleId + '/' + res.data.id + '/task-type')
              }
            }).catch(() => {
              this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
            }).finally(() => {
              this.buttonDisable = false
            })
          }
        } else {
          this.$toasted.error(this.$t('messages.error.validationError')).goAway(1500)
        }
      })
    },
    deleteTask (id) {
      Swal.fire({
        text: this.$t('messages.warning.delete'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#748C41',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('buttons.yes'),
        cancelButtonText: this.$t('buttons.no')
      }).then((result) => {
        if (result.value) {
          this.deleteTestTask(id).then(res => {
            this.$toasted.success(this.$t('messages.success.languageDeleted')).goAway(1500)
          }).catch(() => {
            this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
          })
        }
      })
    },
    restoreTask (id) {
      Swal.fire({
        text: this.$t('message.warning.restore'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#748C41',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('buttons.yes'),
        cancelButtonText: this.$t('buttons.no')
      }).then((result) => {
        if (result.value) {
          this.restoreTestTask(id).then(res => {
            this.$toasted.success(this.$t('messages.success.languageRestored')).goAway(1500)
          }).catch(() => {
            this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
          })
        }
      })
    }
  }
}
</script>
