<template>
  <div>
    <div class="page-header">
      <div>
        <h2 class="main-content-title tx-24 mg-b-5">{{isCreate ? 'Добавление' : 'Редактирование'}} языка обучения</h2>
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="#" @click="$router.go(-1)">Назад</a></li>
          <li class="breadcrumb-item active" aria-current="page">{{isCreate ? 'Добавление' : 'Редактирование'}} языка обучения</li>
        </ol>
      </div>
      <div class="d-flex">
      </div>
    </div>
    <div class="row row-sm justify-content-center">
      <div class="col-lg-6 col-md-12">
        <div class="card custom-card">
          <div class="card-body">
            <div>
              <h6 class="main-content-label mb-1">Язык обучения</h6>
            </div>
            <div>
              <ValidationObserver tag="form" id="teachingLangForm" ref="teachingLang" class="d-flex flex-column" @submit.prevent="formSubmit()">
                <b-tabs content-class="mt-3">
                  <b-tab :title="language.code" v-for="(language, i) in languages" :key="i">
                    <ValidationProvider v-slot="{ classes }" rules="required" tag="div" class="form-group">
                      <label for="name">Название</label>
                      <input class="form-control" id="name" :name="`name[${language.code}]`" v-model="formData.name[language.code]" :class="classes" :placeholder="'Название языка'" type="text">
                    </ValidationProvider>
                  </b-tab>
                </b-tabs>
                <ValidationProvider v-slot="{ classes }" rules="required" tag="div" class="form-group">
                  <label for="code">Код языка</label>
                  <input class="form-control" id="code" v-model="formData.code" name="code" :class="classes" placeholder="Код языка" type="text">
                </ValidationProvider>
                <ValidationProvider v-slot="{ errors }" rules="required" tag="div" class="form-group">
                  <label>Основной цвет</label>
                  <div class="color-picker-dropdown"  v-click-outside="(e) => {showColorPicker = false}">
                    <input type="hidden" name="color" :value="formData.color.style">
                    <a href="#" @click.prevent="showColorPicker = !showColorPicker" class="btn btn-primary">
                      <span class="color-picker-dropdown-color-square mr-2" v-bind:style="{ backgroundImage: formData.color.style }"></span>
                      Выбрать цвет
                      <i class="fas fa-toggle-up ml-3"></i>
                    </a>
                    <div v-if="showColorPicker" class="color-picker-dropdown-content">
                      <div class="position-relative">
                        <ColorPicker
                          :gradient="formData.color"
                          :isGradient="true"
                          :onStartChange="color => onChangeColor(color, 'start')"
                          :onChange="color => onChangeColor(color, 'change')"
                          :onEndChange="color => onChangeColor(color, 'end')"
                        />
                      </div>
                    </div>
                  </div>
                  <small>{{errors[0]}}</small>
                </ValidationProvider>
                <div class="row row-sm justify-content-center">
                  <div class="col-md-12">
                    <div class="card custom-card">
                      <div class="card-body bg-dark text-white">
                        Обязательно поставьте " , " после каждой буквы
                      </div>
                    </div>
                  </div>
                </div>
                <ValidationProvider rules="required" tag="div" class="form-group mt-3">
                  <label  class="form-label">Алфавит:</label>
                  <textarea class="form-control" name="letters" v-model="formData.letters" placeholder="Введите алфавит"></textarea>
                </ValidationProvider>
                <FileUpload accept="image/png,image/jpeg,image/jpg" @fileChange="fileHandler" label="Флаг:" name="flag" uploadType="oneImage" type="image" v-if="(formData.flag && !isCreate) || isCreate" :validation="true" :filesData="formData.flag" />
                <button class="btn ripple btn-main-primary">Сохранить</button>
              </ValidationObserver>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { ColorPicker } from 'vue-color-gradient-picker'
import ClickOutside from 'vue-click-outside'
import FileUpload from '../../components/FileUpload'

export default {
  name: 'TeachingLangCategoryAdd',
  data () {
    return {
      formData: {
        name: {},
        color: {
          style: ''
        }
      },
      teachingCategoryLangId: '',
      isCreate: false,
      showColorPicker: false
    }
  },
  components: {
    ColorPicker,
    FileUpload
  },
  created () {
    this.getLanguages().catch(() => {
      this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
    })
    this.teachingCategoryLangId = this.$route.params.id
    if (this.teachingCategoryLangId === 'add') {
      this.isCreate = true
    } else {
      this.getTeachingLanguage({ id: this.teachingCategoryLangId }).then(res => {
        this.formData = this.teachingLanguage
        this.formData.color = {
          style: this.teachingLanguage.color
        }
        if (this.teachingLanguage.flag === null) {
          this.formData.flag = []
        } else {
          this.formData.flag = [this.teachingLanguage.flag]
        }
      })
    }
  },
  computed: {
    ...mapState({
      languages: state => state.language.languages,
      teachingLanguage: state => state.teachingLanguage.teachingLanguage
    })
  },
  methods: {
    ...mapActions({
      getLanguages: 'language/getLanguages',
      createLanguageCategory: 'teachingLanguage/createLanguageCategory',
      updateTeachingLanguage: 'teachingLanguage/updateTeachingLanguage',
      getTeachingLanguage: 'teachingLanguage/getTeachingLanguage'

    }),
    fileHandler(event){
      this.$set(this.formData, 'flag', event[0])
    },
    onChangeColor (attrs, name) {
      this.formData.color = { ...attrs }
    },
    formSubmit () {
      this.$refs.teachingLang.validate().then(success => {
        if (success) {
          const form = document.getElementById('teachingLangForm')
          const formData = new FormData(form)
          if (this.isCreate) {
            formData.append('flag', this.formData.flag)
            this.createLanguageCategory(formData).then(() => {
              this.$router.push('/teaching-lang-categories')
              this.$toasted.success(this.$t('messages.error.somethingWentWrong')).goAway(1500)
            }).catch(() => {
              this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
            })
          } else {
            this.updateTeachingLanguage({ data: formData, id: this.teachingCategoryLangId }).then(() => {
              this.$router.push('/teaching-lang-categories')
            }).catch(() => {
              this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
            })
          }
        } else {
          this.$toasted.error(this.$t('messages.error.validationError')).goAway(1500)
        }
      })
    }
  },
  directives: {
    ClickOutside
  }
}
</script>
<style lang="scss">
  .color-picker-dropdown {
    position: relative;
    &-color-square {
      display: inline-block;
      width: 24px;
      height: 24px;
      vertical-align: middle;
      border-radius: 4px;
    }
    &-content {
      position: absolute;
      top: -395px;
      left: 80px;
      padding: 20px;
      border-radius: 10px;
      z-index: 5;
      background-color: #ffffff;
      box-shadow: 0 0 50px rgb(1, 1, 1 / 10%)
    }
    .ui-color-picker .gradient-controls .gradient-degrees-options .gradient-degree-value p {
      margin: 0;
    }
  }
</style>
