import { http } from '../../../api'

const coupon = {
  namespaced: true,
  state: {
    coupons: [],
    coupon: null,
    statistics: []
  },
  actions: {
    get: ({ commit }, params) => {
      return http.get('/coupons', {params}).then(res => {
        commit('setCoupons', res.data.data)
        return res.data
      })
    },
    store: ({ commit }, params) => {
      return http.post('/coupons', params).then(res => {
        commit('pushCoupon', res.data.data)
        return res.data.data
      })
    },
    delete: ({ commit }, id) => {
      return http.delete('/coupons/' + id).then(res => {
        commit('deleteCoupon', id)
      })
    },
    update: ({ commit }, {
      id,
      data
    }) => {
      return http.put('/coupons/' + id, data).then(res => {
        commit('updateCoupon', res.data.data)
      })
    },
    show({commit},id){
      return http.get('/coupons/' + id).then(res => {
        commit('setCoupon', res.data.data)
        return res.data.data
      })
    },
    getStatistics({commit}, {id, params}){
      return http.get(`/coupons/${id}/statistics`, {params}).then(res => {
        commit('setStatistics', res.data.data)
        return res.data
      })
    }
  },
  mutations: {
    setCoupons (state, data) {
      state.coupons = data
    },
    pushCoupon (state, data) {
      state.coupons.push(data)
    },
    deleteCoupon (state, data) {
      console.log('data', data)
      state.coupons = state.coupons.filter(coupon => coupon.id !== data)
    },
    updateCoupon (state, data) {
      state.coupons = state.coupons.map(coupon => {
        if (coupon.id === data.id) {
          return data
        }
        return coupon
      })
    },
    setCoupon(state, data){
      state.coupon = data
    },
    setStatistics(state, data){
      state.statistics = data
    }
  },
  getters: {
    coupons: state => state.coupons,
    coupon: state => state.coupon,
    statistics: state => state.statistics
  }
}

export default coupon
