import { http } from '../../../api'

const admin = {
  namespaced: true,
  state: {
    admins: [],
    pagination: {},
    admin: {}
  },
  actions: {
    getAdmins: ({ commit }, params) => {
      const q = new URLSearchParams(params)
      return http.get('/admin?' + q.toString()).then(res => {
        commit('setAdmins', res.data)
      })
    },
    deleteAdmin: ({ commit }, params) => {
      return http.delete('admin/' + params.id).then(res => {
        if (res.data.deleteType === 'forceDelete') {
          commit('setAdminAfterDelete', params.id)
        } else if (res.data.deleteType === 'delete') {
          commit('updateStatus', { id: params.id, status: 'inactive' })
        }
      })
    },
    restoreAdmin: ({ commit }, params) => {
      return http.get('/admin/' + params.id + '/restore').then(res => {
        commit('updateStatus', { id: params.id, status: 'active' })
      })
    },
    getAdmin: ({ commit }, params) => {
      return http.get('/admin/' + params.id).then(res => {
        commit('setAdmin', res.data.data)
        return res
      })
    },
    createAdmin: ({ commit }, params) => {
      return http.post('/admin', params, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(res => {
        return res.data
      })
    },
    updateAdmin: ({ commit }, params) => {
      return http.post('/admin/' + params.id, params.data).then(res => {
      })
    },
    updateAdminPassword: ({ commit }, params) => {
      return http.post('/admin/' + params.id + '/update-password', params.data).then(res => {
        return res
      })
    }
  },
  mutations: {
    setAdmins (state, data) {
      state.admins = data.data
      state.pagination = data.pagination
    },
    setAdminAfterDelete (state, id) {
      state.admins = state.admins.filter(item => item.id !== id)
    },
    updateStatus (state, data) {
      state.admins = state.admins.map(admin => {
        if (admin.id === data.id) {
          admin.status = data.status
        }
        return admin
      })
    },
    setAdmin (state, data) {
      state.admin = data
    }
  },
  getters: {
    admins: state => state.admins,
    pagination: state => state.pagination,
    admin: state => state.admin
  }
}

export default admin
