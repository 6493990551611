<template>
  <div>
    <div class="page-header">
      <div>
        <h2 class="main-content-title tx-24 mg-b-5">
          {{ isCreate ? 'Добавить' : 'Редактировать' }} статью в интересное</h2>
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="#" @click="$router.go(-1)">Назад</a></li>
          <li class="breadcrumb-item active" aria-current="page">
            {{ isCreate ? 'Добавить' : 'Редактировать' }} статью в интересное
          </li>
        </ol>
      </div>
      <div class="d-flex">
      </div>
    </div>
    <div class="row row-sm justify-content-center">
      <div class="col-xl-7 col-lg-10 col-md-12">
        <div class="card custom-card">
          <div class="card-body">
            <div>
              <h6 class="main-content-label mb-1">Статья интересное</h6>
            </div>
            <div>
              <ValidationObserver v-if="showVideo === true" id="addInterestingArticleForm" tag="form"
                                  ref="InterestingArticle" class="d-flex flex-column" @submit.prevent="formSubmit()">
                <b-tabs content-class="mt-3">
                  <b-tab :title="language.code" v-for="(language, i) in languages" :key="i">
                    <ValidationProvider v-slot="{ classes }" rules="required" tag="div" class="form-group">
                      <label for="title">Название</label>
                      <input class="form-control" id="title" v-model="formData.title[language.code]" :class="classes"
                             :name="'title[' + language.code + ']'" placeholder="Название" type="text">
                    </ValidationProvider>
                    <ValidationProvider v-slot="{ classes }" rules="required" tag="div" class="form-group">
                      <label for="shor_description">Короткое описание</label>
                      <textarea rows="3" class="form-control" @input="toCountWord($event.target.value)"
                                pattern="^(\s{0,}|a^)([^\s]+(\s|$)){1,45}(\s{0,}|a^)$"
                                v-model="formData.short_description[language.code]"
                                :name="'short_description[' + language.code + ']'" :class="classes"
                                placeholder="Короткое описание" id="shor_description"></textarea>
                    </ValidationProvider>
                    <ValidationProvider v-slot="{ classes }" rules="required" tag="div" class="form-group">
                      <label :for="'description[' + language.code + ']'" class="form-label">Описание:</label>
                      <ckeditor :class="checkValidation(classes, language.code)"
                                @namespaceloaded="onNamespaceLoaded"
                                v-model="formData.description[language.code]" :id="'description[' + language.code + ']'"
                                :config="ckEditorConfig"></ckeditor>
                    </ValidationProvider>

                    <ValidationProvider v-slot="{ classes }" tag="div" class="form-group">
                      <label :for="'seo_title[' + language.code + ']'" class="form-label">Seo Title:</label>
                      <input :id="'seo_title[' + language.code + ']'" maxlength="80" class="form-control"
                             :name="'seo_title[' + language.code + ']'" v-model="formData.seo_title[language.code]"
                             :class="checkValidation(classes, language.code)" placeholder="Seo Title" type="text">
                    </ValidationProvider>
                    <ValidationProvider v-slot="{ classes }" tag="div" class="form-group">
                      <label :for="'seo_description[' + language.code + ']'" class="form-label">Seo Description:</label>
                      <textarea class="form-control" maxlength="180" :name="'seo_description[' + language.code + ']'"
                                :id="'seo_description[' + language.code + ']'" placeholder="Seo Description"
                                :class="checkValidation(classes, language.code)"
                                v-model="formData.seo_description[language.code]"></textarea>
                    </ValidationProvider>
                  </b-tab>
                  <ValidationProvider rules="required" tag="div" class="form-group" v-slot="{ classes }">
                    <label for="category">Выбрать категорию</label>
                    <multiselect id="category" v-model="formData.category_interesting" :multiple="false"
                                 placeholder="Select Category"
                                 label="title" track-by="title" :options="interestingCategoriesList"
                                 :custom-label="customLabel" :class="classes">
                      <template slot="singleLabel" slot-scope="props"
                                v-if="formData && formData.category_interesting!==null">
                        {{ props.option.title[$i18n.locale] }}
                      </template>
                      <template slot="option" slot-scope="props"
                                v-if="formData && formData.category_interesting!==null">
                        <span class="option__name"
                              v-if="props && props.option.title">{{ props.option.title[$i18n.locale] }}</span>
                      </template>
                    </multiselect>
                  </ValidationProvider>
                </b-tabs>
                <FileUpload :is-multiple="false" uploadType="oneImage" label="Иконка:" name="image"
                            accept="image/png,image/jpeg,image/jpg" :validation="true" :filesData="formData.image"
                            :init-enable="true"
                />
                <a href="#" @click.prevent="openModal">Выбрать из загруженных</a>
                <FileUpload :is-multiple="true" uploadType="images" page="interesting" label="Галерея:" name="gallery[]"
                            accept="image/png,image/jpeg,image/jpg" :validation="true" :filesData="formData.gallery"/>
                <FileUpload :is-multiple="false" uploadType="audio" label="Аудио:" name="audio" accept="audio/*"
                            :validation="true" :filesData="formData.audio" :audio-id="formData.audio_id"
                            @fileChange="audioChange"
                            page="interesting"/>
                <FileUpload v-if="(formData.files && !isCreate) || isCreate" page="interesting" :is-multiple="true"
                            uploadType="files" label="Файлы:" name="files[]"
                            accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                            :validation="true" :filesData="formData.files"/>

                <VideoForm
                  :is-edit="!isCreate"
                  iframe-name="video_iframe"
                  video-name="video"
                  :video-type.sync="videoType"
                  :video-file.sync="uploadedVideoFile"
                  :video-iframe.sync="formData.video_iframe"
                />

                <button class="btn ripple btn-main-primary" :disabled="buttonDisable"
                        :class="buttonDisable ? 'button-readonly' : ''">Сохранить
                </button>
              </ValidationObserver>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal ref="images" id="images" hide-footer centered>
      <div class="d-flex flex-wrap images-container ">
        <div class="image-icon" v-for="(icon, name) in icons" :key="name" @click="pickImage(name, icon)">
          <img class="img-fluid" :src="icon" :alt="name"/>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import FileUpload from '../../components/FileUpload'
import VideoForm from '../../components/VideoForm'
import CkeditorMixin from '../../../mixins/CkeditorMixin'
import AudioMixin from '../../../mixins/AudioMixin'

export default {
  name: 'InterestingAdd',
  mixins: [CkeditorMixin, AudioMixin],
  components: {
    VideoForm,
    FileUpload
  },
  data () {
    return {
      formData: {
        title: {},
        description: {},
        seo_title: {},
        seo_description: {},
        uploadedFiles: null,
        short_description: {},
        category_interesting: null,
        category_interesting_id: null,
        category: []
      },
      buttonDisable: false,
      showData: false,
      InterestingArticleId: '',
      isCreate: false,
      showVideo: false,
      videoType: 'video_iframe',
      fileErrorMessage: '',
      uploadedVideoFile: null
    }
  },
  created () {
    this.getIcons()
    this.getAllInterestingCategories().then(res => {
      this.showData = true
    }).catch(() => {
      this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
    })

    this.InterestingArticleId = this.$route.params.id
    if (this.InterestingArticleId === 'add') {
      this.isCreate = true
      this.showVideo = true
    } else {
      this.getInterestingArticle(this.InterestingArticleId).then(res => {
        this.formData = this.interestingArticle
        this.showVideo = true
        if (this.formData.image === null) {
          this.formData.image = []
        }  {
          this.formData.image = [this.formData.image]
        }
        if (this.formData.audio) {
          this.formData.audio_id = this.formData.audio.id
          this.formData.audio = this.formData.audio.path
        }

        if (this.formData.video_iframe || !this.formData.video) {
          this.videoType = 'video_iframe'
        } else {
          this.videoType = 'video'
          this.uploadedVideoFile = this.formData.video.path
        }
      })
    }
  },
  computed: {
    ...mapState({
      languages: state => state.language.languages,
      interestingArticle: state => state.interesting.interestingArticle,
      interestingCategoriesList: state => state.interesting.interestingCategoriesList,
      icons: state => state.interesting.icons
    }),
    audioId () {
      return this.formData?.audio?.id
    }
  },
  methods: {
    ...mapActions({
      getAllInterestingCategories: 'interesting/getAllInterestingCategories',
      createInterestingArticle: 'interesting/createInterestingArticle',
      updateInterestingArticle: 'interesting/updateInterestingArticle',
      getInterestingArticle: 'interesting/getInterestingArticle',
      getIcons: 'interesting/getIcons'
    }),
    openModal(){
      this.$refs.images.show()
    },
    pickImage(name, path){
      this.$set(this.formData, 'image', [path])
      this.$set(this.formData, 'image_name', name)
      this.$refs.images.hide()
    },
    customLabel ({
      title,
      desc
    }) {
      return `${title[this.$i18n.locale]}`
    },
    checkValidation (data, code) {
      return data
    },
    formSubmit () {
      this.$refs.InterestingArticle.validate().then(success => {
        if (success) {
          this.buttonDisable = true
          const form = document.getElementById('addInterestingArticleForm')
          const formData = new FormData(form)
          this.preUploadAudio(formData)

          if (formData.get('image').size === 0){
            if (this.formData.image_name){
              formData.append('image_name', this.formData.image_name)
            }

            formData.delete('image')
          }

          if (this.videoType === 'video_iframe') {
            formData.delete('video')
          } else {
            if (typeof this.uploadedVideoFile !== 'string') {
              formData.append('video', this.uploadedVideoFile)
            } else {
              formData.delete('video')
            }
            formData.delete('video_iframe')
          }

          this.languages.map(language => {
            formData.append('description[' + language.code + ']', this.formData.description[language.code])
          })
          formData.append('category_interesting_id', this.formData.category_interesting.id)
          if (this.isCreate) {
            this.createInterestingArticle(formData).then(() => {
              this.$router.push('/interesting')
            }).catch(() => {
              this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
            }).finally(() => {
              this.buttonDisable = false
            })
          } else {
            this.updateInterestingArticle({
              data: formData,
              id: this.InterestingArticleId
            }).then(() => {
              this.$router.push('/interesting')
            }).catch(() => {
              this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
            }).finally(() => {
              this.buttonDisable = false
            })
          }
        } else {
          this.$toasted.error(this.$t('messages.error.validationError')).goAway(1500)
        }
      })
    },
    uploadCoverFile (file) {
      this.fileErrorMessage = ''
      const uploadFile = file[0]
      this.uploadedVideoFile = uploadFile
    },
    createYoutubeVideo () {
      this.fileErrorMessage = ''
    },
    toCountWord (value) {
      const arr = value.split(' ')
      const newArr = arr.filter(item => item !== '')
      this.wordCount = newArr.length
      if (this.wordCount > 45) {
        this.$toasted.error(this.$t('Повышен лимит слов! Количество допускаемых слов 45')).goAway(1500)
      }
    }
  }
}
</script>
<style scoped lang="scss">

.images-container {
  padding: 20px;
}

.image-icon {
  height: 100px;
  width: 100px;
  cursor: pointer;
  border: 1px solid black;
  margin-right: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    border: 2px solid green;
  }
}
</style>
