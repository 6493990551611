<template>
  <div>
    <div v-if="showRadioBox" class="row mg-y-10 ">
      <div class="col-lg-3 mg-t-20 mg-lg-t-0">
        <label class="radiobox">
          <input :name="radioName" v-model="video_type" type="radio" value="video_iframe" />
          <span>Добавить Iframe</span>
        </label>
      </div>
      <div class="col-lg-3">
        <label class="radiobox">
          <input :name="radioName" v-model="video_type" type="radio" value="video" />
          <span>Загрузить видео</span>
        </label>
      </div>
    </div>
    <ValidationProvider
      v-if="video_type === 'video_iframe'"
      :rules="{ required, regex: /(?:<iframe[^>]*)(?:(?:\/>)|(?:>.*?<\/iframe>))/ }"
      tag="div"
      v-slot="{ classes }"
      class="form-group"
    >
      <label for="video_iframe" class="form-label">Iframe видео:</label>
      <input
        :id="iframeName"
        class="form-control"
        :name="iframeName"
        @input="createIframeVideo"
        v-model="video_iframe"
        placeholder="Iframe видео"
        :class="classes"
        type="text"
      />
    </ValidationProvider>
    <ValidationProvider v-else tag="div" class="form-group">
      <div>
        <label class="form-label">Видео:</label>
      </div>
      <div class="custom-file">
        <input
          id="inputGroupFile01"
          :name="videoName"
          accept=".mp4, .webm, .ogg, .avi, .mkv"
          type="file"
          class="custom-file-input"
          @change="uploadCoverFile($event.target.files)"
          aria-describedby="inputGroupFileAddon01"
        />
        <label v-if="video_file" class="custom-file-label" for="inputGroupFile01">
          {{ isEdit && !video_file.name ? video_file : video_file.name }}
        </label>
        <label class="custom-file-label" for="inputGroupFile01" v-else>Выбрать файл</label>
      </div>
    </ValidationProvider>

    <span class="text-danger" v-if="fileErrorMessage">{{ fileErrorMessage }}</span>
  </div>
</template>

<script>
export default {
  name: 'VideoForm',
  props: {
    required: {
      type: Boolean,
      default: false
    },
    showRadioBox: {
      type: Boolean,
      default: true
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    videoFile: {
      required: true
    },
    videoIframe: {
      required: true
    },
    videoType: {
      type: String,
      default: null
    },
    videoName: {
      type: String,
      default: 'announcement[video]'
    },
    iframeName: {
      type: String,
      default: 'announcement[video_iframe]'
    },
    radioName: {
      type: String,
      default: 'video_type'
    }
  },
  data() {
    return {
      fileErrorMessage: '',
      video_file: this.videoFile,
      video_iframe: this.videoIframe,
      video_type: this.videoType
    }
  },
  methods: {
    uploadCoverFile(file) {
      this.fileErrorMessage = ''
      const uploadFile = file[0]
      this.video_file = uploadFile
      this.$emit('changeVideoFile', uploadFile)
    },
    createIframeVideo() {
      this.fileErrorMessage = ''
    }
  },
  watch: {
    video_iframe(newVal) {
      this.$emit('update:videoIframe', newVal)
    },
    video_type(newVal) {
      this.$emit('update:videoType', newVal)
    },
    video_file(newVal) {
      this.$emit('update:videoFile', newVal)
    },
    videoType(newVal) {
      this.video_type = newVal
    },
    videoFile(newVal) {
      this.video_file = newVal
    },
    videoIframe(newVal) {
      this.video_iframe = newVal
    }
  }
}
</script>
