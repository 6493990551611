<template>
  <div>
    <div class="page-header">
      <breadcrumbs name="Уровень курса" />
      <div class="d-flex">
      </div>
    </div>
    <div class="row row-sm justify-content-center">
      <div class="col-lg-6 col-md-12">
        <div class="card custom-card">
          <div class="card-body">
            <div>
              <h6 class="main-content-label mb-1">Уровень курса</h6>
            </div>
            <div>
              <ValidationObserver tag="form" ref="courseLevelAddForm" class="d-flex flex-column" @submit.prevent="formSubmit()">
                <b-tabs content-class="mt-3">
                  <b-tab :title="language.code" v-for="(language, i) in languages" :key="i">
                    <ValidationProvider v-slot="{ classes }" rules="required" tag="div" class="form-group">
                      <label :for="'name[' + language.code + ']'" class="form-label">Название:</label>
                      <input :id="'name[' + language.code + ']'" class="form-control" :name="'name[' + language.code + ']'" v-model="formData.name[language.code]" :class="checkValidation(classes, language.code)" placeholder="Название" type="text">
                    </ValidationProvider>
                  </b-tab>
                </b-tabs>
                <button class="btn ripple btn-main-primary mt-2" :disabled="buttonDisable" :class="buttonDisable ? 'button-readonly' : ''"><ButtonName /></button>
              </ValidationObserver>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>import { mapActions, mapState } from 'vuex'
import Breadcrumbs from '../../components/Breadcrumbs'
import ButtonName from '../../components/ButtonName'
export default {
  name: 'CourseLevelAdd',
  components: { ButtonName, Breadcrumbs },
  data () {
    return {
      buttonDisable: false,
      formData: {
        name: {}
      },
      isCreate: false,
      courseLevelId: null
    }
  },
  computed: {
    ...mapState({
      languages: state => state.language.languages,
      courseLevel: state => state.courseLevel.courseLevel
    })
  },
  created () {
    this.courseLevelId = this.$route.params.id
    if (this.courseLevelId === 'add') {
      this.isCreate = true
    } else {
      this.getCourseLevel(this.courseLevelId).then(res => {
        this.formData = this.courseLevel
      })
    }
  },
  methods: {
    ...mapActions({
      createCourseLevel: 'courseLevel/createCourseLevel',
      updateCourseLevel: 'courseLevel/updateCourseLevel',
      getCourseLevel: 'courseLevel/getCourseLevel'
    }),
    customLabel ({ name, desc }) {
      return `${name[this.$i18n.locale]}`
    },
    formSubmit () {
      this.$refs.courseLevelAddForm.validate().then(success => {
        if (success) {
          this.buttonDisable = true
          if (this.isCreate) {
            this.createCourseLevel(this.formData).then(() => {
              this.$router.push('/course-level')
            }).catch(() => {
              this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
            }).finally(() => {
              this.buttonDisable = false
            })
          } else {
            this.updateCourseLevel({ data: this.formData, id: this.courseLevelId }).then(() => {
              this.$router.push('/course-level')
            }).catch(() => {
              this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
            }).finally(() => {
              this.buttonDisable = false
            })
          }
        } else {
          this.$toasted.error(this.$t('messages.error.validationError')).goAway(1500)
        }
      })
    },
    checkValidation (data) {
      return data
    }
  }
}
</script>
