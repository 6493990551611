export default {
  data () {
    return {
      isTest: false,
      isWork: false
    }
  },
  created () {
    if (this.$route.params.testId) {
      if (this.$route.params.testId !== 'work') {
        this.isTest = true
      } else {
        this.isWork = true
      }
    } else {
      this.isTest = false
      this.isWork = false
    }
  },
  methods: {
    preData () {
      this.buttonDisable = true
      const form = document.getElementById('taskAddForm')
      const formData = new FormData(form)

      if (!Object.keys(this.formData.description).length) {
        this.languages.map(language => {
          this.$set(this.formData.description, language.code, '')
        })
      }

      this.languages.map(language => {
        formData.append('description[' + language.code + ']', this.formData.description[language.code])
      })
      this.gallery.forEach(image => {
        formData.append('gallery[]', image)
      })

      if (typeof this.audio === 'string') {
        formData.set('audio', this.audio)
      }else if(this.audio) {
        this.audio.forEach(audio => {
          formData.set('audio', audio)
        })
      }

      if (this.isTest) {
        formData.append('test_id', this.$route.params.testId)
      } else if (this.$route.params.moduleTestId) {
        formData.append('module_test_id', this.$route.params.moduleTestId)
      } else if (this.isWork) {
        formData.append('course_id', this.$route.params.courseId)
      } else {
        formData.append('lesson_block_id', this.$route.params.lessonBlockId)
      }
      if (this.videoType === 'video_iframe') {
        formData.delete('video')
      } else {
        if (typeof this.uploadedVideoFile !== 'string') {
          formData.append('video', this.uploadedVideoFile)
        } else {
          formData.delete('video')
        }
        formData.delete('video_iframe')
      }
      return formData
    },
    afterRequest (type) {
      if (this.isTest) {
        if (type === 'course') {

        } else {
          this.$router.push('/course-test/' + this.$route.params.courseId + '/' + this.$route.params.testId)
        }
      } else if (this.isWork) {
        if (type === 'courses') {
          this.$router.push('/courses')
        } else {
          this.$router.push(`/courses/${this.$route.params.courseId}`)
        }
      } else if (this.$route.params.moduleTestId) {
        if (type === 'course') {
          this.$router.push('/courses')
        } else {
          this.$router.push('/course-module-test/' + this.$route.params.courseId + '/' + this.$route.params.moduleId + '/' + this.$route.params.moduleTestId)
        }
      } else {
        if (type === 'course') {
          this.$router.push('/courses')
        } else {
          this.$router.push('/lesson-block/' + this.$route.params.courseId + '/' + this.$route.params.moduleId + '/' + this.$route.params.lessonId + '/' + this.$route.params.lessonBlockId)
        }
      }
    }
  }
}
