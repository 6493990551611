<template>
  <TaskBase
    help="Введите слова, а система сама перемешает их в случайном порядке."
    :data="formData"
    :update="updateTask"
    :create="createTask"
    :after-validation="afterValidation"
    :task-type-id="12"
    :before-update-request="beforeUpdateRequest"
  >
    <FormCard>
      <b-tabs content-class="mt-3">
        <div class="row">
          <template v-for="(item, index) in words">
            <ValidationProvider v-slot="{ classes }" rules="required" tag="div" class="form-group col-md-5"
                                :key="'words-' + index">
              <input class="form-control" :name="'words[' + index + '][word]'" :class="classes" v-model="item.word"
                     placeholder="Word" type="text">
            </ValidationProvider>
            <ValidationProvider v-slot="{ classes }" rules="required" tag="div" class="form-group col-md-5"
                                :key="'answers-' + index">
              <input class="form-control" :name="'words[' + index + '][answer]'" :class="classes"
                     v-model="item.answer" placeholder="Answer" type="text">
            </ValidationProvider>
            <div class="col-md-2">
                <span class="p-2 text-danger pointer" style="width: 32px;"><i v-if="words.length > 2"
                                                                              @click="deleteTaskWord(item, index)"
                                                                              class="ti-close"></i></span>
            </div>
          </template>
        </div>
        <div class="text-primary pointer" @click="addWord">Добавить слово</div>
      </b-tabs>
    </FormCard>
  </TaskBase>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import FileUpload from '../../../components/FileUpload'
import FormCard from '../../../components/FormCard'
import VideoForm from '../../../components/VideoForm'
import CkeditorMixin from '../../../../mixins/CkeditorMixin'
import TaskMixin from '../../../../mixins/TaskMixin'
import Breadcrumbs from '../../../components/Breadcrumbs'
import AudioMixin from '../../../../mixins/AudioMixin'
import TaskBase from '../../../components/tasks/TaskBase'

export default {
  name: 'TaskRememberFind',
  mixins: [CkeditorMixin, TaskMixin, AudioMixin],
  data () {
    return {
      buttonDisable: false,
      removeWords: [],
      formData: {
        name: {},
        description: {},
        prompt: {},
      },
      videoType: 'video_iframe',
      fileErrorMessage: '',
      uploadedVideoFile: null,
      isCreate: false,
      taskId: null,
      gallery: [],
      words: []
    }
  },
  components: {
    TaskBase,
    Breadcrumbs,
    VideoForm,
    FormCard,
    FileUpload
  },
  computed: {
    ...mapState({
      languages: state => state.language.languages,
      task: state => state.taskRememberFind.task,
      taskType: state => state.taskType.taskType
    })
  },
  created () {
    for (let i = 0; i < 2; i++) {
      this.words.push({
        word: '',
        answer: ''
      })
    }
    this.taskId = this.$route.params.id
    if (this.taskId === 'add') {
      this.isCreate = true
    } else {
      this.isCreate = false
      this.getTask(this.taskId).then(res => {
        this.task.task.words = []
        this.formData = { ...this.task.task }
        this.formData.prompt = this.task.remember_find.prompt
        this.words = [...this.task.remember_find.words]
        if (this.formData.video_iframe || !this.formData.video) {
          this.videoType = 'video_iframe'
        } else {
          this.videoType = 'video'
          this.uploadedVideoFile = this.formData.video
        }
      })
    }
  },
  methods: {
    ...mapActions({
      createTask: 'taskRememberFind/createTask',
      updateTask: 'taskRememberFind/updateTask',
      deleteWord: 'taskRememberFind/deleteWord',
      getTask: 'taskRememberFind/getTask',
      getTaskType: 'taskType/getTaskType'
    }),
    addWord () {
      this.words.push({
        word: '',
        answer: ''
      })
    },
    deleteTaskWord (word, index) {
      if (word.id) {
        this.words = this.words.filter(item => item.id !== word.id)
        this.removeWords.push(word.id)
      } else {
        this.words = this.words.filter((item, i) => i !== index)
      }
    },
    afterValidation(formData){
      if (this.removeWords.length) {
        this.deleteWord({ words_id: this.removeWords })
      }
    },
    beforeUpdateRequest(formData){
      this.words.map((item, index) => {
        formData.set('words[' + index + '][word]', item.word)
        formData.append('words[' + index + '][id]', item.id)
      })
    },
  }
}
</script>
