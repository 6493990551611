import { http } from '../../../api'

const support = {
  namespaced: true,
  state: {
    supportTickets: [],
    userChat: {}
  },
  actions: {
    getAllSupportTickets: ({ commit }, params) => {
      let url = '/support-ticket'
      if (params) {
        const q = new URLSearchParams(params)
        url = url + '?' + q.toString()
      }
      return http.get(url).then(res => {
        commit('setSupportTickets', res.data)
        return res.data
      })
    },
    getUserChat: ({ commit }, params) => {
      return http.get('/support-ticket/' + params.id).then(res => {
        commit('setUserChat', res.data)
        return res.data
      })
    },
    sendNewMessage: ({ commit }, params) => {
      return http.post('/support-ticket/send-message/' + params.id, params.data).then(res => {
      })
    },
    messagesSeen: ({ commit }, params) => {
      return http.get('/support-ticket/mark-as-read/' + params.id).then(res => {
        commit('setMessagesSeen', res.data)
      })
    }
  },
  mutations: {
    setSupportTickets (state, data) {
      state.supportTickets = data.data
      state.paginationSupportTickets = data.pagination
    },
    setMessagesSeen (state, data) {
      state.supportTickets = state.supportTickets.map(chat => {
        if (chat.id === data.data.id) {
          chat.unread_messages = data.data.unread_messages
        }
        return chat
      })
    },
    setUserChat (state, data) {
      state.userChat = data.data
    },
    setNewUserChat (state, data) {
      state.userChat.support_ticket_messages.push(data)
    }
  },
  getters: {
    supportTickets: state => state.supportTickets,
    paginationSupportTickets: state => state.paginationSupportTickets,
    userChat: state => state.userChat
  }
}

export default support
