<template>
  <div>
    <div class="page-header">
      <div>
        <h2 class="main-content-title tx-24 mg-b-5">{{ isCreate ? 'Добавить' : 'Редактировать' }} новость</h2>
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="#" @click="$router.go(-1)">Назад</a></li>
          <li class="breadcrumb-item active" aria-current="page">{{ isCreate ? 'Добавить' : 'Редактировать' }} новость
          </li>
        </ol>
      </div>
      <div class="d-flex">
      </div>
    </div>
    <div class="row row-sm justify-content-center">
      <div class="col-xl-6 col-lg-8 col-md-12">
        <div class="card custom-card">
          <div class="card-body">
            <div>
              <h6 class="main-content-label mb-1">Новость</h6>
            </div>
            <div v-if=" showNews === true">
              <ValidationObserver ref="newsAddForm" id="addNewsForm" tag="form" class="d-flex flex-column"
                                  @submit.prevent="formSubmit()">
                <b-tabs content-class="mt-3">
                  <b-tab :title="language.code" v-for="(language, i) in languages" :key="i">
                    <ValidationProvider v-slot="{ classes }" rules="required" tag="div" class="form-group">
                      <label :for="'title[' + language.code + ']'" class="form-label">Название</label>
                      <input :id="'title[' + language.code + ']'" class="form-control"
                             :name="'title[' + language.code + ']'" v-model="formData.title[language.code]"
                             :class="checkValidation(classes, language.code)" placeholder="Название" type="text">
                    </ValidationProvider>
                    <ValidationProvider v-slot="{ classes }" rules="required" tag="div" class="form-group">
                      <label :for="'shortDescriptions[' + language.code + ']'" class="form-label">Короткое описание</label>
                      <textarea class="form-control" @input="toCountWord($event.target.value)"
                                pattern="^(\s{0,}|a^)([^\s]+(\s|$)){1,45}(\s{0,}|a^)$"
                                :name="'short_description[' + language.code + ']'"
                                :id="'shortDescriptions[' + language.code + ']'" placeholder="Короткое описание"
                                :class="checkValidation(classes, language.code)"
                                v-model="formData.short_description[language.code]"></textarea>
                    </ValidationProvider>
                    <ValidationProvider v-slot="{ classes }" rules="required" tag="div" class="form-group">
                      <label :for="'description[' + language.code + ']'" class="form-label">Описание</label>
                      <ckeditor :class="checkValidation(classes, language.code)"
                                v-model="formData.description[language.code]" :id="'description[' + language.code + ']'"
                                :config="ckEditorConfig"></ckeditor>
                    </ValidationProvider>
                    <ValidationProvider v-slot="{ classes }" rules="required" tag="div" class="form-group">
                      <label :for="'seo_title[' + language.code + ']'" class="form-label">Seo Title:</label>
                      <input :id="'seo_title[' + language.code + ']'" maxlength="80" class="form-control"
                             :name="'seo_title[' + language.code + ']'" v-model="formData.seo_title[language.code]"
                             :class="checkValidation(classes, language.code)" placeholder="Seo Title" type="text">
                    </ValidationProvider>
                    <ValidationProvider v-slot="{ classes }" rules="required" tag="div" class="form-group">
                      <label :for="'seo_description[' + language.code + ']'" class="form-label">Seo Description:</label>
                      <textarea class="form-control" maxlength="180" :name="'seo_description[' + language.code + ']'"
                                :id="'seo_description[' + language.code + ']'" placeholder="Seo Description"
                                :class="checkValidation(classes, language.code)"
                                v-model="formData.seo_description[language.code]"></textarea>
                    </ValidationProvider>
                  </b-tab>
                  <FileUpload accept="image/png,image/jpeg,image/jpg" label="Фото:" name="image" uploadType="oneImage"
                               type="image" :validation="true" :filesData="formData.uploadedFiles"/>
                  <ValidationProvider rules="required" tag="div" v-slot="{ classes }" class="form-group">
                    <label for="image_alt" class="form-label">Image alt:</label>
                    <input id="image_alt" class="form-control" name="image_alt" v-model="formData.image_alt"
                           placeholder="Image alt" :class="classes" type="text">
                  </ValidationProvider>
                  <div class="row mg-y-10 ">
                    <div class="col-lg-3 mg-t-20 mg-lg-t-0">
                      <label class="radiobox">
                        <input checked="" name="video_type" type="radio" v-model="videoType" value="video_iframe">
                        <span>Добавить iframe</span>
                      </label>
                    </div>
                    <div class="col-lg-3">
                      <label class="radiobox">
                        <input name="video_type" v-model="videoType" type="radio" value="video">
                        <span>Загрузить видео</span>
                      </label>
                    </div>
                  </div>
                  <ValidationProvider tag="div" v-if="videoType === 'video_iframe'" v-slot="{ classes }"
                                      :rules="{ regex: /(?:<iframe[^>]*)(?:(?:\/>)|(?:>.*?<\/iframe>))/ }"
                                      class="form-group">
                    <label for="video_iframe" class="form-label">Iframe видео:</label>
                    <input id="video_iframe" class="form-control" name="video_iframe" :class="classes"
                           v-model="formData.video_iframe" placeholder="Iframe видео" type="text">
                  </ValidationProvider>
                  <ValidationProvider v-else tag="div" class="form-group">
                    <div>
                      <label class="form-label">Видео:</label>
                    </div>
                    <div class="custom-file">
                      <input name="video" accept=".mp4, .webm, .ogg" type="file" class="custom-file-input"
                             @change="uploadCoverFile($event.target.files)" id="inputGroupFile01"
                             aria-describedby="inputGroupFileAddon01">
                      <label class="custom-file-label" for="inputGroupFile01"
                             v-if="uploadedVideoFile && uploadedVideoFile.name">{{ isEdit && !uploadedVideoFile.name ? uploadedVideoFile : uploadedVideoFile.name }}</label>
                      <label class="custom-file-label" for="inputGroupFile01" v-else>Выбрать файл</label>
                    </div>
                  </ValidationProvider>
                </b-tabs>
                <button class="btn ripple btn-main-primary" :disabled="buttonDisable"
                        :class="buttonDisable ? 'button-readonly' : ''">{{isCreate?'Сохранить':'Обновить'}}
                </button>
              </ValidationObserver>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import FileUpload from '../../components/FileUpload'

export default {
  name: 'AddNews',
  data () {
    return {
      showNews: false,
      isEdit: false,
      buttonDisable: false,
      formData: {
        title: {},
        short_description: {},
        description: {},
        seo_title: {},
        seo_description: {},
        video_iframe: '',
        errClass: false
      },
      uploadedVideoFile: {},
      ckEditorConfig: {
        toolbar: [['Bold', 'Italic', 'Strikethrough', 'Heading', '|', 'Undo', 'Redo', '|', 'Link', 'BulletedList', 'NumberedList', 'BlockQuote']]
      },
      isCreate: false,
      videoType: 'video_iframe',
      newsId: null,
      letterCount: '',
      wordCount: ''
    }
  },
  components: {
    FileUpload
  },
  computed: {
    ...mapState({
      languages: state => state.language.languages,
      news: state => state.news.news
    })
  },
  created () {
    this.newsId = this.$route.params.id
    if (this.newsId === 'add') {
      this.isEdit = false
      this.isCreate = true
      this.showNews = true
    } else {
      this.isEdit = true
      this.getNews({ id: this.newsId }).then(res => {
        this.showNews = true
        this.formData = this.news
        if (this.formData.video_iframe) {
          this.videoType = 'video_iframe'
        } else {
          this.videoType = 'video'
        }
        if (this.news.image_paths === null) {
          this.formData.uploadedFiles = []
        } else {
          this.formData.uploadedFiles = [this.news.image_paths.small]
        }
        this.uploadedVideoFile.name = this.news.video_path
      })
    }
  },
  methods: {
    ...mapActions({
      createNews: 'news/createNews',
      getNews: 'news/getNews',
      updateNews: 'news/updateNews'

    }),
    checkValidation (data, code) {
      return data
    },
    formSubmit () {
      this.$refs.newsAddForm.validate().then(success => {
        if (success) {
          this.buttonDisable = true
          const form = document.getElementById('addNewsForm')
          const formData = new FormData(form)
          this.languages.map(language => {
            formData.append('description[' + language.code + ']', this.formData.description[language.code])
          })
          if (this.videoType === 'video_iframe') {
            formData.delete('video')
          } else {
            formData.delete('video_iframe')
          }
          if (this.isCreate === true) {
            this.createNews(formData).then(() => {
              this.$toasted.success(this.$t('messages.success.newsCreated')).goAway(1500)
              this.$router.push('/news')
            }).catch(() => {
              this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
            }).finally(() => {
              this.buttonDisable = false
            })
          } else {
            this.updateNews({
              data: formData,
              id: this.$route.params.id
            }).then(res => {
              this.$toasted.success(this.$t('messages.success.newsUpdated')).goAway(1500)
              this.$router.push('/news')
            }).catch(() => {
              this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
            }).finally(() => {
              this.buttonDisable = false
            })
          }
        } else {
          this.$toasted.error(this.$t('messages.error.validationError')).goAway(1500)
        }
      })
    },
    uploadCoverFile (file) {
      const uploadFile = file[0]
      this.uploadedVideoFile = uploadFile
    },
    toCountWord (value) {
      const arr = value.split(' ')
      const newArr = arr.filter(item => item !== '')
      this.wordCount = newArr.length
      if (this.wordCount > 45) {
        this.$toasted.error(this.$t('Повышен лимит слов! Количество допускаемых слов 45')).goAway(1500)
      }
    }
  }
}
</script>
