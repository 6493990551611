<template>
  <div>
    <div class="page-header">
      <Breadcrumbs name="слово в энциклопедию" />
      <div class="d-flex">
      </div>
    </div>
    <div class="row row-sm justify-content-center">
      <div class="col-lg-6 col-md-12">
        <div class="card custom-card">
          <div class="card-body">
            <div>
              <h6 class="main-content-label mb-1">Энциклопедия</h6>
            </div>
            <div>
              <ValidationObserver tag="form" id="teachingLangForm" ref="teachingLang" class="d-flex flex-column"
                                  @submit.prevent="formSubmit()">
                <ValidationProvider v-slot="{ classes }" rules="required" tag="div" class="form-group">
                  <input class="form-control" id="word" v-model="formData.word"
                         :class="classes" type="text" placeholder="Слово">
                </ValidationProvider>
                <ValidationProvider v-slot="{ classes }" rules="required" tag="div" class="form-group">
                  <input class="form-control"  v-model="formData.transliteration"
                         :class="classes" type="text" placeholder="Транслитерация">
                </ValidationProvider>
                <ValidationProvider v-slot="{ classes }" rules="required" tag="div" class="form-group">
                  <input class="form-control" v-model="formData.translate" :class="classes" placeholder="Перевод"
                         type="text">
                </ValidationProvider>
                <ValidationProvider rules="required" tag="div" v-slot="{ classes }" class="form-group mt-3">
                  <label class="form-label" for="example">Пример:</label>
                  <ckeditor :class="classes"
                            id="example" name="letters"
                            v-model="formData.example"
                            placeholder="Пример"
                            :config="ckEditorConfig"></ckeditor>

                </ValidationProvider>
                <ValidationProvider rules="required" v-slot="{ classes }" tag="div" class="form-group mt-3">
                  <label class="form-label" for="Language">Язык:</label>
                  <select class="form-control" :class="classes" id="Language" v-model="formData.teaching_language_id">
                    <option v-for="language in languages" :value="language.id">{{language.name[$i18n.locale]}}</option>
                  </select>
                </ValidationProvider>
                <VideoForm
                  :is-edit="!isCreate"
                  iframe-name="video_iframe"
                  video-name="video"
                  :video-type.sync="formData.videoType"
                  :video-file.sync="formData.video"
                  :video-iframe.sync="formData.iframe"
                />
                <button class="btn ripple btn-main-primary"><ButtonName/></button>
              </ValidationObserver>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import VideoForm from '../../components/VideoForm'
import Breadcrumbs from '../../components/Breadcrumbs'
import ButtonName from '../../components/ButtonName'
export default {
  name: 'EncyclopediaAdd',
  components: { ButtonName, Breadcrumbs, VideoForm },
  data () {
    return {
      formData: {
        word: null,
        example: null,
        transliteration: null,
        translate: null,
        video: '',
        iframe: null,
        teaching_language_id: null,
        videoType: 'video_iframe',
      },
      isCreate: false,
      ckEditorConfig: {
        toolbar: [['Bold', 'Italic', 'Strikethrough', 'Heading', '|', 'Undo', 'Redo', '|', 'Link', 'BulletedList', 'NumberedList', 'BlockQuote']]
      },
    }
  },
  created () {
    this.getLanguageCategoryList().catch(() => {
      this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
    })
    const id = this.$route.params.id
    if (id === 'add') {
      this.isCreate = true
    } else {
      this.getEncyclopedia(id).then(res => {
        this.formData = this.encyclopedia
        if (this.encyclopedia.video){
          this.formData.videoType = 'video'
        }else {
          this.formData.videoType = 'video_iframe'
        }
      })
    }
  },
  computed: {
    ...mapGetters({
      languages: 'teachingLanguage/langCategoryList',
      encyclopedia: 'encyclopedia/encyclopedia'
    })
  },
  methods: {
    ...mapActions({
      getLanguageCategoryList: 'teachingLanguage/getLanguageCategoryList',
      createEncyclopedia: 'encyclopedia/createEncyclopedia',
      getEncyclopedia: 'encyclopedia/getEncyclopedia',
      updateEncyclopedia: 'encyclopedia/updateEncyclopedia',
    }),
    formSubmit () {
      this.$refs.teachingLang.validate().then(success => {
        if (success) {
          const formData = new FormData()
          for (const key  in this.formData){
            formData.append(key, this.formData[key])
          }
          if (this.isCreate) {
            this.createEncyclopedia(formData).then(() => {
              this.$router.push('/encyclopedia')
              this.$toasted.success(this.$t('messages.error.somethingWentWrong')).goAway(1500)
            }).catch(() => {
              this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
            })
          } else {
            formData.append('_method', 'put')
            this.updateEncyclopedia({
              data: formData,
              id: this.encyclopedia.id
            }).then(() => {
              this.$router.push('/encyclopedia')
            }).catch(() => {
              this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
            })
          }
        } else {
          this.$toasted.error(this.$t('messages.error.validationError')).goAway(1500)
        }
      })
    }
  },
}
</script>
<style lang="scss">

</style>
