<template>
  <TaskBase
    :data="formData"
    :update="updateTask"
    :create="createTask"
    :before-update-request="beforeUpdateRequest"
    :after-validation="afterValidation"
    :task-type-id="11"
  >
    <FormCard>
      <div class="d-flex justify-content-between">
        <div class="mb-3 font-weight-bold">Описание: {{ activeQuestion }}</div>
        <div class="d-flex">
          <button v-if="questions.length > 2" type="button" class="btn btn-outline-danger btn-add"
                  @click="removeQuestion"><i
            class="fas fa-trash"></i>
          </button>
          <button type="button" class="btn btn-outline-primary btn-add ml-2" @click="addQuestion"><i
            class="fas fa-plus"></i>
          </button>
        </div>
      </div>
      <div class="d-flex flex-column">
        <b-tabs content-class="mt-3">
          <b-tab :title="language.code" v-for="(language, i) in languages" :key="i">
            <div v-for="(question, index) in questions" :key="index">
              <div v-show="activeQuestion === index + 1">
                <ValidationProvider v-slot="{ classes }" rules="required" tag="div" class="form-group">
                  <label class="form-label">Вопрос:</label>
                  <input class="form-control" :name="'questions['+index+'][question][' + language.code + ']'"
                         v-model="question.question[language.code]" :class="checkValidation(classes, language.code)"
                         placeholder="Введите вопрос" type="text">
                </ValidationProvider>
                <div class="d-flex flex-wrap">
                  <div class="w-50 pl-3 pr-3" v-for="(answer, i) in question.answers" :key="i">
                    <ValidationProvider v-slot="{ classes }" rules="required" tag="div" class="form-group">
                      <label class="form-label"
                             :for="'questions[' + index + '][answers][' + i + '][answer][' + language.code + ']'">Ответ:</label>
                      <div class="d-flex align-items-center">
                        <label
                          :for="'questions[' + index + '][answers][' + i + '][correct_answer][' + language.code + ']'"
                          class="radiobox pr-2 mb-0">
                          <input :value="answer.correct_answer" @change="selectCorrectAnswer(index, i)"
                                 :id="'questions[' + index + '][answers][' + i + '][correct_answer][' + language.code + ']'"
                                 :name="'questions[' + index + '][answers][' + i + '][correct_answer]'"
                                 type="checkbox" v-model="answer.correct_answer">
                          <span></span>
                        </label>
                        <input class="form-control"
                               :name="'questions[' + index + '][answers][' + i + '][answer][' + language.code + ']'"
                               :id="'questions[' + index + '][answers][' + i + '][answer][' + language.code + ']'"
                               v-model="answer.answer[language.code]" :class="checkValidation(classes, language.code)"
                               placeholder="вариант ответа" type="text">
                        <span class="p-2 text-danger pointer" style="width: 32px;"><i
                          v-if="question.answers.length > 4" @click="deleteTaskAnswer(answer, index, i)"
                          class="ti-close"></i></span>
                      </div>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="pl-3 text-primary pointer" @click="addAnswer(index)">Добавить ответ</div>
                <!--                  <ValidationProvider v-slot="{ classes }" tag="div" class="form-group mt-3">-->
                <!--                    <label class="form-label">Prompt:</label>-->
                <!--                    <textarea class="form-control" :name="'questions['+index+'][prompt][' + language.code + ']'"-->
                <!--                              v-model="question.prompt[language.code]"-->
                <!--                              placeholder="Еnter a prompt"></textarea>-->
                <!--                  </ValidationProvider>-->
              </div>
            </div>
            <div class="d-flex justify-content-center align-items-center">
              <button type="button" class="btn btn-outline-primary d-flex align-items-center rounded-circle p-3"
                      @click="prevQuestion"><i class="ti-arrow-left"></i></button>
              <span class="pl-2 pr-2 font-weight-bold">{{ activeQuestion }}/{{ questions.length }}</span>
              <button type="button" class="btn btn-outline-primary d-flex align-items-center rounded-circle p-3"
                      @click="nextQuestion"><i class="ti-arrow-right"></i></button>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </FormCard>
  </TaskBase>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import FileUpload from '../../../components/FileUpload'
import FormCard from '../../../components/FormCard'
import VideoForm from '../../../components/VideoForm'
import CkeditorMixin from '../../../../mixins/CkeditorMixin'
import TaskMixin from '../../../../mixins/TaskMixin'
import Breadcrumbs from '../../../components/Breadcrumbs'
import AudioMixin from '../../../../mixins/AudioMixin'
import TaskBase from '../../../components/tasks/TaskBase'

export default {
  name: 'TaskWheelFortune',
  mixins: [CkeditorMixin, TaskMixin, AudioMixin],
  data () {
    return {
      buttonDisable: false,
      removeAnswers: [],
      removeQuestions: [],
      formData: {
        name: {},
        description: {},
        questions: []
      },
      activeQuestion: 1,
      videoType: 'video_iframe',
      fileErrorMessage: '',
      uploadedVideoFile: null,
      isCreate: false,
      taskId: null,
      gallery: [],
      questions: []
    }
  },
  components: {
    TaskBase,
    Breadcrumbs,
    VideoForm,
    FormCard,
    FileUpload
  },
  computed: {
    ...mapState({
      languages: state => state.language.languages,
      task: state => state.taskWheelFortune.task,
      taskType: state => state.taskType.taskType
    })
  },
  created () {
    for (let i = 0; i < 1; i++) {
      this.questions.push({
        question: {},
        prompt: {},
        answers: [
          {
            answer: {},
            correct_answer: true
          },
          {
            answer: {},
            correct_answer: false
          },
        ]
      })
    }
    this.taskId = this.$route.params.id
    if (this.taskId === 'add') {
      this.isCreate = true
    } else {
      this.isCreate = false
      this.getTask(this.taskId).then(res => {
        this.formData = this.task.task
        this.questions = this.task.questions
      })
    }
  },
  methods: {
    ...mapActions({
      createTask: 'taskWheelFortune/createTask',
      updateTask: 'taskWheelFortune/updateTask',
      deleteAnswers: 'taskWheelFortune/deleteAnswers',
      getTask: 'taskWheelFortune/getTask',
      getTaskType: 'taskType/getTaskType',
      deleteQuestions: 'taskWheelFortune/deleteQuestions',
    }),
    addQuestion () {
      this.questions.push({
        question: {},
        prompt: {},
        answers: [
          {
            answer: {},
            correct_answer: true
          },
          {
            answer: {},
            correct_answer: false
          },
        ]
      })
    },
    removeQuestion () {
      const length = this.questions.length
      if (this.questions[this.activeQuestion - 1].id) {
        this.removeQuestions.push(this.questions[this.activeQuestion - 1].id)
      }
      this.questions.splice(this.activeQuestion - 1, 1)

      length === this.activeQuestion && this.activeQuestion--
    },
    selectCorrectAnswer (questionIndex, answerIndex) {
      this.questions[questionIndex].answers.map((item, index) => {
        if (answerIndex === index) {
          item.correct_answer = true
        } else {
          item.correct_answer = false
        }
      })
    },
    addAnswer (questionIndex) {
      this.questions[questionIndex].answers.push({
        answer: {},
        correct_answer: false
      })
    },
    deleteTaskAnswer (answer, questionIndex, answerIndex) {
      let correctAnswer = false
      console.log('answer', answer)
      if (answer.id) {
        this.removeAnswers.push(answer.id)
        this.questions[questionIndex].answers = this.questions[questionIndex].answers.filter((item, index) => {
          if (index !== answerIndex) {
            return item
          } else {
            if (item.correct_answer) {
              correctAnswer = true
            }
          }
        })
      } else {
        this.questions[questionIndex].answers = this.questions[questionIndex].answers.filter((item, index) => {
          if (index !== answerIndex) {
            return item
          } else {
            if (item.correct_answer) {
              correctAnswer = true
            }
          }
        })
      }
      if (correctAnswer) {
        this.formData.answers[0].correct_answer = true
      }
    },
    customLabel ({
      name,
      desc
    }) {
      return `${name[this.$i18n.locale]}`
    },
    prevQuestion () {
      if (this.activeQuestion > 1) {
        this.activeQuestion -= 1
      }
    },
    nextQuestion () {
      if (this.activeQuestion < this.questions.length) {
        this.activeQuestion += 1
      }
    },
    afterValidation (formData) {
      if (this.removeAnswers.length) {
        this.deleteAnswers({ answers_id: this.removeAnswers })
      }
    },
    beforeUpdateRequest (formData) {
      if (this.removeQuestions.length) {
        this.deleteQuestions({ questions: this.removeQuestions })
      }

      this.questions.map((item, index) => {
        this.languages.map(language => {
          formData.set('questions[' + index + '][question][' + language.code + ']', item.question[language.code])
        })
        formData.append('questions[' + index + '][id]', item.id)
        item.answers.map((answer, i) => {
          this.languages.map(language => {
            formData.set('questions[' + index + '][answers][' + i + '][answer][' + language.code + ']', answer.answer[language.code])
          })
          formData.set('questions[' + index + '][answers][' + i + '][correct_answer]', answer.correct_answer)
          formData.append('questions[' + index + '][answers][' + i + '][id]', answer.id)
        })
      })
    },
    checkValidation (data) {
      return data
    },
  }
}
</script>
