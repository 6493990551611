import { http } from '../../../api'

const lesson = {
  namespaced: true,
  state: {
    lessonsList: [],
    lesson: {}
  },
  actions: {
    getLessons: ({ commit }, params) => {
      return http.get('/lessons-list/module/' + params).then(res => {
        commit('setLessons', res.data.data)
        return res.data
      })
    },
    getLesson: ({ commit }, id) => {
      return http.get('/lesson/' + id).then(res => {
        commit('setLesson', res.data.data)
        return res.data
      })
    },
    createLesson: ({ commit }, params) => {
      return http.post('/lesson', params).then(res => {
        return res.data
      })
    },
    updateLesson: ({ commit }, params) => {
      return http.post('/lesson/' + params.id, params.data).then(res => {
        return res.data
      })
    },
    delete: ({ commit }, id) => {
      return http.delete('/lesson/' + id).then(res => {
        if (res.data.deleteType === 'forceDelete') {
          commit('removeLesson', id)
        } else {
          commit('updateLessonStatus', { id: id, status: 'inactive' })
        }
      })
    },
    restore: ({ commit }, id) => {
      return http.get('/lesson/' + id + '/restore').then(res => {
        commit('updateLessonStatus', { id: id, status: 'active' })
      })
    }
  },
  mutations: {
    setLessons (state, data) {
      state.lessonsList = data
    },
    setLesson (state, data) {
      state.lesson = data
    },
    updateLessonStatus (state, data) {
      state.lessonsList = state.lessonsList.map(lesson => {
        if (lesson.id === data.id) {
          lesson.status = data.status
        }
        return lesson
      })
    },
    updateLesson (state, data) {
      state.lessonsList = state.lessonsList.map(lesson => {
        if (lesson.id === data.id) {
          lesson = data
        }
        return lesson
      })
    },
    removeLesson (state, id) {
      state.lessonsList = state.lessonsList.filter(module => module.id !== id)
    }
  },
  getters: {
    lessonsList: state => state.lessonsList,
    lesson: state => state.lesson
  }
}

export default lesson
