<template>
  <TaskBase
    :data="formData"
    :update="updateTask"
    :create="createTask"
    :after-validation="afterValidation"
    :before-update-request="beforeUpdateRequest"
    :task-type-id="13"
    help="Напишите слова или фразы правильно по порядку, а система сама перемешает их в случайном порядке для
        экзаменуемого."
  >
    <FormCard>
      <b-tabs content-class="mt-3">
        <div class="d-flex justify-content-end">
          <div>
            <button type="button" class="btn btn-success mb-3" @click="addOffer">Добавить ещё</button>
            <button type="button" class="btn btn-danger mb-3 ml-3" v-if="activeOffer > 0" @click="removeOffer">
              Удалить
            </button>
          </div>
        </div>
        <div v-for="(offer,offerIndex ) in offers" :key="`offer${offerIndex}`" v-show="offerIndex === activeOffer">
          <input :name="`offers[${offerIndex}][id]`" :value="offer.id" hidden>
          <div class="form-group d-flex align-items-center" v-for="(item, index) in offer.words"
               :key="'words-' + index">
            <input hidden :name="'offers['+offerIndex+'][words][' + index + '][id]'" :value="item.id">
            <label v-b-tooltip.hover title="Верные слова или словосочетание" class="radiobox"
                   style="width: min-content; margin-bottom: 0">
              <input :value="item.word_select" v-bind:true-value="true" v-bind:false-value="false"
                     :name="'offers['+offerIndex+'][words][' + index + '][word_select]'" type="checkbox"
                     v-model="item.word_select"><span></span>
            </label>
            <div class="ml-1 mr-1 d-flex align-items-center justify-content-center" style="width: 30px">
              {{ index + 1 }}
            </div>
            <ValidationProvider v-slot="{ classes }" rules="required" tag="div" class="w-100">
              <input class="form-control" :name="'offers['+offerIndex+'][words][' + index + '][word]'"
                     :class="classes" v-model="item.word"
                     placeholder="Word or Fragment" type="text">
            </ValidationProvider>
            <span class="p-2 text-danger pointer" style="width: 32px;"><i v-if="offer.words.length > 2"
                                                                          @click="deleteTaskSuggestedText(item, index)"
                                                                          class="ti-close"></i></span>
          </div>
        </div>

        <div class="text-primary pointer" @click="addWord">Добавить слово/фрагмент</div>
        <div class="d-flex justify-content-center align-items-center">
          <button type="button" class="btn btn-outline-primary d-flex align-items-center rounded-circle p-3"
                  @click="prev"><i class="ti-arrow-left"></i></button>
          <span class="pl-2 pr-2 font-weight-bold">{{ activeOffer + 1 }}/{{ offers.length }}</span>
          <button type="button" class="btn btn-outline-primary d-flex align-items-center rounded-circle p-3"
                  @click="next"><i class="ti-arrow-right"></i></button>
        </div>
      </b-tabs>
    </FormCard>
  </TaskBase>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import FileUpload from '../../../components/FileUpload'
import FormCard from '../../../components/FormCard'
import VideoForm from '../../../components/VideoForm'
import CkeditorMixin from '../../../../mixins/CkeditorMixin'
import TaskMixin from '../../../../mixins/TaskMixin'
import Breadcrumbs from '../../../components/Breadcrumbs'
import AudioMixin from '../../../../mixins/AudioMixin'
import TaskBase from '../../../components/tasks/TaskBase'

export default {
  name: 'TaskSuggestedFromWords',
  mixins: [CkeditorMixin, TaskMixin, AudioMixin],
  data () {
    return {
      buttonDisable: false,
      removeSuggestedWords: [],
      formData: {
        name: {},
        description: {},
        prompt: {},
        words: []
      },
      videoType: 'video_iframe',
      fileErrorMessage: '',
      uploadedVideoFile: null,
      isCreate: false,
      taskId: null,
      gallery: [],
      offers: [],
      activeOffer: 0,
      deletedOffers: []
    }
  },
  components: {
    TaskBase,
    Breadcrumbs,
    VideoForm,
    FormCard,
    FileUpload
  },
  computed: {
    ...mapState({
      languages: state => state.language.languages,
      task: state => state.taskSuggestedText.task,
      taskType: state => state.taskType.taskType
    })
  },
  created () {
    this.pushOffer()
    this.taskId = this.$route.params.id

    if (this.taskId === 'add') {
      this.isCreate = true
    } else {
      this.isCreate = false
      this.getTask(this.taskId).then(res => {
        this.task.task.words = []
        this.formData = { ...this.task.task }
        this.formData.prompt = this.task.suggested_from_words.prompt
        this.offers = [...this.task.suggested_from_words.offers]
      })
    }
  },
  methods: {
    ...mapActions({
      createTask: 'taskSuggestedText/createTask',
      updateTask: 'taskSuggestedText/updateTask',
      deleteWord: 'taskSuggestedText/deleteWord',
      getTask: 'taskSuggestedText/getTask',
      getTaskType: 'taskType/getTaskType'
    }),
    addOffer () {
      this.pushOffer()
    },
    removeOffer () {
      if (this.offers[this.activeOffer].id) {
        this.deletedOffers.push(this.offers[this.activeOffer].id)
      }
      this.offers.splice(this.activeOffer, 1)
      this.activeOffer--
    },
    pushOffer (count = 2) {
      this.offers.push({
        id: null,
        words: []
      })
      for (let i = 0; i < count; i++) {
        this.offers[this.offers.length - 1].words.push({
          word: '',
          word_select: false,
          id: null
        })
      }
    },
    addWord () {
      this.offers[this.activeOffer].words.push({
        word: '',
        word_select: false,
        id: null
      })
    },
    deleteTaskSuggestedText (word, index) {
      if (word.id) {
        this.removeSuggestedWords.push(word.id)
      }
      this.offers[this.activeOffer].words.splice(index, 1)
    },
    afterValidation (formData) {
      if (this.removeSuggestedWords.length) {
        this.deleteWord({ words_id: this.removeSuggestedWords })
      }

      this.offers.forEach((offer, offerIndex) => {
        let number = 1
        offer.words.forEach((item, index) => {
          if (item.word_select) {
            formData.set('offers[' + offerIndex + '][words][' + index + '][number]', number)
            number += 1
          } else {
            formData.set('offers[' + offerIndex + '][words][' + index + '][number]', 0)
          }
        })
      })
    },
    beforeUpdateRequest (formData) {
      this.deletedOffers.forEach((offer, index) => {
        formData.set(`offersId[${index}]`, offer)
      })
    },
    prev () {
      if (this.activeOffer > 0) {
        this.activeOffer -= 1
      }
    },
    next () {
      if (this.activeOffer < this.offers.length - 1) {
        this.activeOffer += 1
      }
    },
  }
}
</script>
