var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"page-header"},[_c('Breadcrumbs',{attrs:{"name":"слово в энциклопедию"}}),_c('div',{staticClass:"d-flex"})],1),_c('div',{staticClass:"row row-sm justify-content-center"},[_c('div',{staticClass:"col-lg-6 col-md-12"},[_c('div',{staticClass:"card custom-card"},[_c('div',{staticClass:"card-body"},[_vm._m(0),_c('div',[_c('ValidationObserver',{ref:"teachingLang",staticClass:"d-flex flex-column",attrs:{"tag":"form","id":"teachingLangForm"},on:{"submit":function($event){$event.preventDefault();return _vm.formSubmit()}}},[_c('ValidationProvider',{staticClass:"form-group",attrs:{"rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.word),expression:"formData.word"}],staticClass:"form-control",class:classes,attrs:{"id":"word","type":"text","placeholder":"Слово"},domProps:{"value":(_vm.formData.word)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "word", $event.target.value)}}})]}}])}),_c('ValidationProvider',{staticClass:"form-group",attrs:{"rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.transliteration),expression:"formData.transliteration"}],staticClass:"form-control",class:classes,attrs:{"type":"text","placeholder":"Транслитерация"},domProps:{"value":(_vm.formData.transliteration)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "transliteration", $event.target.value)}}})]}}])}),_c('ValidationProvider',{staticClass:"form-group",attrs:{"rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.translate),expression:"formData.translate"}],staticClass:"form-control",class:classes,attrs:{"placeholder":"Перевод","type":"text"},domProps:{"value":(_vm.formData.translate)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "translate", $event.target.value)}}})]}}])}),_c('ValidationProvider',{staticClass:"form-group mt-3",attrs:{"rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
return [_c('label',{staticClass:"form-label",attrs:{"for":"example"}},[_vm._v("Пример:")]),_c('ckeditor',{class:classes,attrs:{"id":"example","name":"letters","placeholder":"Пример","config":_vm.ckEditorConfig},model:{value:(_vm.formData.example),callback:function ($$v) {_vm.$set(_vm.formData, "example", $$v)},expression:"formData.example"}})]}}])}),_c('ValidationProvider',{staticClass:"form-group mt-3",attrs:{"rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
return [_c('label',{staticClass:"form-label",attrs:{"for":"Language"}},[_vm._v("Язык:")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.teaching_language_id),expression:"formData.teaching_language_id"}],staticClass:"form-control",class:classes,attrs:{"id":"Language"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.formData, "teaching_language_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.languages),function(language){return _c('option',{domProps:{"value":language.id}},[_vm._v(_vm._s(language.name[_vm.$i18n.locale]))])}),0)]}}])}),_c('VideoForm',{attrs:{"is-edit":!_vm.isCreate,"iframe-name":"video_iframe","video-name":"video","video-type":_vm.formData.videoType,"video-file":_vm.formData.video,"video-iframe":_vm.formData.iframe},on:{"update:videoType":function($event){return _vm.$set(_vm.formData, "videoType", $event)},"update:video-type":function($event){return _vm.$set(_vm.formData, "videoType", $event)},"update:videoFile":function($event){return _vm.$set(_vm.formData, "video", $event)},"update:video-file":function($event){return _vm.$set(_vm.formData, "video", $event)},"update:videoIframe":function($event){return _vm.$set(_vm.formData, "iframe", $event)},"update:video-iframe":function($event){return _vm.$set(_vm.formData, "iframe", $event)}}}),_c('button',{staticClass:"btn ripple btn-main-primary"},[_c('ButtonName')],1)],1)],1)])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h6',{staticClass:"main-content-label mb-1"},[_vm._v("Энциклопедия")])])}]

export { render, staticRenderFns }