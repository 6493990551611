<template>
  <div class="main">
    <div class="image-containet">
      <label class="form-label input__label">Изображение</label>
      <FileUpload
        uploadType="oneImageType"
        :is-multiple="false"
        accept="image/png,image/jpeg,image/jpg"
        :filesData="item.image"
        :name="imageName"
        :init-enable="true"
      />
    </div>
    <div class="video-containet position-relative">
      <label class="form-label input__label">Аудио</label>
      <FileUpload uploadType="audio" :is-multiple="false" accept="audio/*" :filesData="item.audio" :name="audioName" :init-enable="true" />
    </div>
    <div class="audio-containet">
      <VideoForm
        :videoFile="item.video"
        :videoIframe="item.video_iframe"
        :videoName="videoName"
        :iframeName="iframeName"
        :videoType="item.video ? 'video' : 'video_iframe'"
        :isEdit="true"
      />
    </div>
  </div>
</template>

<script>
import FileUpload from './FileUpload.vue'
import VideoForm from './VideoForm.vue'
export default {
  name: 'InsertMediaButtons',
  components: {
    FileUpload,
    VideoForm
  },
  data() {
    return {}
  },
  props: {
    item: {
      type: Object
    },
    audioName: {
      type: String
    },
    imageName: {
      type: String
    },
    videoName: {
      type: String
    },
    iframeName: {
      type: String
    }
  }
}
</script>

<style lang="scss" scoped>
.main {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
}
</style>
