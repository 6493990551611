import { http } from '../../../api'
const resource = 'task-typos'
const taskTypos = {
  namespaced: true,
  state: {
    task: null
  },
  actions: {
    createTask: ({ commit }, params) => {
      return http.post(`/${resource}`, params).then(res => {
        return res.data
      })
    },
    getTask: ({ commit }, params) => {
      return http.get(`/${resource}/` + params).then(res => {
        commit('setTask', res.data)
      })
    },
    updateTask: ({ commit }, params) => {
      return http.post(`/${resource}/` + params.id, params.data).then(res => {
      })
    }
  },
  mutations: {
    setTask (state, data) {
      state.task = data
    }
  },
  getters: {
    task: state => state.task
  }
}

export default taskTypos
