<template>
  <div>
    <div class="page-header">
      <Breadcrumbs name="купон"/>
      <div class="d-flex">
      </div>
    </div>
    <div class="row row-sm justify-content-center">
      <div class="col-lg-6 col-md-12">
        <div class="card custom-card">
          <div class="card-body">
            <div>
              <h6 class="main-content-label mb-1">Купон</h6>
            </div>
            <div class="d-flex justify-content-end">
              <b-button @click="generateCode">Сгенерировать купон</b-button>
            </div>
            <div>
              <ValidationObserver tag="form" id="teachingLangForm" ref="teachingLang" class="d-flex flex-column"
                                  @submit.prevent="formSubmit()">
                <LgInput label="Название" v-model="formData.name" rules="required"/>
                <div class="d-flex align-items-center">
                  <LgInput ref="coupon" label="Купон" class="w-75" v-model="formData.code" rules="required"/>
                  <img @click="copy" width="40" height="40" src="@/assets/img/copy.png" class="cursor-pointer"
                       alt="copy"/>
                </div>

                <div class="w-100 my-2">
                  <label>Тип операции:</label>
                  <div class="d-flex justify-content-between col-5 p-0">
                    <span>Вычитание суммы</span>
                    <ToggleButton color="#6259ca" v-model="formData.is_percent" :sync="true"/>
                    <span>Процент</span>
                  </div>
                </div>
                <LgInput label="Сумма" type="number" v-model="formData.price" rules="required"/>
                <LgInput label="Дата начала работы купона:" rules="required">
                  <b-form-datepicker
                    v-model="formData.start"
                    locale="ru"
                  />
                </LgInput>
                <LgInput label="Дата окончания купона:" help="Если дату не выбрали, значит купон действует не ограниченно.">
                  <b-form-datepicker
                    v-model="formData.end"
                    locale="ru"
                    placeholder="Выберите дату"
                  />
                </LgInput>
                <LgInput label="Количество применений купона:" type="number" v-model="formData.count" rules="required"/>
                <LgInput label="Количество применений купона одним пользователем:" type="number"
                         v-model="formData.count_user" rules="required"
                         help="Если указано 0, то кол-во применений неограниченно."
                />
                <div class="my-2 w-100">
                  <label >Статус:</label>
                  <div class="d-flex justify-content-between col-5 p-0">
                    <span>Отключен</span>
                    <ToggleButton color="#6259ca" v-model="formData.is_active" :sync="true"/>
                    <span>Включен</span>
                  </div>
                </div>

                <button class="btn ripple btn-main-primary">
                  <ButtonName/>
                </button>
              </ValidationObserver>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Breadcrumbs from '../../components/Breadcrumbs'
import ButtonName from '../../components/ButtonName'
import LgInput from '../../components/forms/LgInput'
import { ToggleButton } from 'vue-js-toggle-button'
import Swal from 'sweetalert2'

export default {
  name: 'CouponAdd',
  components: {
    LgInput,
    ButtonName,
    Breadcrumbs,
    ToggleButton
  },
  data () {
    return {
      formData: {
        name: null,
        code: null,
        is_percent: false,
        price: null,
        start: this.getToday(),
        end: null,
        count: 1000,
        count_user: 1,
        is_active: true
      },
      isCreate: false,
    }
  },
  computed: {
    ...mapGetters({
      coupon: 'coupon/coupon'
    })
  },
  created () {
    const id = this.$route.params.id
    if (id === 'add') {
      this.isCreate = true
    } else {
      this.getCoupon(id).then(() => {
        this.formData = this.coupon
      })
    }
  },
  methods: {
    ...mapActions({
      create: 'coupon/store',
      getCoupon: 'coupon/show',
      update: 'coupon/update',
    }),
    getToday () {
      const now = new Date()
      return new Date(now.getFullYear(), now.getMonth(), now.getDate())
    },
    copy () {
      const input = this.$refs.coupon.$refs.input
      input.focus()
      input.select()
      document.execCommand('copy')
      this.$toasted.success('Успешно скопирован').goAway(1500)
    },
    generateCode () {
      this.formData.code = `L2G${this.generateStr(1)}-${this.generateStr(4)}-${this.generateStr(4)}`
    },
    generateStr (length) {
      let result = ''
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ123456789'
      const charactersLength = characters.length
      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() *
          charactersLength))
      }
      return result
    },
    formSubmit () {
      this.$refs.teachingLang.validate().then(success => {
        if (success) {
          if (this.isCreate) {
            this.create(this.formData).then(() => {
              this.$router.push('/coupons')
              this.$toasted.success('Успешно добавлен').goAway(1500)
            }).catch(() => {
              this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
            })
          } else {
            this.update({
              data: this.formData,
              id: this.coupon.id
            }).then(() => {
              this.$router.push('/coupons')
            }).catch(() => {
              this.$toasted.error('Успешно обновлен').goAway(1500)
            })
          }
        } else {
          this.$toasted.error(this.$t('messages.error.validationError')).goAway(1500)
        }
      })
    }
  },
}
</script>
<style lang="scss">
.cursor-pointer {
  cursor: pointer;
}
</style>
