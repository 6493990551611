<template>
  <div>
    <div class="page-header">
      <div>
        <h2 class="main-content-title tx-24 mg-b-5">Статистика использования купона "{{$route.query.name}}"</h2>
      </div>
    </div>
    <div class="row row-sm">
      <div class="col-lg-12">
        <div class="card custom-card">
          <div class="card-body">
            <div>
              <h6 class="main-content-label mb-1">Статистика</h6>
            </div>
            <div class="table-responsive">
              <b-table striped hover :items="statistics" :fields="tableFields">
              </b-table>
              <div class="d-flex justify-content-end  mt-3" v-if="pagination && pagination.last_page > 1">
                <div>
                  <b-pagination
                    class="m-0"
                    @change="getStats"
                    v-model="paginationCurrentPage"
                    :total-rows="pagination.total"
                    :per-page="pagination.per_page"
                    first-text="<<"
                    prev-text="<"
                    next-text=">"
                    last-text=">>"
                  ></b-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Swal from 'sweetalert2'

export default {
  name: 'CouponsStatistics',
  computed: {
    ...mapGetters({
      statistics: 'coupon/statistics',
    }),
    tableFields () {
      return [
        {
          key: 'id',
          label: 'Id',
          sortable: true
        },
        {
          key: 'fio',
          label: 'ФИО пользователя',
          sortable: true
        },
        {
          key: 'comment',
          label: 'Комментарий',
          sortable: true
        },
        {
          key: 'discount',
          label: 'Сумма скидки',
          sortable: true,
          formatter: (value, key, item) => {
            return `-${value} рублей`
          }
        },
      ]
    }
  },
  data () {
    return {
      paginationCurrentPage: 1,
      pagination: null,
    }
  },
  created () {
    this.getStats()
  },
  methods: {
    ...mapActions({
      getStatistics: 'coupon/getStatistics',
    }),
    getStats () {
      const params = {
        id: this.$route.params.id,
        params: {
          page: this.paginationCurrentPage
        }
      }

      this.getStatistics(params)
        .then(res => {
          this.pagination = res.meta
        })
        .catch(() => {
          this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
        })
    },
    Delete (id) {
      Swal.fire({
        text: this.$t('messages.warning.delete'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#748C41',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('buttons.yes'),
        cancelButtonText: this.$t('buttons.no')
      }).then((result) => {
        if (result.value) {
          this.delete(id).then(res => {
            this.$toasted.success('Успешно удален').goAway(1500)
          }).catch(() => {
            this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
          })
        }
      })
    }
  }
}
</script>
<style>

</style>
