<template>
  <div>
    <div class="page-header">
      <div>
        <h2 class="main-content-title tx-24 mg-b-5">{{isCreate ? 'Add Dynamic Page Text' : 'Edit Dynamic Page Text'}}</h2>
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="#" @click="$router.go(-1)">Назад</a></li>
          <li class="breadcrumb-item active" aria-current="page">{{isCreate ? 'Add Dynamic Page Text' : 'Edit Dynamic Page Text'}}</li>
        </ol>
      </div>
      <div class="d-flex">
      </div>
    </div>
    <div class="row row-sm justify-content-center">
      <div class="col-xl-6 col-lg-8 col-md-12">
        <div class="card custom-card">
          <div class="card-body">
            <div>
              <h6 class="main-content-label mb-1">Dynamic Page Text</h6>
            </div>
            <div v-if="showVideo === true">
              <ValidationObserver ref="dynamicPageTextAddForm" id="addDynamicPageTextForm" tag="form" class="d-flex flex-column" @submit.prevent="formSubmit()">
                <b-tabs content-class="mt-3">
                  <b-tab :title="language.code" v-for="(language, i) in languages" :key="i">
                    <ValidationProvider rules="required" tag="div" class="form-group">
                      <multiselect v-model="dynamicPage" placeholder="Select Page" track-by="id" @close="selectDynamicPage(dynamicPage)" :options="dynamicPageList">
                        <template slot="singleLabel" slot-scope="props">
                          {{props.option.title[$i18n.locale]}}
                        </template>
                        <template slot="option" slot-scope="props">
                          <span class="option__name">{{props.option.title[$i18n.locale]}}</span>
                        </template>
                      </multiselect>
                    </ValidationProvider>
                    <ValidationProvider v-slot="{ classes }" rules="required" tag="div" class="form-group">
                      <label :for="'description[' + language.code + ']'" class="form-label">Description:</label>
                      <ckeditor :class="checkValidation(classes, language.code)" v-model="formData.description[language.code]"  :id="'description[' + language.code + ']'" :config="ckEditorConfig"></ckeditor>
                    </ValidationProvider>
                  </b-tab>
                </b-tabs>
                <button class="btn ripple btn-main-primary mt-2" :disabled="buttonDisable" :class="buttonDisable ? 'button-readonly' : ''">Submit</button>
              </ValidationObserver>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>import { mapActions, mapState } from 'vuex'
export default {
  name: 'DynamicPageTextAdd',
  data () {
    return {
      showVideo: false,
      isEdit: false,
      dynamicPage: '',
      buttonDisable: false,
      ckEditorConfig: {
        toolbar: [['Bold', 'Italic', 'Strikethrough', 'Heading', '|', 'Undo', 'Redo', '|', 'Link', 'BulletedList', 'NumberedList', 'BlockQuote']]
      },
      formData: {
        description: {},
        dynamic_page_id: ''
      },
      isCreate: false,
      dynamicPageTextId: ''
    }
  },
  computed: {
    ...mapState({
      languages: state => state.language.languages,
      dynamicPageText: state => state.dynamicPageText.dynamicPageText,
      dynamicPageList: state => state.dynamicPage.dynamicPageList
    })
  },
  created () {
    this.dynamicPageTextId = this.$route.params.id
    this.getAllDynamicPages().then(res => {
    })
    if (this.dynamicPageTextId === 'add') {
      this.isEdit = false
      this.isCreate = true
      this.showVideo = true
    } else {
      this.isEdit = true
      this.getDynamicPageText({ id: this.dynamicPageTextId }).then(res => {
        this.showVideo = true
        this.formData = this.dynamicPageText
        this.dynamicPage = this.formData.dynamic_page
        this.formData.dynamic_page_id = this.formData.dynamic_page.id
      })
    }
  },
  methods: {
    ...mapActions({
      createDynamicPageText: 'dynamicPageText/createDynamicPageText',
      getDynamicPageText: 'dynamicPageText/getDynamicPageText',
      updateDynamicPageText: 'dynamicPageText/updateDynamicPageText',
      getAllDynamicPages: 'dynamicPage/getAllDynamicPages'
    }),
    checkValidation (data) {
      return data
    },
    selectDynamicPage (value) {
      this.formData.dynamic_page_id = value.id
    },
    formSubmit () {
      this.$refs.dynamicPageTextAddForm.validate().then(success => {
        if (success) {
          this.buttonDisable = true
          if (this.isCreate === true) {
            this.createDynamicPageText(this.formData).then(() => {
              this.$toasted.success(this.$t('messages.success.videoCreated')).goAway(1500)
              this.$router.push('/dynamic-page-text')
            }).catch(() => {
              this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
            }).finally(() => {
              this.buttonDisable = false
            })
          } else {
            this.updateDynamicPageText({ data: this.formData, id: this.$route.params.id }).then(res => {
              this.$toasted.success(this.$t('messages.success.videoUpdated')).goAway(1500)
              this.$router.push('/dynamic-page-text')
            }).catch(() => {
              this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
            }).finally(() => {
              this.buttonDisable = false
            })
          }
        } else {
          this.$toasted.error(this.$t('messages.error.validationError')).goAway(1500)
        }
      })
    }
  }
}
</script>
