import { http } from '../../../api'

const encyclopedia = {
  namespaced: true,
  state: {
    encyclopediaList: [],
    encyclopedia: null,
    pagination: {}
  },
  actions: {
    getEncyclopediaList: ({ commit }, params) => {
      return http.get('/vocabularies', params).then(res => {
        commit('setEncyclopediaList', res.data.data)
        commit('setPagination', res.data.meta)
        return res.data
      })
    },
    createEncyclopedia: ({ commit }, params) => {
      return http.post('/vocabularies', params).then(res => {
        return res.data
      })
    },
    deleteEncyclopedia: ({ commit }, id) => {
      return http.delete('/vocabularies/' + id).then(res => {
        if (res.data.deleteType === 'forceDelete') {
          commit('setEncyclopediaAfterDelete', id)
        } else {
          commit('setUpdateEncyclopediaStatus', {
            id,
            status: 'inactive'
          })
        }
      })
    },
    restoreEncyclopedia: ({ commit }, id) => {
      return http.get('/vocabularies/' + id + '/restore').then(res => {
        commit('setUpdateEncyclopediaStatus', {
          id,
          status: 'active'
        })
      })
    },
    getEncyclopedia: ({ commit }, id) => {
      return http.get('/vocabularies/' + id).then(res => {
        commit('setEncyclopedia', res.data.data)
      })
    },
    updateEncyclopedia: ({ commit }, params) => {
      return http.post('/vocabularies/' + params.id, params.data).then(res => {
      })
    },
    getAll: ({ commit }, params) => {
      console.log('params', params)
      return http.get('/vocabularies/getAll?code=' + params.code + '&query=' + params.query).then(res => {
        commit('setEncyclopediaList', res.data.data)
      })
    }
  },
  mutations: {
    setUpdateEncyclopediaStatus (state, data) {
      state.encyclopediaList = state.encyclopediaList.map(item => {
        if (item.id === data.id) {
          item.status = data.status
        }
        return item
      })
    },
    setEncyclopediaList (state, data) {
      state.encyclopediaList = data
    },
    setEncyclopediaAfterDelete (state, id) {
      state.encyclopediaList = state.encyclopediaList.filter(item => item.id !== id)
    },
    setEncyclopedia (state, data) {
      state.encyclopedia = data
    },
    setPagination (state, data) {
      state.pagination = data
    }

  },
  getters: {
    encyclopediaList: state => state.encyclopediaList,
    encyclopedia: state => state.encyclopedia,
    pagination: state => state.pagination
  }
}

export default encyclopedia
