<template>
  <div>
    <div class="page-header">
      <div>
        <h2 class="main-content-title tx-24 mg-b-5">
          {{ isCreate ? 'Добавить' : 'Редактировать' }} категорию</h2>
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="#" @click="$router.go(-1)">Назад</a></li>
          <li class="breadcrumb-item active" aria-current="page">
            {{ isCreate ? 'Добавить' : 'Редактировать' }} категорию
          </li>
        </ol>
      </div>
      <div class="d-flex">
      </div>
    </div>
    <div class="row row-sm justify-content-center">
      <div class="col-xl-6 col-lg-8 col-md-12">
        <div class="card custom-card">
          <div class="card-body">
            <div>
              <h6 class="main-content-label mb-1">Категория</h6>
            </div>
            <div v-if="showVideo === true">
              <ValidationObserver ref="interestingCategoryAddForm" id="interestingCategoryAddForm" tag="form"
                                  class="d-flex flex-column" @submit.prevent="formSubmit()">
                <b-tabs content-class="mt-3">
                  <b-tab :title="language.code" v-for="(language, i) in languages" :key="i">
                    <ValidationProvider v-slot="{ classes }" rules="required" tag="div" class="form-group">
                      <label :for="'title[' + language.code + ']'" class="form-label">Название:</label>
                      <input :id="'title[' + language.code + ']'" class="form-control"
                             :name="'title[' + language.code + ']'" v-model="formData.title[language.code]"
                             :class="checkValidation(classes, language.code)" placeholder="Название" type="text">
                    </ValidationProvider>
                    <ValidationProvider v-slot="{ classes }" tag="div" class="form-group">
                      <label :for="'seo_title[' + language.code + ']'" class="form-label">Seo Title:</label>
                      <input :id="'seo_title[' + language.code + ']'" maxlength="80" class="form-control"
                             :name="'seo_title[' + language.code + ']'" v-model="formData.seo_title[language.code]"
                             :class="checkValidation(classes, language.code)" placeholder="Seo Title" type="text">
                    </ValidationProvider>
                    <ValidationProvider v-slot="{ classes }" tag="div" class="form-group">
                      <label :for="'seo_description[' + language.code + ']'" class="form-label">Seo Description:</label>
                      <textarea class="form-control" maxlength="180" :name="'seo_description[' + language.code + ']'"
                                :id="'seo_description[' + language.code + ']'" placeholder="Seo Description"
                                :class="checkValidation(classes, language.code)"
                                v-model="formData.seo_description[language.code]"></textarea>
                    </ValidationProvider>
                  </b-tab>
                  <ValidationProvider rules="required" tag="div" class="form-group" v-if="langCategoryList">
                    <label for="language">Язык</label>
                    <multiselect v-model="formData.language" id="language" placeholder="Выбрать язык" label="name"
                                 track-by="id" :options="langCategoryList">
                      <template slot="singleLabel" slot-scope="props">
                        {{ props.option.name[$i18n.locale] }}
                      </template>
                      <template slot="option" slot-scope="props">
                        <span class="option__name">{{ props.option.name[$i18n.locale] }}</span>
                      </template>
                    </multiselect>
                  </ValidationProvider>
                </b-tabs>
                <button class="btn ripple btn-main-primary mt-2" :disabled="buttonDisable"
                        :class="buttonDisable ? 'button-readonly' : ''">Submit
                </button>
              </ValidationObserver>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { ColorPicker } from 'vue-color-gradient-picker'

export default {
  name: 'InterestingCategoryAdd',
  data () {
    return {
      showVideo: false,
      isEdit: false,
      buttonDisable: false,
      formData: {
        title: {},
        seo_title: {},
        seo_description: {},
        teaching_language_id: null
      },
      isCreate: false,
      interestingCategoryId: '',
      showColorPicker: false
    }
  },
  components: {
    ColorPicker
  },
  computed: {
    ...mapState({
      languages: state => state.language.languages,
      interestingCategory: state => state.interesting.interestingCategory,
      langCategoryList: state => state.teachingLanguage.langCategoryList,
    })
  },
  created () {
    this.interestingCategoryId = this.$route.params.id
    this.getLanguageCategoryList()
    if (this.interestingCategoryId === 'add') {
      this.isEdit = false
      this.isCreate = true
      this.showVideo = true
    } else {
      this.isEdit = true
      this.getInterestingCategory(this.interestingCategoryId).then(res => {
        this.showVideo = true
        this.formData = this.interestingCategory
        this.formData.color = {
          style: this.interestingCategory.color
        }
      })
    }
  },
  methods: {
    ...mapActions({
      createInterestingCategory: 'interesting/createInterestingCategory',
      updateInterestingCategory: 'interesting/updateInterestingCategory',
      getInterestingCategory: 'interesting/getInterestingCategory',
      getLanguageCategoryList: 'teachingLanguage/getLanguageCategoryList',
    }),
    checkValidation (data) {
      return data
    },
    customLabel ({
      name,
      desc
    }) {
      return `${name[this.$i18n.locale]}`
    },
    formSubmit () {
      this.$refs.interestingCategoryAddForm.validate().then(success => {
        if (success) {
          this.formData.teaching_language_id = this.formData.language && this.formData.language.id
          this.buttonDisable = true
          if (this.isCreate === true) {
            this.createInterestingCategory(this.formData).then(() => {
              this.$toasted.success(this.$t('messages.success.courseCreated')).goAway(1500)
              this.$router.push('/interesting-categories')
            }).catch(() => {
              this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
            }).finally(() => {
              this.buttonDisable = false
            })
          } else {
            this.updateInterestingCategory({
              data: this.formData,
              id: this.$route.params.id
            }).then(res => {
              this.$toasted.success(this.$t('messages.success.courseUpdated')).goAway(1500)
              this.$router.push('/interesting-categories')
            }).catch(() => {
              this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
            }).finally(() => {
              this.buttonDisable = false
            })
          }
        } else {
          this.$toasted.error(this.$t('messages.error.validationError')).goAway(1500)
        }
      })
    },
    onChangeColor (attrs, name) {
      this.formData.color = { ...attrs }
    }
  }
}
</script>
