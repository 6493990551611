<template>
  <div>
    <div class="page-header">
      <div>
        <h2 class="main-content-title tx-24 mg-b-5">FAQ</h2>
      </div>
      <div class="d-flex">
        <div class="justify-content-center">
          <button type="button" @click="sortPosition" class="btn btn-white btn-icon-text my-2 mr-2">
            <i class="ti-exchange-vertical mr-1"></i> Сортировать
          </button>
          <router-link to="/faq/add" type="button" class="btn btn-primary my-2 btn-icon-text">
            <i class="fe fe-plus-square mr-2"></i> Добавить FAQ
          </router-link>
        </div>
      </div>
    </div>
    <div class="row row-sm">
      <div class="col-lg-12">
        <div class="card custom-card">
          <div class="card-body">
            <div>
              <h6 class="main-content-label mb-1">FAQ список</h6>
            </div>
            <div class="table-responsive">
              <b-table striped hover :items="faqList" :fields="tableFields">
                <template #cell(sort)="row">
                  <input type="number" min="0" style="width: 100px" v-model="row.item.sort">
                </template>
                <template #cell(categories)="row">
                  <p class="badge text-white bg-info mr-1" v-for="(category, index) in row.item.categories" :key="index">{{category.name[$i18n.locale]}}</p>
                </template>
                <template #cell(status)="row">
                  <span class="badge text-white" :class="{'bg-success': row.item.status === 'active', 'bg-danger': row.item.status !== 'active'}">{{row.item.status}}</span>
                </template>
                <template #cell(actions)="row">
                  <router-link :to="`/faq/${row.item.id}`" class="btn btn-outline-primary mr-2 mt-1 mb-1">
                    <i class="fe fe-edit"></i>
                  </router-link>
                  <button v-if="row.item.status === 'active'" @click="removeFaq(row.item.id)" type="button" class="btn btn-outline-danger mr-2 mt-1 mb-1">
                    <i class="ti-close"></i>
                  </button>
                  <div v-else class="d-inline-block">
                    <button @click="restoreFaq(row.item.id)" type="button" class="btn btn-outline-success mr-2 mt-1 mb-1">
                      <i class="fe fe-rotate-ccw"></i>
                    </button>
                    <button @click="removeFaq(row.item.id)" type="button" class="btn btn-outline-danger mr-2 mt-1 mb-1">
                      <i class="fe fe-trash-2"></i>
                    </button>
                  </div>
                </template>
              </b-table>
              <div class="d-flex justify-content-end  mt-3">
                <div class="mr-3">
                  <b-form-select
                    @change="changePaginationPerPage"
                    style="width: 80px"
                    v-model="paginationPerPage"
                    :options="paginationOptions"
                  >
                  </b-form-select>
                </div>
                <div>
                  <b-pagination
                    class="m-0"
                    @change="changePagination"
                    v-model="paginationCurrentPage"
                    :total-rows="pagination.count"
                    :per-page="paginationPerPage"
                    first-text="<<"
                    prev-text="<"
                    next-text=">"
                    last-text=">>"
                  ></b-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Swal from 'sweetalert2'

export default {
  name: 'Faq',
  data () {
    return {
      paginationOptions: [
        { value: 6, text: '6' },
        { value: 10, text: '10' },
        { value: 30, text: '30' },
        { value: 100, text: '100' }
      ],
      paginationCurrentPage: 1,
      paginationPerPage: 6,
      pagination: {}
    }
  },
  computed: {
    ...mapState({
      faqList: state => state.faq.faqList
    }),
    tableFields () {
      return [
        {
          key: 'id',
          sortable: true
        },
        {
          key: 'sort',
          label: 'Очередность',
          sortable: true
        },
        {
          key: 'question.' + this.$i18n.locale,
          label: 'Вопрос',
          sortable: true
        },
        {
          key: 'answer.' + this.$i18n.locale,
          label: 'Ответ',
          sortable: true
        },
        {
          key: 'categories',
          label: 'Категория',
          sortable: true
        },
        {
          key: 'status',
          label: 'Статус',
          sortable: true
        },
        {
          key: 'actions',
          label: 'Действие',
          sortable: false
        }
      ]
    }
  },
  created () {
    this.getFaqList({})
  },
  methods: {
    ...mapActions({
      getAllFaqs: 'faq/getAllFaqs',
      restore: 'faq/restore',
      deleteFaq: 'faq/deleteFaq',
      sortPositionFaq: 'faq/sortPositionFaq'
    }),
    getFaqList (params) {
      this.getAllFaqs(params).then(res => {
        this.pagination = res.pagination
      }).catch(() => {
        this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
      })
    },
    changePaginationPerPage (value) {
      this.getFaqList({ page: this.paginationCurrentPage, count: value })
    },
    changePagination (page) {
      this.getFaqList({ page: page, count: this.paginationPerPage })
    },
    removeFaq (id) {
      Swal.fire({
        text: this.$t('messages.warning.delete'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#748C41',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('buttons.yes'),
        cancelButtonText: this.$t('buttons.no')
      }).then((result) => {
        if (result.value) {
          this.deleteFaq(id).then(res => {
            this.$toasted.success(this.$t('messages.success.faqDeleted')).goAway(1500)
          }).catch(() => {
            this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
          })
        }
      })
    },
    restoreFaq (id) {
      Swal.fire({
        text: this.$t('message.warning.restore'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#748C41',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('buttons.yes'),
        cancelButtonText: this.$t('buttons.no')
      }).then((result) => {
        if (result.value) {
          this.restore(id).then(res => {
            this.$toasted.success(this.$t('messages.success.faqRestored')).goAway(1500)
          }).catch(() => {
            this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
          })
        }
      })
    },
    sortPosition () {
      const formData = { data: [] }
      for (let i = 0; i < this.faqList.length; i++) {
        formData.data.push({ id: this.faqList[i].id, sort: this.faqList[i].sort })
      }
      this.sortPositionFaq({ data: formData }).then(res => {
        this.getFaqList({ page: this.paginationCurrentPage, count: this.paginationPerPage })
        this.$toasted.success(res.data.data).goAway(1500)
      })
    }
  }
}
</script>
