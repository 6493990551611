import { http } from '../../../api'

const taskGallows = {
  namespaced: true,
  state: {
    task: null
  },
  actions: {
    createTask: ({ commit }, params) => {
      return http.post('/task-gallows', params).then(res => {
        return res.data
      })
    },
    getTask: ({ commit }, params) => {
      return http.get('/task-gallows/' + params).then(res => {
        commit('setTask', res.data)
      })
    },
    updateTask: ({ commit }, params) => {
      return http.post('/task-gallows/' + params.id, params.data).then(res => {
      })
    }
  },
  mutations: {
    setTask (state, data) {
      state.task = data
    }
  },
  getters: {
    task: state => state.task
  }
}

export default taskGallows
