import Vue from 'vue'
import createPersistedState from 'vuex-persistedstate'
import Vuex from 'vuex'
import auth from './modules/auth'
import faq from './modules/faq'
import faqCategory from './modules/faqCategory'
import language from './modules/language'
import settings from './modules/settings'
import news from './modules/news'
import videos from './modules/videos'
import dynamicPage from './modules/dynamicPage'
import dynamicPageText from './modules/dynamicPageText'
import dashboard from './modules/dashboard'
import user from './modules/user'
import review from './modules/review'
import teachingLangCategory from './modules/teachingLanguage'
import interesting from './modules/interesting'
import contacts from './modules/contacts'
import admin from './modules/admin'
import supportCategory from './modules/supportCategory'
import support from './modules/support'
import announcement from './modules/announcement'
import course from './modules/course'
import courseTariff from './modules/courseTariff'
import book from './modules/book'
import teachingVideo from './modules/teachingVideo'
import teachingAudio from './modules/teachingAudio'
import currency from './modules/currency'
import paymentMethod from './modules/paymentMethod'
import courseModule from './modules/courseModule'
import otherMaterial from './modules/otherMaterial'
import lesson from './modules/lesson'
import task from './modules/task'
import taskTest from './modules/taskTest'
import taskMissingWord from './modules/taskMissingWord'
import taskPickUpTranslation from './modules/taskPickUpTranslation'
import taskFieldDreams from './modules/taskFieldDreams'
import taskGallows from './modules/taskGallows'
import taskRememberFind from './modules/taskRememberFind'
import taskComposeText from './modules/taskComposeText'
import taskDeckCard from './modules/taskDeckCard'
import taskWheelFortune from './modules/taskWheelFortune'
import taskCategorize from './modules/taskCategorize'
import taskType from './modules/taskType'
import courseLevel from './modules/courseLevel'
import lessonBlock from './modules/lessonBlock'
import chatExpert from './modules/chatExpert'
import taskCrossword from './modules/taskCrossword'
import courseTest from './modules/courseTest'
import taskSpeakText from './modules/taskSpeakText'
import taskSuggestedText from './modules/taskSuggestedText'
import courseVideo from './modules/courseVideo'
import courseModuleTest from './modules/courseModuleTest'
import emailTemplate from './modules/emailTemplate'
import subscribe from './modules/subscribe'
import taskTypos from './modules/taskTypos'
import encyclopedia from './modules/encyclopedia'
import type from './modules/type'
import phones from './modules/phones'
import coupon from './modules/coupons'

Vue.use(Vuex)

const dataState = createPersistedState({
  paths: ['auth']
})

export default new Vuex.Store({
  modules: {
    auth: auth,
    language: language,
    settings: settings,
    faqCategory: faqCategory,
    faq: faq,
    news: news,
    videos: videos,
    dynamicPage: dynamicPage,
    dashboard: dashboard,
    user: user,
    review: review,
    interesting: interesting,
    teachingLanguage: teachingLangCategory,
    contacts: contacts,
    admin: admin,
    dynamicPageText: dynamicPageText,
    supportCategory: supportCategory,
    support: support,
    announcement: announcement,
    course: course,
    courseTariff: courseTariff,
    book: book,
    teachingVideo: teachingVideo,
    teachingAudio: teachingAudio,
    paymentMethod: paymentMethod,
    currency: currency,
    courseModule: courseModule,
    otherMaterial: otherMaterial,
    lesson: lesson,
    task: task,
    taskTest: taskTest,
    taskType: taskType,
    taskTypos: taskTypos,
    courseLevel: courseLevel,
    lessonBlock: lessonBlock,
    taskMissingWord: taskMissingWord,
    taskPickUpTranslation: taskPickUpTranslation,
    taskFieldDreams: taskFieldDreams,
    taskGallows: taskGallows,
    taskRememberFind: taskRememberFind,
    taskComposeText: taskComposeText,
    taskDeckCard: taskDeckCard,
    taskWheelFortune: taskWheelFortune,
    taskCategorize: taskCategorize,
    chatExpert: chatExpert,
    taskCrossword: taskCrossword,
    courseTest: courseTest,
    taskSuggestedText: taskSuggestedText,
    courseVideo: courseVideo,
    taskSpeakText: taskSpeakText,
    emailTemplate: emailTemplate,
    subscribe: subscribe,
    courseModuleTest: courseModuleTest,
    encyclopedia: encyclopedia,
    type,
    phones,
    coupon
  },
  plugins: [dataState]
})
