<template>
  <TaskBase
    :data="formData"
    :update="updateTask"
    :create="createTask"
    :after-validation="afterValidation"
    :help="help"
    :validation="validation"
    :task-type-id="6"
  >
    <FormCard>
      <div class="d-flex flex-row align-items-start justify-content-between mb-3">
        <div class="font-weight-bold">Вопросы</div>
        <PresentationButtons
          :contentOutputMethod="formData.content_output_method"
          :contentOutputCount="formData.content_output_count"
          @changePresentationType="$event=>formData.content_output_method = $event"
          @input="$event=>formData.content_output_count = $event"
        />
      </div>
      <hr />
      <div
        class="row border-bottom mb-3"
        v-for="(data, index) in formData.answers"
        :key="index"
      >
        <input hidden :name="`speaks[${index}][id]`" :value="data.id">
        <div class="col-md-6">
          <LgInput type="textarea" label="Пояснительный текст к заданию" :name="`speaks[${index}][prompt]`"
                   v-model="data.prompt"/>
          <ValidationProvider v-slot="{ classes }" rules="required" tag="div" class="form-group">
            <label :for="`answer${index}`" class="form-label">Текст ответа:</label>
            <textarea class="form-control" :name="`speaks[${index}][answer]`" :id="`answer${index}`"
                      placeholder="Answer Text" :class="classes" v-model.trim="data.answer"></textarea>
            <small>Чтобы показать часть текста пользователю, оберните текст в фигурные скобки { }</small>
          </ValidationProvider>
        </div>
        <div class="col-md-2 d-flex flex-column justify-content-center">
          <b-form-checkbox
            :id="`on_sound-${index}`"
            v-model="data.on_sound"
            :name="`speaks[${index}][on_sound]`"
            :value="1"
            :unchecked-value="0"
          >
            Прослушать слово
          </b-form-checkbox>
          <b-form-checkbox
            :id="`can_voice_recording-${index}`"
            v-model="data.can_voice_recording"
            :name="`speaks[${index}][can_voice_recording]`"
            :value="1"
            :unchecked-value="0"
            :class="{'text-danger': !data.can_voice_recording && !data.can_text_input}"
          >
            Запись голоса
          </b-form-checkbox>
          <b-form-checkbox
            :id="`can_text-${index}`"
            v-model="data.can_text_input"
            :name="`speaks[${index}][can_text_input]`"
            :value="1"
            :unchecked-value="0"
            :class="{'text-danger': !data.can_voice_recording && !data.can_text_input}"
          >
            Ввод текста
          </b-form-checkbox>
        </div>
        <div class="col-md-1 pt-4">
          <PlayerButton :play="play[index]" @click.native="playPauseSound(index)"/>
        </div>
        <div class="col-md-1 pt-4 d-flex justify-content-between">
          <button type="button" class="btn btn-outline-primary btn-add" @click="addAnswer"><i
            class="fas fa-plus"></i>
          </button>
          <button type="button" class="btn btn-outline-danger btn-add" v-if="index > 0" @click="deleteAnswer(index)">
            <i
              class="fas fa-trash"></i>
          </button>
        </div>
        <InsertMediaButtons
          :item="data"
          :imageName="`speaks[${index}][image]`"
          :audioName="`speaks[${index}][audio]`"
          :videoName="`speaks[${index}][video]`"
          :iframeName="`speaks[${index}][video_iframe]`"
        />
      </div>
    </FormCard>
  </TaskBase>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import FormCard from '../../../components/FormCard'
import PlayerButton from '../../../components/PlayerButton'
import CkeditorMixin from '../../../../mixins/CkeditorMixin'
import TaskMixin from '../../../../mixins/TaskMixin'
import LgInput from '../../../components/forms/LgInput'
import AudioMixin from '../../../../mixins/AudioMixin'
import TaskBase from '../../../components/tasks/TaskBase'
import PresentationButtons from '../../../components/PresentationButtons.vue'
import InsertMediaButtons from '../../../components/InsertMediaButtons'

export default {
  name: 'TaskSpeakText',
  mixins: [CkeditorMixin, TaskMixin, AudioMixin],
  data () {
    return {
      help: 'Запишите в поле слово, которое должен отгадать экзаменуемый, либо отметьте галочкой "Выбрать из словаря" и\n' +
        '        система сама его подберет из словаря в случайном порядке.\n' +
        '        Если система сама сгенерирует слово, то подсказка не будет отображаться.\n' +
        '        Важно, что длина слова должна быть не более 9 букв, чем меньше букв в слове, тем больше возможностей\n' +
        '        ошибиться есть у экаменуемого и, соответсвенно, легче уровень.',
      buttonDisable: false,
      speech: null,
      play: [],
      formData: {
        name: {},
        description: {},
        content_output_method: 0,
        content_output_count: 3,
        answers: [
          {
            on_sound: false,
            answer: '',
            id: '',
            can_voice_recording: 1,
            can_text_input: 1,
            image: null
          }
        ],
        prompt: {}
      },
      gallery: [],
      videoType: 'video_iframe',
      speakTextvideoType: 'video_iframe',
      speakTextvideoOrAudio: 'audio',
      fileErrorMessage: '',
      fileErrorMessageSpeak: '',
      uploadedVideoFile: null,
      uploadedVideoFileSpeak: null,
      isCreate: false,
      taskId: null
    }
  },
  components: {
    TaskBase,
    LgInput,
    PlayerButton,
    FormCard,
    PresentationButtons,
    InsertMediaButtons
  },
  computed: {
    ...mapState({
      languages: state => state.language.languages,
      task: state => state.taskSpeakText.task,
      taskType: state => state.taskType.taskType
    }),
    ...mapGetters({
      course: 'course/course'
    })

  },
  created () {
    this.speech = new SpeechSynthesisUtterance()
    this.taskId = this.$route.params.id
    if (!this.course) {
      this.getCourse({ id: this.$route.params.courseId })
    }
    if (this.taskId === 'add') {
      this.isCreate = true
    } else {
      this.isCreate = false
      this.getTask(this.taskId).then(res => {
        const { task } = this.task
        this.formData = { ...task, ...this.task.task_speak_text }

        if (task.video_iframe) {
          this.speakTextvideoType = 'video_iframe'
        } else {
          this.speakTextvideoType = 'video'
          this.uploadedVideoFileSpeak = task.video
        }
      })
    }
  },
  methods: {
    ...mapActions({
      createTask: 'taskSpeakText/createTask',
      updateTask: 'taskSpeakText/updateTask',
      getTask: 'taskSpeakText/getTask',
      getTaskType: 'taskType/getTaskType',
      getCourse: 'course/getCourse'
    }),
    deleteAnswer (index) {
      this.formData.answers.splice(index, 1)
    },
    playPauseSound (index) {
      if (!this.formData.answers[index].answer.length) {
        return false
      }

      this.play.map((value, i) => {
        if (index !== i) {
          this.$set(this.play, i, false)
        }
      })

      if (window.speechSynthesis.speaking && this.play[index]) {
        window.speechSynthesis.pause()
        this.$set(this.play, index, false)
        return false
      }

      if (window.speechSynthesis.speaking && !window.speechSynthesis.paused) {
        window.speechSynthesis.cancel()
      }

      this.$set(this.play, index, true)
      this.speech.text = this.formData.answers[index].answer
      this.speech.lang = this.course.teaching_language.code
      window.speechSynthesis.speak(this.speech)
      this.speech.onend = () => {
        this.$set(this.play, index, false)
      }
    },
    addAnswer () {
      this.formData.answers.push({
        on_sound: false,
        answer: '',
        id: '',
        can_voice_recording: 1,
        can_text_input: 1,
        image: null
      })
    },
    afterValidation (formData) {
      if (this.speakTextvideoType === 'video_iframe') {
        formData.delete('speak_text[video]')
      } else {
        formData.append('speak_text[video]', this.uploadedVideoFileSpeak)
        formData.delete('speak_text[video_iframe]')
      }
      formData.append('content_output_method', this.formData.content_output_method)
      formData.append('content_output_count', this.formData.content_output_count)
    },
    validation () {
      return true
    }
  }
}
</script>
