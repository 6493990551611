import { http } from '../../../api'

const otherMaterial = {
  namespaced: true,
  state: {
    otherMaterials: [],
    otherMaterial: {}
  },
  actions: {
    getOtherMaterialsList: ({ commit }, params) => {
      let url = '/other-material'
      if (params) {
        const q = new URLSearchParams(params)
        url = url + '?' + q.toString()
      }
      return http.get(url).then(res => {
        commit('setOtherMaterialsList', res.data.data)
        return res.data
      })
    },
    getOtherMaterial: ({ commit }, id) => {
      return http.get('/other-material/' + id).then(res => {
        commit('setOtherMaterial', res.data.data)
        return res.data
      })
    },
    create: ({ commit }, params) => {
      return http.post('/other-material', params).then(res => {
        return res
      })
    },
    update: ({ commit }, params) => {
      return http.post('/other-material/' + params.id, params.data).then(res => {
        return res
      })
    },
    delete: ({ commit }, id) => {
      return http.delete('/other-material/' + id).then(res => {
        if (res.data.deleteType === 'forceDelete') {
          commit('removeOtherMaterial', id)
        } else {
          commit('updateOtherMaterialStatus', { id: id, status: 'inactive' })
        }
      })
    },
    restore: ({ commit }, id) => {
      return http.get('/faq-category/' + id + '/restore').then(res => {
        commit('updateOtherMaterialStatus', { id: id, status: 'active' })
      })
    },
    deleteFileMaterial: ({ commit }, id) => {
      return http.delete('/other-material/' + id + '/delete-file')
    }
  },
  mutations: {
    setOtherMaterialsList (state, data) {
      state.otherMaterials = data
    },
    updateOtherMaterialStatus (state, data) {
      state.otherMaterials = state.otherMaterials.map(material => {
        if (material.id === data.id) {
          material.status = data.status
        }
        return material
      })
    },
    removeOtherMaterial (state, id) {
      state.otherMaterials = state.otherMaterials.filter(material => material.id !== id)
    },
    setOtherMaterial (state, data) {
      state.otherMaterial = data
    }
  },
  getters: {
    otherMaterials: state => state.otherMaterials,
    otherMaterial: state => state.otherMaterial
  }
}

export default otherMaterial
