<template>
  <div class="container">
    <br/><br/>
    <div class="center crossword d-none" id="crossword"></div>
    <div class="center crossword" id="crossword-arm">
      <div class="row" v-for="(words, index) in board" :key="index">
        <div class="square" :class="word ? 'letter' : ''" v-for="(word, i) in words" :key="i">
          <input v-if="word && !show" class="char" type="text" maxlength="1">
          <span v-else>{{word}}</span>
        </div>
      </div>
    </div>
    <div class="mt-3">
      <div v-for="(word, index) in wordBank" :key="index" class="text-warning text-left">"{{word.string}}" the word does not match</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Crossword',
  props: {
    show: {
      type: Boolean,
      required: false,
      default: true
    },
    wordArray: {
      type: Array,
      required: false,
      default: () => { return ['world', 'programmer', 'crossword', 'vue'] }
    }
  },
  data () {
    return {
      wordArrInput: [],
      board: [],
      wordArr: [],
      wordBank: [],
      wordsActive: [],
      mode: true,
      bounds: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    }
  },
  mounted () {
    this.create()
    this.play()
  },
  methods: {
    update (x, y) {
      this.bounds.top = Math.min(y, this.bounds.top)
      this.bounds.right = Math.max(x, this.bounds.right)
      this.bounds.bottom = Math.max(y, this.bounds.bottom)
      this.bounds.left = Math.min(x, this.bounds.left)
    },
    clean () {
      this.bounds.top = 999
      this.bounds.right = 0
      this.bounds.bottom = 0
      this.bounds.left = 999
    },
    play () {
      this.mode = false
      this.toggleInputBoxes(false)
    },
    toggleInputBoxes (active) {
      const w = window.document.getElementsByClassName('word')
      const d = window.document.getElementsByClassName('clue')

      for (let i = 0; i < w.length; i++) {
        if (active === true) {
          this.removeClass(w[i], 'hide')
          this.removeClass(d[i], 'clueReadOnly')
          d[i].disabled = ''
        } else {
          this.addClass(w[i], 'hide')
          this.addClass(d[i], 'clueReadOnly')
          d[i].disabled = 'readonly'
        }
      }
    },
    addClass (ele, classStr) {
      ele.className = ele.className.replaceAll(' ' + classStr, '') + ' ' + classStr
    },
    removeClass (ele, classStr) {
      ele.className = ele.className.replaceAll(' ' + classStr, '')
    },
    create () {
      if (!this.mode) {
        this.toggleInputBoxes(true)
        window.document.getElementById('crossword').innerHTML = this.boardToHtml()
        this.mode = true
      } else {
        this.wordArr = this.wordArray
        for (var i = 0, isSuccess = false; i < 10 && !isSuccess; i++) {
          this.cleanVars()
          isSuccess = this.populateBoard()
        }
        window.document.getElementById('crossword').innerHTML = (isSuccess) ? this.boardToHtml() : 'Failed to find crossword.'
      }
    },
    boardToHtml () {
      let str = ' '
      for (let i = this.bounds.top - 1; i < this.bounds.bottom + 2; i++) {
        str += "<div class='row'>"
        for (let j = this.bounds.left - 1; j < this.bounds.right + 2; j++) {
          str += this.boardCharToElement(this.board[j][i])
        }
        str += '</div>'
      }
      return str
    },
    cleanVars () {
      this.clean()
      this.wordBank = []
      this.wordsActive = []
      this.board = []
      for (let i = 0; i < 32; i++) {
        this.board.push([])
        for (let j = 0; j < 32; j++) {
          this.board[i].push(null)
        }
      }
    },
    populateBoard () {
      this.prepareBoard()
      for (var i = 0, isOk = true, len = this.wordBank.length; i < len && isOk; i++) {
        isOk = this.addWordToBoard()
      }
      if (this.wordBank) {
        const wordArr = []
        for (let i = 0; i < this.wordBank.length; i++) {
          wordArr.push(this.wordBank[i].string)
        }
        this.$emit('newWordArray', wordArr)
      }
      return isOk
    },
    prepareBoard () {
      this.wordBank = []
      for (var i = 0, len = this.wordArr.length; i < len; i++) {
        this.wordBank.push({
          string: this.wordArr[i],
          char: this.wordArr[i].split(''),
          totalMatches: 0,
          effectiveMatches: 0,
          successfulMatches: []
        })
      }
      for (let i = 0; i < this.wordBank.length; i++) {
        for (let j = 0, wA = this.wordBank[i]; j < wA.char.length; j++) {
          for (let k = 0, cA = wA.char[j]; k < this.wordBank.length; k++) {
            // eslint-disable-next-line no-unmodified-loop-condition
            for (let l = 0, wB = this.wordBank[k]; k !== i && l < wB.char.length; l++) {
              wA.totalMatches += (cA === wB.char[l]) ? 1 : 0
            }
          }
        }
      }
    },
    addWordToBoard () {
      let i
      let len
      let curIndex
      let curWord
      let curChar
      let testWord
      let testChar
      const minMatchDiff = 9999
      let curMatchDiff

      if (this.wordsActive.length < 1) {
        curIndex = 0
        for (i = 0, len = this.wordBank.length; i < len; i++) {
          if (this.wordBank[i].totalMatches < this.wordBank[curIndex].totalMatches) {
            curIndex = i
          }
        }
        this.wordBank[curIndex].successfulMatches = [{ x: 12, y: 12, dir: 0 }]
      } else {
        curIndex = -1

        for (i = 0, len = this.wordBank.length; i < len; i++) {
          console.log('this.wordBank[i]', this.wordBank[i])
          curWord = this.wordBank[i]
          curWord.effectiveMatches = 0
          curWord.successfulMatches = []
          for (let j = 0, lenJ = curWord.char.length; j < lenJ; j++) {
            curChar = curWord.char[j]
            for (let k = 0, lenK = this.wordsActive.length; k < lenK; k++) {
              testWord = this.wordsActive[k]
              for (let l = 0, lenL = testWord.char.length; l < lenL; l++) {
                testChar = testWord.char[l]
                if (curChar === testChar) {
                  curWord.effectiveMatches++
                  const curCross = { x: testWord.x, y: testWord.y, dir: 0 }
                  if (testWord.dir === 0) {
                    curCross.dir = 1
                    curCross.x += l
                    curCross.y -= j
                  } else {
                    curCross.dir = 0
                    curCross.y += l
                    curCross.x -= j
                  }
                  let isMatch = true
                  for (var m = -1, lenM = curWord.char.length + 1; m < lenM; m++) {
                    const crossVal = []
                    if (m !== j) {
                      if (curCross.dir === 0) {
                        const xIndex = curCross.x + m

                        if (xIndex < 0 || xIndex > this.board.length) {
                          isMatch = false
                          break
                        }

                        crossVal.push(this.board[xIndex][curCross.y])
                        crossVal.push(this.board[xIndex][curCross.y + 1])
                        crossVal.push(this.board[xIndex][curCross.y - 1])
                      } else {
                        const yIndex = curCross.y + m

                        if (yIndex < 0 || yIndex > this.board[curCross.x].length) {
                          isMatch = false
                          break
                        }

                        crossVal.push(this.board[curCross.x][yIndex])
                        crossVal.push(this.board[curCross.x + 1][yIndex])
                        crossVal.push(this.board[curCross.x - 1][yIndex])
                      }

                      if (m > -1 && m < lenM - 1) {
                        if (crossVal[0] !== curWord.char[m]) {
                          if (crossVal[0] !== null) {
                            isMatch = false
                            break
                          } else if (crossVal[1] !== null) {
                            isMatch = false
                            break
                          } else if (crossVal[2] !== null) {
                            isMatch = false
                            break
                          }
                        }
                      } else if (crossVal[0] !== null) {
                        isMatch = false
                        break
                      }
                    }
                  }
                  if (isMatch === true) {
                    curWord.successfulMatches.push(curCross)
                  }
                }
              }
            }
          }
          curMatchDiff = curWord.totalMatches - curWord.effectiveMatches
          if (curMatchDiff < minMatchDiff && curWord.successfulMatches.length > 0) {
            curMatchDiff = minMatchDiff
            curIndex = i
          } else if (curMatchDiff <= 0) {
            return false
          }
        }
      }
      if (curIndex === -1) {
        return false
      }

      const spliced = this.wordBank.splice(curIndex, 1)
      this.wordsActive.push(spliced[0])

      const pushIndex = this.wordsActive.length - 1
      const rand = Math.random()
      const matchArr = this.wordsActive[pushIndex].successfulMatches
      const matchIndex = Math.floor(rand * matchArr.length)
      const matchData = matchArr[matchIndex]

      this.wordsActive[pushIndex].x = matchData.x
      this.wordsActive[pushIndex].y = matchData.y
      this.wordsActive[pushIndex].dir = matchData.dir

      for (i = 0, len = this.wordsActive[pushIndex].char.length; i < len; i++) {
        let xIndex = matchData.x
        let yIndex = matchData.y

        if (matchData.dir === 0) {
          xIndex += i
          this.board[xIndex][yIndex] = this.wordsActive[pushIndex].char[i]
        } else {
          yIndex += i
          this.board[xIndex][yIndex] = this.wordsActive[pushIndex].char[i]
        }
        this.update(xIndex, yIndex)
      }

      return true
    },
    boardCharToElement (c) {
      const arr = (c) ? ['square', 'letter'] : ['square']
      return this.eleStr('div', [{ a: 'class', v: arr }], c)
    },
    eleStr (e, c, h) {
      h = !h ? '' : h
      let s = '<' + e + ' '
      for (let i = 0; i < c.length; i++) {
        s += c[i].a + "='" + this.arrayToString(c[i].v, ' ') + "' "
      }
      return (s + '>' + h + '</' + e + '>')
    },
    arrayToString (a, s) {
      if (a === null || a.length < 1) return ''
      if (s === null) s = ','
      let r = a[0]
      for (let i = 1; i < a.length; i++) {
        r += s + a[i]
      }
      return r
    }
  }
}
</script>

<style scoped>
  html{
    height:100%;
  }
  body{
    height:100%;
    min-width: 400px;
    margin:0;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .container{
    text-align: center;
  }
  .center{
    margin: 0 auto;
  }

  .line{
    height: 2em;
  }
  .word, .clue{
    display: inline-block;
    height: 1.5em;
    padding: 0 5px;
  }
  .word{
    text-align: right;
    width: 100px;
  }
  .clue{
    width: 500px;
  }
  .crossword{
    display: block;
    background-color: rgb(32,32,32);
  }
  .square{
    margin: 0 1px 1px 0;
    display: inline-block;
    font: 24px Calibri;
    width: 1.25em;
    height: 1.25em;
    line-height: 1.25em;
    vertical-align: middle;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .letter{
    background-color: rgb(255,255,255);

    -webkit-touch-callout: text;
    -webkit-user-select: text;
    -khtml-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
  }
  .char:focus{
    -webkit-box-shadow: 0 0 0 2px rgba(255,32,32,1);
    -moz-box-shadow: 0 0 0 2px rgba(255,32,32,1);
    box-shadow: inset 0 0 0 2px rgba(255,32,32,1);
  }
  .char {
    font-size:24px;
    text-transform: uppercase;
    outline: 0;
    border: 0;
    padding: 0;
    margin: -1px 0 0 -1px;
    width: 1.35em;
    height: 1.35em;
    text-align: center;
    background: none;
  }

  .hide{
    visibility: hidden;
  }

  .clueReadOnly
  {
    border: 0 ;
    outline: 0;
    color:#303030 !important;
    background:none;
  }
</style>
