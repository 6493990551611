<template>
  <div>
    <div class="page-header">
      <div>
        <h2 class="main-content-title tx-24 mg-b-5">Edit Review</h2>
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="#" @click="$router.go(-1)">Назад</a></li>
          <li class="breadcrumb-item active" aria-current="page">Edit Review</li>
        </ol>
      </div>
      <div class="d-flex">
      </div>
    </div>
    <div class="row row-sm justify-content-center">
      <div class="col-lg-6 col-md-12">
        <div class="card custom-card">
          <div class="card-body">
            <div>
              <h6 class="main-content-label mb-1">Review</h6>
            </div>
            <div>
              <ValidationObserver  tag="form" ref="userReview" class="d-flex flex-column" @submit.prevent="formSubmit()" v-if="showReview">
                <b-tabs content-class="mt-3">
                  <b-tab >
                    <ValidationProvider v-slot="{ classes }" rules="required" tag="div" class="form-group">
                      <input class="form-control" :class="classes" placeholder="User full name" type="text" v-model="formData.full_name">
                    </ValidationProvider>
                    <ValidationProvider v-slot="{ classes }" rules="required" tag="div" class="form-group">
                      <input class="form-control" :class="classes" placeholder="Email" type="text" v-model="formData.email">
                    </ValidationProvider>
                    <ValidationProvider v-slot="{ classes }" rules="required" tag="div" class="form-group">
                      <input class="form-control" :class="classes" placeholder="Text" type="text" v-model="formData.text">
                    </ValidationProvider>
                    <ValidationProvider v-slot="{ classes }" rules="required" tag="div" class="form-group">
                      <input class="form-control"  :class="classes" placeholder="Rating (0-5)"  max="5" min="0" type="number" v-model="formData.rating">
                    </ValidationProvider>
                  </b-tab>
                </b-tabs>
                <button class="btn ripple btn-main-primary" type="submit">Submit</button>
              </ValidationObserver>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'AddFaq',
  data () {
    return {
      showReview: false,
      formData: {
        full_name: '',
        email: '',
        rating: '',
        text: ''
      }
    }
  },
  created () {
    this.getReview(this.$route.params.id).then(res => {
      this.showReview = true
      this.formData = this.review
    })
  },
  computed: {
    ...mapState({
      review: state => state.review.review
    })
  },
  methods: {
    ...mapActions({
      getReview: 'review/getReview',
      updateReview: 'review/updateReview'
    }),
    formSubmit () {
      this.$refs.userReview.validate().then(success => {
        if (success) {
          this.updateReview({ data: this.formData, id: this.$route.params.id }).then(() => {
            this.$router.push('/reviews')
            this.$toasted.success(this.$t('messages.success.reviewsUpdated')).goAway(1500)
          }).catch(() => {
            this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
          })
        }
      })
    }
  }
}
</script>
