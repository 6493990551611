<template>
  <span>
    {{buttonName}}
  </span>

</template>

<script>
export default {
  name: 'ButtonName',
  computed: {
    buttonName(){
      return this.$route.params.id === 'add' ? 'Сохранить':'Обновить'
    }
  }
}
</script>

<style scoped>

</style>
