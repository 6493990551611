import { http } from '../../../api'

export default {
  namespaced: true,
  state: {
    tasksList: [],
    task: null,
  },
  actions: {
    getTasksByLessonBlock: ({ commit }, params) => {
      return http.get('/task/' + params + '/block').then(res => {
        commit('setTasksByLessonBlock', res.data.data)
      })
    },
    getTasks ({ commit }, params) {
      return http.get('/getTasks', { params }).then(res => {
        commit('setTasksByLessonBlock', res.data.data)
      }).catch(e => {
        commit('setTasksByLessonBlock', [])
      })
    },

    delete: ({ commit }, params) => {
      return http.delete('/task/' + params).then(res => {
        if (res.data.deleteType === 'forceDelete') {
          commit('setTaskAfterDelete', params)
        } else {
          commit('setUpdateTaskStatus', {
            id: params,
            status: 'inactive'
          })
        }
      })
    },
    restore: ({ commit }, params) => {
      return http.get('/task/' + params + '/restore').then(res => {
        commit('setUpdateTaskStatus', {
          id: params,
          status: 'active'
        })
      })
    },
    update: ({ commit }, params) => {
      return new Promise((resolve, reject) => {
        return http.put(`/task/${params.id}`, params).then(({ data }) => {
          commit('setTask', data)
          resolve(data)
        }).catch(e => {
          reject(e)
        })
      })
    },
    store: ({ commit }, params) => {
      return new Promise((resolve, reject) => {
        return http.post('/task', params).then(({ data }) => {
          commit('setTask', data)
          resolve(data)
        }).catch(e => {
          reject(e)
        })
      })
    },
    getTask: ({ commit }, id) => {
      return new Promise((resolve, reject) => {
        return http.get(`/task/${id}`).then(({ data }) => {
          commit('setTask', data)
          resolve(data)
        }).catch(e => {
          reject(e)
        })
      })
    },
    sort ({ commit }, params) {
      return http.post('tasks/sort', params).then(({ data }) => {
        return data
      })
    }
  },
  mutations: {
    setTasksByLessonBlock (state, data) {
      state.tasksList = data
    },
    setUpdateTaskStatus (state, data) {
      state.tasksList = state.tasksList.map(item => {
        if (item.id === data.id) {
          item.status = data.status
        }
        return item
      })
    },
    setTaskAfterDelete (state, id) {
      state.tasksList = state.tasksList.filter(item => item.id !== id)
    },
    setTask (state, data) {
      state.task = data
    }
  },
  getters: {
    tasksList: state => state.tasksList,
    task: state => state.task
  }
}

