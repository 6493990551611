import { http } from '../../../api'

const faq = {
  namespaced: true,
  state: {
    contact: {},
    contacts: []
  },
  actions: {
    getContactsList: ({ commit }) => {
      return http.get('/contacts').then(res => {
        commit('setContactsList', res.data.data)
      })
    },
    getContact: ({ commit }, id) => {
      return http.get('/contacts/' + id).then(res => {
        commit('setContact', res.data.data)
        return res.data
      })
    }
  },
  mutations: {
    setContactsList (state, data) {
      state.contacts = data
    },
    setContact (state, data) {
      state.contact = data
    }
  },
  getters: {
    contacts: state => state.contacts,
    contact: state => state.contact
  }
}

export default faq
