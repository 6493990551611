module.exports = [
  {
    name: 'dashboard',
    links: [
      {
        name: 'dashboard',
        path: '/dashboard',
        icon: 'ti-server'
      }
    ]
  },
  {
    name: 'pages',
    links: [
      {
        name: 'Новости',
        path: '/news',
        icon: 'ti-receipt'
      },
      {
        name: 'Интересное',
        show: false,
        icon: 'ti-gallery',
        subMenu: [
          {
            name: 'Интересное',
            path: '/interesting',
            icon: ''
          },
          {
            name: 'Категории интересного',
            path: '/interesting-categories',
            icon: ''
          }
        ]
      },
      {
        name: 'Видео',
        path: '/videos',
        icon: 'ti-video-clapper'
      },
      {
        name: 'dynamic page',
        path: '/dynamic-page',
        icon: 'ti-write'
      },
      {
        name: 'dynamic page text',
        path: '/dynamic-page-text',
        icon: 'ti-text'
      },
      {
        name: 'FAQ',
        show: false,
        icon: 'ti-agenda',
        subMenu: [
          {
            name: 'FAQ',
            path: '/faq',
            icon: ''
          },
          {
            name: 'FAQ категории',
            path: '/faq-category',
            icon: ''
          },
          {
            name: 'Вопрос от клиента',
            path: '/faq-application',
            icon: ''
          }
        ]
      }
    ]
  },
  {
    name: 'Teaching',
    links: [
      {
        name: 'Материал обучения',
        show: false,
        icon: 'ti-briefcase',
        subMenu: [
          {
            name: 'Книги обучения',
            path: '/books',
            icon: ''
          },
          {
            name: 'Видео обучения',
            path: '/teaching-videos',
            icon: ''
          },
          {
            name: 'Аудио обучения',
            path: '/teaching-audios',
            icon: ''
          },
          {
            name: 'Язык обучения',
            path: '/teaching-lang-categories',
            icon: 't'
          },
          {
            name: 'Другие материалы',
            path: '/other-materials',
            icon: ''
          }
        ]
      },
      {
        name: 'Курсы',
        show: false,
        icon: 'ti-write',
        subMenu: [
          {
            name: 'Курсы',
            path: '/courses',
            icon: ''
          },
          {
            name: 'Купоны',
            path: '/coupons',
            icon: ''
          },
          {
            name: 'Тарифы',
            path: '/course-tariff',
            icon: ''
          },
          {
            name: 'Уровни курса',
            path: '/course-level',
            icon: ''
          }
        ]
      }
    ]
  },
  {
    name: 'Клиенты',
    links: [
      {
        name: 'Клиенты',
        show: false,
        icon: 'ti-user',
        subMenu: [
          {
            name: 'Пользователи',
            path: '/user?role=customer',
            icon: 'ti-user'
          },
          {
            name: 'Эксперты',
            path: '/experts?role=expert',
            icon: 'ti-user'
          },
          {
            name: 'Отзывы',
            path: '/reviews',
            icon: 'ti-pencil-alt'
          },
          {
            name: 'Контакты',
            path: '/contacts',
            icon: 'ti-headphone-alt'
          }
        ]
      },
      {
        name: 'Поддержка',
        show: false,
        icon: 'ti-headphone-alt',
        subMenu: [
          {
            name: 'Тема поддержки',
            path: '/support-category',
            icon: 'ti-headphone-alt'
          },
          {
            name: 'support-tickets',
            path: '/support-tickets',
            icon: 'ti-headphone-alt'
          }
        ]
      }
    ]
  },
  {
    name: 'Настройки',
    links: [
      {
        name: 'Язык сайта',
        path: '/language',
        icon: 'ti-server'
      },
      {
        name: 'Подписчики',
        path: '/subscribers',
        icon: 'ti-share'
      },
      {
        name: 'Валюта',
        path: '/currency',
        icon: 'ti-money'
      },
      {
        name: 'Условие оплаты',
        path: '/payment-method',
        icon: 'ti-money'
      },
      {
        name: 'Настройки',
        show: false,
        icon: 'ti-settings',
        subMenu: [
          {
            name: 'Общие',
            path: '/settings'
          },
          {
            name: 'E-mail шаблон',
            path: '/email-template'
          },
          {
            name: 'Номера телефонов',
            path: '/phones'
          }
        ]
      },
      {
        name: 'Администраторы',
        path: '/admins',
        icon: 'fa fa-users-cog'
      },
      {
        name: 'Mail delivery',
        path: '/mail-delivery',
        icon: 'fa fa-envelope-square'
      }
    ]
  }
]
