var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"page-header"},[_c('Breadcrumbs',{attrs:{"name":"аудио обучения"}}),_c('div',{staticClass:"d-flex"})],1),_c('div',{staticClass:"row row-sm justify-content-center"},[_c('div',{staticClass:"col-xl-6 col-lg-8 col-md-12"},[_c('div',{staticClass:"card custom-card"},[_c('div',{staticClass:"card-body"},[_vm._m(0),(_vm.showVideo === true)?_c('div',[_c('ValidationObserver',{ref:"teachingAudioAddForm",staticClass:"d-flex flex-column",attrs:{"id":"teachingAudioAddForm","tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.formSubmit()}}},[_c('b-tabs',{attrs:{"content-class":"mt-3"}},[_vm._l((_vm.languages),function(language,i){return _c('b-tab',{key:i,attrs:{"title":language.code}},[_c('ValidationProvider',{staticClass:"form-group",attrs:{"rules":"required|max:120","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
return [_c('label',{staticClass:"form-label",attrs:{"for":'name[' + language.code + ']'}},[_vm._v("Название:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.name[language.code]),expression:"formData.name[language.code]"}],staticClass:"form-control",class:_vm.checkValidation(classes, language.code),attrs:{"id":'name[' + language.code + ']',"name":'name[' + language.code + ']',"placeholder":"Название","type":"text"},domProps:{"value":(_vm.formData.name[language.code])},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData.name, language.code, $event.target.value)}}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"form-group",attrs:{"rules":"max:500","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
return [_c('label',{staticClass:"form-label",attrs:{"for":'descriptions[' + language.code + ']'}},[_vm._v("Описание:")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.description[language.code]),expression:"formData.description[language.code]"}],staticClass:"form-control",class:_vm.checkValidation(classes, language.code),attrs:{"name":'description[' + language.code + ']',"id":'descriptions[' + language.code + ']',"placeholder":"Description"},domProps:{"value":(_vm.formData.description[language.code])},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData.description, language.code, $event.target.value)}}})]}}],null,true)})],1)}),_c('FileUpload',{attrs:{"is-multiple":false,"uploadType":"audio","label":"Аудио:","name":"audio","accept":"audio/*","validation":true,"filesData":_vm.formData.audio}}),_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"Курс","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('label',{staticClass:"form-label",attrs:{"for":"course_id"}},[_vm._v("Курс:")]),_c('b-select',{class:classes,attrs:{"id":"course_id","value-field":"id","text-field":("name." + (_vm.$i18n.locale)),"options":_vm.courses,"name":"course_id"},model:{value:(_vm.formData.course_id),callback:function ($$v) {_vm.$set(_vm.formData, "course_id", $$v)},expression:"formData.course_id"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,417797811)}),(_vm.fileErrorMessage)?_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.fileErrorMessage))]):_vm._e()],2),_c('button',{staticClass:"btn ripple btn-main-primary mt-2",class:_vm.buttonDisable ? 'button-readonly' : '',attrs:{"disabled":_vm.buttonDisable}},[_c('ButtonName')],1)],1)],1):_vm._e()])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h6',{staticClass:"main-content-label mb-1"},[_vm._v("Аудио обучения")])])}]

export { render, staticRenderFns }