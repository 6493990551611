<template>
  <div>
    <div class="page-header">
      <Breadcrumbs name="книгу"/>
      <div class="d-flex">
      </div>
    </div>
    <div class="row row-sm justify-content-center">
      <div class="col-xl-6 col-lg-8 col-md-12">
        <div class="card custom-card">
          <div class="card-body">
            <div>
              <h6 class="main-content-label mb-1">Книга</h6>
            </div>
            <div v-if="showVideo === true">
              <ValidationObserver ref="bookAddForm" id="bookAddForm" tag="form" class="d-flex flex-column"
                                  @submit.prevent="formSubmit()">
                <b-tabs content-class="mt-3">
                  <b-tab :title="language.code" v-for="(language, i) in languages" :key="i">
                    <ValidationProvider name="Название" v-slot="{ classes, errors }" rules="required|max:120" tag="div" class="form-group">
                      <label :for="'name[' + language.code + ']'" class="form-label">Название:</label>
                      <input :id="'name[' + language.code + ']'" class="form-control"
                             :name="'name[' + language.code + ']'" v-model="formData.name[language.code]"
                             :class="checkValidation(classes, language.code)" placeholder="Название" type="text">
                      <div class="invalid-feedback">{{errors[0]}}</div>
                    </ValidationProvider>
                    <ValidationProvider name="Описание" v-slot="{ classes, errors }" rules="required|max:500" tag="div" class="form-group">
                      <label :for="'descriptions[' + language.code + ']'" class="form-label">Описание:</label>
                      <textarea class="form-control" :name="'description[' + language.code + ']'"
                                :id="'descriptions[' + language.code + ']'" placeholder="Описание"
                                :class="checkValidation(classes, language.code)"
                                v-model="formData.description[language.code]"></textarea>
                      <div class="invalid-feedback">{{errors[0]}}</div>
                    </ValidationProvider>
                  </b-tab>
                  <FileUpload accept="image/png,image/jpeg,image/jpg" label="Фото:" name="image" uploadType="oneImage"
                              type="image" :validation="true" :filesData="formData.image"/>
                  <FileUpload
                    :is-multiple="false"
                    uploadType="audio"
                    label="Аудио:"
                    name="audio"
                    accept="audio/*"
                    @fileChange="audioChange"
                    :validation="true"
                    :filesData="formData.audio"
                  />
                  <FileUpload :is-multiple="false" uploadType="file" label="Файл:" name="file"
                              accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,.txt,.fb2,.fb3,.epub,.zip,.mobi"
                              :validation="true"
                              :filesData="formData.file"/>
                  <ValidationProvider name="Курс" v-slot="{ classes, errors }" rules="required" tag="div" class="form-group">
                    <label for="course_id" class="form-label">Курс:</label>
                    <b-select
                      :class="classes"
                      id="course_id"
                      value-field="id"
                      :text-field="`name.${$i18n.locale}`"
                      :options="courses"
                      name="course_id"
                      v-model="formData.course_id"
                    />
                    <div class="invalid-feedback">{{errors[0]}}</div>
                  </ValidationProvider>
                </b-tabs>
                <button class="btn ripple btn-main-primary mt-2" :disabled="buttonDisable"
                        :class="buttonDisable ? 'button-readonly' : ''">
                  <ButtonName/>
                </button>
              </ValidationObserver>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import FileUpload from '../../components/FileUpload'
import Breadcrumbs from '../../components/Breadcrumbs'
import ButtonName from '../../components/ButtonName'
import AudioMixin from '../../../mixins/AudioMixin'

export default {
  name: 'CourseAdd',
  mixins: [AudioMixin],
  data () {
    return {
      showVideo: false,
      isEdit: false,
      buttonDisable: false,
      formData: {
        name: {},
        description: {}
      },
      isCreate: false,
      bookId: ''
    }
  },
  components: {
    ButtonName,
    Breadcrumbs,
    FileUpload
  },
  computed: {
    ...mapState({
      languages: state => state.language.languages,
      book: state => state.book.book
    }),
    ...mapGetters({
      courses: 'course/activeCoursesList'
    }),
  },
  created () {
    this.bookId = this.$route.params.id
    this.getCourses()
    if (this.bookId === 'add') {
      this.isEdit = false
      this.isCreate = true
      this.showVideo = true
    } else {
      this.isEdit = true
      this.getBook({ id: this.bookId }).then(res => {
        this.showVideo = true
        this.formData = this.book
        if (this.formData.image === null) {
          this.formData.image = []
        } else {
          this.formData.image = [this.formData.image]
        }
      })
    }
  },
  methods: {
    ...mapActions({
      createBook: 'book/createBook',
      getBook: 'book/getBook',
      updateBook: 'book/updateBook',
      getCourses: 'course/getActiveCoursesList'
    }),
    checkValidation (data) {
      return data
    },
    formSubmit () {
      this.$refs.bookAddForm.validate().then(success => {
        if (success) {
          const form = document.getElementById('bookAddForm')
          const formData = new FormData(form)

          this.preUploadAudio(formData)

          this.buttonDisable = true

          if (this.isCreate === true) {
            this.createBook(formData).then(() => {
              this.$toasted.success(this.$t('messages.success.courseCreated')).goAway(1500)
              this.$router.push('/books')
            }).catch(() => {
              this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
            }).finally(() => {
              this.buttonDisable = false
            })
          } else {
            this.updateBook({
              data: formData,
              id: this.$route.params.id
            }).then(res => {
              this.$toasted.success(this.$t('messages.success.courseUpdated')).goAway(1500)
              this.$router.push('/books')
            }).catch(() => {
              this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
            }).finally(() => {
              this.buttonDisable = false
            })
          }
        } else {
          this.$toasted.error("Ошибка валидации формы").goAway(1500)
        }
      })
    }
  }
}
</script>
