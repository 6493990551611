<template>
  <div>
    <div class="page-header">
      <Breadcrumbs name="другие материалы"/>
      <div class="d-flex">
      </div>
    </div>
    <div class="row row-sm justify-content-center">
      <div class="col-lg-6 col-md-12">
        <div class="card custom-card">
          <div class="card-body">
            <div>
              <h6 class="main-content-label mb-1">Другой материал</h6>
            </div>
            <div>
              <ValidationObserver tag="form" ref="otherMaterialForm" id="otherMaterialForm" class="d-flex flex-column"
                                  @submit.prevent="formSubmit()">
                <b-tabs content-class="mt-3">
                  <b-tab :title="language.code" v-for="(language, i) in languages" :key="i">
                    <ValidationProvider v-slot="{ classes }" rules="required" tag="div" class="form-group">
                      <label :for="'name[' + language.code + ']'" class="form-label">Название:</label>
                      <input :id="'name[' + language.code + ']'" class="form-control"
                             :name="'name[' + language.code + ']'" v-model="formData.name[language.code]"
                             :class="checkValidation(classes, language.code)" placeholder="title" type="text">
                    </ValidationProvider>
                    <ValidationProvider v-slot="{ classes }" tag="div" class="form-group">
                      <label :for="'description[' + language.code + ']'" class="form-label">Описание:</label>
                      <textarea class="form-control" :name="'description[' + language.code + ']'"
                                :id="'description[' + language.code + ']'" placeholder="Description"
                                :class="checkValidation(classes, language.code)"
                                v-model="formData.description[language.code]"></textarea>
                    </ValidationProvider>
                  </b-tab>
                  <ValidationProvider name="Курс" v-slot="{ classes, errors }" rules="required" tag="div" class="form-group">
                    <label for="course_id" class="form-label">Курс:</label>
                    <b-select
                      :class="classes"
                      id="course_id"
                      value-field="id"
                      :text-field="`name.${$i18n.locale}`"
                      :options="courses"
                      name="course_id"
                      v-model="formData.course_id"
                    />
                    <div class="invalid-feedback">{{errors[0]}}</div>
                  </ValidationProvider>
                  <FileUpload v-if="(formData.gallery && !isCreate) || isCreate" page="material" :is-multiple="true"
                              uploadType="images" label="Галерея:" name="gallery[]"
                              accept="image/png,image/jpeg,image/jpg" :validation="true" :filesData="formData.gallery"/>
                  <FileUpload v-if="(formData.files && !isCreate) || isCreate" page="material" :is-multiple="true"
                              uploadType="files" label="Файлы:" name="files[]"
                              accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                              :validation="true" :filesData="formData.files"/>
                </b-tabs>
                <button class="btn ripple btn-main-primary mt-2" :disabled="buttonDisable"
                        :class="buttonDisable ? 'button-readonly' : ''">
                  <ButtonName/>
                </button>
              </ValidationObserver>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import FileUpload from '../../components/FileUpload'
import Breadcrumbs from '../../components/Breadcrumbs'
import ButtonName from '../../components/ButtonName'

export default {
  name: 'OtherMaterialsAdd',
  data () {
    return {
      formData: {
        name: {},
        description: {}
      },
      buttonDisable: false,
      otherMaterialId: '',
      isCreate: false
    }
  },
  components: {
    ButtonName,
    Breadcrumbs,
    FileUpload
  },
  created () {
    this.getLanguages().catch(() => {
      this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
    })

    this.getCourses()

    this.otherMaterialId = this.$route.params.id
    if (this.otherMaterialId === 'add') {
      this.isCreate = true
    } else {
      this.getOtherMaterial(this.otherMaterialId).then(res => {
        this.formData = res.data
        this.formData.files = res.data.files_list
      })
    }
  },
  computed: {
    ...mapState({
      languages: state => state.language.languages,
      otherMaterial: state => state.otherMaterial.otherMaterial
    }),
    ...mapGetters({
      courses: 'course/activeCoursesList'
    })
  },
  methods: {
    ...mapActions({
      createOtherMaterial: 'otherMaterial/create',
      updateOtherMaterial: 'otherMaterial/update',
      getLanguages: 'language/getLanguages',
      getOtherMaterial: 'otherMaterial/getOtherMaterial',
      getCourses: 'course/getActiveCoursesList'
    }),
    checkValidation (data) {
      return data
    },
    formSubmit () {
      this.$refs.otherMaterialForm.validate().then(success => {
        if (success) {
          const form = document.getElementById('otherMaterialForm')
          const formData = new FormData(form)
          this.buttonDisable = true
          if (this.isCreate) {
            this.createOtherMaterial(formData).then(() => {
              this.$router.push('/other-materials')
            }).catch(() => {
              this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
            }).finally(() => {
              this.buttonDisable = false
            })
          } else {
            this.updateOtherMaterial({
              data: formData,
              id: this.otherMaterialId
            }).then(() => {
              this.$router.push('/other-materials')
            }).catch(() => {
              this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
            }).finally(() => {
              this.buttonDisable = false
            })
          }
        } else {
          this.$toasted.error(this.$t('messages.error.validationError')).goAway(1500)
        }
      })
    }
  }
}
</script>
