<template>
  <div>
    <div class="page-header">
      <div>
        <h2 class="main-content-title tx-24 mg-b-5">{{isCreate ? 'Добавить' : 'Редактировать'}} администратора</h2>
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="#" @click="$router.go(-1)">Назад</a></li>
          <li class="breadcrumb-item active" aria-current="page">{{isCreate ? 'Добавить' : 'Редактировать'}} администратора</li>
        </ol>
      </div>
      <div class="d-flex">
      </div>
    </div>
    <div class="row row-sm justify-content-center">
      <div class="col-xl-6 col-lg-8 col-md-12">
        <div class="card custom-card">
          <div class="card-body">
            <div>
              <h6 class="main-content-label mb-1">Администратор</h6>
            </div>
            <ValidationObserver tag="form" id="addAdminForm" ref="adminForm" class="d-flex flex-column" @submit.prevent="formSubmit()">
              <ValidationProvider v-slot="{ classes }" rules="required" tag="div" class="form-group">
                <label for='first_name' class="form-label">Имя</label>
                <input id="first_name" :class="classes" type="text" v-model="formData.name" name="name" class="form-control fs-16" placeholder="Имя"/>
              </ValidationProvider>
              <ValidationProvider v-slot="{ classes }" rules="required" tag="div" class="form-group">
                <label for='email' class="form-label">Почта</label>
                <input type="email" :class="classes" id="email" v-model="formData.email" name="email" class="form-control fs-16" placeholder="Почта"
                />
              </ValidationProvider>
              <ValidationProvider v-slot="{ classes }" rules="required" tag="div" class="form-group">
                <label for='phone_number' class="form-label">Телефон</label>
                <input type="text" id="phone_number" :class="classes" v-model="formData.phone" name="phone" class="form-control fs-16" placeholder="Телефон"/>
              </ValidationProvider>
              <FileUpload accept="image/x-png,image/jpeg,image/jpg" label="Фото:" name="avatar" uploadType="oneImage" v-if="(formData.avatar && !isCreate) || isCreate" :filesData="formData.avatar"/>
              <span class="text-danger" v-if="imageErrorMessage">{{imageErrorMessage}}</span>
              <div v-if="isCreate">
                <ValidationProvider v-slot="{ classes }" rules="required" tag="div" class="form-group">
                  <label for='password' class="form-label">Пароль</label>
                  <input type="password" id="password_add" :class="classes" name="password" v-model="formData.password" class="form-control fs-16" placeholder="Enter your Password"
                  />
                </ValidationProvider>
                <ValidationProvider v-slot="{ classes }" rules="required" tag="div" class="form-group">
                  <label for='password_confirmation' class="form-label">Подтверждения пароля</label>
                  <input type="password" id="password_confirmation_add" :class="classes" v-model="formData.password_confirmation"  name="password_confirmation" class="form-control fs-16" placeholder="Подтверждения пароля"
                  />
                </ValidationProvider>
              </div>
              <ValidationProvider rules="required" tag="div" class="form-group">
                <label for="role">Роль</label>
                <multiselect v-model="formData.role" :multiple="false" id="role" placeholder="Select Role" label="title" track-by="name" :options="roles" @select="selectRole">
                  <template slot="singleLabel" slot-scope="props">
                    {{props.option.name}}
                  </template>
                  <template slot="option" slot-scope="props">
                    <span class="option__name">{{ props.option.name }}</span>
                  </template>
                </multiselect>
              </ValidationProvider>
              <div class="form-group">
                <button type="submit" class="btn ripple btn-main-primary btn-block" :disabled="buttonDisable" :class="buttonDisable ? 'button-readonly' : ''">{{isCreate?'Сохранить':'Обновить'}}</button>
              </div>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
    <div class="row row-sm justify-content-center" v-if="!isCreate">
      <div class="col-xl-6 col-lg-8 col-md-12">
        <div class="card custom-card">
          <div class="card-body">
            <div>
              <h6 class="main-content-label mb-1">Reset Password</h6>
            </div>
            <ValidationObserver tag="form" id="updateAdminPasswordForm" ref="updateAdminPasswordForm" class="d-flex flex-column" @submit.prevent="submitPassword()">
              <ValidationProvider v-slot="{ classes }" rules="required" tag="div" class="form-group">
                <label for='password' class="form-label">Current Password</label>
                <input type="password" id="current_password" :class="classes" name="current_password" v-model="passwordFormData.current_password" class="form-control fs-16" placeholder="Enter your Current Password"
                />
              </ValidationProvider>
              <ValidationProvider v-slot="{ classes }" rules="required" tag="div" class="form-group">
                <label for='password' class="form-label">Password</label>
                <input type="password" id="password" :class="classes" name="password" v-model="passwordFormData.password" class="form-control fs-16" placeholder="Enter your Password"
                />
              </ValidationProvider>
              <ValidationProvider v-slot="{ classes }" rules="required" tag="div" class="form-group">
                <label for='password_confirmation' class="form-label">Confirm Password</label>
                <input type="password" id="password_confirmation" :class="classes" v-model="passwordFormData.password_confirmation"  name="password_confirmation" class="form-control fs-16" placeholder="Enter your Confirm Password"
                />
              </ValidationProvider>
              <div class="form-group">
                <button type="submit" class="btn ripple btn-main-primary btn-block" :disabled="passwordButtonDisable" :class="passwordButtonDisable ? 'button-readonly' : ''">Submit</button>
              </div>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import FileUpload from '../../components/FileUpload'

export default {
  name: 'AddAdmin',
  data () {
    return {
      isCreate: false,
      adminId: '',
      formData: {
        role: {}
      },
      passwordFormData: {},
      buttonDisable: false,
      passwordButtonDisable: false,
      imageErrorMessage: '',
      roles: [
        {
          name: 'support',
          id: 4
        },
        {
          name: 'content_manager',
          id: 3
        },
        {
          name: 'super_admin',
          id: 2
        },
        {
          name: 'admin',
          id: 1
        }
      ]
    }
  },
  components: {
    FileUpload
  },
  created () {
    this.adminId = this.$route.params.id
    if (this.adminId === 'add') {
      this.isCreate = true
    } else {
      this.isCreate = false
      this.getAdminEdit(this.adminId)
    }
  },
  computed: {
    ...mapState({
      admin: state => state.admin.admin
    })
  },
  methods: {
    ...mapActions({
      getAdmin: 'admin/getAdmin',
      createAdmin: 'admin/createAdmin',
      updateAdmin: 'admin/updateAdmin',
      updateAdminPassword: 'admin/updateAdminPassword'
    }),
    getAdminEdit (id) {
      this.getAdmin({ id }).then(() => {
        this.formData = this.admin
        this.formData.role_id = this.formData.role.id
        if (this.formData.avatar === null) {
          this.formData.avatar = []
        } else {
          this.formData.avatar = [this.formData.avatar]
        }
      })
    },
    selectRole (item) {
      this.formData.role_id = item.id
      this.formData.role.name = item.name
    },
    formSubmit () {
      this.$refs.adminForm.validate().then(success => {
        if (success) {
          this.buttonDisable = true
          const form = document.getElementById('addAdminForm')
          const formData = new FormData(form)
          formData.append('role_id', this.formData.role.id)
          if (formData.get('avatar').name === '') {
            this.imageErrorMessage = 'Please upload Image!'
            this.buttonDisable = false
            return false
          } else {
            this.imageErrorMessage = ''
          }
          if (this.isCreate === true) {
            this.createAdmin(formData).then(() => {
              this.$toasted.success(this.$t('messages.success.adminCreated')).goAway(1500)
              this.$router.push('/admins')
            }).catch(() => {
              this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
            }).finally(() => {
              this.buttonDisable = false
            })
          } else {
            this.updateAdmin({ data: formData, id: this.$route.params.id }).then(res => {
              this.$toasted.success(this.$t('messages.success.adminUpdated')).goAway(1500)
              this.$router.push('/admins')
            }).catch(() => {
              this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
            }).finally(() => {
              this.buttonDisable = false
            })
          }
        } else {
          this.$toasted.error(this.$t('messages.error.validationError')).goAway(1500)
        }
      })
    },
    submitPassword () {
      this.$refs.updateAdminPasswordForm.validate().then(success => {
        if (success) {
          this.passwordButtonDisable = true
          this.updateAdminPassword({ data: this.passwordFormData, id: this.$route.params.id }).then(() => {
            this.$toasted.success(this.$t('messages.success.adminUpdatedPassword')).goAway(1500)
            this.$router.push('/admins')
          }).catch(err => {
            const keys = Object.keys(err.response.data)
            this.$toasted.error(err.response.data[keys[0]]).goAway(1500)
          }).finally(() => {
            this.passwordButtonDisable = false
          })
        } else {
          this.$toasted.error(this.$t('messages.error.validationError')).goAway(1500)
        }
      })
    }
  }
}
</script>
