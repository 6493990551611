import { http } from '../../../api'

const user = {
  namespaced: true,
  state: {
    user: {},
    users: [],
    boughtCourses: []
  },
  actions: {
    getUsers: ({ commit }, params) => {
      let url = '/user'
      if (params) {
        const q = new URLSearchParams(params)
        url = url + '?' + q.toString()
      }
      return http.get(url).then(res => {
        commit('setUsers', res.data)
        return res.data
      })
    },
    getUser: ({ commit }, params) => {
      const url = '/user/' + params.id
      return http.get(url).then(res => {
        commit('setUser', res.data)
        return res.data
      })
    },
    restore: ({ commit }, id) => {
      return http.get('/user/' + id + '/restore').then(res => {
        commit('updateUserStatus', { id: id, status: 'active' })
      })
    },
    delete: ({ commit }, id) => {
      return http.delete('/user/' + id).then(res => {
        if (res.data.deleteType === 'forceDelete') {
          commit('removeUser', id)
        } else {
          commit('updateUserStatus', { id: id, status: 'inactive' })
        }
      })
    },
    lockUser: ({ commit }, id) => {
      return http.get('/user/' + id + '/lock').then(res => {
        commit('updateUserBlockStatus', res.data.data)
      })
    },
    updateUserPassword: ({ commit }, params) => {
      return http.post('/user/update-password', params).then(res => {
        return res
      })
    },
    getUserBoughtCourses: ({ commit }, params) => {
      return http.get('user/' + params.id + '/bought-courses').then(res => {
        commit('setUserBoughtCourses', res.data)
      })
    },
    updateUserCourseLevel: ({ commit }, params) => {
      return http.post('/user/update-course-Level', params).then(res => {
        return res
      })
    },
    updateUserCourseTariff: ({ commit }, params) => {
      return http.post('/user/update-course-tariff', params).then(res => {
        return res
      })
    },
    sendMessage: ({ commit }, params) => {
      return http.post('/user/send-message', params).then(res => {
        return res
      })
    },
    storeExpert({commit}, params){
      return http.post('/expert', params).then(res => {
        return res
      })
    },
    updateExpert({commit}, params){
      return http.post(`/expert/${params.id}`, params).then(res => {
        return res
      })
    },
    resetProgress({commit}, {user_id, course_id}){
      return http.post(`/users/${user_id}/reset`, {course_id}).then(res => {
        return res.data
      })
    }
  },
  mutations: {
    setUser (state, data) {
      state.user = data.data
    },
    setUserBoughtCourses (state, data) {
      state.boughtCourses = data.data
    },
    setUsers (state, data) {
      state.users = data.data
    },
    updateUserStatus (state, data) {
      state.users = state.users.map(item => {
        if (item.id === data.id) {
          item.status = data.status
        }
        return item
      })
    },
    updateUserBlockStatus (state, data) {
      state.users = state.users.map(item => {
        if (item.id === data.id) {
          item.locked = data.locked
        }
        return item
      })
    },
    removeUser (state, id) {
      state.users = state.users.filter(item => item.id !== id)
    }
  },
  getters: {
    users: state => state.users,
    boughtCourses: state => state.boughtCourses,
    user: state => state.user
  }
}

export default user
