export default {
  data () {
    return {
      ckEditorConfig: {
        toolbarGroups: [
          { name: 'clipboard', groups: ['clipboard', 'undo'] },
          { name: 'editing', groups: ['find', 'selection', 'spellchecker', 'editing'] },
          { name: 'document', groups: ['mode', 'document', 'doctools'] },
          { name: 'forms', groups: ['forms'] },
          { name: 'basicstyles', groups: ['basicstyles', 'cleanup', 'FontSize'] },
          { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph'] },
          { name: 'links', groups: ['links'] },
          { name: 'insert', groups: ['insert'] },
          { name: 'styles', groups: ['styles'] },
          { name: 'colors', groups: ['colors'] },
          { name: 'tools', groups: ['tools'] },
          { name: 'others', groups: ['others'] },
          { name: 'about', groups: ['about'] }
        ],
        extraPlugins: ['font', 'embed', 'autoembed', 'justify'],
        embed_provider: '//ckeditor.iframe.ly/api/oembed?url={url}&callback={callback}',
        uploadUrl: `${process.env.VUE_APP_WEB_API}ckeditor/upload`
      }
    }
  },
  methods: {
    onNamespaceLoaded (CKEDITOR) {
      CKEDITOR.plugins.addExternal('font', `${location.protocol}//${location.host}/font/`, 'plugin.js')
    }
  }
}
