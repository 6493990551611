<template>
  <div>
    <div class="page-header">
      <div>
        <h2 class="main-content-title tx-24 mg-b-5">Supports</h2>
      </div>
    </div>
    <div class="row row-sm">
      <div class="col-sm-12 col-md-12 col-lg-5 col-xl-4">
        <div class="card custom-card">
          <div class="main-content-app pt-0">
            <div class="main-content-left main-content-left-chat">
              <div class="card-body">
                <div class="input-group">
                  <input type="text" class="form-control" placeholder="Search ..."> <span class="input-group-append"> <button
                  class="btn ripple btn-primary" type="button">Search</button> </span></div>
              </div>
              <nav class="nav main-nav-line main-nav-line-chat card-body">
                <a class="nav-link active" data-toggle="tab" href="#ChatList">Recent Chat</a>
              </nav>
              <div class="tab-content main-chat-list ps ps--active-y">
                <div class="tab-pane active" id="ChatList">
                  <div class="main-chat-list tab-pane">
                    <div class="media" v-for="(ticket, index) in supportTickets" :key="index"
                         @click="selectChat(ticket.id)" :class="chatId === ticket.id ? 'bg-gray-100' : ''">
                      <div class="main-img-user online">
                        <img alt="" v-if="ticket.user.avatar" :src="ticket.user.avatar_path">
                        <img alt="" v-else src="../../../assets/img/users/default.png">
                        <span v-if="ticket.unread_messages">{{ ticket.unread_messages }}</span>
                      </div>
                      <div class="media-body">
                        <div class="media-contact-name">
                          <span>{{ ticket.user.first_name + ' ' + ticket.user.last_name }}</span>
                          <span>{{ ticket.created_at  | moment('DD.MM.YYYY | HH:mm') }}</span>
                        </div>
                        <p>{{ ticket.support_ticket_category.name[$i18n.locale] }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-7 col-xl-8">
        <div class="card custom-card">
          <div class="main-content-app pt-0" v-if="userChat">
            <div class="main-content-body main-content-body-chat">
              <div class="main-chat-header pt-3" v-if="userChat.user">
                <div class="main-img-user online">
                  <img alt="" v-if="userChat.user.avatar" :src="userChat.user.avatar_path">
                  <img alt="" v-else src="../../../assets/img/users/default.png">
                </div>
                <div class="main-chat-msg-name">
                  <h6>{{ userChat.user.first_name + ' ' + userChat.user.last_name }}</h6>
                  <h6 class="text-primary">{{ userChat.support_ticket_category.name[$i18n.locale] }}</h6>
                </div>
              </div>
              <div class="main-chat-body" id="ChatBody" ref="ChatBody">
                <div class="content-inner">
                  <div class="media" :class="message.admin_id ? 'flex-row-reverse' : ''"
                       v-for="(message, i) in userChat.support_ticket_messages" :key="i">
                    <div class="main-img-user online">
                      <img alt="" v-if="message.admin_id && message.admin.avatar" :src="message.admin.avatar">
                      <img alt="" v-else-if="!message.admin_id && message.user.avatar" :src="message.user.avatar_path">
                      <img alt="" v-else src="../../../assets/img/users/default.png">
                    </div>
                    <div class="media-body">
                      <div class="main-msg-wrapper">{{ message.message }}</div>
                      <div>
                        <span>{{ message.created_at  | moment('DD.MM.YYYY | HH:mm') }}</span>
                        <a href="">
                          <i class="icon ion-android-more-horizontal"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="main-chat-footer" v-if="userChat.user">
                <input class="form-control" v-on:keyup.enter="sendMessage" v-model="newMessageForm.message"
                       placeholder="Type your message here..." type="text">
                <div type="button" class="main-msg-send" @click="sendMessage">
                  <i class="far fa-paper-plane"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'

export default {
  name: 'SupportTickets',
  data () {
    return {
      chatId: '',
      newMessageForm: {},
      paginationOptions: [
        {
          value: 6,
          text: '6'
        },
        {
          value: 10,
          text: '10'
        },
        {
          value: 30,
          text: '30'
        },
        {
          value: 100,
          text: '100'
        }
      ],
      paginationCurrentPage: 1,
      paginationPerPage: 6,
      activeChannel: null
    }
  },
  computed: {
    ...mapState({
      supportTickets: state => state.support.supportTickets,
      paginationSupportTickets: state => state.support.paginationSupportTickets,
      userChat: state => state.support.userChat
    }),
    ...mapGetters({
      user: 'auth/user'
    }),
    tableFields () {
      return [
        {
          key: 'id',
          label: 'Id',
          sortable: true
        },
        {
          key: 'name.' + this.$i18n.locale,
          label: 'Name',
          sortable: true
        },
        {
          key: 'created_at',
          label: 'Created',
          sortable: true
        },
        {
          key: 'actions',
          sortable: false
        }
      ]
    }
  },
  created () {
    this.getAllSupportTicketsList({})
  },
  methods: {
    ...mapActions({
      getAllSupportTickets: 'support/getAllSupportTickets',
      getUserChat: 'support/getUserChat',
      sendNewMessage: 'support/sendNewMessage',
      messagesSeen: 'support/messagesSeen'
    }),
    ...mapMutations({
      setNewMessage: 'support/setNewUserChat',
    }),
    getAllSupportTicketsList (params) {
      this.getAllSupportTickets(params).then(res => {
        if (res.data.length) {
          this.selectChat(res.data[0].id)
        }
      }).catch(() => {
        this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
      })
    },
    selectChat (id) {
      if (this.chatId !== id) {
        this.chatId = id
        this.joinChat(id)
        this.getUserChat({ id }).then(res => {
          this.messagesSeen({ id: id })
          const chatContainer = this.$refs.ChatBody
          chatContainer.scrollTop = chatContainer.scrollHeight
        }).catch(() => {
          this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
        })
      }
    },
    joinChat (id) {

      if (this.activeChannel) {
        this.$echo.leaveChannel(this.activeChannel.name)
      }

      this.activeChannel = this.$echo.join(`support-chat.${id}`)
        .here((users) => {
          console.log('here', users)
        })
        .leaving((user) => {

        })
        .joining((user) => {

        })
        .listen('MessageSent', (e) => {
          this.pushNewMessage(e.message)
          console.log('message', e)
        })
    },
    pushNewMessage (message) {
      this.setNewMessage(message)
    },
    sendMessage () {
      if (this.newMessageForm.message) {
        this.sendNewMessage({
          id: this.chatId,
          data: this.newMessageForm
        }).then(() => {
          const chatContainer = this.$refs.ChatBody
          chatContainer.scrollTop = chatContainer.scrollHeight
          this.newMessageForm = {}
        }).catch(() => {
          this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
        })
      }
    }
  }
}
</script>
