<template>
  <div>
    <div class="page-header">
      <div>
        <h2 class="main-content-title tx-24 mg-b-5">{{isCreate ? 'Add Email Template' : 'Edit Email Template'}}</h2>
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="#" @click="$router.go(-1)">Назад</a></li>
          <li class="breadcrumb-item active" aria-current="page">{{isCreate ? 'Add Email Template' : 'Edit Email Template'}}</li>
        </ol>
      </div>
      <div class="d-flex">
      </div>
    </div>
    <ValidationObserver  tag="form" ref="emailTemplateForm" @submit.prevent="formSubmit()">
      <FormCard>
            <div>
              <h6 class="main-content-label mb-1">Email Template</h6>
            </div>
            <div>
              <ValidationProvider v-slot="{ classes }" rules="required" tag="div" class="form-group">
                <input class="form-control" v-model="formData.name" :class="classes" placeholder="Name" type="text">
              </ValidationProvider>
            </div>
            <div class="d-flex justify-content-between">
              <div>
                <h6 class="main-content-label mb-1">Email Template Texts</h6>
              </div>
            </div>
            <div>
              <b-tabs content-class="mt-3">
                <b-tab :title="language.code" v-for="(language, i) in languages" :key="i">
                  <table class="table">
                    <tbody>
                    <tr v-for="(email_template_text, j) in formData.email_template_texts" :key="j">
                      <td class="border-0">
                        <ValidationProvider v-slot="{ classes }" rules="required" tag="div" class="form-group">
                          <input maxlength="25" hidden :class="classes" class="form-control" v-model="formData.email_template_texts[j].key" placeholder="Key" type="text" readonly>
                        </ValidationProvider>
                        {{email_template_text.title || email_template_text.key }}
                      </td>
                      <td class="border-0">
                        <ValidationProvider v-slot="{ classes }" rules="required" tag="div" class="form-group">
                          <textarea :class="classes" class="form-control" v-model="formData.email_template_texts[j].value[language.code]" placeholder="Value" ></textarea>
                        </ValidationProvider>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </b-tab>
              </b-tabs>
              <button class="btn ripple btn-main-primary" type="submit">Submit</button>
            </div>
      </FormCard>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import FormCard from '../../components/FormCard'

export default {
  name: 'AddEmailTemplate',
  components: { FormCard },
  data () {
    return {
      formData: {
        name: '',
        blade_name: '',
        email_template_texts: [],
        deleted_email_template_texts: []
      },
      emailTemplateBlades: [
        { value: 'verify_email', text: 'Verify Email' },
        { value: 'application', text: 'Application' }
      ],
      emailTemplateId: '',
      isCreate: false
    }
  },
  created () {
    this.emailTemplateId = this.$route.params.id
    if (this.emailTemplateId === 'add') {
      this.isCreate = true
    } else {
      this.getEmailTemplate({ id: this.emailTemplateId }).then(res => {
        this.formData = res.data
        this.formData.deleted_email_template_texts = []
      })
    }
  },
  computed: {
    ...mapState({
      languages: state => state.language.languages
    })
  },
  methods: {
    ...mapActions({
      getEmailTemplate: 'emailTemplate/getEmailTemplate',
      create: 'emailTemplate/create',
      update: 'emailTemplate/update'
    }),
    customLabel ({ name, desc }) {
      return `${name[this.$i18n.locale]}`
    },
    addText () {
      this.formData.email_template_texts.push({
        key: '',
        value: {}
      })
    },
    deleteText (j, emailTemplateText) {
      if (emailTemplateText.id) {
        this.formData.deleted_email_template_texts.push(emailTemplateText.id)
      }
      this.formData.email_template_texts = this.formData.email_template_texts.filter((value, index) => {
        return index !== j
      })
    },
    formSubmit () {
      this.$refs.emailTemplateForm.validate().then(success => {
        if (success) {
          if (this.isCreate) {
            this.create(this.formData).then(() => {
              this.$router.push('/email-template')
            }).catch(() => {
              this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
            })
          } else {
            this.update({ data: this.formData, id: this.emailTemplateId }).then(() => {
              this.$router.push('/email-template')
            }).catch(() => {
              this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
            })
          }
        }
      })
    }
  }
}
</script>
