<template>
  <div>
    <div class="page-header">
      <div>
        <h2 class="main-content-title tx-24 mg-b-5">Mail Delivery</h2>
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="#" @click="$router.go(-1)">Назад</a></li>
          <li class="breadcrumb-item active" aria-current="page">Mail Delivery</li>
        </ol>
      </div>
      <div class="d-flex">
      </div>
    </div>
    <ValidationObserver tag="form" ref="taskAddForm" id="taskAddForm" class="d-flex flex-column"
                        @submit.prevent="submit">
      <FormCard>
        <div class="form-group">
          <b-button variant="success" @click="pickAll">Выбрать все</b-button>
        </div>
        <div class="form-group mt-5" v-if="subscribers">
          <multiselect
            v-model="formData.emailData"
            :options="subscribers"
            :multiple="true"
            placeholder="Выбрать получателя"
            label="email"
            track-by="id"
            required
          />
        </div>
        <div class="form-group">
          <label for="subject">Тема</label>
          <input class="form-control" id="subject" v-model="formData.subject" required/>
        </div>
        <div class="form-group">
          <label for="body">Сообщение</label>
          <ckeditor tag-name="textarea" :config="ckEditorConfig" id="body" @namespaceloaded="onNamespaceLoaded"
                    placeholder="Description"
                    v-model="formData.body" required></ckeditor>
        </div>
        <div class="form-group d-flex justify-content-end">
          <b-button variant="success" type="submit" :disabled="disabled">Отправить письмо</b-button>
        </div>
      </FormCard>
    </ValidationObserver>
  </div>
</template>

<script>
import FormCard from '../../components/FormCard'
import { mapActions, mapGetters } from 'vuex'
import CkeditorMixin from '../../../mixins/CkeditorMixin'

export default {
  name: 'MailDelivery',
  mixins: [CkeditorMixin],
  data () {
    return {
      formData: {
        emailData: [],
        subject: null,
        body: null,
        emails: [],
      },
      disabled: false
    }
  },
  components: { FormCard },
  computed: {
    ...mapGetters({
      subscribers: 'subscribe/subscribers'
    })
  },
  created () {
    this.getSubscribers()
  },
  methods: {
    ...mapActions({
      getSubscribers: 'subscribe/getSubscribers',
      sendMessages: 'subscribe/sendMessages',
    }),
    pickAll () {
      this.subscribers.map(subscribe => {
        if (!this.formData.emailData.includes(subscribe)) {
          this.formData.emailData.push(subscribe)
        }
      })
    },
    async submit () {
      this.disabled = true
      this.formData.emails = this.formData.emailData.map(email => {
        return email.email
      })

      try {
        await this.sendMessages(this.formData)
        this.$toasted.success('Сообщение успешно отправлено').goAway(1500)
      } catch (e) {
        this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
      }
      this.formData = {}
      this.disabled = false
    },
  }
}
</script>

<style scoped>

</style>
