<template>
  <div>
    <div class="page-header">
      <div>
        <h2 class="main-content-title tx-24 mg-b-5">Типы задания</h2>
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="#" @click="$router.go(-1)">Назад</a></li>
          <li class="breadcrumb-item active" aria-current="page">Типы задания</li>
        </ol>
      </div>
      <div class="d-flex">
      </div>
    </div>
    <div class="row row-sm justify-content-center">
      <div class="col-lg-8 col-md-12">
        <div class="card custom-card">
          <div class="card-body">
            <div>
              <h6 class="main-content-label mb-1">Типы задания</h6>
            </div>
            <div class="p-2">
              <div v-for="(taskType, index) in taskTypesList" :key="index">
                <router-link v-if="taskType.id !== 5" tag="div" :to="'/task/' + $route.params.courseId + '/' + $route.params.moduleId + '/' + $route.params.lessonId + '/' + $route.params.lessonBlockId + '/' + 'task-type/' + taskType.id + '/add'" class="mt-4 card p-2 pointer">
                  <div class="title font-weight-bold tx-20">{{taskType.name[$i18n.locale]}}</div>
                  <div class="d-flex mt-2">
                    <img height="200" width="300" :src="taskType.image" alt="">
                    <div class="ml-5" v-html="taskType.description[$i18n.locale]"></div>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'TaskType',
  computed: {
    ...mapState({
      taskTypesList: state => state.taskType.taskTypesList
    })
  },
  created () {
    this.getTaskTypes().then(() => {
    })
  },
  methods: {
    ...mapActions({
      getTaskTypes: 'taskType/getTaskTypes'
    })
  }
}
</script>
