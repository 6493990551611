import { http } from '../../../api'

const settings = {
  namespaced: true,
  state: {
    settingsList: [],
    setting: null
  },
  actions: {
    getSettingsList: ({ commit }) => {
      return http.get('/project-setting').then(res => {
        commit('setSettingsList', res.data.data)
      })
    },
    updateSettings: ({ commit }, params) => {
      return http.post('/project-setting', params).then(res => {
      })
    },
    getSettingByKey: ({commit}, key) => {
        return http.get(`/project-setting-by-key?key=${key}`).then(res => {
          commit('setSetting', res.data)
        })
    }
  },
  mutations: {
    setSettingsList (state, data) {
      state.settingsList = data
    },
    setSetting(state,data){
      state.setting = data
    }
  },
  getters: {
    settingsList: state => state.settingsList,
    setting: state => state.setting
  }
}

export default settings
