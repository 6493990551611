<template>
  <TaskBase
    :help="help"
    :data="formData"
    :create="task_type === 'manual_input' ? createTask : createComposeTask"
    :update="task_type === 'manual_input' ? updateTask : updateComposeTask"
    :task-type-id="$route.query.type ? parseInt($route.query.type): 2"
    :after-validation="afterValidation"
  >
    <FormCard>
      <div class="flex-column mb-2">
        <div class="mb-1 font-weight-bold">Тип задачи</div>
        <div>
          <label class="radiobox">
            <input v-model="task_type" type="radio" value="manual_input"> <span>Ручной Ввод</span>
          </label>
        </div>
        <div>
          <label class="radiobox">
            <input v-model="task_type" type="radio" value="drag"> <span>Перетаскивание</span>
          </label>
        </div>
      </div>
      <b-tabs content-class="mt-3">
        <button type="button" class="btn btn-dark mb-3" @click="hideWord">Скрыть</button>
        <ValidationProvider v-slot="{ classes }" rules="required" tag="div" class="form-group">
                  <textarea class="form-control" ref="missingWords" name="missing_words_text" @input="changeTextarea"
                            placeholder="Missing Words" :class="classes"
                            v-model="formData.missing_words_text"></textarea>
        </ValidationProvider>
      </b-tabs>
    </FormCard>
  </TaskBase>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import FileUpload from '../../../components/FileUpload'
import FormCard from '../../../components/FormCard'
import VideoForm from '../../../components/VideoForm'
import CkeditorMixin from '../../../../mixins/CkeditorMixin'
import TaskMixin from '../../../../mixins/TaskMixin'
import Breadcrumbs from '../../../components/Breadcrumbs'
import AudioMixin from '../../../../mixins/AudioMixin'
import TaskBase from '../../../components/tasks/TaskBase'

export default {
  name: 'TaskMissingWords',
  mixins: [CkeditorMixin, TaskMixin, AudioMixin],
  data () {
    return {
      help: 'Заключая фрагмент текста в квадратные скобки - [ ] - вы скрываете его для экзаменуемого. И именно этот\n' +
        '        фрагмент должен будет угадать экзаменуемый. Также вы можете выделить фрагмент текста и и нажать кнопку\n' +
        '        "Скрыть."\n' +
        '        <br>\n' +
        '        Например:\n' +
        '        <br>\n' +
        '        Ich reise viel, ich [reise] gern.\n' +
        '        <br>\n' +
        '        В задании:\n' +
        '        <br>\n' +
        '        Ich reise viel, ich ... gern.',
      buttonDisable: false,
      task_type: 'manual_input',
      formData: {
        name: {},
        description: {},
        prompt: {},
        missing_words_text: ''
      },
      videoType: 'video_iframe',
      fileErrorMessage: '',
      uploadedVideoFile: null,
      isCreate: false,
      taskId: null,
      changeTextareaText: '',
      gallery: [],
      create: () => {},
      update: () => {},
    }
  },
  components: {
    TaskBase,
    Breadcrumbs,
    VideoForm,
    FormCard,
    FileUpload
  },
  computed: {
    ...mapState({
      languages: state => state.language.languages,
      taskType: state => state.taskType.taskType
    }),
    ...mapGetters({
      composeTask: 'taskComposeText/task',
      task: 'taskMissingWord/task'
    })
  },
  created () {
    this.taskId = this.$route.params.id
    if (this.taskId === 'add') {
      this.isCreate = true
    } else {
      this.isCreate = false
      if (this.$route.query.type) {
        this.getComposeTask(this.taskId).then(res => {
          this.formData = this.composeTask.task
          this.formData.missing_words_text = this.composeTask.compose_text.missing_words_text
          this.task_type = 'drag'
          this.formData.prompt = this.composeTask.compose_text.prompt
        })
      } else {
        this.getTask(this.taskId).then(res => {
          this.formData = this.task.task
          this.formData.missing_words_text = this.task.missing_word.missing_words_text
          this.formData.prompt = this.task.missing_word.prompt
        })
      }
    }
  },
  methods: {
    ...mapActions({
      createTask: 'taskMissingWord/createTask',
      updateTask: 'taskMissingWord/updateTask',
      getTask: 'taskMissingWord/getTask',
      getTaskType: 'taskType/getTaskType',
      createComposeTask: 'taskComposeText/createComposeTask',
      updateComposeTask: 'taskComposeText/updateComposeTask',
      getComposeTask: 'taskComposeText/getTask'
    }),
    changeTextarea () {
      const selectableTextAreaMouseUp = this.$refs.missingWords
      const _this = this
      selectableTextAreaMouseUp.addEventListener('mouseup', function (e) {
        var selectedText = window.getSelection().toString()
        console.log('selectedText', selectedText)
        var start = e.target.selectionStart
        var end = start + selectedText.length
        if (start >= 0 && end >= 0) {
          const selectedTextStart = _this.formData.missing_words_text.slice(0, start)
          const selectedTextEnd = _this.formData.missing_words_text.slice(end, _this.formData.missing_words_text.length)
          _this.changeTextareaText = selectedTextStart + '[' + selectedText + ']' + selectedTextEnd
        }
      })
    },
    hideWord () {
      if (this.changeTextareaText) {
        this.formData.missing_words_text = this.changeTextareaText
      }
      window.getSelection().empty()
      this.changeTextareaText = ''
    },
    customLabel ({
      name,
      desc
    }) {
      return `${name[this.$i18n.locale]}`
    },
    afterValidation (formData) {
      if (this.task_type === 'manual_input') {
        formData.append('task_type_id', 2)
        this.update = this.updateTask
        this.create = this.createTask
      } else {
        formData.append('task_type_id', 5)
        this.update = this.updateComposeTask
        this.create = this.createComposeTask
      }
    },
  }
}
</script>
