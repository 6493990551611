<template>
    <div class="page">
      <SideMenu/>
      <Header/>
      <div class="jumps-prevent"></div>
      <div class="main-content side-content pt-0">
        <div class="container-fluid">
          <div class="inner-body">
            <router-view/>
          </div>
        </div>
      </div>
      <b-modal hide-footer v-model="incomingCallDialog"  size="sm">
        <div class="col">
          <p>
            Incoming Call From <strong>{{ callerDetails ? callerDetails.first_name + ' ' +  callerDetails.last_name: '' }}</strong>
          </p>
          <div class="btn-group" role="group">
            <button
              type="button"
              class="btn btn-danger"
              data-dismiss="modal"
              @click="declineCall"
            >
              Decline
            </button>
            <button
              type="button"
              class="btn btn-success ml-5"
              @click="acceptCall"
            >
              Accept
            </button>
          </div>
        </div>
      </b-modal>
      <audio ref="callRingtone">
        <source src="../../assets/mp3/iphone-ringtone.mp3" type="audio/mpeg">
        Your browser does not support the audio element.
      </audio>
    </div>
</template>

<script>
import SideMenu from '../components/SideMenu'
import Header from '../components/Header'
import { mapActions } from 'vuex'
import EventBus from '../../event-bus'

export default {
  name: 'IndexLayout',
  components: {
    Header,
    SideMenu
  },
  data () {
    return {
      videoCallParams: {
        users: [],
        stream: null,
        receivingCall: false,
        caller: null,
        callerSignal: null,
        callAccepted: false,
        channel: null,
        peer1: null,
        peer2: null
      }
    }
  },
  computed: {
    incomingCallDialog () {
      if (this.videoCallParams.receivingCall) {
        return true
      }
      return false
    },
    callerDetails () {
      if (
        this.videoCallParams.caller
      ) {
        return this.videoCallParams.caller
      }
      return null
    }
  },
  created () {
    this.getLanguages().catch((e) => {
      console.log('getLanguages', e)
      this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
    })
    // this.initializeChannel() // subscribes to video presence channel and listens to video events
    // this.initializeCallListeners() // subscribes to video presence channel and listens to video events
  },
  methods: {
    ...mapActions({
      getLanguages: 'language/getLanguages'
    }),

    declineCall () {
      this.videoCallParams.receivingCall = false
    },
    acceptCall () {
      EventBus.$emit('acceptCall')
      this.videoCallParams.receivingCall = false
    },
    initializeChannel () {
      this.videoCallParams.channel = window.Echo.join('expert-video-chat-channel')
    },
    initializeCallListeners () {
      this.videoCallParams.channel.here((users) => {
        this.videoCallParams.users = users
      })

      this.videoCallParams.channel.joining((user) => {
        // check user availability
        const joiningUserIndex = this.videoCallParams.users.findIndex(
          (data) => data.id === user.id
        )
        if (joiningUserIndex < 0) {
          this.videoCallParams.users.push(user)
        }
      })

      this.videoCallParams.channel.leaving((user) => {
        const leavingUserIndex = this.videoCallParams.users.findIndex(
          (data) => data.id === user.id
        )
        this.videoCallParams.users.splice(leavingUserIndex, 1)
      })
      this.videoCallParams.channel.listen('StartExpertVideoChat', ({ data }) => {
        if (data.type === 'incomingCall') {
          // add a new line to the sdp to take care of error
          const updatedSignal = {
            ...data.signalData,
            sdp: `${data.signalData.sdp}\n`
          }
          this.videoCallParams.receivingCall = true
          this.videoCallParams.caller = data.from
          this.videoCallParams.callerSignal = updatedSignal
        }
      })
    }
  }
}
</script>
<style>
</style>
