<template>
  <div>
    <div class="page-header">
      <breadcrumbs name="курс" />
      <div class="d-flex"></div>
    </div>
    <ValidationObserver ref="courseAddForm" id="courseAddForm" tag="form">
      <div class="row row-sm justify-content-center">
        <div class="col-xl-6 col-lg-8 col-md-12">
          <div class="card custom-card">
            <div class="card-body">
              <div>
                <h6 class="main-content-label mb-1">{{ $t('Course') }}</h6>
              </div>
              <div v-if="showVideo === true">
                <div class="d-flex flex-column">
                  <b-tabs content-class="mt-3">
                    <b-tab :title="language.code" v-for="(language, i) in languages" :key="i">
                      <ValidationProvider v-slot="{ classes }" rules="required" tag="div" class="form-group">
                        <label :for="'name[' + language.code + ']'" class="form-label">Название курса:</label>
                        <input
                          :id="'name[' + language.code + ']'"
                          class="form-control"
                          :name="'name[' + language.code + ']'"
                          v-model="formData.name[language.code]"
                          :class="checkValidation(classes, language.code)"
                          placeholder="Название"
                          type="text"
                        />
                      </ValidationProvider>
                      <ValidationProvider v-slot="{ classes }" tag="div" class="form-group">
                        <label :for="'description[' + language.code + ']'" class="form-label">Описание курса:</label>
                        <ckeditor
                          @namespaceloaded="onNamespaceLoaded"
                          :class="checkValidation(classes, language.code)"
                          v-model="formData.description[language.code]"
                          name="description"
                          :id="'description[' + language.code + ']'"
                          :config="ckEditorConfig"
                        ></ckeditor>
                      </ValidationProvider>
                    </b-tab>
                    <ValidationProvider v-slot="{ classes }" tag="div" class="form-group">
                      <label for="beginner_description">Начинающий описание</label>
                      <textarea
                        class="form-control"
                        name="beginner_description"
                        id="beginner_description"
                        v-model="formData.beginner_description"
                      ></textarea>
                    </ValidationProvider>
                    <ValidationProvider v-slot="{ classes }" tag="div" class="form-group">
                      <label for="average_description">Средний описание</label>
                      <textarea
                        class="form-control"
                        name="average_description"
                        id="average_description"
                        v-model="formData.average_description"
                      ></textarea>
                    </ValidationProvider>
                    <ValidationProvider v-slot="{ classes }" tag="div" class="form-group">
                      <label for="advanced_description">Продвинутый описание</label>
                      <textarea
                        class="form-control"
                        name="advanced_description"
                        id="advanced_description"
                        v-model="formData.advanced_description"
                      ></textarea>
                    </ValidationProvider>
                    <FileUpload
                      accept="image/png,image/jpeg,image/jpg"
                      label="Логотип:"
                      name="image"
                      uploadType="oneImage"
                      type="image"
                      :validation="true"
                      :filesData="formData.image_path"
                    />
                    <div class="flex-column mb-2">
                      <div class="mb-1 font-weight-bold">Тип курса</div>
                      <div>
                        <label class="radiobox">
                          <input checked="" name="course_type" type="radio" v-model="formData.course_type" value="main" />
                          <span>Основной</span>
                        </label>
                      </div>
                      <div>
                        <label class="radiobox">
                          <input name="course_type" v-model="formData.course_type" type="radio" value="additional" />
                          <span>Дополнительный</span>
                        </label>
                      </div>
                    </div>
                    <ValidationProvider rules="required" tag="div" class="form-group">
                      <multiselect
                        v-model="teachingLanguage"
                        placeholder="Выбрать язык обучения"
                        label="name"
                        track-by="id"
                        @close="selectLanguage(teachingLanguage)"
                        :options="langCategoryList"
                      >
                        <template slot="singleLabel" slot-scope="props">
                          {{ props.option.name[$i18n.locale] }}
                        </template>
                        <template slot="option" slot-scope="props">
                          <span class="option__name">{{ props.option.name[$i18n.locale] }}</span>
                        </template>
                      </multiselect>
                    </ValidationProvider>
                    <ValidationProvider rules="required" tag="div" class="form-group">
                      <multiselect
                        v-model="courseLevel"
                        placeholder="Выбрать уровень обучения"
                        :searchable="false"
                        track-by="name"
                        label="name"
                        @close="selectCourseLevel(courseLevel)"
                        :options="activeCourseLevelsList"
                      >
                        <template slot="singleLabel" slot-scope="props">
                          {{ props.option.name[$i18n.locale] }}
                        </template>
                        <template slot="option" slot-scope="props">
                          <span class="option__name">{{ props.option.name[$i18n.locale] }}</span>
                        </template>
                      </multiselect>
                    </ValidationProvider>
                    <ValidationProvider tag="div" class="form-group">
                      <multiselect
                        v-model="formData.tariffs"
                        :multiple="true"
                        placeholder="Выбрать тариф"
                        label="name"
                        track-by="id"
                        :options="courseTariffList"
                        :custom-label="customLabel"
                      >
                        <template slot="singleLabel" slot-scope="props">
                          {{ props.option.name[$i18n.locale] }}
                        </template>
                        <template slot="option" slot-scope="props">
                          <span class="option__name">{{ props.option.name[$i18n.locale] }}</span>
                        </template>
                      </multiselect>
                    </ValidationProvider>
                    <div>
                      <label class="radiobox">
                        <input checked="" name="is_free" type="checkbox" v-model="formData.is_free" />
                        <span>Это бесплатно</span>
                      </label>
                    </div>
                    <div>
                      <label class="radiobox">
                        <input checked="" name="is_free_lesson" type="checkbox" v-model="formData.is_free_lesson" />
                        <span>Есть бесплатный урок</span>
                      </label>
                    </div>
                    <template v-if="!formData.is_free">
                      <h5>Настройки стоимости курса</h5>
                      <ValidationProvider tag="div" class="form-group">
                        <label for="tarrif_price">Cтоимость курса</label>
                        <input
                          type="number"
                          class="form-control"
                          id="tarrif_price"
                          :name="`formData.price`"
                          v-model="formData.price"
                          required
                        />
                      </ValidationProvider>
                      <ValidationProvider tag="div" class="form-group">
                        <label for="tarrif_price">Cкидка (%)</label>
                        <input
                          type="number"
                          class="form-control"
                          id="tarrif_price"
                          :name="`formData.discount`"
                          v-model="formData.discount"
                        />
                      </ValidationProvider>

                      <ValidationProvider tag="div" class="form-group">
                        <label for="tarrif_price">Стоимость с учетом скидки: </label>
                        {{ discountPrice }}
                      </ValidationProvider>

                      <ValidationProvider tag="div" class="form-group">
                        <label for="tarrif_price">Дата до которой действует скидка</label>
                        <input
                          type="date"
                          class="form-control"
                          id="data"
                          :name="`formData.discount_end_date`"
                          v-model="formData.discount_end_date"
                          required
                        />
                      </ValidationProvider>

                      <ValidationProvider tag="div" class="form-group">
                        <label for="tarrif_price">Ссылка на курс</label>
                        <input
                          type="text"
                          class="form-control"
                          id="link_to_course"
                          :name="`formData.link_to_course`"
                          v-model="formData.link_to_course"
                          required
                        />
                      </ValidationProvider>
                    </template>

                    <ValidationProvider tag="div" class="form-group mt-5" v-if="experts">
                      <multiselect
                        v-model="formData.experts"
                        :options="experts"
                        :multiple="true"
                        label="first_name"
                        track-by="id"
                        placeholder="Выбрать экспертов курса"
                      />
                    </ValidationProvider>

                    <b-form-checkbox id="checkbox-1" v-model="formData.in_developing" name="in_developing" :value="1" :unchecked-value="0">
                      В разработке
                    </b-form-checkbox>
                  </b-tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row row-sm justify-content-center">
        <div class="col-xl-6 col-lg-8 col-md-12">
          <div class="card custom-card">
            <div class="card-body">
              <div>
                <h6 class="main-content-label mb-1">Анонс курса</h6>
              </div>
              <div v-if="showVideo === true">
                <div class="d-flex flex-column">
                  <b-tabs content-class="mt-3">
                    <b-tab :title="language.code" v-for="(language, i) in languages" :key="i">
                      <ValidationProvider v-slot="{ classes }" rules="required" tag="div" class="form-group">
                        <label :for="'announcement[title][' + language.code + ']'" class="form-label">Название курса в анонсе:</label>
                        <input
                          :id="'announcement[title][' + language.code + ']'"
                          class="form-control"
                          :name="'announcement[title][' + language.code + ']'"
                          v-model="formData.announcement.title[language.code]"
                          :class="checkValidation(classes, language.code)"
                          placeholder="Название"
                          type="text"
                        />
                      </ValidationProvider>
                      <ValidationProvider v-slot="{ classes }" rules="required" tag="div" class="form-group">
                        <label :for="'announcement[descriptions][' + language.code + ']'" class="form-label"
                          >Описание курса в анонсе :</label
                        >
                        <textarea
                          class="form-control"
                          maxlength="300"
                          :name="'announcement[description][' + language.code + ']'"
                          :id="'announcement[description][' + language.code + ']'"
                          placeholder="Описание"
                          :class="checkValidation(classes, language.code)"
                          v-model="formData.announcement.description[language.code]"
                        ></textarea>
                      </ValidationProvider>
                    </b-tab>
                    <VideoForm
                      :is-edit="isEdit"
                      :video-file.sync="uploadedVideoFile"
                      :video-iframe.sync="formData.announcement.video_iframe"
                      :video-type.sync="videoType"
                    />
                    <div>
                      <label class="radiobox">
                        <input
                          checked=""
                          name="announcement[is_show_in_home]"
                          type="checkbox"
                          v-model="formData.announcement.is_show_in_home"
                        />
                        <span>Показывать на главной</span>
                      </label>
                    </div>
                  </b-tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ValidationObserver>
    <div class="row row-sm justify-content-center">
      <div class="col-xl-6 col-lg-8 col-md-12">
        <div class="card custom-card">
          <div class="card-body">
            <div class="d-flex justify-content-between align-items-center">
              <h6 class="main-content-label mb-1">Модули</h6>
              <button
                type="button"
                @click="formSubmit('module')"
                class="btn ripple btn-main-primary mt-2"
                :disabled="buttonDisable || loading"
                :class="buttonDisable ? 'button-readonly' : ''"
              >
                Добавить модуль
              </button>
            </div>
            <div v-if="courseModulesList && courseModulesList.length">
              <div class="add-course-item" v-for="(module, index) in courseModulesList" :key="index">
                <div class="name">{{ module.name[$i18n.locale] }}</div>
                <span>Уроки: {{ module.lessons_count }}</span>
                <div>
                  <div @click="formSubmit('module-edit', module.id)" class="btn btn-outline-primary mr-2 mt-1 mb-1">
                    <i class="fe fe-edit"></i>
                  </div>
                  <button
                    v-if="module.status === 'active'"
                    @click="deleteCourseModule(module.id)"
                    type="button"
                    class="btn btn-outline-danger ml-2"
                  >
                    <i class="ti-close"></i>
                  </button>
                  <div v-else class="d-inline-block">
                    <button @click="restoreCourseModule(module.id)" type="button" class="btn btn-outline-success ml-2">
                      <i class="fe fe-rotate-ccw"></i>
                    </button>
                    <button @click="deleteCourseModule(module.id)" type="button" class="btn btn-outline-danger ml-2">
                      <i class="fe fe-trash-2"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row row-sm justify-content-center">
      <div class="col-xl-6 col-lg-8 col-md-12">
        <div class="card custom-card">
          <div class="card-body">
            <div class="d-flex justify-content-between align-items-center">
              <h6 class="main-content-label mb-1">Тесты</h6>
              <button
                type="button"
                @click="formSubmit('test')"
                class="btn ripple btn-main-primary mt-2"
                :disabled="buttonDisable || loading"
                :class="buttonDisable ? 'button-readonly' : ''"
              >
                Добавить тест
              </button>
            </div>
            <div v-if="courseTestsList && courseTestsList.length">
              <div class="add-course-item" v-for="(test, index) in courseTestsList" :key="index">
                <div class="name">{{ test.title[$i18n.locale] }}</div>
                <div>
                  <router-link :to="'/course-test/' + test.course_id + '/' + test.id" class="btn btn-outline-primary mr-2 mt-1 mb-1">
                    <i class="fe fe-edit"></i>
                  </router-link>
                  <button
                    v-if="test.status === 'active'"
                    @click="deleteCourseTest(test.id)"
                    type="button"
                    class="btn btn-outline-danger ml-2"
                  >
                    <i class="ti-close"></i>
                  </button>
                  <div v-else class="d-inline-block">
                    <button @click="restoreCourseTest(test.id)" type="button" class="btn btn-outline-success ml-2">
                      <i class="fe fe-rotate-ccw"></i>
                    </button>
                    <button @click="deleteCourseTest(test.id)" type="button" class="btn btn-outline-danger ml-2">
                      <i class="fe fe-trash-2"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row row-sm justify-content-center">
      <div class="col-xl-6 col-lg-8 col-md-12">
        <div class="card custom-card">
          <div class="card-body">
            <div class="d-flex justify-content-between align-items-center">
              <h6 class="main-content-label mb-1">Видео материалы</h6>
              <button
                type="button"
                @click="formSubmit('video')"
                class="btn ripple btn-main-primary mt-2"
                :disabled="buttonDisable || loading"
                :class="buttonDisable ? 'button-readonly' : ''"
              >
                Добавить видео
              </button>
            </div>
            <div v-if="courseVideosList && courseVideosList.length">
              <div class="add-course-item" v-for="(video, index) in courseVideosList" :key="index">
                <div class="name">{{ video.name[$i18n.locale] }}</div>
                <div>
                  <router-link :to="'/course-video/' + video.course_id + '/' + video.id" class="btn btn-outline-primary mr-2 mt-1 mb-1">
                    <i class="fe fe-edit"></i>
                  </router-link>
                  <button
                    v-if="video.status === 'active'"
                    @click="deleteCourseVideo(video.id)"
                    type="button"
                    class="btn btn-outline-danger ml-2"
                  >
                    <i class="ti-close"></i>
                  </button>
                  <div v-else class="d-inline-block">
                    <button @click="restoreCourseVideo(video.id)" type="button" class="btn btn-outline-success ml-2">
                      <i class="fe fe-rotate-ccw"></i>
                    </button>
                    <button @click="deleteCourseVideo(video.id)" type="button" class="btn btn-outline-danger ml-2">
                      <i class="fe fe-trash-2"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row row-sm justify-content-center">
      <div class="col-xl-6 col-lg-8 col-md-12">
        <div class="card custom-card">
          <div class="card-body">
            <div class="d-flex justify-content-between align-items-center">
              <h6 class="main-content-label mb-1">Самостоятельные работы</h6>
              <button
                type="button"
                @click="formSubmit('task')"
                class="btn ripple btn-main-primary mt-2"
                :disabled="buttonDisable || loading"
                :class="buttonDisable ? 'button-readonly' : ''"
              >
                Добавить задание
              </button>
            </div>
            <div v-if="course">
              <div class="add-course-item" v-for="(task, index) in taskLists" :key="task.id">
                <div class="name">{{ task.name[$i18n.locale] }}</div>
                <div>
                  <router-link
                    :to="`/task/${course.id}/work/task-type/${task.task_type_id}/${task.id}`"
                    class="btn btn-outline-primary mr-2 mt-1 mb-1"
                  >
                    <i class="fe fe-edit"></i>
                  </router-link>
                  <button v-if="task.status === 'active'" @click="deleteTask(task.id)" type="button" class="btn btn-outline-danger ml-2">
                    <i class="ti-close"></i>
                  </button>
                  <div v-else class="d-inline-block">
                    <button @click="restoreTask(task.id)" type="button" class="btn btn-outline-success ml-2">
                      <i class="fe fe-rotate-ccw"></i>
                    </button>
                    <button @click="deleteTask(task.id)" type="button" class="btn btn-outline-danger ml-2">
                      <i class="fe fe-trash-2"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row row-sm justify-content-center">
      <div class="col-xl-6 col-lg-8 col-md-12">
        <div class="card custom-card">
          <div class="card-body">
            <button
              class="btn ripple btn-main-primary w-100"
              @click="formSubmit('course')"
              :disabled="buttonDisable || loading"
              :class="buttonDisable ? 'button-readonly' : ''"
            >
              <button-name />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import FileUpload from '../../components/FileUpload'
import Swal from 'sweetalert2'
import VideoForm from '../../components/VideoForm'
import CkeditorMixin from '../../../mixins/CkeditorMixin'
import TaskCRUDMixin from '../../../mixins/TaskCRUDMixin'
import Breadcrumbs from '../../components/Breadcrumbs'
import ButtonName from '../../components/ButtonName'

export default {
  name: 'CourseAdd',
  mixins: [CkeditorMixin, TaskCRUDMixin],
  data() {
    return {
      loading: false,
      videoType: 'video_iframe',
      fileErrorMessage: '',
      uploadedVideoFile: null,
      teachingLanguage: [],
      courseLevel: [],
      tariffName: this.$i18n.locale,
      showVideo: false,
      isEdit: false,
      buttonDisable: false,
      formData: {
        tariffs: [],
        course_type: 'main',
        experts: [],
        announcement: {
          title: {},
          description: {},
          is_show_in_home: false,
          video_iframe: null,
          video: null
        },
        name: {},
        description: {},
        is_free: false,
        is_free_lesson: false
      },
      rateSettings: {},
      isCreate: false,
      courseId: '',

      price: 0,
      discount: 0,
      price_discount: 0,
      discount_end_date: new Date(),
      link_to_course: ''
    }
  },
  components: {
    ButtonName,
    Breadcrumbs,
    VideoForm,
    FileUpload
  },
  watch: {
    tarrifs(newVal) {
      if (this.loading) return

      newVal.forEach(item => {
        if (!this.rateSettings[item.id]) {
          this.$set(this.rateSettings, item.id, { price: '', extension_price: '', duration: '' })
        }
      })
    }
  },
  computed: {
    ...mapState({
      languages: state => state.language.languages,
      course: state => state.course.course,
      langCategoryList: state => state.teachingLanguage.langCategoryList,
      courseTariffList: state => state.courseTariff.courseTariffList,
      announcementList: state => state.announcement.announcementList,
      activeCourseLevelsList: state => state.courseLevel.activeCourseLevelsList,
      courseModulesList: state => state.courseModule.courseModulesList,
      courseTestsList: state => state.courseTest.courseTestsList,
      courseVideosList: state => state.courseVideo.courseVideosList
    }),
    ...mapGetters({
      taskLists: 'task/tasksList',
      experts: 'user/users'
    }),
    tarrifs() {
      return this.formData.tariffs
    },
    discountPrice() {
      if (!this.formData) {
        return 0
      }
      if (this.formData.price_discount) {
        return this.formData.price_discount
      }
      return this.formData.price - (this.formData.discount / 100) * this.formData.price
    }
  },
  created() {
    this.courseId = this.$route.params.id
    this.getUsers({ role: 'expert' })
    this.getCourseVideoListByCourse(this.courseId)
    this.getLanguageCategoryList()
    this.getAllCourseTariff()
    this.getAllAnnouncement()
    this.getCourseModulesByCourse(this.courseId)
    this.getCourseTestsListByCourse(this.courseId)
    this.getActiveCourseLevels()
    this.getTasks({
      course_id: this.courseId,
      by: 'work'
    })
    if (this.courseId === 'add') {
      this.isEdit = false
      this.isCreate = true
      this.showVideo = true
    } else {
      this.isEdit = true
      this.loading = true
      this.getCourse({ id: this.courseId }).then(res => {
        this.showVideo = true
        this.formData = this.course
        if (this.formData.is_free_lesson) {
          this.formData.is_free_lesson = true
        } else {
          this.formData.is_free_lesson = false
        }
        if (this.formData.image_path === null) {
          this.formData.image_path = []
        } else {
          this.formData.image_path = [this.formData.image_path]
        }
        if (this.formData.announcement) {
          this.formData.announcement_id = this.formData.announcement.id
        } else {
          this.formData.announcement = {
            title: {},
            description: {},
            is_show_in_home: false
          }
        }
        if (this.formData.announcement.video_iframe || !this.formData.announcement.video) {
          this.videoType = 'video_iframe'
        } else {
          this.videoType = 'video'
          this.uploadedVideoFile = this.formData.announcement.video
        }
        this.formData.teaching_language_id = this.course.teaching_language.id
        this.teachingLanguage = this.formData.teaching_language
        this.courseLevel = this.formData.course_level

        this.formData.tariffs.forEach(({ id, pivot }) => {
          this.$set(this.rateSettings, id, pivot)
        })

        this.loading = false
      })
    }
  },
  methods: {
    ...mapActions({
      createCourse: 'course/createCourse',
      getCourse: 'course/getCourse',
      updateCourse: 'course/updateCourse',
      getActiveCourseLevels: 'courseLevel/getActiveCourseLevels',
      getLanguageCategoryList: 'teachingLanguage/getLanguageCategoryList',
      getAllCourseTariff: 'courseTariff/getAllCourseTariff',
      getAllAnnouncement: 'announcement/getAllAnnouncement',
      delete: 'courseModule/delete',
      restore: 'courseModule/restore',
      createAnnouncement: 'announcement/createAnnouncement',
      getCourseModulesByCourse: 'courseModule/getCourseModulesByCourse',
      updateAnnouncement: 'announcement/updateAnnouncement',
      getCourseTestsListByCourse: 'courseTest/getCourseTestsListByCourse',
      restoreTest: 'courseTest/restoreTest',
      deleteTest: 'courseTest/deleteTest',
      restoreVideo: 'courseVideo/restoreVideo',
      deleteVideo: 'courseVideo/deleteVideo',
      getCourseVideoListByCourse: 'courseVideo/getCourseVideoListByCourse',
      getTasks: 'task/getTasks',
      getUsers: 'user/getUsers'
    }),
    checkValidation(data) {
      return data
    },
    customLabel({ name, desc }) {
      return `${name[this.$i18n.locale]}`
    },
    selectLanguage(value) {
      this.formData.teaching_language_id = value.id
    },
    selectCourseLevel(value) {
      if (value) {
        this.formData.course_level_id = value.id
      }
    },
    formSubmit(type, moduleId) {
      this.$refs.courseAddForm.validate().then(success => {
        if (success) {
          const form = document.getElementById('courseAddForm')
          const formData = new FormData(form)

          if (!Object.keys(this.formData.description).length) {
            this.languages.map(language => {
              this.$set(this.formData.description, language.code, '')
            })
          }
          this.formData.experts.map((value, index) => {
            formData.append(`experts[${index}]`, value.id)
          })
          this.languages.map(language => {
            formData.append('description[' + language.code + ']', this.formData.description[language.code])
          })
          formData.append('teaching_language_id', this.formData.teaching_language_id)
          formData.append('course_level_id', this.formData.course_level_id)

          this.formData.tariffs.forEach(item => {
            formData.append('tariffs[]', item.id)
          })

          formData.delete('is_free')
          if (this.formData.is_free === true) {
            formData.append('is_free', 1)
          } else {
            formData.append('is_free', 0)
          }
          formData.delete('is_free_lesson')
          if (this.formData.is_free_lesson === true) {
            formData.append('is_free_lesson', 1)
          } else {
            formData.append('is_free_lesson', 0)
          }
          const formDataAnnouncement = new FormData()
          for (const key in this.formData.announcement.description) {
            formDataAnnouncement.append('description[' + key + ']', this.formData.announcement.description[key])
          }
          for (const key in this.formData.announcement.title) {
            formDataAnnouncement.append('title[' + key + ']', this.formData.announcement.title[key])
          }
          formDataAnnouncement.append('is_show_in_home', this.formData.announcement.is_show_in_home)
          formDataAnnouncement.append('teaching_language_id', this.formData.teaching_language_id)
          formDataAnnouncement.append('video_type', this.videoType)
          if (this.videoType === 'video_iframe') {
            formDataAnnouncement.delete('video')
            formDataAnnouncement.append('video_iframe', this.formData.announcement.video_iframe)
          } else {
            if (typeof this.uploadedVideoFile !== 'string') {
              formDataAnnouncement.append('video', this.uploadedVideoFile)
            } else {
              formDataAnnouncement.delete('video')
            }
            formDataAnnouncement.delete('video_iframe')
          }
          // LAN-38
          formData.append('price', this.formData.price)
          formData.append('discount', this.formData.discount)
          formData.append('discount_end_date', this.formData.discount_end_date)
          formData.append('link_to_course', this.formData.link_to_course)

          formData.append('rateSettings[4][duration]', 5)
          // !LAN-38
          this.buttonDisable = true

          if (this.isCreate === true) {
            this.createAnnouncement(formDataAnnouncement)
              .then(res => {
                formData.append('announcement_id', res.data.id)
                this.createCourse(formData)
                  .then(res => {
                    this.$toasted.success('Курс успешно создан').goAway(1500)
                    if (type === 'course') {
                      this.$router.push('/courses')
                    } else if (type === 'test') {
                      this.$router.push('/course-test/' + res.data.id + '/add')
                    } else if (type === 'video') {
                      this.$router.push('/course-video/' + res.data.id + '/add')
                    } else if (type === 'module-edit') {
                      this.$router.push('/course-module/' + res.data.id + '/' + moduleId)
                    } else if (type === 'task') {
                      this.$router.push({
                        name: 'CourseTaskType',
                        params: { courseId: res.data.id }
                      })
                    } else {
                      this.$router.push('/course-module/' + res.data.id + '/add')
                    }
                  })
                  .catch(() => {
                    this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
                  })
                  .finally(() => {
                    this.buttonDisable = false
                  })
              })
              .catch(() => {
                this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
              })
          } else {
            this.updateAnnouncement({
              data: formDataAnnouncement,
              id: this.formData.announcement.id
            })
              .then(res => {
                formData.append('announcement_id', res.data.id)
                this.updateCourse({
                  data: formData,
                  id: this.$route.params.id
                })
                  .then(res => {
                    this.$toasted.success('Курс успешно обновлен').goAway(1500)
                    if (type === 'course') {
                      this.$router.push('/courses')
                    } else if (type === 'test') {
                      this.$router.push('/course-test/' + res.data.id + '/add')
                    } else if (type === 'video') {
                      this.$router.push('/course-video/' + res.data.id + '/add')
                    } else if (type === 'module-edit') {
                      this.$router.push('/course-module/' + res.data.id + '/' + moduleId)
                    } else if (type === 'task') {
                      this.$router.push({
                        name: 'CourseTaskType',
                        params: { courseId: res.data.id }
                      })
                    } else {
                      this.$router.push('/course-module/' + res.data.id + '/add')
                    }
                  })
                  .catch(() => {
                    this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
                  })
                  .finally(() => {
                    this.buttonDisable = false
                  })
              })
              .catch(() => {
                this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
              })
          }
        } else {
          this.$toasted.error(this.$t('messages.error.validationError')).goAway(1500)
        }
      })
    },
    deleteCourseModule(id) {
      Swal.fire({
        text: this.$t('messages.warning.delete'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#748C41',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('buttons.yes'),
        cancelButtonText: this.$t('buttons.no')
      }).then(result => {
        if (result.value) {
          this.delete(id)
            .then(res => {
              this.$toasted.success(this.$t('messages.success.languageDeleted')).goAway(1500)
            })
            .catch(() => {
              this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
            })
        }
      })
    },
    restoreCourseModule(id) {
      Swal.fire({
        text: this.$t('message.warning.restore'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#748C41',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('buttons.yes'),
        cancelButtonText: this.$t('buttons.no')
      }).then(result => {
        if (result.value) {
          this.restore(id)
            .then(res => {
              this.$toasted.success(this.$t('messages.success.languageRestored')).goAway(1500)
            })
            .catch(() => {
              this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
            })
        }
      })
    },
    deleteCourseTest(id) {
      Swal.fire({
        text: this.$t('messages.warning.delete'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#748C41',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('buttons.yes'),
        cancelButtonText: this.$t('buttons.no')
      }).then(result => {
        if (result.value) {
          this.deleteTest(id)
            .then(res => {
              this.$toasted.success(this.$t('messages.success.languageDeleted')).goAway(1500)
            })
            .catch(() => {
              this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
            })
        }
      })
    },
    restoreCourseTest(id) {
      Swal.fire({
        text: this.$t('message.warning.restore'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#748C41',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('buttons.yes'),
        cancelButtonText: this.$t('buttons.no')
      }).then(result => {
        if (result.value) {
          this.restoreTest(id)
            .then(res => {
              this.$toasted.success(this.$t('messages.success.languageRestored')).goAway(1500)
            })
            .catch(() => {
              this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
            })
        }
      })
    },
    deleteCourseVideo(id) {
      Swal.fire({
        text: this.$t('messages.warning.delete'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#748C41',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('buttons.yes'),
        cancelButtonText: this.$t('buttons.no')
      }).then(result => {
        if (result.value) {
          this.deleteVideo(id)
            .then(res => {
              this.$toasted.success(this.$t('messages.success.languageDeleted')).goAway(1500)
            })
            .catch(() => {
              this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
            })
        }
      })
    },
    restoreCourseVideo(id) {
      Swal.fire({
        text: this.$t('message.warning.restore'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#748C41',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('buttons.yes'),
        cancelButtonText: this.$t('buttons.no')
      }).then(result => {
        if (result.value) {
          this.restoreVideo(id)
            .then(res => {
              this.$toasted.success(this.$t('messages.success.languageRestored')).goAway(1500)
            })
            .catch(() => {
              this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
            })
        }
      })
    },
    uploadCoverFile(file) {
      this.fileErrorMessage = ''
      const uploadFile = file[0]
      this.uploadedVideoFile = uploadFile
    },
    createIframeVideo() {
      this.fileErrorMessage = ''
    }
  }
}
</script>
