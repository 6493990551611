<template>
  <div>
    <div class="page-header">
      <div>
        <h2 class="main-content-title tx-24 mg-b-5">Редактирование клиента</h2>
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="#" @click="$router.go(-1)">Назад</a></li>
        </ol>
      </div>
      <div class="d-flex">
      </div>
    </div>
    <div class="row row-sm justify-content-center">
      <div class="col-xl-7 col-lg-10 col-md-12">
        <div class="card custom-card">
          <div class="card-body">
            <div>
              <button class="btn ripple btn-main-primary w-100 mt-2 mb-2" v-b-modal.modal-reset-password>Обновить пароль</button>
              <button class="btn ripple btn-main-primary w-100 mt-2 mb-2" v-b-modal.modal-send-message>Написать сообщение / уведомление</button>
<!--              <button class="btn ripple btn-main-primary w-100 mt-2 mb-2" v-b-modal.modal-course-level>Change course level to user</button>-->
              <button class="btn ripple btn-main-primary w-100 mt-2 mb-2" v-b-modal.modal-course-tariff>Радактировать курсов пользователя</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <b-modal
        id="modal-reset-password"
        ref="modal"
        title="Update Password"
        @hidden="resetPasswordModal"
        @ok="updatePasswordSubmit"
        ok-title="Submit"
        centered
      >
        <ValidationObserver ref="passwordEditForm" id="passwordEditForm" tag="form" class="d-flex flex-column">
            <ValidationProvider rules="required" tag="div" v-slot="{ classes }" class="form-group w-75 m-auto pt-3 pb-2" vid="password">
              <label for="password" class="form-label">Password:</label>
              <input id="password" class="form-control" name="new_password" v-model="passwordFormData.new_password" placeholder="Password" :class="classes" type="password">
            </ValidationProvider>
            <ValidationProvider rules="required|confirmed:password" tag="div" v-slot="{ classes }" class="form-group w-75 m-auto pt-2 pb-3">
              <label for="password_confirmation" class="form-label">Password Confirmation:</label>
              <input id="password_confirmation" class="form-control" name="new_password_confirmation" v-model="passwordFormData.new_password_confirmation" placeholder="Password Confirmation" :class="classes" type="password">
            </ValidationProvider>
        </ValidationObserver>
      </b-modal>
<!--      <b-modal-->
<!--        id="modal-course-level"-->
<!--        ref="modal"-->
<!--        title="Edit Course Level"-->
<!--        @show="openCourseLevelModal"-->
<!--        @hidden="resetCourseLevelModal"-->
<!--        @ok="updateCourseLevelSubmit"-->
<!--        ok-title="Submit"-->
<!--        centered-->
<!--      >-->
<!--        <ValidationObserver ref="editCourseLevel" id="editCourseLevel" tag="form" class="d-flex flex-column">-->
<!--          <div v-if="boughtCourses && boughtCourses.length" class="w-75 m-auto pt-2">-->
<!--            <div v-for="(item, index) in boughtCourses" :key="index">-->
<!--              <div class="pt-2 text-center font-weight-bold">{{item.course.name[$i18n.locale]}}</div>-->
<!--              <ValidationProvider rules="required" tag="div" class="form-group">-->
<!--                <label>Select Level</label>-->
<!--                <multiselect v-model="courseLevel[index]" placeholder="Select Course Level" :searchable="false" track-by="name" label="name" @close="selectCourseLevel(index, courseLevel[index])" :options="activeCourseLevelsList">-->
<!--                  <template slot="singleLabel" slot-scope="props">-->
<!--                    {{props.option.name[$i18n.locale]}}-->
<!--                  </template>-->
<!--                  <template slot="option" slot-scope="props">-->
<!--                    <span class="option__name">{{props.option.name[$i18n.locale]}}</span>-->
<!--                  </template>-->
<!--                </multiselect>-->
<!--              </ValidationProvider>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div v-else class="text-center pt-3 pb-3">Нету купленних курсов</div>-->
<!--        </ValidationObserver>-->
<!--      </b-modal>-->
      <b-modal
        id="modal-course-tariff"
        ref="modal"
        title="Edit Course Tariff"
        @show="openCourseTariffModal"
        @hidden="resetCourseTariffModal"
        @ok="updateCourseTariffSubmit"
        ok-title="Submit"
        centered
      >
        <ValidationObserver ref="editCourseTariff" id="editCourseTariff" tag="form" class="d-flex flex-column">
          <div v-if="boughtCourses && boughtCourses.length" class="w-75 m-auto pt-2">
            <div v-for="(item, index) in boughtCourses" :key="index">
              <div class="pt-2 text-center font-weight-bold">{{item.course.name[$i18n.locale]}}</div>
              <ValidationProvider rules="required" tag="div" class="form-group">
                <label>Select Tariff</label>
                <multiselect v-model="courseTariff[index]" placeholder="Select Course Level" :searchable="false" track-by="name" label="name" @close="selectCourseTariff(index, courseTariff[index])" :options="item.course.tariffs">
                  <template slot="singleLabel" slot-scope="props">
                    {{props.option.name[$i18n.locale]}}
                  </template>
                  <template slot="option" slot-scope="props">
                    <span class="option__name">{{props.option.name[$i18n.locale]}}</span>
                  </template>
                </multiselect>
              </ValidationProvider>
            </div>
          </div>
          <div v-else class="text-center pt-3 pb-3">Нету купленних курсов</div>
        </ValidationObserver>
      </b-modal>
      <b-modal
        id="modal-send-message"
        ref="modal"
        title="Send Message"
        @hidden="resetSendMessageModal"
        @ok="sendMessageSubmit"
        ok-title="Submit"
        centered
      >
        <ValidationObserver ref="sendMessage" id="sendMessage" tag="form" class="d-flex flex-column">
          <ValidationProvider rules="required" tag="div" v-slot="{ classes }" class="form-group w-75 m-auto pt-2 pb-3 d-flex flex-column">
            <label for="category">Select Category</label>
            <select class="w-100" name="category" id="category" v-model="messageFormData.support_ticket_category_id" :class="classes">
              <option :value="category.id" v-for="(category, index) in supportCategoriesList" :key="index">{{category.name[$i18n.locale]}}</option>
            </select>
          </ValidationProvider>
          <ValidationProvider rules="required" tag="div" v-slot="{ classes }" class="form-group w-75 m-auto pt-2 pb-3">
            <label for="message" class="form-label">Message:</label>
            <textarea class="w-100" name="message" id="message" rows="5" v-model="messageFormData.message" placeholder="Message" :class="classes"></textarea>
          </ValidationProvider>
        </ValidationObserver>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'UserEdit',
  data () {
    return {
      passwordFormData: {},
      messageFormData: {
        support_ticket_category_id: ''
      },
      courseLevelFormData: {
        courses: []
      },
      courseLevel: [],
      courseTariffFormData: {
        courses: []
      },
      courseTariff: []
    }
  },
  computed: {
    ...mapState({
      boughtCourses: state => state.user.boughtCourses,
      activeCourseLevelsList: state => state.courseLevel.activeCourseLevelsList,
      supportCategoriesList: state => state.supportCategory.supportCategoriesList
    })
  },
  created () {
    this.getActiveCourseLevels().then(() => {})
    this.getSupportCategoriesList().then(() => {})
  },
  methods: {
    ...mapActions({
      updateUserPassword: 'user/updateUserPassword',
      getUserBoughtCourses: 'user/getUserBoughtCourses',
      getActiveCourseLevels: 'courseLevel/getActiveCourseLevels',
      updateUserCourseLevel: 'user/updateUserCourseLevel',
      updateUserCourseTariff: 'user/updateUserCourseTariff',
      sendMessage: 'user/sendMessage',
      getSupportCategoriesList: 'supportCategory/getSupportCategoriesList'
    }),
    resetPasswordModal () {
      this.passwordFormData = {}
    },
    resetCourseLevelModal () {
      this.courseLevelFormData = {
        courses: []
      }
      this.courseLevel = []
    },
    resetCourseTariffModal () {
      this.courseTariffFormData = {
        courses: []
      }
      this.courseTariff = []
    },
    // openCourseLevelModal () {
    //   this.getUserBoughtCourses({ id: this.$route.params.id }).then(() => {
    //     for (let i = 0; i < this.boughtCourses.length; i++) {
    //       this.courseLevelFormData.courses.push({
    //         id: this.boughtCourses[i].course.id,
    //         course_level_id: this.boughtCourses[i].course.course_level_id
    //       })
    //       this.courseLevel.push(this.boughtCourses[i].course.course_level)
    //     }
    //   })
    // },
    openCourseTariffModal () {
      this.getUserBoughtCourses({ id: this.$route.params.id }).then(() => {
        for (let i = 0; i < this.boughtCourses.length; i++) {
          this.courseTariffFormData.courses.push({
            id: this.boughtCourses[i].course.id,
            course_tariff_id: this.boughtCourses[i].course_tariff_id
          })
          this.courseTariff.push(this.boughtCourses[i].course_tariff)
        }
      })
    },
    updatePasswordSubmit (bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.passwordEditForm.validate().then(success => {
        this.passwordFormData.user_id = this.$route.params.id
        if (success) {
          this.updateUserPassword(this.passwordFormData).then(res => {
            this.$nextTick(() => {
              this.$toasted.success('Пароль пользователя изменён!').goAway(1500)
              this.$bvModal.hide('modal-reset-password')
            })
          }).catch(err => {
            if (err.response.status === 400) {
              if (err.response.data.new_password) {
                this.$toasted.error(err.response.data.new_password[0]).goAway(1500)
              }
              if (err.response.data.new_password_confirmation) {
                this.$toasted.error(err.response.data.new_password_confirmation[0]).goAway(1500)
              }
            } else {
              this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
            }
          })
        } else {
          this.$toasted.error(this.$t('messages.error.validationError')).goAway(1500)
        }
      })
    },
    // updateCourseLevelSubmit (bvModalEvt) {
    //   bvModalEvt.preventDefault()
    //   this.$refs.editCourseLevel.validate().then(success => {
    //     this.courseLevelFormData.user_id = this.$route.params.id
    //     if (success) {
    //       this.updateUserCourseLevel(this.courseLevelFormData).then(res => {
    //         this.$nextTick(() => {
    //           this.$toasted.success('Уровень курса изменён!').goAway(1500)
    //           this.$bvModal.hide('modal-course-level')
    //         })
    //       }).catch(err => {
    //         if (err.response.status === 400) {
    //           console.log(err.response.data)
    //         } else {
    //           this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
    //         }
    //       })
    //     } else {
    //       this.$toasted.error(this.$t('messages.error.validationError')).goAway(1500)
    //     }
    //   })
    // },
    updateCourseTariffSubmit (bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.editCourseTariff.validate().then(success => {
        this.courseTariffFormData.user_id = +this.$route.params.id
        if (success) {
          this.updateUserCourseTariff(this.courseTariffFormData).then(res => {
            this.$nextTick(() => {
              this.$toasted.success('Пакет пользователя изменён!').goAway(1500)
              this.$bvModal.hide('modal-course-tariff')
            })
          }).catch(err => {
            if (err.response.status === 400) {
              console.log(err.response.data)
            } else {
              this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
            }
          })
        } else {
          this.$toasted.error(this.$t('messages.error.validationError')).goAway(1500)
        }
      })
    },
    selectCourseLevel (index, value) {
      if (value) {
        this.courseLevel[index] = value
        this.courseLevelFormData.courses[index].course_level_id = value.id
      }
    },
    selectCourseTariff (index, value) {
      if (value) {
        this.courseTariff[index] = value
        this.courseTariffFormData.courses[index].course_tariff_id = value.id
      }
    },
    resetSendMessageModal () {
      this.messageFormData = {
        support_ticket_category_id: ''
      }
    },
    sendMessageSubmit (bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.sendMessage.validate().then(success => {
        this.messageFormData.user_id = +this.$route.params.id
        if (success) {
          this.sendMessage(this.messageFormData).then(res => {
            this.$nextTick(() => {
              this.$toasted.success('Сообщение отправлено!').goAway(1500)
              this.$bvModal.hide('modal-send-message')
            })
          }).catch(err => {
            if (err.response.status === 400) {
              console.log(err.response.data)
            } else {
              this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
            }
          })
        } else {
          this.$toasted.error(this.$t('messages.error.validationError')).goAway(1500)
        }
      })
    }
  }
}
</script>
