import { http } from '../../../api'

const courseTariff = {
  namespaced: true,
  state: {
    courseTariffList: [],
    courseTariff: null
  },
  actions: {
    getAllCourseTariff: ({ commit }, params) => {
      let url = '/course-tariff'
      if (params) {
        const q = new URLSearchParams(params)
        url = url + '?' + q.toString()
      }
      return http.get(url).then(res => {
        commit('setCourseTariffList', res.data.data)
        return res.data
      })
    },
    createCourseTariff: ({ commit }, params) => {
      return http.post('/course-tariff', params).then(res => {
        return res.data
      })
    },
    deleteCourseTariff: ({ commit }, params) => {
      return http.delete('/course-tariff/' + params.id).then(res => {
        if (res.data.deleteType === 'forceDelete') {
          commit('setCourseTariffAfterDelete', params.id)
        } else {
          commit('setUpdateCourseTariffStatus', { id: params.id, status: 'inactive' })
        }
      })
    },
    restoreCourseTariff: ({ commit }, params) => {
      return http.get('/course-tariff/' + params.id + '/restore').then(res => {
        commit('setUpdateCourseTariffStatus', { id: params.id, status: 'active' })
      })
    },
    getCourseTariff: ({ commit }, params) => {
      return http.get('/course-tariff/' + params.id).then(res => {
        commit('setCourseTariff', res.data.data)
      })
    },
    updateCourseTariff: ({ commit }, params) => {
      return http.put('/course-tariff/' + params.id, params.data).then(res => {
      })
    }
  },
  mutations: {
    setUpdateCourseTariffStatus (state, data) {
      state.courseTariffList = state.courseTariffList.map(item => {
        if (item.id === data.id) {
          item.status = data.status
        }
        return item
      })
    },
    setCourseTariffList (state, data) {
      state.courseTariffList = data
    },
    setCourseTariffAfterDelete (state, id) {
      state.courseTariffList = state.courseTariffList.filter(item => item.id !== id)
    },
    setCourseTariff (state, data) {
      state.courseTariff = data
    }
  },
  getters: {
    courseTariffList: state => state.courseTariffList,
    courseTariff: state => state.courseTariff
  }
}

export default courseTariff
