import { http } from '../../../api'

const book = {
  namespaced: true,
  state: {
    booksList: [],
    book: null
  },
  actions: {
    getAllBooks: ({ commit }, params) => {
      let url = '/teaching-book'
      if (params) {
        const q = new URLSearchParams(params)
        url = url + '?' + q.toString()
      }
      return http.get(url).then(res => {
        commit('setBooksList', res.data.data)
        return res.data
      })
    },
    createBook: ({ commit }, params) => {
      return http.post('/teaching-book', params).then(res => {
        return res.data
      })
    },
    deleteBook: ({ commit }, params) => {
      return http.delete('/teaching-book/' + params.id).then(res => {
        if (res.data.deleteType === 'forceDelete') {
          commit('setBookAfterDelete', params.id)
        } else {
          commit('setUpdateBookStatus', { id: params.id, status: 'inactive' })
        }
      })
    },
    restoreBook: ({ commit }, params) => {
      return http.get('/teaching-book/' + params.id + '/restore').then(res => {
        commit('setUpdateBookStatus', { id: params.id, status: 'active' })
      })
    },
    getBook: ({ commit }, params) => {
      return http.get('/teaching-book/' + params.id).then(res => {
        commit('setBook', res.data.data)
      })
    },
    updateBook: ({ commit }, params) => {
      return http.post('/teaching-book/' + params.id, params.data).then(res => {
      })
    }
  },
  mutations: {
    setUpdateBookStatus (state, data) {
      state.booksList = state.booksList.map(item => {
        if (item.id === data.id) {
          item.status = data.status
        }
        return item
      })
    },
    setBooksList (state, data) {
      state.booksList = data
    },
    setBookAfterDelete (state, id) {
      state.booksList = state.booksList.filter(item => item.id !== id)
    },
    setBook (state, data) {
      state.book = data
    }
  },
  getters: {
    booksList: state => state.booksList,
    book: state => state.book
  }
}

export default book
