<template>
  <div>
    <div class="page-header">
      <Breadcrumbs name="слово в энциклопедию" />
      <div class="d-flex">
      </div>
    </div>
    <div class="row row-sm justify-content-center">
      <div class="col-lg-6 col-md-12">
        <div class="card custom-card">
          <div class="card-body">
            <div>
              <h6 class="main-content-label mb-1">Настройки номеров</h6>
            </div>
            <div>
              <ValidationObserver tag="form" id="teachingLangForm" ref="teachingLang" class="d-flex flex-column"
                                  @submit.prevent="formSubmit()">
                <ValidationProvider v-slot="{ classes }" rules="required" tag="div" class="form-group">
                  <label for="example">Пример</label>
                  <input class="form-control" id="example" v-model="formData.example"
                         :class="classes" type="text" placeholder="Пример">
                </ValidationProvider>
                <ValidationProvider v-slot="{ classes }" rules="required" tag="div" class="form-group">
                  <label for="country_code">Код страны</label>
                  <input class="form-control"  v-model="formData.country_code"
                         id="country_code"
                         :class="classes" type="text" placeholder="Код страны">
                </ValidationProvider>
                <button class="btn ripple btn-main-primary"><ButtonName/></button>
              </ValidationObserver>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import VideoForm from '../../components/VideoForm'
import Breadcrumbs from '../../components/Breadcrumbs'
import ButtonName from '../../components/ButtonName'
export default {
  name: 'EncyclopediaAdd',
  components: { ButtonName, Breadcrumbs, VideoForm },
  data () {
    return {
      formData: {
        country_code: null,
        example: null,
      },
      isCreate: false,
    }
  },
  created () {
    const id = this.$route.params.id
    if (id === 'add') {
      this.isCreate = true
    } else {
      this.getPhone(id).then(res => {
        this.formData = this.phone
      })
    }
  },
  computed: {
    ...mapGetters({
      phone: 'phones/phone'
    })
  },
  methods: {
    ...mapActions({
      create: 'phones/create',
      getPhone: 'phones/show',
      update: 'phones/update',
    }),
    formSubmit () {
      this.$refs.teachingLang.validate().then(success => {
        if (success) {
          if (this.isCreate) {
            this.create(this.formData).then(() => {
              this.$router.push('/phones')
              this.$toasted.success(this.$t('messages.error.somethingWentWrong')).goAway(1500)
            }).catch(() => {
              this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
            })
          } else {
            this.update({
              data: this.formData,
              id: this.phone.id
            }).then(() => {
              this.$router.push('/phones')
            }).catch(() => {
              this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
            })
          }
        } else {
          this.$toasted.error(this.$t('messages.error.validationError')).goAway(1500)
        }
      })
    }
  },
}
</script>
<style lang="scss">

</style>
