import { http } from '../../../api'

const courseModuleTest = {
  namespaced: true,
  state: {
    courseModuleTestsList: [],
    courseModuleTest: [],
    testTasksList: []
  },
  actions: {
    getCourseModuleTestsListByCourse: ({ commit }, params) => {
      return http.get('/course-module-test/' + params + '/list').then(res => {
        commit('setCourseModuleTestsList', res.data.data)
        return res.data
      })
    },
    getTestTasksList: ({ commit }, params) => {
      return http.get('/course-module-test/' + params + '/task-list').then(res => {
        commit('setTestTasksList', res.data.data)
        return res.data
      })
    },
    getCourseModuleTest: ({ commit }, id) => {
      return http.get('/course-module-test/' + id).then(res => {
        commit('setCourseModuleTest', res.data.data)
        return res.data
      })
    },
    createCourseModuleTest: ({ commit }, params) => {
      return http.post('/course-module-test', params).then(res => {
        return res.data
      })
    },
    updateCourseModuleTest: ({ commit }, params) => {
      return http.put('/course-module-test/' + params.id, params.data).then(res => {
        return res.data
      })
    },
    deleteModuleTest: ({ commit }, id) => {
      return http.delete('/course-module-test/' + id).then(res => {
        if (res.data.deleteType === 'forceDelete') {
          commit('removeCourseModuleTest', id)
        } else {
          commit('updateCourseModuleTestStatus', { id: id, status: 'inactive' })
        }
      })
    },
    restoreModuleTest: ({ commit }, id) => {
      return http.get('/course-module-test/' + id + '/restore').then(res => {
        commit('updateCourseModuleTestStatus', { id: id, status: 'active' })
      })
    },
    deleteTestTask: ({ commit }, params) => {
      return http.delete('/task/' + params).then(res => {
        if (res.data.deleteType === 'forceDelete') {
          commit('setTaskAfterDelete', params)
        } else {
          commit('setUpdateTaskStatus', { id: params, status: 'inactive' })
        }
      })
    },
    restoreTestTask: ({ commit }, params) => {
      return http.get('/task/' + params + '/restore').then(res => {
        commit('setUpdateTaskStatus', { id: params, status: 'active' })
      })
    }
  },
  mutations: {
    setUpdateTaskStatus (state, data) {
      state.testTasksList = state.testTasksList.map(item => {
        if (item.id === data.id) {
          item.status = data.status
        }
        return item
      })
    },
    setTaskAfterDelete (state, id) {
      state.testTasksList = state.testTasksList.filter(item => item.id !== id)
    },
    setCourseModuleTest (state, data) {
      state.courseModuleTest = data
    },
    setTestTasksList (state, data) {
      state.testTasksList = data
    },
    setCourseModuleTestsList (state, data) {
      state.courseModuleTestsList = data
    },
    updateCourseModuleTestStatus (state, data) {
      state.courseModuleTestsList = state.courseModuleTestsList.map(module => {
        if (module.id === data.id) {
          module.status = data.status
        }
        return module
      })
    },
    removeCourseModuleTest (state, id) {
      state.courseModuleTestsList = state.courseModuleTestsList.filter(module => module.id !== id)
    }
  },
  getters: {
    courseModuleTestsList: state => state.courseModuleTestsList,
    courseModuleTest: state => state.courseModuleTest
  }
}

export default courseModuleTest
