import { http } from '../../../api'

const taskSuggestedText = {
  namespaced: true,
  state: {
    task: null
  },
  actions: {
    createTask: ({ commit }, params) => {
      return http.post('/task-suggested-text', params).then(res => {
        return res.data
      })
    },
    getTask: ({ commit }, params) => {
      return http.get('/task-suggested-text/' + params).then(res => {
        commit('setTask', res.data)
      })
    },
    updateTask: ({ commit }, params) => {
      return http.post('/task-suggested-text/' + params.id, params.data).then(res => {
      })
    },
    deleteWord: ({ commit }, params) => {
      return http.post('/task-suggested-text/delete-words', params).then(res => {
      })
    }
  },
  mutations: {
    setTask (state, data) {
      state.task = data
    }
  },
  getters: {
    task: state => state.task
  }
}

export default taskSuggestedText
