<template>
  <div  class="rounded-circle d-flex align-items-center justify-content-center player-button">
    <img v-if="!play" src="~@/assets/img/icons/audio-play.svg" alt="play">
    <img v-else src="~@/assets/img/icons/audio-pause.svg" alt="play">
  </div>
</template>

<script>
export default {
  props: {
    play: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
.player-button{
  height: 30px;
  width: 30px;
  border: 2px solid red;
  cursor: pointer;

  img {
    max-width: 10px;
    height: auto;
  }
}
</style>
