<template>
  <div id="app" class="main-body leftmenu" :class="{'main-sidebar-hide': isOpenMenu }">
     <router-view/>
  </div>
</template>

<script>

import EventBus from './event-bus'

export default {
  name: 'App',
  data () {
    return {
      isOpenMenu: false
    }
  },
  mounted () {
    const _this = this
    EventBus.$on('toggleMenu', function (payLoad) {
      _this.isOpenMenu = payLoad
    })
  }
}
</script>

<style>

</style>
