import { http } from '../../../api'

const taskPickUpTranslation = {
  namespaced: true,
  state: {
    task: null
  },
  actions: {
    createTask: ({ commit }, params) => {
      return http.post('/task-pick-up-translation', params).then(res => {
        return res.data
      })
    },
    getTask: ({ commit }, params) => {
      return http.get('/task-pick-up-translation/' + params).then(res => {
        commit('setTask', res.data)
      })
    },
    updateTask: ({ commit }, params) => {
      return http.post('/task-pick-up-translation/' + params.id, params.data).then(res => {
      })
    },
    deleteTranslations: ({ commit }, params) => {
      return http.post('task-pick-up-translation/delete-translations', params).then(res => {
      })
    }
  },
  mutations: {
    setTask (state, data) {
      state.task = data
    }
  },
  getters: {
    task: state => state.task
  }
}

export default taskPickUpTranslation
