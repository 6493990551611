<template>
  <div>
    <div class="page-header">
      <Breadcrumbs name="эксперта"/>
      <div class="d-flex">
      </div>
    </div>
    <div class="row row-sm justify-content-center">
      <div class="col-xl-6 col-lg-8 col-md-12">
        <div class="card custom-card">
          <div class="card-body">
            <div>
              <h6 class="main-content-label mb-1">Эксперт</h6>
            </div>
            <ValidationObserver ref="bookAddForm" id="bookAddForm" tag="form" class="d-flex flex-column"
                                @submit.prevent="formSubmit()">
              <b-form-group
                label="Имя"
                label-for="first_name"
              >
                <b-form-input id="first_name" v-model="form.first_name" required/>
              </b-form-group>
              <b-form-group
                label="Фамилия"
                label-for="last_name"
              >
                <b-form-input id="last_name" v-model="form.last_name" required/>
              </b-form-group>

              <b-form-group
                label="Логин"
                label-for="login"
              >
                <b-form-input id="login" v-model="form.login" required/>
              </b-form-group>

              <b-form-group
                label="Почта"
                label-for="Email"
              >
                <b-form-input id="Email" v-model="form.email" type="email" required/>
              </b-form-group>

              <b-form-group
                label="Пароль"
                label-for="Password"
              >
                <b-form-input id="Password" v-model="form.password" type="password" :required="isCreate"/>
              </b-form-group>

              <b-form-group
                label="Подтвердить пароль"
                label-for="confirmation"
              >
                <b-form-input id="confirmation" v-model="form.password_confirmation" type="password"
                              :required="isCreate"/>
              </b-form-group>

              <button class="btn ripple btn-main-primary mt-2" :disabled="buttonDisable"
                      :class="buttonDisable ? 'button-readonly' : ''"><ButtonName/>
              </button>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Breadcrumbs from '../../components/Breadcrumbs'
import ButtonName from '../../components/ButtonName'

export default {
  name: 'ExpertEdit',
  components: { ButtonName, Breadcrumbs },
  data () {
    return {
      form: {
        id: null,
        first_name: null,
        last_name: null,
        email: null,
        password: null,
        login: null,
        password_confirmation: null
      },
      errors: [],
      isCreate: true,
      buttonDisable: false,
    }
  },
  async created () {
    this.isCreate = this.$route.params.id === 'add'

    if (!this.isCreate) {
      await this.getUser({ id: this.$route.params.id })
      this.form = this.user
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/user'
    })
  },
  methods: {
    ...mapActions({
      storeExpert: 'user/storeExpert',
      updateExpert: 'user/updateExpert',
      getUser: 'user/getUser',
    }),

    async formSubmit () {
      this.buttonDisable = true

      try {
        if (this.isCreate) {
          await this.storeExpert(this.form)
        } else {
          await this.updateExpert(this.form)
        }

        this.$toasted.success(this.$t('messages.success.courseCreated')).goAway(1500)
        await this.$router.push('/experts?role=expert')
      } catch (e) {
        this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
      }

      this.buttonDisable = false
    }
  }
}
</script>

<style scoped>

</style>
