import { http } from '../../../api'

const taskType = {
  namespaced: true,
  state: {
    taskTypesList: [],
    taskType: {}
  },
  actions: {
    getTaskTypes: ({ commit }) => {
      return http.get('/task-type').then(res => {
        commit('setTaskTypes', res.data.data)
      })
    },
    getTaskType: ({ commit }, params) => {
      return http.get('/task-type/' + params).then(res => {
        commit('setTaskType', res.data.data)
      })
    }
  },
  mutations: {
    setTaskTypes (state, data) {
      state.taskTypesList = data
    },
    setTaskType (state, data) {
      state.taskType = data
    }
  },
  getters: {
    taskTypesList: state => state.taskTypesList,
    taskType: state => state.taskType
  }
}

export default taskType
