<template>
  <div>
    <div class="page-header">
      <div>
        <h2 class="main-content-title tx-24 mg-b-5">Settings</h2>
      </div>
    </div>

    <form action="" class="form-horizontal" @submit.prevent="submit">
      <b-tabs content-class="mt-3">
        <template v-for="(settings, settingIndex) in formData" >
          <b-tab :title="settings.name" :active="settingIndex === 0" :key="`group${settings.id}`">
            <div class="form-group" v-for="(setting, index) in settings.settings" :key="setting.id">
              <div class="row row-sm">
                <div class="col-md-3">
                  <label class="form-label text-capitalize">{{ setting.key.replaceAll('_', ' ') }}</label>
                </div>
                <div class="col-md-9"
                     v-if="setting.field === 'input'">
                  <input
                    :type="setting.key === 'news_slider_count' || setting.key === 'interesting_slider_count' ? 'number' : 'text'"
                    class="form-control" v-model="setting.value" placeholder="Designation">
                </div>
                <div class="col-md-9" v-else-if="setting.field === 'json' && setting.key !== 'promo_video'">
                  <b-form-tags input-id="tags-basic" v-model="setting.value"></b-form-tags>
<!--                  <input-tag  class="form-control" :addTagOnKeys=[13,188,9,32]></input-tag>-->
                </div>
                <div v-else-if="setting.key === 'promo_video'" class="col-md-9">
                  <div class="d-flex">
                    <div>
                      <label class="radiobox">
                        <input checked="" name="video_type" type="radio" v-model="videoType" value="video_iframe"> <span>Add Video Iframe</span>
                      </label>
                    </div>
                    <div class="ml-5">
                      <label class="radiobox">
                        <input name="video_type" v-model="videoType" type="radio" value="video"> <span>Upload Video</span>
                      </label>
                    </div>
                  </div>
                  <div class="mt-3">
                    <ValidationProvider rules="required" tag="div" v-slot="{ classes }" v-if="videoType === 'video_iframe'"
                                        class="form-group">
                      <label for="video_iframe" class="form-label">Video Iframe:</label>
                      <input id="video_iframe" class="form-control" name="video_iframe" v-model="setting.value.video_iframe"
                             placeholder="Video Iframe" :class="classes" type="text">
                    </ValidationProvider>
                    <ValidationProvider v-else tag="div" class="form-group">
                      <div>
                        <label for="video" class="form-label">Video:</label>
                      </div>
                      <div class="custom-file">
                        <input name="video" accept=".mp4, .webm, .ogg" type="file" id="video" class="custom-file-input"
                               @change="uploadCoverFile($event.target.files,index)" aria-describedby="inputGroupFileAddon01">
                        <label class="custom-file-label" for="video"
                               v-if="setting.value && setting.value.video_path">{{ upload ? setting.value.video_path.name : setting.value.video_path }}</label>
                        <label class="custom-file-label after-bg-secondary" for="video" v-else>Choose file</label>
                      </div>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="col-md-9" v-else-if="setting.key === 'coordinates'">
                  <input type="text" class="form-control" v-model="setting.value" placeholder="Designation">
                  <div v-html="setting.value" class="mt-3 border-dark"></div>
                </div>

                <div class="col-md-9" v-else-if="setting.key === 'check_course_edit'">
                  <ToggleButton color="#6259ca" :value="setting.value === '1'"
                                @change="(value) => setting.value = value.value"
                                :labels="{checked: 'On', unchecked: 'Off'}"/>
                </div>
              </div>
            </div>
          </b-tab>
        </template>

      </b-tabs>
      <button type="submit" class="btn btn-primary mt-3 mr-1 float-right">Submit</button>
    </form>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { ToggleButton } from 'vue-js-toggle-button'

export default {
  components: { ToggleButton },
  name: 'Settings',
  data () {
    return {
      formData: [],
      videoType: 'video_iframe',
      upload: false
    }
  },
  computed: {
    ...mapState({
      settingsList: state => state.settings.settingsList
    })
  },
  created () {
    this.getSettingsList().then(() => {
      this.formData = [...this.settingsList]
      if (this.formData.value.video_iframe) {
        this.videoType = 'video_iframe'
      } else if (this.formData.value.video_path) {
        this.videoType = 'video'
      }
      // this.settingsList.map(setting => {
      //   if (setting.field === 'boolean') {
      //     setting.value = setting.value === '1'
      //   }
      // })
      console.log('settingList', this.settingsList)
    })
  },
  methods: {
    ...mapActions({
      getSettingsList: 'settings/getSettingsList',
      updateSettings: 'settings/updateSettings'
    }),
    submit () {
      let params = []
      this.formData.forEach(data => {
         data.settings.forEach(item => {
           params.push(item)
         })
      })
      this.updateSettings(params).then(() => {
        this.$toasted.success('Setting update was successfully!').goAway(1500)
      }).catch(() => {
        this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
      })
    },
    uploadCoverFile (file, index) {
      this.upload = true
      this.formData[index].value.video_path = file[0]
    }
  },
}
</script>
