<template>
  <div>
    <div class="page-header">
      <div>
        <h2 class="main-content-title tx-24 mg-b-5">Вопрос от клиента</h2>
      </div>
      <div class="d-flex">
        <div class="justify-content-center">
        </div>
      </div>
    </div>
    <div class="row row-sm">
      <div class="col-lg-12">
        <div class="card custom-card">
          <div class="card-body">
            <div>
              <h6 class="main-content-label mb-1">Список вопросов от клиента</h6>
            </div>
            <div class="table-responsive">
              <b-table striped hover :items="applicationList" :fields="tableFields">
                <template #cell(created_at)="row">
                  <div>{{row.item.created_at  | moment("DD.MM.YYYY")}}</div>
                </template>
                <template #cell(status)="row">
                    {{row.item.status && row.item.status.name}}
                </template>
                <template #cell(text)="row">
                  <div :class="{'clump-2': !showMore[row.item.id]}">{{row.item.text}}</div>
                </template>
                <template #cell(actions)="row">
                  <div class="d-flex align-items-center">
                    <i class="fas mr-4 cursor-pointer"
                      :class="{'fa-eye': !showMore[row.item.id], 'fa-eye-slash': showMore[row.item.id]}"
                       @click="show(row.item.id)"
                    ></i>
                    <b-button style="width: 177px;"  v-b-modal.modal-1 @click="activeItem = row.item">Изменить статус</b-button>
                  </div>

                </template>
              </b-table>
              <div class="d-flex justify-content-end  mt-3">
                <div class="mr-3">
                  <b-form-select
                    @change="changePaginationPerPage"
                    style="width: 80px"
                    v-model="paginationPerPage"
                    :options="paginationOptions"
                  >
                  </b-form-select>
                </div>
                <div>
                  <b-pagination
                    class="m-0"
                    @change="changePagination"
                    v-model="paginationCurrentPage"
                    :total-rows="pagination.count"
                    :per-page="paginationPerPage"
                    first-text="<<"
                    prev-text="<"
                    next-text=">"
                    last-text=">>"
                  ></b-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="modal-1" title="Статус" hide-footer>
      <b-form @submit.prevent="changeStatus" class="container p-5">
        <b-form-group
          id="input-group-1"
          label="Статус"
          label-for="input-1"
        >
          <b-form-select
            v-model="activeItem.status_id"
            :options="statuses"
            required
            value-field="id"
            text-field="name"
          />
        </b-form-group>
        <b-button type="submit" variant="primary" @click="$bvModal.hide('modal-1')">Обновить</b-button>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'Application',
  data () {
    return {
      paginationOptions: [
        { value: 6, text: '6' },
        { value: 10, text: '10' },
        { value: 30, text: '30' },
        { value: 100, text: '100' }
      ],
      paginationCurrentPage: 1,
      paginationPerPage: 6,
      pagination: {},
      activeItem: {
        status_id: null,
        id: null,
      },
      showMore: {}
    }
  },
  computed: {
    ...mapState({
      applicationList: state => state.faq.applicationList,
      statuses: state => state.faq.statuses,
    }),
    tableFields () {
      return [
        {
          key: 'id',
          sortable: true
        },
        {
          key: 'name',
          label: 'Имя',
          sortable: true
        },
        {
          key: 'email',
          label: 'Почта',
          sortable: true
        },
        {
          key: 'phone_number',
          label: 'Телефон',
          sortable: true
        },
        {
          key: 'text',
          label: 'Вопрос',
          sortable: true
        },
        {
          key: 'status',
          label: 'Статус',
          sortable: true
        },
        {
          key: 'created_at',
          label: 'Создан',
          sortable: true
        },
        {
          key: 'actions',
          label: 'Действие',
          sortable: false
        },
      ]
    }
  },
  async created () {
    this.getStatuses()
    this.getApplicationList({})

  },
  methods: {
    ...mapActions({
      getApplication: 'faq/getApplication',
      getStatuses: 'faq/getStatuses',
      updateStatus: 'faq/updateStatus'
    }),
    show(id){
      console.log('id', id)
      this.$set(this.showMore, id, !this.showMore[id])
    },
    async changeStatus(){
      const params = {
        id: this.activeItem.id,
        status_id: this.activeItem.status_id
      }
      try {
        const response = await this.updateStatus(params)
        const index= this.applicationList.findIndex(item => item.id === params.id)
        this.$set(this.applicationList,index,response)
      }catch (e){
        console.log('e')
      }
    },
    getApplicationList (params) {
      this.getApplication(params).then(res => {
        this.pagination = res.pagination
        this.applicationList.forEach(app => {
            this.$set(this.showMore, app.id, false)
        })
      }).catch(() => {
        this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
      })
    },
    changePaginationPerPage (value) {
      this.getApplicationList({ page: this.paginationCurrentPage, count: value })
    },
    changePagination (page) {
      this.getApplicationList({ page: page, count: this.paginationPerPage })
    }
  }
}
</script>
<style lang="scss" scoped>
.clump-2{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.cursor-pointer{
  cursor: pointer;
}
</style>
