<template>
  <div>
    <h2 class="main-content-title tx-24 mg-b-5">{{ isCreate ? 'Добавить' : 'Редактировать' }} {{name}}</h2>
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a href="#" @click="$router.go(-1)">Назад</a></li>
      <li class="breadcrumb-item active" aria-current="page">{{ isCreate ? 'Добавить' : 'Редактировать' }} {{name}}</li>
    </ol>
  </div>
</template>

<script>
export default {
  name: 'Breadcrumbs',
  props: {
    name: ''
  },
  computed: {
    isCreate(){
      return this.$route.params.id === 'add'
    }
  }
}
</script>

<style scoped>

</style>
