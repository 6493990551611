<template>
  <div>
    <div class="page-header">
      <breadcrumbs name="тариф"/>
      <div class="d-flex">
      </div>
    </div>
    <div class="row row-sm justify-content-center">
      <div class="col-xl-6 col-lg-8 col-md-12">
        <div class="card custom-card">
          <div class="card-body">
            <div>
              <h6 class="main-content-label mb-1">Тариф</h6>
            </div>
            <div v-if="showVideo === true">
              <ValidationObserver ref="courseTariffAddForm" id="courseTariffAddForm" tag="form" class="d-flex flex-column" @submit.prevent="formSubmit()">
                <b-tabs content-class="mt-3">
                  <b-tab :title="language.code" v-for="(language, i) in languages" :key="i">
                    <ValidationProvider v-slot="{ classes }" rules="required" tag="div" class="form-group">
                      <label :for="'name[' + language.code + ']'" class="form-label">Название:</label>
                      <input :id="'name[' + language.code + ']'" class="form-control" :name="'name[' + language.code + ']'" v-model="formData.name[language.code]" :class="checkValidation(classes, language.code)" placeholder="Название" type="text">
                    </ValidationProvider>
                  </b-tab>
                  <ValidationProvider v-slot="{ classes, errors }" rules="required" tag="div" class="form-group">
                    <label for="level">Уровень</label>
                    <input type="number" id="level" class="form-control" v-model="formData.level" />
                    <div class="invalid-feedback">{{errors[0]}}</div>
                  </ValidationProvider>
                  <ValidationProvider v-slot="{ classes }" rules="" tag="div" class="form-group">
                    <label for="discount_for_family">Скидка на любую следующую покупку в школе для себя или члена семьи</label>
                    <input type="number" id="discount_for_family" class="form-control" v-model="formData.discount_for_family" />
                  </ValidationProvider>
                  <ValidationProvider v-slot="{ classes }" rules="" tag="div" class="form-group">
                    <label for="access_materials_after_purchasing_course">Сохранение доступа к материалам при покупке следующего курса по одному языку</label>
                    <input type="number" id="access_materials_after_purchasing_course" class="form-control" v-model="formData.access_materials_after_purchasing_course" />
                  </ValidationProvider>
                  <ValidationProvider v-slot="{ classes }" rules="" tag="div" class="form-group">
                    <label for="consultation">Индивидуальная консультация с экспертом</label>
                    <input type="number" id="consultation" class="form-control" v-model="formData.consultation" />
                  </ValidationProvider>
                  <LgInput label="% скидки, который получит пользователь от реферала" v-model="formData.referral_discount" name="referral_discount" :rules="{required:true,regex:/^-?\d+\.?\d*$/}" />
                  <div>
                    <label class="radiobox">
                      <input :checked="formData.automatic_check_tasks" name="automatic_check_tasks" type="checkbox" v-model="formData.automatic_check_tasks"> <span>Автоматическая проверка задачи</span>
                    </label>
                  </div>
                  <div>
                    <label class="radiobox">
                      <input :checked="formData.access_independent_work" name="access_independent_work" type="checkbox" v-model="formData.access_independent_work"> <span>Доступ к самостоятельной работе"</span>
                    </label>
                  </div>
                  <div>
                    <label class="radiobox">
                      <input :checked="formData.access_additional_materials" name="access_additional_materials" type="checkbox" v-model="formData.access_additional_materials"> <span>Доступ ко всем дополнительным материалам</span>
                    </label>
                  </div>
                  <div>
                    <label class="radiobox">
                      <input :checked="formData.access_dictionary" name="access_dictionary" type="checkbox" v-model="formData.access_dictionary"> <span>Доступ к словарю</span>
                    </label>
                  </div>
                  <div>
                    <label class="radiobox">
                      <input :checked="formData.access_grammar" name="access_grammar" type="checkbox" v-model="formData.access_grammar"> <span>Доступ к разделу Грамматика</span>
                    </label>
                  </div>
                  <div>
                    <label class="radiobox">
                      <input :checked="formData.access_notes" name="access_notes" type="checkbox" v-model="formData.access_notes"> <span>Доступ к заметкам</span>
                    </label>
                  </div>
                  <div>
                    <label class="radiobox">
                      <input :checked="formData.access_chat" name="access_chat" type="checkbox" v-model="formData.access_chat"> <span>Доступ к внутреннему чату курса</span>
                    </label>
                  </div>
                  <div>
                    <label class="radiobox">
                      <input :checked="formData.access_fb_chat" name="access_fb_chat" type="checkbox" v-model="formData.access_fb_chat"> <span>Доступ к группам на ФБ и в мессенджерах</span>
                    </label>
                  </div>
                  <div>
                    <label class="radiobox">
                      <input :checked="formData.feedback_experts" name="feedback_experts" type="checkbox" v-model="formData.feedback_experts"> <span>Обратная связь от экспертов школы</span>
                    </label>
                  </div>
                  <div>
                    <label class="radiobox">
                      <input :checked="formData.additional_course_gift" name="additional_course_gift" type="checkbox" v-model="formData.additional_course_gift"> <span>Любой дополнительный курс в подарок (на выбор) в течении 6 месяцев</span>
                    </label>
                  </div>
                  <div>
                    <label  class="radiobox">
                      <input :checked="formData.access_upgrade_tariff"  type="checkbox" v-model="formData.access_upgrade_tariff"> <span>Возможность апгрейда пакета</span>
                    </label>
                  </div>
                  <div>
                    <label  class="radiobox">
                      <input :checked="formData.extension_access_course"  type="checkbox" v-model="formData.extension_access_course"> <span>Возможность продления доступа к курсу</span>
                    </label>
                  </div>
                  <div>
                    <label  class="radiobox">
                      <input :checked="formData.freezing_possibility"  type="checkbox" v-model="formData.freezing_possibility"> <span>Возможность заморозки</span>
                    </label>
                  </div>
                </b-tabs>
                <button class="btn ripple btn-main-primary mt-2" :disabled="buttonDisable" :class="buttonDisable ? 'button-readonly' : ''"><button-name/></button>
              </ValidationObserver>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Breadcrumbs from '../../components/Breadcrumbs'
import ButtonName from '../../components/ButtonName'
import LgInput from '../../components/forms/LgInput'

export default {
  name: 'CourseTariffAdd',
  components: { LgInput, ButtonName, Breadcrumbs },
  data () {
    return {
      showVideo: false,
      isEdit: false,
      buttonDisable: false,
      formData: {
        access_extend: {},
        name: {},
        automatic_check_tasks: 0,
        access_independent_work: 0,
        access_additional_materials: 0,
        access_dictionary: 0,
        access_grammar: 0,
        access_notes: 0,
        access_chat: 0,
        access_fb_chat: 0,
        feedback_experts: 0,
        additional_course_gift: 0
      },
      isCreate: false,
      courseId: ''
    }
  },
  computed: {
    ...mapState({
      languages: state => state.language.languages,
      courseTariff: state => state.courseTariff.courseTariff
    })
  },
  created () {
    this.courseId = this.$route.params.id
    if (this.courseId === 'add') {
      this.isEdit = false
      this.isCreate = true
      this.showVideo = true
    } else {
      this.isEdit = true
      this.getCourseTariff({ id: this.courseId }).then(res => {
        this.showVideo = true
        this.formData = this.courseTariff
      })
    }
  },
  methods: {
    ...mapActions({
      createCourseTariff: 'courseTariff/createCourseTariff',
      getCourseTariff: 'courseTariff/getCourseTariff',
      updateCourseTariff: 'courseTariff/updateCourseTariff'

    }),
    checkValidation (data) {
      return data
    },
    formSubmit () {
      this.$refs.courseTariffAddForm.validate().then(success => {
        if (success) {
          this.buttonDisable = true

          if (this.isCreate === true) {
            this.createCourseTariff(this.formData).then(() => {
              this.$toasted.success(this.$t('messages.success.courseTariffCreated')).goAway(1500)
              this.$router.push('/course-tariff')
            }).catch(() => {
              this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
            }).finally(() => {
              this.buttonDisable = false
            })
          } else {
            this.updateCourseTariff({ data: this.formData, id: this.$route.params.id }).then(res => {
              this.$toasted.success(this.$t('messages.success.courseTariffUpdated')).goAway(1500)
              this.$router.push('/course-tariff')
            }).catch(() => {
              this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
            }).finally(() => {
              this.buttonDisable = false
            })
          }
        } else {
          this.$toasted.error(this.$t('messages.error.validationError')).goAway(1500)
        }
      })
    }
  }
}
</script>
