import { http } from '../../../api'

const faq = {
  namespaced: true,
  state: {
    faqList: [],
    applicationList: [],
    faq: {},
    statuses: [],
  },
  actions: {
    getAllFaqs: ({ commit }, params) => {
      let url = '/faq/get-all'
      if (params) {
        const q = new URLSearchParams(params)
        url = url + '?' + q.toString()
      }
      return http.get(url).then(res => {
        commit('setFaqList', res.data.data)
        return res.data
      })
    },
    getApplication: ({ commit }, params) => {
      let url = '/application'
      if (params) {
        const q = new URLSearchParams(params)
        url = url + '?' + q.toString()
      }
      return http.get(url).then(res => {
        commit('setApplication', res.data.data)
        return res.data
      })
    },
    getStatuses({commit}) {
      return http.get('application/statuses').then(res => {
        commit('setStatuses', res.data)
      })
    },
    getFaqList: ({ commit }) => {
      return http.get('/faq').then(res => {
        commit('setFaqList', res.data.data)
      })
    },
    getFaq: ({ commit }, id) => {
      return http.get('/faq/' + id).then(res => {
        commit('setFaq', res.data.data)
        return res.data
      })
    },
    updateStatus({commit}, params){
      return http.put(`application/${params.id}`, params).then(res => res.data)
    },
    create: ({ commit }, params) => {
      return http.post('/faq', params).then(res => {
        return res
      })
    },
    update: ({ commit }, params) => {
      return http.put('/faq/' + params.id, params.data).then(res => {
        return res
      })
    },
    restore: ({ commit }, id) => {
      return http.get('/faq/' + id + '/restore').then(res => {
        commit('updateFaqStatus', { id: id, status: 'active' })
      })
    },
    deleteFaq: ({ commit }, id) => {
      return http.delete('/faq/' + id).then(res => {
        if (res.data.deleteType === 'forceDelete') {
          commit('removeFaq', id)
        } else {
          commit('updateFaqStatus', { id: id, status: 'inactive' })
        }
      })
    },
    sortPositionFaq: ({ commit }, params) => {
      return http.post('/faq/update-sort', params.data).then(res => {
        return res
      })
    }
  },
  mutations: {
    setFaqList (state, data) {
      state.faqList = data
    },
    setApplication (state, data) {
      state.applicationList = data
    },
    updateFaqStatus (state, data) {
      state.faqList = state.faqList.map(faq => {
        if (faq.id === data.id) {
          faq.status = data.status
        }
        return faq
      })
    },
    removeFaq (state, id) {
      state.faqList = state.faqList.filter(faq => faq.id !== id)
    },
    setFaq (state, data) {
      state.faq = data
    },
    setStatuses(state, data){
      state.statuses = data
    }
  },
  getters: {
    faqList: state => state.faqList,
    applicationList: state => state.applicationList,
    faq: state => state.faq,
    statuses: state => state.statuses
  }
}

export default faq
