<template>
  <div>
    <div class="page-header">
      <div>
        <h2 class="main-content-title tx-24 mg-b-5">Test Task Types</h2>
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="#" @click="$router.go(-1)">Назад</a></li>
          <li class="breadcrumb-item active" aria-current="page">Test Task Types</li>
        </ol>
      </div>
      <div class="d-flex">
      </div>
    </div>
    <div class="row row-sm justify-content-center">
      <div class="col-lg-6 col-md-12">
        <div class="card custom-card">
          <div class="card-body">
            <div>
              <h6 class="main-content-label mb-1">Test Task Types</h6>
            </div>
            <div class="p-2">
              <router-link tag="div" :to="'/task/' + $route.params.courseId + '/' + $route.params.moduleId + '/' + $route.params.testId + '/' + 'task-type/' + taskType.id + '/add'" class="mt-4 card p-2 pointer" v-for="(taskType, index) in taskTypesList" :key="index">
                <div class="title font-weight-bold tx-20">{{taskType.name[$i18n.locale]}}</div>
                <div class="d-flex mt-2">
                  <img height="200" width="300" :src="taskType.image" alt="">
                  <div class="ml-5">{{taskType.description[$i18n.locale]}}</div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'CourseTestTaskType',
  computed: {
    ...mapState({
      taskTypesList: state => state.taskType.taskTypesList
    })
  },
  created () {
    this.getTaskTypes().then(() => {
    })
  },
  methods: {
    ...mapActions({
      getTaskTypes: 'taskType/getTaskTypes'
    })
  }
}
</script>
