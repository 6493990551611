<template>
  <div>
    <div class="page-header">
      <div>
        <h2 class="main-content-title tx-24 mg-b-5">Chat With Expert</h2>
      </div>
      <div class="d-flex">
        <div class="justify-content-center">
        </div>
      </div>
    </div>
    <div class="row row-sm">
      <div class="col" v-if="user">
        <div class="card custom-card mb-0">
          <div class="pt-0">
            <div class="main-content-body main-content-body-chat">
              <div class="main-chat-header pt-3" v-if="user && user !== null">
                <div class="main-img-user online">
                  <img v-if="user.avatar_path" class="img-thumbnail table-image" :src="user.avatar_path"
                       alt="avatar">
                  <img v-else class="img-thumbnail table-image" src="../../../assets/img/users/default.png"
                       alt="avatar">
                </div>
                <div class="main-chat-msg-name" v-if="user.first_name && user.last_name">
                  <h6 v-if="user">{{user.first_name + ' ' + user.last_name}}</h6>
                </div>
                <nav class="nav">
                  <div class="dropdown-menu dropdown-menu-right shadow">
                    <a class="dropdown-item" href="#" @click.prevent="placeVideoCall(user.id, user.name)"><i class="fe fe-video mr-1"></i> Video Call</a>
                  </div>
                  <a class="nav-link" data-toggle="tooltip" href="" @click.prevent="placeVideoCall(user.id, user.name)" title="" data-original-title="Video Call">
                    <i class="fe fe-video"></i>
                  </a>
                </nav>
              </div><!-- main-chat-header -->
              <div class="row">
                <div class="col video-container" v-if="callPlaced">
                  <video
                    ref="userVideo"
                    muted
                    width="100%"
                    playsinline
                    autoplay
                    class="cursor-pointer"
                    :class="isFocusMyself === true ? 'user-video' : 'partner-video'"
                    @click="toggleCameraArea"
                  />
                  <video
                    ref="partnerVideo"
                    playsinline
                    autoplay
                    width="100%"
                    class="cursor-pointer"
                    :class="isFocusMyself === true ? 'partner-video' : 'user-video'"
                    @click="toggleCameraArea"
                    v-if="videoCallParams.callAccepted"
                  />
                  <div class="partner-video" v-else>
                    <div v-if="callPartner" class="column items-center q-pt-xl">
                      <div class="col q-gutter-y-md text-center">
                        <p class="q-pt-md">
                          <strong>{{ callPartner }}</strong>
                        </p>
                        <p>calling...</p>
                      </div>
                    </div>
                  </div>
                  <div class="action-btns">
                    <button type="button" class="btn btn-info" @click="toggleMuteAudio">
                      {{ mutedAudio ? "Unmute" : "Mute" }}
                    </button>
                    <button
                      type="button"
                      class="btn btn-primary mx-4"
                      @click="toggleMuteVideo"
                    >
                      {{ mutedVideo ? "ShowVideo" : "HideVideo" }}
                    </button>
                    <button type="button" class="btn btn-danger" @click="endCall">
                      EndCall
                    </button>
                  </div>
                </div>
                <div class="col">
                  <div class="main-chat-body ps--active-y" id="ChatBody">
                    <div class="p-3 content-inner" v-chat-scroll>
                      <div class="media" :class="message.sender_is_user ? '' : 'flex-row-reverse' "
                           v-for="(message, index) in userChatMessages" :key="index">
                        <div class="main-img-user online" v-if="message.sender_is_user">
                          <img v-if="message.user && message.user.avatar" :src="message.user.avatar_path" alt="avatar">
                          <img v-else src="../../../assets/img/users/default.png" alt="avatar">
                        </div>
                        <div class="main-img-user online" v-else>
                          <img v-if="message.admin && message.admin.avatar" :src="message.admin.avatar_path" alt="avatar">
                          <img v-else src="../../../assets/img/users/default.png" alt="avatar">
                        </div>
                        <div class="media-body">
                          <div class="main-msg-wrapper">{{message.message}}</div>
                          <div><span>{{message.created_at | moment('DD.MM.YYYY | HH:mm')}}</span></div>
                          <div v-if="message.sender_is_user"></div>
                          <div v-else></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <form class="main-chat-footer" @submit.prevent="sendUserChatForm()">
                  <textarea v-model="userChatFormData.message" class="form-control"
                          placeholder="Type your message here..." type="text"></textarea>
                    <button class="main-msg-send btn btn-outline-light" type="submit" :disabled="disableButtonChatForm"><i
                      class="far fa-paper-plane"></i></button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Peer from 'simple-peer'
import { getPermissions } from '../../../helpers'
import EventBus from '../../../event-bus'

export default {
  name: 'ChatExpert',
  computed: {
    ...mapState({
      user: state => state.user.user
    })
  },
  data () {
    return {
      userChatMessages: [],
      userChatFormData: {},
      disableButtonChatForm: false,
      selectedUser: null,

      isFocusMyself: true,
      callPlaced: false,
      callPartner: null,
      mutedAudio: false,
      mutedVideo: false,
      videoCallParams: {
        users: [],
        stream: null,
        receivingCall: false,
        caller: null,
        callerSignal: null,
        callAccepted: false,
        channel: null,
        peer1: null,
        peer2: null
      }
    }
  },
  created () {
    this.getUser({ id: this.$route.params.userId }).then(res => {
      this.getMessages({
        course_id: this.$route.params.id,
        user_id: res.data.id
      }).then(res => {
        setTimeout(() => {
          const chatContainer = document.getElementById('ChatBody')
          chatContainer.scrollTop = chatContainer.scrollHeight
        }, 500)
        this.userChatMessages = res.data
        this.listenForChatExpertNewMessage(this.$route.params.userCourseId)
        this.initializeChannel() // this initializes laravel echo
        this.initializeCallListeners() // subscribes to video presence channel and listens to video events
      })
    })
  },
  mounted () {
    const _this = this
    EventBus.$on('acceptCall', function (payLoad) {
      _this.acceptCall()
    })
  },
  methods: {
    ...mapActions({
      getUser: 'user/getUser',
      acceptUserCall: 'chatExpert/acceptCall',
      callUser: 'chatExpert/callUser',
      getMessages: 'chatExpert/getMessages',
      sendMessage: 'chatExpert/sendMessage'
    }),
    getMediaPermission () {
      return getPermissions()
        .then((stream) => {
          this.videoCallParams.stream = stream
          if (this.$refs.userVideo) {
            this.$refs.userVideo.srcObject = stream
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    initializeChannel () {
      this.videoCallParams.channel = window.Echo.join('expert-video-chat-channel')
    },
    changePaginationPerPage (value) {
      this.getUsersList({
        page: this.paginationCurrentPage,
        count: value
      })
    },
    hideUserChatModal () {
      this.$refs.userChatModal.hide()
    },
    listenForChatExpertNewMessage (userCourseID) {
      window.Echo.join(`chat-expert.${userCourseID}`)
        .here((users) => {
          console.log(users)
        })
        .joining((user) => {
          console.log(user)
        })
        .leaving((user) => {
          console.log(user)
        })
        .listen('ChatExpertMessageSent', (e) => {
          this.userChatMessages.push(e.data)
        })
    },
    sendUserChatForm () {
      if (this.userChatFormData.message !== '') {
        this.disableButtonChatForm = true
        this.userChatFormData.course_id = this.$route.params.id
        this.userChatFormData.user_id = this.user.id
        this.sendMessage(this.userChatFormData).then(res => {
          this.userChatFormData = {}
          this.userChatMessages.push(res.data)
        }).catch(() => {
          this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
        }).finally(() => {
          this.disableButtonChatForm = false
        })
      }
    },
    changePagination (page) {
      this.getUsersList({
        page: page,
        count: this.paginationPerPage
      })
    },
    async placeVideoCall () {
      this.callPlaced = true
      await this.getMediaPermission()
      this.videoCallParams.peer1 = new Peer({
        initiator: true,
        trickle: false,
        stream: this.videoCallParams.stream,
        config: {
          iceServers: [
            {
              url: 'turn:numb.viagenie.ca',
              credential: 'muazkh',
              username: 'webrtc@live.com'
            }
          ]
        }
      })

      this.videoCallParams.peer1.on('signal', (data) => {
        this.callUser({
          user_to_call: this.user.id,
          signal_data: data
        }).then((res) => {
          console.log(res)
        }).catch((error) => {
          console.log(error)
        })
      })

      this.videoCallParams.peer1.on('stream', (stream) => {
        console.log('call streaming')
        if (this.$refs.partnerVideo) {
          this.$refs.partnerVideo.srcObject = stream
        }
      })

      this.videoCallParams.peer1.on('connect', () => {
        console.log('peer connected')
      })

      this.videoCallParams.peer1.on('error', (err) => {
        console.log(err)
      })

      this.videoCallParams.peer1.on('close', () => {
        console.log('call closed caller')
      })

      this.videoCallParams.channel.listen('StartExpertVideoChat', ({ data }) => {
        console.log('StartExpertVideoChat', data)
        if (data.type === 'callAccepted') {
          if (data.signal.renegotiate) {
            console.log('renegotating')
          }
          if (data.signal.sdp) {
            this.videoCallParams.callAccepted = true
            const updatedSignal = {
              ...data.signal,
              sdp: `${data.signal.sdp}\n`
            }
            this.videoCallParams.peer1.signal(updatedSignal)
          }
        }
      })
    },

    async acceptCall () {
      this.callPlaced = true
      this.videoCallParams.callAccepted = true
      await this.getMediaPermission()
      this.videoCallParams.peer2 = new Peer({
        initiator: false,
        trickle: false,
        stream: this.videoCallParams.stream,
        config: {
          iceServers: [
            {
              url: 'turn:numb.viagenie.ca',
              credential: 'muazkh',
              username: 'webrtc@live.com'
            }
          ]
        }
      })
      this.videoCallParams.receivingCall = false
      this.videoCallParams.peer2.on('signal', (data) => {
        this.acceptUserCall({
          signal: data,
          to: this.videoCallParams.caller
        })
          .then(() => {
          })
          .catch((error) => {
            console.log(error)
          })
      })

      this.videoCallParams.peer2.on('stream', (stream) => {
        this.videoCallParams.callAccepted = true
        this.$refs.partnerVideo.srcObject = stream
      })

      this.videoCallParams.peer2.on('connect', () => {
        console.log('peer connected')
        this.videoCallParams.callAccepted = true
      })

      this.videoCallParams.peer2.on('error', (err) => {
        console.log(err)
      })

      this.videoCallParams.peer2.on('close', () => {
        console.log('call closed accepter')
      })

      this.videoCallParams.peer2.signal(this.videoCallParams.callerSignal)
    },
    toggleCameraArea () {
      if (this.videoCallParams.callAccepted) {
        this.isFocusMyself = !this.isFocusMyself
      }
    },
    getUserOnlineStatus (id) {
      const onlineUserIndex = this.videoCallParams.users.findIndex(
        (data) => data.id === id
      )
      if (onlineUserIndex < 0) {
        return 'Offline'
      }
      return 'Online'
    },
    declineCall () {
      this.videoCallParams.receivingCall = false
    },

    toggleMuteAudio () {
      if (this.mutedAudio) {
        this.$refs.userVideo.srcObject.getAudioTracks()[0].enabled = true
        this.mutedAudio = false
      } else {
        this.$refs.userVideo.srcObject.getAudioTracks()[0].enabled = false
        this.mutedAudio = true
      }
    },

    toggleMuteVideo () {
      if (this.mutedVideo) {
        this.$refs.userVideo.srcObject.getVideoTracks()[0].enabled = true
        this.mutedVideo = false
      } else {
        this.$refs.userVideo.srcObject.getVideoTracks()[0].enabled = false
        this.mutedVideo = true
      }
    },

    stopStreamedVideo (videoElem) {
      const stream = videoElem.srcObject
      const tracks = stream.getTracks()
      tracks.forEach((track) => {
        track.stop()
      })
      videoElem.srcObject = null
    },
    endCall () {
      // if video or audio is muted, enable it so that the stopStreamedVideo method will work
      if (!this.mutedVideo) this.toggleMuteVideo()
      if (!this.mutedAudio) this.toggleMuteAudio()
      this.stopStreamedVideo(this.$refs.userVideo)
      if (this.authuserid === this.videoCallParams.caller) {
        this.videoCallParams.peer1.destroy()
      } else {
        this.videoCallParams.peer2.destroy()
      }
      this.videoCallParams.channel.pusher.channels.channels[
        'presence-expert-video-chat-channel'
      ].disconnect()

      setTimeout(() => {
        this.callPlaced = false
      }, 3000)
    },
    initializeCallListeners () {
      this.videoCallParams.channel.here((users) => {
        this.videoCallParams.users = users
      })

      this.videoCallParams.channel.joining((user) => {
        // check user availability
        const joiningUserIndex = this.videoCallParams.users.findIndex(
          (data) => data.id === user.id
        )
        if (joiningUserIndex < 0) {
          this.videoCallParams.users.push(user)
        }
      })

      this.videoCallParams.channel.leaving((user) => {
        const leavingUserIndex = this.videoCallParams.users.findIndex(
          (data) => data.id === user.id
        )
        this.videoCallParams.users.splice(leavingUserIndex, 1)
      })
      this.videoCallParams.channel.listen('StartExpertVideoChat', ({ data }) => {
        console.log('StartExpertVideoChat')
        if (data.type === 'incomingCall') {
          // add a new line to the sdp to take care of error
          const updatedSignal = {
            ...data.signalData,
            sdp: `${data.signalData.sdp}\n`
          }
          this.videoCallParams.receivingCall = true
          this.videoCallParams.caller = data.from
          this.videoCallParams.callerSignal = updatedSignal
        }
      })
    }
  }
}
</script>

<style>
  p {
    margin: 0;
  }
  .video-container {
  }
  .video-container .user-video {
    width: 30%;
    position: absolute;
    right: 16px;
    bottom: 40px;
    border: 1px solid #fff;
    border-radius: 6px;
    z-index: 2;
  }

  .video-container .partner-video {
    width: 100%;
    height: 100%;
    background-color: #333333;
    border: 2px solid;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 1;
    margin: 0;
    padding: 0;
  }
  .video-container .action-btns {
    position: absolute;
    left: 18px;
    bottom: 15px;
    border-radius: 6px;
    z-index: 2;
  }
</style>
