import Swal from 'sweetalert2'
import { mapActions } from 'vuex'

export default {
  methods: {
    ...mapActions({
      taskDelete: 'task/delete',
      taskRestore: 'task/restore',
    }),
    deleteTask (id) {
      Swal.fire({
        text: this.$t('messages.warning.delete'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#748C41',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('buttons.yes'),
        cancelButtonText: this.$t('buttons.no')
      }).then((result) => {
        if (result.value) {
          this.taskDelete(id).then(res => {
            this.$toasted.success(this.$t('messages.success.languageDeleted')).goAway(1500)
          }).catch(() => {
            this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
          })
        }
      })
    },
    restoreTask (id) {
      Swal.fire({
        text: this.$t('message.warning.restore'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#748C41',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('buttons.yes'),
        cancelButtonText: this.$t('buttons.no')
      }).then((result) => {
        if (result.value) {
          this.taskRestore(id).then(res => {
            this.$toasted.success(this.$t('messages.success.languageRestored')).goAway(1500)
          }).catch(() => {
            this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
          })
        }
      })
    }
  }
}
