import { http } from '../../../api'

const teachingLangCategory = {
  namespaced: true,
  state: {
    langCategoryList: [],
    teachingLanguage: null
  },
  actions: {
    createLanguageCategory: ({ commit }, data) => {
      return http.post('/teaching-language', data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(res => {
        return res
      })
    },
    updateTeachingLanguage: ({ commit }, params) => {
      return http.post('/teaching-language/' + params.id + '/update', params.data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(res => {
        return res
      })
    },
    deleteTeachingLanguage: ({ commit }, id) => {
      return http.delete('/teaching-language/' + id).then(res => {
        if (res.data.deleteType === 'forceDelete') {
          commit('removeTeachingLanguage', id)
        } else {
          commit('updateTeachingLanguageStatus', { id: id, status: 'inactive' })
        }
      })
    },
    getTeachingLanguage: ({ commit }, params) => {
      return http.get('/teaching-language/' + params.id).then(res => {
        commit('setTeachingLanguage', res.data.data)
      })
    },
    restoreTeachingLanguage: ({ commit }, id) => {
      return http.get('/teaching-language/' + id + '/restore').then(res => {
        commit('updateTeachingLanguageStatus', { id: id, status: 'active' })
      })
    },
    getLanguageCategoryList: ({ commit }, data = {}) => {
      return http.get('/teaching-language').then(res => {
        commit('setLanguageCategoryList', res.data.data)
      })
    }
  },
  mutations: {
    setLanguageCategoryList (state, data) {
      state.langCategoryList = data
    },
    setTeachingLanguage (state, data) {
      state.teachingLanguage = data
    },
    removeTeachingLanguage (state, id) {
      state.langCategoryList = state.langCategoryList.filter(item => item.id !== id)
    },
    updateTeachingLanguageStatus (state, data) {
      state.langCategoryList = state.langCategoryList.map(item => {
        if (item.id === data.id) {
          item.status = data.status
        }
        return item
      })
    }
  },
  getters: {
    langCategoryList: state => state.langCategoryList,
    teachingLanguage: state => state.teachingLanguage
  }
}

export default teachingLangCategory
