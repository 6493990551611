<template>
  <div>
    <div class="page-header">
      <div>
        <h2 class="main-content-title tx-24 mg-b-5">Тарифы</h2>
      </div>
      <div class="d-flex">
        <div class="justify-content-center">
          <router-link to="/course-tariff/add" type="button" class="btn btn-primary my-2 btn-icon-text">
            <i class="fe fe-plus-square mr-2"></i> Добавить тариф
          </router-link>
        </div>
      </div>
    </div>
    <div class="row row-sm">
      <div class="col-lg-12">
        <div class="card custom-card">
          <div class="card-body">
            <div>
              <h6 class="main-content-label mb-1">Список тарифов</h6>
            </div>
            <div class="table-responsive">
              <b-table striped hover :items="courseTariffList" :fields="tableFields">
                <template #cell(actions)="row">
                  <router-link :to="`/course-tariff/${row.item.id}`" class="btn btn-outline-primary mr-2 mt-1 mb-1">
                    <i class="fe fe-edit"></i>
                  </router-link>
                  <button v-if="row.item.status === 'active'" @click="removeCourseTariff(row.item.id)" type="button" class="btn btn-outline-danger mr-2 mt-1 mb-1">
                    <i class="ti-close"></i>
                  </button>
                  <div v-else class="d-inline-block">
                    <button @click="restCourseTariff(row.item.id)" type="button" class="btn btn-outline-success mr-2 mt-1 mb-1">
                      <i class="fe fe-rotate-ccw"></i>
                    </button>
                    <button @click="removeCourseTariff(row.item.id)" type="button" class="btn btn-outline-danger mr-2 mt-1 mb-1">
                      <i class="fe fe-trash-2"></i>
                    </button>
                  </div>
                </template>
              </b-table>
              <div class="d-flex justify-content-end  mt-3">
                <div class="mr-3">
                  <b-form-select
                    @change="changePaginationPerPage"
                    style="width: 80px"
                    v-model="paginationPerPage"
                    :options="paginationOptions"
                  >
                  </b-form-select>
                </div>
                <div>
                  <b-pagination
                    class="m-0"
                    @change="changePagination"
                    v-model="paginationCurrentPage"
                    :total-rows="pagination.count"
                    :per-page="paginationPerPage"
                    first-text="<<"
                    prev-text="<"
                    next-text=">"
                    last-text=">>"
                  ></b-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Swal from 'sweetalert2'

export default {
  name: 'CourseTariff',
  data () {
    return {
      paginationOptions: [
        { value: 6, text: '6' },
        { value: 10, text: '10' },
        { value: 30, text: '30' },
        { value: 100, text: '100' }
      ],
      paginationCurrentPage: 1,
      paginationPerPage: 6,
      pagination: {}
    }
  },
  computed: {
    ...mapState({
      courseTariffList: state => state.courseTariff.courseTariffList
    }),
    tableFields () {
      return [
        {
          key: 'id',
          label: 'Id',
          sortable: true
        },
        {
          key: 'name.' + this.$i18n.locale,
          label: 'Название',
          sortable: true
        },
        {
          key: 'level',
          label: 'Уровень',
          sortable: true
        },
        {
          key: 'discount_for_family',
          label: 'Скидка на любую следующую покупку в школе для себя или члена семьи',
          sortable: true,
        },
        {
          key: 'access_materials_after_purchasing_course',
          label: 'Сохранение доступа к материалам при покупке следующего курса по одному языку',
          sortable: true,
        },
        {
          key: 'consultation',
          label: 'Индивидуальная консультация с экспертом',
          sortable: true,
        },
        {
          key: 'referral_discount',
          label: '% скидки, который получит пользователь от реферала',
          sortable: true,
        },
        {
          key: 'freezing_possibility',
          label: 'Возможность заморозки',
          sortable: true,
          formatter: (value, key, item) => {
            return item.freezing_possibility ? '+' : '-'
          }
        },
        {
          key: 'access_upgrade_tariff',
          label: 'Возможность апгрейда пакета',
          sortable: true,
          formatter: (value, key, item) => {
            return item.freezing_possibility ? '+' : '-'
          }
        },
        {
          key: 'access_upgrade_tariff',
          label: 'Возможность апгрейда пакета',
          sortable: true,
          formatter: (value, key, item) => {
            return item.freezing_possibility ? '+' : '-'
          }
        },
        {
          key: 'extension_access_course',
          label: 'Возможность продления доступа к курсу',
          sortable: true,
          formatter: (value, key, item) => {
            return item.freezing_possibility ? '+' : '-'
          }
        },
        {
          key: 'automatic_check_tasks',
          label: 'Автоматическая проверка задачи',
          sortable: true,
          formatter: (value, key, item) => {
            return item.automatic_check_tasks ? '+' : '-'
          }
        },
        {
          key: 'access_independent_work',
          label: 'Доступ к самостоятельной работе',
          sortable: true,
          formatter: (value, key, item) => {
            return item.access_independent_work ? '+' : '-'
          }
        },
        {
          key: 'access_additional_materials',
          label: 'Доступ ко всем дополнительным материалам',
          sortable: true,
          formatter: (value, key, item) => {
            return item.access_additional_materials ? '+' : '-'
          }
        },
        {
          key: 'access_dictionary',
          label: 'Доступ к словарю',
          sortable: true,
          formatter: (value, key, item) => {
            return item.access_dictionary ? '+' : '-'
          }
        },
        {
          key: 'access_grammar',
          label: 'Доступ к разделу Грамматика',
          sortable: true,
          formatter: (value, key, item) => {
            return item.access_grammar ? '+' : '-'
          }
        },
        {
          key: 'access_notes',
          label: 'Доступ к заметкам',
          sortable: true,
          formatter: (value, key, item) => {
            return item.access_notes ? '+' : '-'
          }
        },
        {
          key: 'access_chat',
          label: 'Доступ к внутреннему чату курса',
          sortable: true,
          formatter: (value, key, item) => {
            return item.access_chat ? '+' : '-'
          }
        },
        {
          key: 'access_fb_chat',
          label: 'Доступ к группам на ФБ и в мессенджерах',
          sortable: true,
          formatter: (value, key, item) => {
            return item.access_fb_chat ? '+' : '-'
          }
        },
        {
          key: 'feedback_experts',
          label: 'Обратная связь от экспертов школы',
          sortable: true,
          formatter: (value, key, item) => {
            return item.feedback_experts ? '+' : '-'
          }
        },
        {
          key: 'additional_course_gift',
          label: 'Любой дополнительный курс в подарок (на выбор) в течении 6 месяцев',
          sortable: true,
          formatter: (value, key, item) => {
            return item.additional_course_gift ? '+' : '-'
          }
        },
        {
          label: 'Действие',
          key: 'actions',
          sortable: false
        }
      ]
    }
  },
  created () {
    this.getCourseTariff({})
  },
  methods: {
    ...mapActions({
      getAllCourseTariff: 'courseTariff/getAllCourseTariff',
      deleteCourseTariff: 'courseTariff/deleteCourseTariff',
      restoreCourseTariff: 'courseTariff/restoreCourseTariff'
    }),
    getCourseTariff (params) {
      this.getAllCourseTariff(params).then(res => {
        this.pagination = res.pagination
      }).catch(() => {
        this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
      })
    },
    changePaginationPerPage (value) {
      this.getCourseTariff({ page: this.paginationCurrentPage, count: value })
    },
    changePagination (page) {
      this.getCourseTariff({ page: page, count: this.paginationPerPage })
    },
    removeCourseTariff (id) {
      Swal.fire({
        text: this.$t('messages.warning.delete'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#748C41',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('buttons.yes'),
        cancelButtonText: this.$t('buttons.no')
      }).then((result) => {
        if (result.value) {
          this.deleteCourseTariff({ id }).then(res => {
          }).catch(() => {
            this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
          })
        }
      })
    },
    restCourseTariff (id) {
      this.restoreCourseTariff({ id }).then(res => {
      })
    }
  }
}
</script>

<style scoped>
  .custom-card{
    overflow-x: auto !important;
  }
</style>
