<template>
  <div>
    <div class="page-header">
      <div>
        <h2 class="main-content-title tx-24 mg-b-5">{{isCreate ? 'Добавить' : 'Редактировать'}} валюту</h2>
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="#" @click="$router.go(-1)">Назад</a></li>
          <li class="breadcrumb-item active" aria-current="page">{{isCreate ? 'Добавить' : 'Редактировать'}} валюту</li>
        </ol>
      </div>
      <div class="d-flex">
      </div>
    </div>
    <div class="row row-sm justify-content-center">
      <div class="col-xl-6 col-lg-8 col-md-12">
        <div class="card custom-card">
          <div class="card-body">
            <div>
              <h6 class="main-content-label mb-1">Валюта</h6>
            </div>
            <div v-if="showVideo === true">
              <ValidationObserver ref="currencyAddForm" id="currencyAddForm" tag="form" class="d-flex flex-column" @submit.prevent="formSubmit()">
                <b-tabs content-class="mt-3">
                  <b-tab :title="language.code" v-for="(language, i) in languages" :key="i">
                    <ValidationProvider v-slot="{ classes }" rules="required" tag="div" class="form-group">
                      <label :for="'name[' + language.code + ']'" class="form-label">Название валюты:</label>
                      <input :id="'name[' + language.code + ']'" class="form-control" :name="'name[' + language.code + ']'" v-model="formData.name[language.code]" :class="checkValidation(classes, language.code)" placeholder="Название валюты" type="text">
                    </ValidationProvider>
                  </b-tab>
                  <ValidationProvider rules="required" tag="div" v-slot="{ classes }" class="form-group">
                    <label for="code" class="form-label">Код валюты:</label>
                    <input id="code" class="form-control" name="code" v-model="formData.code" placeholder="Код" :class="classes" type="text">
                  </ValidationProvider>
                  <ValidationProvider rules="required" tag="div" v-slot="{ classes }" class="form-group">
                    <label for="symbol" class="form-label">Символ валюты:</label>
                    <input id="symbol" class="form-control" name="symbol" v-model="formData.symbol" placeholder="Символ" :class="classes" type="text">
                  </ValidationProvider>
                </b-tabs>
                <button class="btn ripple btn-main-primary mt-2" :disabled="buttonDisable" :class="buttonDisable ? 'button-readonly' : ''">Сохранить</button>
              </ValidationObserver>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'CurrencyAdd',
  data () {
    return {
      showVideo: false,
      isEdit: false,
      buttonDisable: false,
      formData: {
        name: {}
      },
      isCreate: false,
      currencyId: ''
    }
  },
  computed: {
    ...mapState({
      languages: state => state.language.languages,
      currency: state => state.currency.currency
    })
  },
  created () {
    this.currencyId = this.$route.params.id
    if (this.currencyId === 'add') {
      this.isEdit = false
      this.isCreate = true
      this.showVideo = true
    } else {
      this.isEdit = true
      this.getCurrency({ id: this.currencyId }).then(res => {
        this.showVideo = true
        this.formData = this.currency
      })
    }
  },
  methods: {
    ...mapActions({
      createCurrency: 'currency/createCurrency',
      getCurrency: 'currency/getCurrency',
      updateCurrency: 'currency/updateCurrency'

    }),
    checkValidation (data) {
      return data
    },
    formSubmit () {
      this.$refs.currencyAddForm.validate().then(success => {
        if (success) {
          this.buttonDisable = true

          if (this.isCreate === true) {
            this.createCurrency(this.formData).then(() => {
              this.$toasted.success(this.$t('messages.success.courseCreated')).goAway(1500)
              this.$router.push('/currency')
            }).catch(() => {
              this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
            }).finally(() => {
              this.buttonDisable = false
            })
          } else {
            this.updateCurrency({ data: this.formData, id: this.$route.params.id }).then(res => {
              this.$toasted.success(this.$t('messages.success.courseUpdated')).goAway(1500)
              this.$router.push('/currency')
            }).catch(() => {
              this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
            }).finally(() => {
              this.buttonDisable = false
            })
          }
        } else {
          this.$toasted.error(this.$t('messages.error.validationError')).goAway(1500)
        }
      })
    }
  }
}
</script>
