<template>
  <div class="main-header side-header sticky sticky-pin" style="margin-bottom: -64px;">
    <div class="container-fluid">
      <div class="main-header-left">
        <a class="main-header-menu-icon" @click.prevent="toggleMenu()" href="#" id="mainSidebarToggle"><span></span></a>
      </div>
      <div class="main-header-center">
        <div class="responsive-logo">
          <a href=""><img src="" class="mobile-logo" alt="logo"></a>
          <a href=""><img src="" class="mobile-logo-dark" alt="logo"></a>
        </div>
        <div class="input-group">
          <div class="input-group-btn search-panel">
            <select class="form-control select2-no-search">
              <option label="All categories">
              </option>
              <option value="IT Projects">
                IT Projects
              </option>
              <option value="Business Case">
                Business Case
              </option>
              <option value="Microsoft Project">
                Microsoft Project
              </option>
              <option value="Risk Management">
                Risk Management
              </option>
              <option value="Team Building">
                Team Building
              </option>
            </select>
          </div>
          <input type="search" class="form-control" placeholder="Search for anything...">
          <button class="btn search-btn"><i class="fe fe-search"></i></button>
        </div>
      </div>
      <div class="main-header-right">
        <div class="dropdown header-search">
          <a class="nav-link icon header-search">
            <i class="fe fe-search header-icons"></i>
          </a>
          <div class="dropdown-menu">
            <div class="main-form-search p-2">
              <div class="input-group">
                <div class="input-group-btn search-panel">
                  <select class="form-control select2-no-search">
                    <option label="All categories">
                    </option>
                    <option value="IT Projects">
                      IT Projects
                    </option>
                    <option value="Business Case">
                      Business Case
                    </option>
                    <option value="Microsoft Project">
                      Microsoft Project
                    </option>
                    <option value="Risk Management">
                      Risk Management
                    </option>
                    <option value="Team Building">
                      Team Building
                    </option>
                  </select>
                </div>
                <input type="search" class="form-control" placeholder="Search for anything...">
                <button class="btn search-btn"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg></button>
              </div>
            </div>
          </div>
        </div>
        <b-dropdown size="lg" no-caret variant="link" right class="flag-dropdown">
          <template class="ou" #button-content>
            <a class="nav-link icon country-Flag">
              <img v-if="$i18n.locale === 'ru'" src="@/assets/img/flags/ru.svg" alt="img">
              <img v-if="$i18n.locale === 'en'" src="@/assets/img/flags/us.svg" alt="img">
            </a>
          </template>
          <b-dropdown-item href="#" link-class="d-flex" @click.prevent="changeLanguage('ru')">
            <span class="avatar  mr-3 align-self-center bg-transparent"><img src="@/assets/img/flags/ru.svg" alt="img"></span>
            <div class="d-flex">
              <span class="mt-2">Russia</span>
            </div>
          </b-dropdown-item>
          <b-dropdown-item href="#" link-class="d-flex" @click.prevent="changeLanguage('en')">
            <span class="avatar  mr-3 align-self-center bg-transparent"><img src="@/assets/img/flags/us.svg" alt="img"></span>
            <div class="d-flex">
              <span class="mt-2">English</span>
            </div>
          </b-dropdown-item>
        </b-dropdown>
        <div class="dropdown d-md-flex">
          <a class="nav-link icon full-screen-link" href="">
            <i class="fe fe-maximize fullscreen-button fullscreen header-icons"></i>
            <i class="fe fe-minimize fullscreen-button exit-fullscreen header-icons"></i>
          </a>
        </div>
        <div class="dropdown main-header-notification">
          <a class="nav-link icon" href="">
            <i class="fe fe-bell header-icons"></i>
            <span class="badge badge-danger nav-link-badge">4</span>
          </a>
          <div class="dropdown-menu">
            <div class="header-navheading">
              <p class="main-notification-text">You have 1 unread notification<span class="badge badge-pill badge-primary ml-3">View all</span></p>
            </div>
            <div class="main-notification-list">
              <div class="media new">
                <div class="main-img-user online"><img alt="avatar" src="@/assets/img/users/default.png"></div>
                <div class="media-body">
                  <p>Congratulate <strong>Olivia James</strong> for New template start</p><span>Oct 15 12:32pm</span>
                </div>
              </div>
              <div class="media">
                <div class="main-img-user"><img alt="avatar" src="@/assets/img/users/default.png"></div>
                <div class="media-body">
                  <p><strong>Joshua Gray</strong> New Message Received</p><span>Oct 13 02:56am</span>
                </div>
              </div>
              <div class="media">
                <div class="main-img-user online"><img alt="avatar" src="@/assets/img/users/default.png"></div>
                <div class="media-body">
                  <p><strong>Elizabeth Lewis</strong> added new schedule realease</p><span>Oct 12 10:40pm</span>
                </div>
              </div>
            </div>
            <div class="dropdown-footer">
              <a href="#">View All Notifications</a>
            </div>
          </div>
        </div>
        <div class="main-header-notification">
          <a class="nav-link icon" href="#">
            <i class="fe fe-message-square header-icons"></i>
            <span class="badge badge-success nav-link-badge">6</span>
          </a>
        </div>
        <b-dropdown size="lg" no-caret variant="link" right class="main-profile-menu">
          <template class="ou" #button-content>
            <a class="d-flex" href="">
              <span class="main-img-user" ><img alt="avatar" src="@/assets/img/users/default.png"></span>
            </a>
          </template>
          <b-dropdown-item href="#" link-class="h-auto">
            <div class="header-navheading w-100">
              <h6 class="main-notification-title">{{user.name}}</h6>
              <p class="main-notification-text">{{user.role.name}}</p>
            </div>
          </b-dropdown-item>
          <b-dropdown-item href="#"><i class="fe fe-user"></i> My Profile</b-dropdown-item>
          <b-dropdown-item href="#"> <i class="fe fe-edit"></i> Edit Profile</b-dropdown-item>
          <b-dropdown-item href="#"><i class="fe fe-settings"></i> Account Settings</b-dropdown-item>
          <b-dropdown-item href="#" @click="signOut"><i class="fe fe-power"></i> Sign Out</b-dropdown-item>
        </b-dropdown>
        <button class="navbar-toggler navresponsive-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent-4"
                aria-controls="navbarSupportedContent-4" aria-expanded="false" aria-label="Toggle navigation">
          <i class="fe fe-more-vertical header-icons navbar-toggler-icon"></i>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import Swal from 'sweetalert2'
import EventBus from '../../event-bus'

export default {
  name: 'Header',
  data () {
    return {
      isOpenMenu: false
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      isAuthenticated: state => state.auth.isAuthenticated
    })
  },
  methods: {
    ...mapActions({
      logout: 'auth/logout'
    }),
    changeLanguage (lang) {
      this.$i18n.locale = lang
      localStorage.setItem('lang', lang)
    },
    initNotification(){
      this.$echo.private('App.Models.Admin.' + this.user.id).notification((notification) => {
      })
    },
    signOut () {
      Swal.fire({
        text: this.$t('message.warning.signOut'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#748C41',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('buttons.yes'),
        cancelButtonText: this.$t('buttons.no')
      }).then((result) => {
        if (result.value) {
          this.logout().then(() => {
            this.$router.push('/auth')
          })
        }
      })
    },
    toggleMenu () {
      this.isOpenMenu = !this.isOpenMenu
      EventBus.$emit('toggleMenu', this.isOpenMenu)
    }
  }
}
</script>
<style>

</style>
