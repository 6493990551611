import { http } from '../../../api'

const courseModule = {
  namespaced: true,
  state: {
    courseModulesList: [],
    courseModule: []
  },
  actions: {
    getCourseModulesByCourse: ({ commit }, params) => {
      return http.get('/course-module/course/' + params).then(res => {
        commit('setCourseModulesList', res.data.data)
        return res.data
      })
    },
    getCourseModule: ({ commit }, id) => {
      return http.get('/course-module/' + id).then(res => {
        commit('setCourseModule', res.data.data)
        return res.data
      })
    },
    createCourseModule: ({ commit }, params) => {
      return http.post('/course-module', params).then(res => {
        return res.data
      })
    },
    updateCourseModule: ({ commit }, params) => {
      return http.put('/course-module/' + params.id, params.data).then(res => {
        return res.data
      })
    },
    delete: ({ commit }, id) => {
      return http.delete('/course-module/' + id).then(res => {
        if (res.data.deleteType === 'forceDelete') {
          commit('removeCourseModule', id)
        } else {
          commit('updateCourseModuleStatus', { id: id, status: 'inactive' })
        }
      })
    },
    restore: ({ commit }, id) => {
      return http.get('/course-module/' + id + '/restore').then(res => {
        commit('updateCourseModuleStatus', { id: id, status: 'active' })
      })
    }
  },
  mutations: {
    setCourseModulesList (state, data) {
      state.courseModulesList = data
    },
    setCourseModule (state, data) {
      state.courseModule = data
    },
    updateCourseModuleStatus (state, data) {
      state.courseModulesList = state.courseModulesList.map(module => {
        if (module.id === data.id) {
          module.status = data.status
        }
        return module
      })
    },
    updateCourseModule (state, data) {
      state.courseModulesList = state.courseModulesList.map(module => {
        if (module.id === data.id) {
          module = data
        }
        return module
      })
    },
    removeCourseModule (state, id) {
      state.courseModulesList = state.courseModulesList.filter(module => module.id !== id)
    }
  },
  getters: {
    courseModulesList: state => state.courseModulesList,
    courseModule: state => state.courseModule
  }
}

export default courseModule
