<template>
  <div>
    <div class="page-header">
      <div>
        <h2 class="main-content-title tx-24 mg-b-5">{{ $route.query.role === 'customer' ? 'Клиенты' : 'Эксперты' }}</h2>
      </div>
      <div class="d-flex">
        <div class="input-group rounded">
          <input type="search" class="form-control rounded" v-model="search_user" v-on:keyup.enter="search"
                 placeholder="Search"/>
          <button type="button" class="border-0 btn btn-primary" @click="search">
            <i class="fas fa-search"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end" v-if="$route.query.role === 'expert'">
      <div class="justify-content-center">
        <router-link to="expert/add" type="button" class="btn btn-primary my-2 btn-icon-text">
          <i class="fe fe-plus-square mr-2"></i> Добавить эксперта
        </router-link>
      </div>
    </div>
    <div class="row row-sm">
      <div class="col-lg-12">
        <div class="card custom-card">
          <div class="card-body">
            <div>
              <h6 class="main-content-label mb-1">
                {{ $route.query.role === 'customer' ? 'Спиоск клиентов' : 'Список экспертов' }}</h6>
            </div>
            <div class="table-responsive">
              <b-table striped hover :items="users" :fields="tableFields">
                <template #cell(avatar_path)="row">
                  <img class="img-thumbnail table-image" v-if="row.item.avatar_path" :src="row.item.avatar_path"
                       alt="avatar">
                  <img class="img-thumbnail table-image" v-else src="../../../assets/img/default-avatar.jpg"
                       alt="avatar">
                </template>
                <template #cell(first_name)="row">
                  <div>{{ row.item.first_name }} {{ row.item.last_name }}</div>
                </template>
                <template #cell(email_verified_at)="row">
                  <div>{{ row.item.email_verified_at ? 'Yes' : 'No' }}</div>
                </template>
                <template #cell(actions)="row">
                  <router-link :to="`${$route.query.role === 'customer'?'user':'expert'}/${row.item.id}`"
                               class="btn btn-outline-primary mr-2 mt-1 mb-1">
                    <i class="fe fe-edit"></i>
                  </router-link>
                  <button v-if="row.item.locked" @click="blockUser(row.item.id)" type="button"
                          class="btn btn-outline-success mr-2 mt-1 mb-1">
                    <i class="fe fe-unlock"></i>
                  </button>
                  <button v-if="$route.query.role === 'customer'" @click="reset(row.item.id)" type="button"
                          class="btn btn-outline-warning mr-2 mt-1 mb-1">
                    <i class="ti-reload"></i>
                  </button>
                  <button v-else @click="blockUser(row.item.id)" type="button"
                          class="btn btn-outline-danger mr-2 mt-1 mb-1">
                    <i class="fe fe-lock"></i>
                  </button>
                  <button v-if="row.item.status === 'active'" @click="deleteUser(row.item.id)" type="button"
                          class="btn btn-outline-danger mr-2 mt-1 mb-1">
                    <i class="ti-close"></i>
                  </button>
                  <div v-else class="d-inline-block">
                    <button @click="restoreUser(row.item.id)" type="button"
                            class="btn btn-outline-success mr-2 mt-1 mb-1">
                      <i class="fe fe-rotate-ccw"></i>
                    </button>
                    <button @click="deleteUser(row.item.id)" type="button"
                            class="btn btn-outline-danger mr-2 mt-1 mb-1">
                      <i class="fe fe-trash-2"></i>
                    </button>
                  </div>
                </template>
              </b-table>
              <div class="d-flex justify-content-end  mt-3">
                <div class="mr-3">
                  <b-form-select
                    @change="changePaginationPerPage"
                    style="width: 80px"
                    v-model="paginationPerPage"
                    :options="paginationOptions"
                  >
                  </b-form-select>
                </div>
                <div>
                  <b-pagination
                    class="m-0"
                    @change="changePagination"
                    v-model="paginationCurrentPage"
                    :total-rows="pagination.count"
                    :per-page="paginationPerPage"
                    first-text="<<"
                    prev-text="<"
                    next-text=">"
                    last-text=">>"
                  ></b-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Swal from 'sweetalert2'
import ToastMixin from '../../../mixins/ToastMixin'

export default {
  name: 'User',
  mixins: [ToastMixin],
  data () {
    return {
      paginationOptions: [
        {
          value: 6,
          text: '6'
        },
        {
          value: 10,
          text: '10'
        },
        {
          value: 30,
          text: '30'
        },
        {
          value: 100,
          text: '100'
        }
      ],
      paginationCurrentPage: 1,
      paginationPerPage: 6,
      pagination: {},
      search_user: ''
    }
  },
  watch: {
    $route(){
      this.getUsersList({ role: this.$route.query.role })
    }
  },
  computed: {
    ...mapState({
      users: state => state.user.users,
      userPagination: state => state.user.userPagination
    }),
    tableFields () {
      let fields = [
        {
          key: 'id',
          label: 'Id',
          sortable: true
        },
        {
          key: 'avatar_path',
          label: 'Аватар',
          sortable: false
        },
        {
          key: 'first_name',
          label: 'Имя',
          sortable: true
        },
        {
          key: 'email',
          label: 'Почта',
          sortable: true
        },
        {
          key: 'login',
          label: 'Логин',
          sortable: true
        },
      ]
      if (this.$route.query.role === 'customer') {
        fields = [...fields, {
          key: 'phone',
          label: 'Телефон',
          sortable: true
        },
          {
            key: 'birthday',
            label: 'Дата рождения',
            sortable: true
          },
          {
            key: 'gender',
            label: 'Пол',
            sortable: true
          }, {
            key: 'email_verified_at',
            label: 'Подтвержден',
            sortable: true
          },
        ]
      }
      return [...fields,  {
        key: 'actions',
        label: 'Действие',
        sortable: false
      }]
    }
  },
  created () {
    this.getUsersList({ role: this.$route.query.role })
  },
  methods: {
    ...mapActions({
      getUsers: 'user/getUsers',
      delete: 'user/delete',
      restore: 'user/restore',
      lockUser: 'user/lockUser',
      resetProgress: 'user/resetProgress'
    }),
    reset(user_id){
      this.ToastShow('Вы точно хотите сбросить прогресс?', 'warning', () =>{
        this.resetProgress({user_id}).then(res => {
          this.$toasted.success(res.message).goAway(1500)
        }).catch(() => {
          this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
        })
      })
    },
    getUsersList (params) {
      this.getUsers(params).then(res => {
        this.pagination = res.pagination
      }).catch(() => {
        this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
      })
    },
    changePaginationPerPage (value) {
      this.getUsersList({
        page: this.paginationCurrentPage,
        count: value,
        user_name: this.search_user,
        role: this.$route.query.role
      })
    },
    changePagination (page) {
      this.getUsersList({
        page: page,
        count: this.paginationPerPage,
        user_name: this.search_user,
        role: this.$route.query.role
      })
    },
    deleteUser (id) {
      Swal.fire({
        text: this.$t('messages.warning.delete'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#748C41',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('buttons.yes'),
        cancelButtonText: this.$t('buttons.no')
      }).then((result) => {
        if (result.value) {
          this.delete(id).then(res => {
            this.$toasted.success(this.$t('messages.success.paymentMethodDeleted')).goAway(1500)
          }).catch(() => {
            this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
          })
        }
      })
    },
    restoreUser (id) {
      Swal.fire({
        text: this.$t('message.warning.restore'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#748C41',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('buttons.yes'),
        cancelButtonText: this.$t('buttons.no')
      }).then((result) => {
        if (result.value) {
          this.restore(id).then(res => {
            this.$toasted.success(this.$t('messages.success.paymentMethodDeleted')).goAway(1500)
          }).catch(() => {
            this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
          })
        }
      })
    },
    search () {
      this.getUsersList({
        page: this.paginationCurrentPage,
        count: this.paginationPerPage,
        user_name: this.search_user,
        role: this.$route.query.role
      })
    },
    blockUser (id) {
      Swal.fire({
        text: this.$t('messages.warning.block'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#748C41',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('buttons.yes'),
        cancelButtonText: this.$t('buttons.no')
      }).then((result) => {
        if (result.value) {
          this.lockUser(id).then(res => {
            this.$toasted.success(this.$t('messages.success.paymentMethodDeleted')).goAway(1500)
          }).catch(() => {
            this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
          })
        }
      })
    }
  }
}
</script>
