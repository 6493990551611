<template>
  <TaskBase
    help="Расположите правильно слова и перевод друг напротив друга, а система сама перемешает их в случайном
        порядке для экзаменуемого."
    :task-type-id="3"
    :after-validation="afterValidation"
    :data="formData"
    :update="updateTask"
    :create="createTask"
  >
    <FormCard>
      <b-tabs content-class="mt-3">
        <div v-for="(translation, index) in translations" :key="'translations-' + index"
             class="d-flex align-items-center border-bottom">
          <div class="d-flex align-items-center mt-3 w-100">
            <div class="form-group w-50">
              <TranslationsTypes
                :radio-name="`translations[${index}][phraseVideoType]`"
                :type-name="`translations[${index}][phraseType]`"
                v-model="translation.phrase"
                :type.sync="translation.phraseType"
                :video-type.sync="translation.phraseVideoType"
                :is-create="isCreate"
                :name="`translations[${index}][phrase]`"
                :default-name="`translations[${index}][default_phrase]`"
                :default-value.sync="translation.default_phrase"
              />
            </div>
            <div class="form-group w-50">
              <TranslationsTypes
                :radio-name="`translations[${index}][translationVideoType]`"
                :type-name="`translations[${index}][translationType]`"
                v-model="translation.translation"
                :type.sync="translation.translationType"
                :video-type.sync="translation.translationVideoType"
                :is-create="isCreate"
                :name="`translations[${index}][translation]`"
                :default-name="`translations[${index}][default_translation]`"
                :default-value.sync="translation.default_translation"
              />
            </div>
            <input hidden :name="`translations[${index}][id]`" :value="translation.id">
          </div>
          <span class="p-2 text-danger pointer" style="width: 32px;"><i v-if="translations.length > 2"
                                                                        @click="deleteTranslation(translation, index)"
                                                                        class="ti-close"></i></span>
        </div>
        <div class="text-primary pointer" @click="addTranslation()">Добавить перевод</div>
      </b-tabs>
    </FormCard>
  </TaskBase>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import FileUpload from '../../../components/FileUpload'
import FormCard from '../../../components/FormCard'
import VideoForm from '../../../components/VideoForm'
import TranslationsTypes from '../../../components/TranslationsTypes'
import CkeditorMixin from '../../../../mixins/CkeditorMixin'
import TaskMixin from '../../../../mixins/TaskMixin'
import Breadcrumbs from '../../../components/Breadcrumbs'
import AudioMixin from '../../../../mixins/AudioMixin'
import TaskBase from '../../../components/tasks/TaskBase'

export default {
  name: 'TaskPickUpTranslation',
  mixins: [CkeditorMixin, TaskMixin, AudioMixin],
  data () {
    return {
      buttonDisable: false,
      removeTranslations: [],
      formData: {
        name: {},
        description: {},
        prompt: {},
        translations: [],
        translationType: 'text'
      },
      translations: [],
      videoType: 'video_iframe',
      fileErrorMessage: '',
      uploadedVideoFile: null,
      isCreate: false,
      taskId: null,
      gallery: []
    }
  },
  components: {
    TaskBase,
    Breadcrumbs,
    TranslationsTypes,
    VideoForm,
    FormCard,
    FileUpload
  },
  computed: {
    ...mapState({
      languages: state => state.language.languages,
      task: state => state.taskPickUpTranslation.task,
      taskType: state => state.taskType.taskType
    })
  },
  created () {
    this.taskId = this.$route.params.id
    if (this.taskId === 'add') {
      this.isCreate = true
      for (let i = 0; i < 2; i++) {
        this.pushTranslation()
      }
    } else {
      this.isCreate = false
      this.getTask(this.taskId).then(res => {
        this.task.task.translations = []
        this.formData = { ...this.task.task }
        this.formData.prompt = this.task.pick_up_translation.prompt
        this.task.pick_up_translation.translations.forEach(trans => {
          this.$set(this.translations, this.translations.length, {
            phrase: trans.phrase,
            translation: trans.translation,
            phraseType: trans.phrase_type === 'iframe' ? 'video' : trans.phrase_type,
            translationType: trans.translation_type === 'iframe' ? 'video' : trans.translation_type,
            phraseVideoType: trans.phrase_type === 'iframe' ? 'video_iframe' : trans.phrase_type,
            translationVideoType: trans.translation_type === 'iframe' ? 'video_iframe' : trans.translation_type,
            id: trans.id,
            default_phrase: trans.default_phrase,
            default_translation: trans.default_translation,
          })
        })
      })
    }
  },
  methods: {
    ...mapActions({
      createTask: 'taskPickUpTranslation/createTask',
      updateTask: 'taskPickUpTranslation/updateTask',
      deleteTranslations: 'taskPickUpTranslation/deleteTranslations',
      getTask: 'taskPickUpTranslation/getTask',
      getTaskType: 'taskType/getTaskType'
    }),
    pushTranslation () {
      this.translations.push({
        phrase: null,
        translation: null,
        phraseType: 'text',
        translationType: 'text',
        phraseVideoType: 'video_iframe',
        translationVideoType: 'video_iframe',
        id: null,
        default_phrase: null,
        default_translation: null,
      })
    },
    fileChange (val) {
      this.formData.audio = val
    },
    addTranslation () {
      this.pushTranslation()
    },
    deleteTranslation (translation, index) {
      if (translation.id) {
        this.translations = this.translations.filter((item, i) => i !== index)
        this.removeTranslations.push(translation.id)
      } else {
        this.translations = this.translations.filter((item, i) => i !== index)
      }
    },
    customLabel ({
      name,
      desc
    }) {
      return `${name[this.$i18n.locale]}`
    },
    afterValidation(formData){
      const types = ['image', 'audio']
      this.translations.forEach((trans, index) => {
        if (types.includes(trans.translationType)) {
          formData.set(`translations[${index}][translation]`, trans.translation)
        }

        if (types.includes(trans.phraseType)) {
          formData.set(`translations[${index}][phrase]`, trans.phrase)
        }
      })

      if (this.removeTranslations.length) {
        this.deleteTranslations({ translations_id: this.removeTranslations })
      }
    },
  }
}
</script>
