<template>
  <div>
    <div class="page-header">
      <div>
        <h2 class="main-content-title tx-24 mg-b-5">Contacts</h2>
      </div>
    </div>
    <div class="row row-sm">
      <div class="col-lg-12">
        <div class="card custom-card">
          <div class="card-body">
            <div>
              <h6 class="main-content-label mb-1">Contacts List</h6>
            </div>
            <div class="table-responsive">
              <b-table striped hover :items="contacts" :fields="tableFields">
                <template #cell(text)="row">
                  <span class="d-inline-block">
                    {{(row.item.text.length > 60) ? row.item.text.slice(0,60) + '...' : row.item.text}}
                  </span>
                </template>
                <template #cell(actions)="row">
                  <router-link :to="'contacts/' + row.item.id" class="d-inline-block">
                    Show
                  </router-link>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'Contacts',
  data () {
    return {
    }
  },
  computed: {
    ...mapState({
      contacts: state => state.contacts.contacts
    }),
    tableFields () {
      return [
        {
          key: 'id',
          sortable: true
        },
        {
          key: 'name',
          sortable: true
        },
        {
          key: 'email',
          sortable: true
        },
        {
          key: 'phone_number',
          sortable: true
        },
        {
          key: 'text',
          sortable: true
        },
        {
          key: 'actions',
          sortable: false
        }
      ]
    }
  },
  created () {
    this.getContactsList().catch(() => {
      this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
    })
  },
  methods: {
    ...mapActions({
      getContactsList: 'contacts/getContactsList'
    })
  }
}
</script>
