<template>
  <div class="row row-sm justify-content-center">
    <div class="col-md-12">
      <div class="card custom-card">
        <slot name="content">
          <div class="card-body" :class="bodyClass">
            <slot/>
          </div>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    bodyClass: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>

</style>
