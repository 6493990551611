import { render, staticRenderFns } from "./InsertMediaButtons.vue?vue&type=template&id=4179cdc8&scoped=true&"
import script from "./InsertMediaButtons.vue?vue&type=script&lang=js&"
export * from "./InsertMediaButtons.vue?vue&type=script&lang=js&"
import style0 from "./InsertMediaButtons.vue?vue&type=style&index=0&id=4179cdc8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4179cdc8",
  null
  
)

export default component.exports