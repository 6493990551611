<template>
  <div>
    <div class="form-group">
      <label>Выберите тип</label>
      <select class="form-control mb-3" :name="typeName" :value="type" @input="input('update:type', $event)">
        <option
          v-for="(type, index) in types"
          :key="index"
          :value="type.value"
        >{{ type.text }}</option>
      </select>
    </div>
    <ValidationProvider v-slot="{ classes }" rules="required" tag="div" v-if="type === 'text'">
      <input
        class="form-control"
        :value="value"
        @input="input('input', $event)"
        :class="classes"
        placeholder="Phrase"
        type="text"
        :name="name"
      >
    </ValidationProvider>
    <VideoForm
      v-else-if="type === 'video'"
      :radio-name="radioName"
      :is-edit="!isCreate"
      :iframe-name="name"
      :video-name="name"
      :video-type.sync="typeVideo"
      :video-file.sync="video"
      :video-iframe.sync="video"
      :required="true"
    />
    <FileUpload v-else-if="type === 'audio'" :is-multiple="false" uploadType="audio"
                 label="Audio:" :name="name" accept="audio/*" :validation="true"
                 @fileChange="fileChange"
                 :filesData="value"/>
    <FileUpload v-else page="taskTest" :is-multiple="false"
                 :validation="true"
                 uploadType="oneImageType" label="image:"
                 :name="name"
                 accept="image/png,image/jpeg,image/jpg"
                 @fileChange="(file) => $emit('input', file[0])"
                 :filesData="value"/>
    <input
      v-if="type === 'image'"
      class="form-control"
      :value="defaultValue"
      @input="input('update:defaultValue', $event.target.value)"
      placeholder="Текст"
      type="text"
      :name="defaultName"
    >

  </div>
</template>

<script>
import VideoForm from './VideoForm'
import FileUpload from './FileUpload'

export default {
  components: {
    FileUpload,
    VideoForm
  },
  props: {
    type: {
      type: String,
      default: null
    },
    typeName: {
      type: String,
      default: 'type'
    },
    value: {
      default: null
    },
    name: {
      type: String,
      default: null
    },
    isCreate: {
      type: Boolean,
      default: false
    },
    radioName: {
      type: String,
      default: null
    },
    videoName: {
      type: String,
      default: null
    },
    iframeName: {
      type: String,
      default: null
    },
    videoType: {
      type: String,
      default: 'video_iframe'
    },
    defaultName: {
      type: String,
      default: ''
    },
    defaultValue: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      types: [
        {
          value: 'text',
          text: 'Текст'
        },
        {
          value: 'image',
          text: 'Картинка'
        },
        {
          value: 'video',
          text: 'Видео'
        },
        {
          value: 'audio',
          text: 'Аудио'
        }
      ],
      video: this.value,
      typeVideo: this.videoType
    }
  },
  methods: {
    input (eventName, event) {
      this.$emit(eventName, event.target.value)
    },
    fileChange (file) {
      console.log('file', file)
      this.$emit('input', typeof file === 'string' ? file : file[0])
    }
  },
  watch: {
    video (newVal) {
      this.$emit('input', newVal)
    },
    typeVideo (newVal) {
      this.$emit('update:videoType', newVal)
      this.$emit('input', null)
    },
    type () {
      this.$emit('input', null)
    },
    value (newVal) {
      this.video = newVal
    }
  }
}
</script>

<style scoped>

</style>
