<template>
  <ValidationProvider :name="label" v-slot="{ classes, errors }" :rules="rules" tag="div" class="form-group">
    <label :for="inputId">{{ label }}</label>

    <slot>
      <textarea
        v-if="type === 'textarea'"
        ref="input"
        :value="value" @input="$emit('input', $event.target.value)"
        class="form-control"
        :id="inputId"
        :name="name"
        :class="classes"
        :placeholder="placeholder"
      >

      </textarea>
      <input
        v-else
        ref="input"
        :value="value" @input="$emit('input', $event.target.value)"
        class="form-control"
        :id="inputId"
        :name="name"
        :class="classes" :type="type"
        :placeholder="placeholder">
    </slot>

    <div class="invalid-feedback">{{ errors[0] }}</div>
    <small>{{help}}</small>
  </ValidationProvider>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'LgInput',
  props: {
    rules: {
      type: [String,Object],
      required: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    value: {
      type: String,
      default: ''
    },
    help: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      inputId: ''
    }
  },
  created () {
    if (!this.id) {
      this.inputId = uuidv4()
    } else {
      this.inputId = this.id
    }
  },
  methods: {
    onContext (ctx) {
      // The date formatted in the locale, or the `label-no-date-selected` string
      this.formatted = ctx.selectedFormatted
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedYMD
    }
  }
}
</script>

<style scoped>

</style>
