<template>
  <div class="dropdown-settings relative">
    <button type="button" class="button btn-td-hover" :class="openClose ? 'active' : ''"  v-click-outside="hide" @click = "openClose = !openClose">
      <svg class="m-auto" width="16" height="4" viewBox="0 0 16 4" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4 2C4 3.10457 3.10457 4 2 4C0.895431 4 0 3.10457 0 2C0 0.895431 0.895431 0 2 0C3.10457 0 4 0.895431 4 2Z" fill="#444444"/>
        <path d="M10 2C10 3.10457 9.10457 4 8 4C6.89543 4 6 3.10457 6 2C6 0.895431 6.89543 0 8 0C9.10457 0 10 0.895431 10 2Z" fill="#444444"/>
        <path d="M14 4C15.1046 4 16 3.10457 16 2C16 0.895431 15.1046 0 14 0C12.8954 0 12 0.895431 12 2C12 3.10457 12.8954 4 14 4Z" fill="#444444"/>
      </svg>
    </button>
    <div v-if="openClose">
      <div class="dropdown">
        <div class="text-success" v-if="item.status_name !== 'approve'" @click="setModerationStatus(2)">Утвердить</div>
        <div class="text-danger" v-if="item.status_name !== 'reject'" @click="setModerationStatus(3)">Отклонять</div>
        <div class="text-dark" v-if="item.status_name !== 'moderation'" @click="setModerationStatus(1)">На модерации</div>
        <div @click="edit">Редактировать</div>
        <div @click="openAnswerModal">Проверка ответа</div>
        <div class="text-success" v-if="item.deleted" @click="restoreReview">Восстановить</div>
        <div class="text-danger" @click="deleteReviewItem">Удалить</div>
      </div>
    </div>
    <b-modal hide-footer hide-header ref="answerModal">
            <div class="main-mail-compose-box">
              <div class="main-mail-compose-header"><span>Проверка ответа</span>
                <nav class="nav">
                  <div class="nav-link" @click="closeAnswerModal">
                    <i class="fas fa-times"></i>
                  </div>
                </nav>
              </div>
              <ValidationObserver ref="answerReview" id="answerReview" tag="div" class="main-mail-compose-body">
                <ValidationProvider v-slot="{ classes }" rules="required" tag="div" class="form-group">
                  <textarea class="form-control" :class="classes" v-model="formData.answer" placeholder="" rows="12"></textarea>
                </ValidationProvider>
                  <div class="form-group mg-b-0 d-flex justify-content-end">
                  <button type="button" @click="sendAnswerReview" class="btn ripple btn-primary">Отправить</button>
                </div>
              </ValidationObserver>
            </div>
    </b-modal>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'
import { mapActions } from 'vuex'
import Swal from 'sweetalert2'

export default {
  name: 'Actions',
  props: ['item'],
  data () {
    return {
      openClose: false,
      showDeleteClaimModal: false,
      formData: {}
    }
  },
  methods: {
    ...mapActions({
      getAllReviews: 'review/getAllReviews',
      deleteReview: 'review/deleteReview',
      restore: 'review/restore',
      setStatus: 'review/setStatus',
      answerReview: 'review/answerReview'
    }),
    hide () {
      this.openClose = false
    },
    edit () {
      this.$router.push('/review/edit/' + this.item.id)
    },
    openAnswerModal () {
      if (this.item.answer) {
        this.formData.answer = this.item.answer
      }
      this.$refs.answerModal.show()
    },
    closeAnswerModal () {
      this.$refs.answerModal.hide()
    },
    restoreReview () {
      this.restore({ id: this.item.id }).then(res => {
        this.getAllReviews()
      })
    },
    deleteReviewItem () {
      Swal.fire({
        text: this.$t('messages.warning.delete'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#748C41',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('buttons.yes'),
        cancelButtonText: this.$t('buttons.no')
      }).then((result) => {
        if (result.value) {
          this.deleteReview({ id: this.item.id }).then(res => {
            this.getAllReviews()
            this.$toasted.success(this.$t('messages.success.reviewsDelete')).goAway(1500)
          }).catch(() => {
            this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
          })
        }
      })
    },
    setModerationStatus (statusId) {
      this.setStatus({ id: this.item.id, statusId: statusId }).then(res => {
        this.getAllReviews()
        this.$toasted.success(this.$t('messages.success.reviewsDelete')).goAway(1500)
      }).catch(() => {
        this.$toasted.error('Server Error!').goAway(1500)
      })
    },
    sendAnswerReview () {
      this.$refs.answerReview.validate().then(success => {
        if (success) {
          this.answerReview({ id: this.item.id, data: this.formData }).then(res => {
            this.closeAnswerModal()
            this.formData = {}
            this.$toasted.success(this.$t('messages.success.answerReview')).goAway(1500)
          }).catch(() => {
            this.$toasted.error('Server Error!').goAway(1500)
          })
        } else {
          this.$toasted.error(this.$t('messages.error.validationError')).goAway(1500)
        }
      })
    }
  },
  directives: {
    ClickOutside
  }
}
</script>

<style scoped lang="scss">
  .dropdown-settings {
    position: relative;
    width: auto;
    display: initial;
    .btn-td-hover {
      cursor: pointer;
      border: none;
      border-radius: 11px;
      padding: 0 10px;
      width: 40px;
    }
    .active {
      background-color: #d4d4d4;
    }
    .btn-td-hover:hover {
      background-color: #d4d4d4;
    }

    .btn-img {
      margin: 0 auto;
    }

    .btn-td-hover:focus {
      outline: none;
    }

    .dropdown {
      width: 190px;
      height: auto;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: #5F5F5F;
      position: absolute;
      background: white;
      border: 1px solid #DEDEDE;
      border-radius: 6px;
      top: 13px;
      right: 31px;
      z-index: 10;
      padding: 5px 0;
    }

    .dropdown div {
      width: 180px;
      height: 37px;
      border-radius: 6px;
      display: flex;
      align-items: center;
      margin: 0 auto;
      cursor: pointer;
      padding-left: 10px;
    }
    .dropdown div:hover {
      background: #F5F5F5;
    }
  }
</style>
