import { http } from '../../../api'

const course = {
  namespaced: true,
  state: {
    coursesList: [],
    activeCoursesList: [],
    course: null
  },
  actions: {
    getAllCourses: ({ commit }, params) => {
      let url = '/course'
      if (params) {
        const q = new URLSearchParams(params)
        url = url + '?' + q.toString()
      }
      return http.get(url).then(res => {
        commit('setCoursesList', res.data.data)
        return res.data
      })
    },
    getActiveCoursesList: ({ commit }) => {
      return http.get('/course/list').then(res => {
        commit('setActiveCoursesList', res.data.data)
        return res.data
      })
    },
    createCourse: ({ commit }, params) => {
      return http.post('/course', params).then(res => {
        return res.data
      })
    },
    deleteCourse: ({ commit }, params) => {
      return http.delete('/course/' + params.id).then(res => {
        if (res.data.deleteType === 'forceDelete') {
          commit('setCourseAfterDelete', params.id)
        } else {
          commit('setUpdateCourseStatus', {
            id: params.id,
            status: 'inactive'
          })
        }
      })
    },
    restoreCourse: ({ commit }, params) => {
      return http.get('/course/' + params.id + '/restore').then(res => {
        commit('setUpdateCourseStatus', {
          id: params.id,
          status: 'active'
        })
      })
    },
    getCourse: ({ commit }, params) => {
      return http.get('/course/' + params.id).then(res => {
        commit('setCourse', res.data.data)
        return res.data
      })
    },
    updateCourse: ({ commit }, params) => {
      return http.post('/course/' + params.id, params.data).then(res => {
        return res.data
      })
    },
    cancelBuy ({ commit }, {
      user_id,
      course_id
    }) {
      return http.post(`/courses/${course_id}/cancel/${user_id}`).then(res => {
        commit('removeCourseUser', user_id)
        return res.data
      })
    }
  },
  mutations: {
    setUpdateCourseStatus (state, data) {
      state.coursesList = state.coursesList.map(item => {
        if (item.id === data.id) {
          item.status = data.status
        }
        return item
      })
    },
    setCoursesList (state, data) {
      state.coursesList = data
    },
    setActiveCoursesList (state, data) {
      state.activeCoursesList = data
    },
    setCourseAfterDelete (state, id) {
      state.coursesList = state.coursesList.filter(item => item.id !== id)
    },
    setCourse (state, data) {
      console.log('setCourse', data)
      state.course = data
    },
    removeCourseUser(state,id){
      state.course.users = state.course.users.filter(user => user.id !== id)
    }
  },
  getters: {
    coursesList: state => state.coursesList,
    activeCoursesList: state => state.activeCoursesList,
    course: state => state.course
  }
}

export default course
