<template>
  <div>
    <div class="buttons__container">
      <div v-for="(pType, index) in presentationTypes" :key="index">
        <div
          class="buttons__item"
          :class="{ active: contentOutputMethod === index }"
          @click="() => $emit('changePresentationType', pType.id)"
        >
          <img :src="require(`../../assets/img/icons/${pType.type}.svg`)" :alt="pType.type" />
        </div>
      </div>
    </div>
    <div v-if="contentOutputMethod === presentationTypes[1].id" class="input__container">
      <ValidationProvider :rules="{ required }">
        <label class="form-label input__label">Макс. кол-во вопросов на странице </label>
        <input
          class="form-control input__control"
          name="content_output_count"
          v-model="contentOutputCountInner"
          placeholder="Ввод"
          type="text"
          maxlength="2"
          @input="change"
        />
      </ValidationProvider>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PresentationButtons',
  props: {
    contentOutputMethod: {
      type: Number,
      default: 0
    },
    contentOutputCount: {
      type: Number,
      default: 3
    }
  },
  data() {
    return {
      presentationTypes: [
        { id: 0, type: 'list' },
        { id: 1, type: 'list-slider' },
        { id: 2, type: 'slider' }
      ],
      contentOutputCountInner: '3'
    }
  },
  watch: {
    contentOutputCount(newVal) {
      this.contentOutputCountInner = newVal.toString()
    }
  },
  methods: {
    change(event) {
      const value = Number(event.target.value)
      const _value = value > 0 ? value : null
      this.$emit('input', !_value ? null : _value)
      this.contentOutputCountInner = !_value ? '' : _value?.toString()
    }
  }
}
</script>

<style lang="scss" scoped>
.buttons {
  &__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #eaecf5;
    border-radius: 8px;
    padding: 2px;
    margin-bottom: 12px;
    width: fit-content;
    margin-left: auto;
  }

  &__item {
    border-radius: 6px;
    padding: 8px;
    cursor: pointer;
    opacity: 0.5;
    img {
      width: 24px;
      height: 24px;
    }
    &.active {
      background-color: #ffffff;
      opacity: 1;
    }
  }
}

.input {
  &__container {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
  }

  &__label {
    margin-bottom: 0;
  }

  &__control {
    max-width: 60px;
    margin-left: auto;
    padding: 7px;
  }
}
</style>
