<template>
  <div>
    <div class="page-header">
      <breadcrumbs name="модуль курса" />
    </div>
    <div class="row row-sm justify-content-center">
      <div class="col-lg-6 col-md-12">
        <div class="card custom-card">
          <div class="card-body">
            <div>
              <h6 class="main-content-label mb-1">Модуль курса</h6>
            </div>
            <div>
              <ValidationObserver tag="form" ref="courseModuleAddForm" class="d-flex flex-column">
                <b-tabs content-class="mt-3">
                  <b-tab :title="language.code" v-for="(language, i) in languages" :key="i">
                    <ValidationProvider v-slot="{ classes }" rules="required" tag="div" class="form-group">
                      <label :for="'name[' + language.code + ']'" class="form-label">Название модуля:</label>
                      <input  :id="'name[' + language.code + ']'" class="form-control" :name="'name[' + language.code + ']'" v-model="formData.name[language.code]" :class="checkValidation(classes, language.code)" placeholder="Название" type="text">
                    </ValidationProvider>
                    <ValidationProvider v-slot="{ classes }"  tag="div" class="form-group">
                      <label :for="'description[' + language.code + ']'" class="form-label">Описание:</label>
                      <ckeditor @namespaceloaded="onNamespaceLoaded"  v-model="formData.description[language.code]" name="description"  :id="'description[' + language.code + ']'" :config="ckEditorConfig"></ckeditor>
                    </ValidationProvider>
                  </b-tab>
                </b-tabs>
              </ValidationObserver>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row row-sm justify-content-center">
      <div class="col-xl-6 col-lg-8 col-md-12">
        <div class="card custom-card">
          <div class="card-body">
            <div class="d-flex justify-content-between align-items-center">
              <h6 class="main-content-label mb-1">Уроки</h6>
              <button type="button" @click="formSubmit('lesson')" class="btn ripple btn-main-primary mt-2" :disabled="buttonDisable" :class="buttonDisable ? 'button-readonly' : ''">Добавить урок</button>
            </div>
            <div v-if="lessonsList && lessonsList.length">
              <div class="add-course-item" v-for="(lesson, index) in lessonsList" :key="index">
                <div class="name">{{lesson.name[$i18n.locale]}}</div>
                <div>
                  <router-link :to="'/lesson/' + $route.params.courseId + '/' + formData.id + '/' + lesson.id" class="btn btn-outline-primary mr-2 mt-1 mb-1">
                    <i class="fe fe-edit"></i>
                  </router-link>
                  <button v-if="lesson.status === 'active'" @click="deleteCourseModule(lesson.id)" type="button" class="btn btn-outline-danger ml-2">
                    <i class="ti-close"></i>
                  </button>
                  <div v-else class="d-inline-block">
                    <button @click="restoreCourseModule(lesson.id)" type="button" class="btn btn-outline-success ml-2">
                      <i class="fe fe-rotate-ccw"></i>
                    </button>
                    <button @click="deleteCourseModule(lesson.id)" type="button" class="btn btn-outline-danger ml-2">
                      <i class="fe fe-trash-2"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row row-sm justify-content-center">
      <div class="col-xl-6 col-lg-8 col-md-12">
        <div class="card custom-card">
          <div class="card-body">
            <div class="d-flex justify-content-between align-items-center">
              <h6 class="main-content-label mb-1">Tests</h6>
              <button type="button" @click="formSubmit('test')" class="btn ripple btn-main-primary mt-2" :disabled="buttonDisable" :class="buttonDisable ? 'button-readonly' : ''">Add Test</button>
            </div>
            <div v-if="courseModuleTestsList && courseModuleTestsList.length">
              <div class="add-course-item" v-for="(test, index) in courseModuleTestsList" :key="index">
                <div class="name">{{test.title[$i18n.locale]}}</div>
                <div>
                  <router-link :to="'/course-module-test/' + $route.params.courseId + '/' + courseModuleId + '/' + test.id" class="btn btn-outline-primary mr-2 mt-1 mb-1">
                    <i class="fe fe-edit"></i>
                  </router-link>
                  <button v-if="test.status === 'active'" @click="deleteCourseModuleTest(test.id)" type="button" class="btn btn-outline-danger ml-2">
                    <i class="ti-close"></i>
                  </button>
                  <div v-else class="d-inline-block">
                    <button @click="restoreCourseModuleTest(test.id)" type="button" class="btn btn-outline-success ml-2">
                      <i class="fe fe-rotate-ccw"></i>
                    </button>
                    <button @click="deleteCourseModuleTest(test.id)" type="button" class="btn btn-outline-danger ml-2">
                      <i class="fe fe-trash-2"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row row-sm justify-content-center">
      <div class="col-xl-6 col-lg-8 col-md-12">
        <div class="card custom-card">
          <div class="card-body">
            <button class="btn ripple btn-main-primary w-100" @click="formSubmit('module')" :disabled="buttonDisable" :class="buttonDisable ? 'button-readonly' : ''">Submit</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Swal from 'sweetalert2'
import CkeditorMixin from '../../../mixins/CkeditorMixin'
import Breadcrumbs from '../../components/Breadcrumbs'
export default {
  name: 'CourseModuleAdd',
  components: { Breadcrumbs },
  mixins: [CkeditorMixin],
  data () {
    return {
      buttonDisable: false,
      formData: {
        name: {},
        description: {},
        course: []
      },
      isCreate: false,
      courseModuleId: null
    }
  },
  computed: {
    ...mapState({
      languages: state => state.language.languages,
      courseModule: state => state.courseModule.courseModule,
      courseModuleTestsList: state => state.courseModuleTest.courseModuleTestsList,
      lessonsList: state => state.lesson.lessonsList
    })
  },
  created () {
    this.courseModuleId = this.$route.params.id
    this.getLessons(this.courseModuleId)
    this.getCourseModuleTestsListByCourse(this.courseModuleId)
    if (this.courseModuleId === 'add') {
      this.$set(this.formData.description,this.$i18n.locale, null)
      this.isCreate = true
    } else {
      this.getCourseModule(this.courseModuleId).then(res => {
        this.formData = this.courseModule
        if (!this.formData.description || this.formData.description.length === 0){
            const langObject = {}
            langObject[this.$i18n.locale] = null
            this.formData.description = langObject
        }
      })
    }
  },
  methods: {
    ...mapActions({
      createCourseModule: 'courseModule/createCourseModule',
      getCourseModuleTestsListByCourse: 'courseModuleTest/getCourseModuleTestsListByCourse',
      updateCourseModule: 'courseModule/updateCourseModule',
      getCourseModule: 'courseModule/getCourseModule',
      restoreModuleTest: 'courseModuleTest/restoreModuleTest',
      deleteModuleTest: 'courseModuleTest/deleteModuleTest',
      delete: 'lesson/delete',
      restore: 'lesson/restore',
      getLessons: 'lesson/getLessons'
    }),
    formSubmit (type) {
      this.$refs.courseModuleAddForm.validate().then(success => {
        if (success) {
          this.formData.course_id = this.$route.params.courseId
          this.buttonDisable = true
          if (this.isCreate) {
            this.createCourseModule(this.formData).then(res => {
              if (type === 'module') {
                this.$router.push('/courses/' + this.$route.params.courseId)
              } else if (type === 'test') {
                this.$router.push('/course-module-test/' + this.$route.params.courseId + '/' + res.data.id + '/add')
              } else {
                this.$router.push('/lesson/' + this.$route.params.courseId + '/' + res.data.id + '/add')
              }
            }).catch(() => {
              this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
            }).finally(() => {
              this.buttonDisable = false
            })
          } else {
            this.updateCourseModule({ data: this.formData, id: this.courseModuleId }).then(res => {
              if (type === 'module') {
                this.$router.push('/courses/' + this.$route.params.courseId)
              } else if (type === 'test') {
                this.$router.push('/course-module-test/' + this.$route.params.courseId + '/' + res.data.id + '/add')
              } else {
                this.$router.push('/lesson/' + this.$route.params.courseId + '/' + res.data.id + '/add')
              }
            }).catch(() => {
              this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
            }).finally(() => {
              this.buttonDisable = false
            })
          }
        } else {
          this.$toasted.error(this.$t('messages.error.validationError')).goAway(1500)
        }
      })
    },
    checkValidation (data) {
      return data
    },
    deleteCourseModuleTest (id) {
      Swal.fire({
        text: this.$t('messages.warning.delete'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#748C41',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('buttons.yes'),
        cancelButtonText: this.$t('buttons.no')
      }).then((result) => {
        if (result.value) {
          this.deleteModuleTest(id).then(res => {
            this.$toasted.success(this.$t('messages.success.languageDeleted')).goAway(1500)
          }).catch(() => {
            this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
          })
        }
      })
    },
    restoreCourseModuleTest (id) {
      Swal.fire({
        text: this.$t('message.warning.restore'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#748C41',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('buttons.yes'),
        cancelButtonText: this.$t('buttons.no')
      }).then((result) => {
        if (result.value) {
          this.restoreModuleTest(id).then(res => {
            this.$toasted.success(this.$t('messages.success.languageRestored')).goAway(1500)
          }).catch(() => {
            this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
          })
        }
      })
    },
    deleteCourseModule (id) {
      Swal.fire({
        text: this.$t('messages.warning.delete'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#748C41',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('buttons.yes'),
        cancelButtonText: this.$t('buttons.no')
      }).then((result) => {
        if (result.value) {
          this.delete(id).then(res => {
            this.$toasted.success(this.$t('messages.success.languageDeleted')).goAway(1500)
          }).catch(() => {
            this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
          })
        }
      })
    },
    restoreCourseModule (id) {
      Swal.fire({
        text: this.$t('message.warning.restore'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#748C41',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('buttons.yes'),
        cancelButtonText: this.$t('buttons.no')
      }).then((result) => {
        if (result.value) {
          this.restore(id).then(res => {
            this.$toasted.success(this.$t('messages.success.languageRestored')).goAway(1500)
          }).catch(() => {
            this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
          })
        }
      })
    }
  }
}
</script>
