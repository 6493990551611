<template>
  <div>
    <div class="page-header">
      <breadcrumbs name="урок" />
      <div class="d-flex">
      </div>
    </div>
    <div class="row row-sm justify-content-center">
      <div class="col-lg-6 col-md-12">
        <div class="card custom-card">
          <div class="card-body">
            <div>
              <h6 class="main-content-label mb-1">Урок</h6>
            </div>
            <div>
              <ValidationObserver tag="form" ref="lessonAddForm" id="lessonAddForm" class="d-flex flex-column">
                <b-tabs content-class="mt-3">
                  <b-tab :title="language.code" v-for="(language, i) in languages" :key="i">
                    <ValidationProvider v-slot="{ classes }" rules="required" tag="div" class="form-group">
                      <label :for="'name[' + language.code + ']'" class="form-label">Название урока:</label>
                      <input :id="'name[' + language.code + ']'" class="form-control" :name="'name[' + language.code + ']'" v-model="formData.name[language.code]" :class="checkValidation(classes, language.code)" placeholder="Название" type="text">
                    </ValidationProvider>
                    <ValidationProvider v-slot="{ classes }"  tag="div" class="form-group">
                      <label :for="'description[' + language.code + ']'" class="form-label">Описание урока:</label>
                      <ckeditor @namespaceloaded="onNamespaceLoaded" v-model="formData.description[language.code]" :name="'description[' + language.code + ']'"  placeholder="Описание" :id="'description[' + language.code + ']'" :config="ckEditorConfig"></ckeditor>
                    </ValidationProvider>
                    <ValidationProvider v-slot="{ classes }" tag="div" class="form-group">
                      <label :for="'short_description[' + language.code + ']'" class="form-label">Короткое описание:</label>
                      <textarea class="form-control" :name="'short_description[' + language.code + ']'" :id="'short_description[' + language.code + ']'" placeholder="Short Description" :class="checkValidation(classes, language.code)" v-model="formData.short_description[language.code]"></textarea>
                    </ValidationProvider>
                  </b-tab>
                  <VideoForm
                    :is-edit="!isCreate"
                    :video-file.sync="uploadedVideoFile"
                    :video-iframe.sync="formData.video_iframe"
                    :video-type.sync="videoType"
                    iframe-name="video_iframe"
                    video-name="video_file"
                  />
                </b-tabs>
              </ValidationObserver>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row row-sm justify-content-center">
      <div class="col-xl-6 col-lg-8 col-md-12">
        <div class="card custom-card">
          <div class="card-body">
            <div class="d-flex justify-content-between align-items-center">
              <h6 class="main-content-label mb-1">Блоки</h6>
              <button type="button" @click="formSubmit('block')" class="btn ripple btn-main-primary mt-2" :disabled="buttonDisable" :class="buttonDisable ? 'button-readonly' : ''">Добавить блок</button>
            </div>
            <div v-if="lessonBlockList && lessonBlockList.length">
              <div class="add-course-item" v-for="(lessonBlock, index) in lessonBlockList" :key="index">
                <div class="name">{{lessonBlock.name[$i18n.locale]}}</div>
                <div>
                  <router-link :to="'/lesson-block/' + $route.params.courseId + '/' + $route.params.moduleId + '/' + formData.id + '/' + lessonBlock.id" class="btn btn-outline-primary mr-2 mt-1 mb-1">
                    <i class="fe fe-edit"></i>
                  </router-link>
                  <button v-if="lessonBlock.status === 'active'" @click="deleteLessonBlock(lessonBlock.id)" type="button" class="btn btn-outline-danger ml-2">
                    <i class="ti-close"></i>
                  </button>
                  <div v-else class="d-inline-block">
                    <button @click="restoreLessonBlock(lessonBlock.id)" type="button" class="btn btn-outline-success ml-2">
                      <i class="fe fe-rotate-ccw"></i>
                    </button>
                    <button @click="deleteLessonBlock(lessonBlock.id)" type="button" class="btn btn-outline-danger ml-2">
                      <i class="fe fe-trash-2"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row row-sm justify-content-center">
      <div class="col-xl-6 col-lg-8 col-md-12">
        <div class="card custom-card">
          <div class="card-body">
            <button class="btn ripple btn-main-primary w-100" @click="formSubmit('lesson')" :disabled="buttonDisable" :class="buttonDisable ? 'button-readonly' : ''"><ButtonName /></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Swal from 'sweetalert2'
import VideoForm from '../../components/VideoForm'
import CkeditorMixin from '../../../mixins/CkeditorMixin'
import Breadcrumbs from '../../components/Breadcrumbs'
import ButtonName from '../../components/ButtonName'
export default {
  name: 'LessonAdd',
  mixins: [CkeditorMixin],
  components: { ButtonName, Breadcrumbs, VideoForm },
  data () {
    return {
      buttonDisable: false,
      formData: {
        name: {},
        description: {},
        short_description: {},
        video_iframe: null,
        video_file: null,
      },
      isCreate: false,
      lessonId: null,
      videoType: 'video_iframe',
      fileErrorMessage: '',
      uploadedVideoFile: null
    }
  },
  computed: {
    ...mapState({
      languages: state => state.language.languages,
      lesson: state => state.lesson.lesson,
      lessonBlockList: state => state.lessonBlock.lessonBlockList
    })
  },
  created () {
    this.$set(this.formData.description, this.$i18n.locale, null)
    this.lessonId = this.$route.params.id
    this.getLessonBlocksByLesson(this.lessonId)
    if (this.lessonId === 'add') {
      this.isCreate = true
    } else {
      this.getLesson(this.lessonId).then(res => {
        this.formData = this.lesson
        if (this.formData.video_iframe || !this.formData.video_file) {
          this.videoType = 'video_iframe'
        } else {
          this.videoType = 'video'
          this.uploadedVideoFile = this.formData.video_file
        }
      })
    }
  },
  methods: {
    ...mapActions({
      createLesson: 'lesson/createLesson',
      updateLesson: 'lesson/updateLesson',
      getLesson: 'lesson/getLesson',
      getLessonBlocksByLesson: 'lessonBlock/getLessonBlocksByLesson',
      delete: 'lessonBlock/delete',
      restore: 'lessonBlock/restore'
    }),
    customLabel ({ name, desc }) {
      return `${name[this.$i18n.locale]}`
    },
    formSubmit (type) {
      this.$refs.lessonAddForm.validate().then(success => {
        if (success) {
          this.buttonDisable = true
          const form = document.getElementById('lessonAddForm')
          const formData = new FormData(form)
          formData.delete('video_type')
          if (this.videoType === 'video_iframe') {
            formData.delete('video_file')
          } else {
            formData.delete('video_iframe')
          }

          if (!Object.keys(this.formData.description).length){
            this.languages.map(language => {
              this.$set(this.formData.description, language.code, '')
            })
          }

          this.languages.map(language => {
            formData.append('description[' + language.code + ']', this.formData.description[language.code])
          })
          // if (!this.formData.video_iframe && !this.uploadedVideoFile) {
          //   this.fileErrorMessage = 'Please upload Video!'
          //   this.buttonDisable = false
          //   return false
          // } else {
          //   this.fileErrorMessage = ''
          // }
          formData.append('course_module_id', this.$route.params.moduleId)
          if (this.isCreate) {
            this.createLesson(formData).then(res => {
              if (type === 'lesson') {
                this.$router.push('/course-module/' + this.$route.params.courseId + '/' + this.$route.params.moduleId)
              } else {
                this.$router.push('/lesson-block/' + this.$route.params.courseId + '/' + this.$route.params.moduleId + '/' + res.data.id + '/add')
              }
            }).catch(() => {
              this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
            }).finally(() => {
              this.buttonDisable = false
            })
          } else {
            this.updateLesson({ data: formData, id: this.lessonId }).then(res => {
              if (type === 'lesson') {
                this.$router.push('/course-module/' + this.$route.params.courseId + '/' + this.$route.params.moduleId)
              } else {
                this.$router.push('/lesson-block/' + this.$route.params.courseId + '/' + this.$route.params.moduleId + '/' + res.data.id + '/add')
              }
            }).catch((e) => {
              console.log(e)
              this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
            }).finally(() => {
              this.buttonDisable = false
            })
          }
        } else {
          this.$toasted.error(this.$t('messages.error.validationError')).goAway(1500)
        }
      })
    },
    deleteLessonBlock (id) {
      Swal.fire({
        text: this.$t('messages.warning.delete'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#748C41',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('buttons.yes'),
        cancelButtonText: this.$t('buttons.no')
      }).then((result) => {
        if (result.value) {
          this.delete(id).then(res => {
            this.$toasted.success(this.$t('messages.success.languageDeleted')).goAway(1500)
          }).catch(() => {
            this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
          })
        }
      })
    },
    restoreLessonBlock (id) {
      Swal.fire({
        text: this.$t('message.warning.restore'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#748C41',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('buttons.yes'),
        cancelButtonText: this.$t('buttons.no')
      }).then((result) => {
        if (result.value) {
          this.restore(id).then(res => {
            this.$toasted.success(this.$t('messages.success.languageRestored')).goAway(1500)
          }).catch(() => {
            this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
          })
        }
      })
    },
    checkValidation (data) {
      return data
    },
    createYoutubeVideo () {
      this.fileErrorMessage = ''
    },
    uploadCoverFile (file) {
      this.fileErrorMessage = ''
      const uploadFile = file[0]
      this.uploadedVideoFile = uploadFile
    }
  },
  watch:{
    videoIframe(newVal){
      console.log('videoIframe',newVal)
    }
  }
}
</script>
