<template>
  <div>
    <div class="page-header">
      <div>
        <h2 class="main-content-title tx-24 mg-b-5">Энциклопедия</h2>
      </div>
      <div class="d-flex">
        <div class="justify-content-center">
          <router-link to="/encyclopedia/add" type="button" class="btn btn-primary my-2 btn-icon-text">
            <i class="fe fe-plus-square mr-2"></i> Добавить
          </router-link>
        </div>
      </div>
    </div>
    <div class="row row-sm">
      <div class="col-lg-12">
        <div class="card custom-card">
          <div class="card-body">
            <div>
              <h6 class="main-content-label mb-1">Список</h6>
            </div>
            <div class="table-responsive">
              <b-table striped hover :items="encyclopediaList" :fields="tableFields">
                <template #cell(actions)="row">
                  <router-link :to="`/encyclopedia/${row.item.id}`" class="btn btn-outline-primary">
                    <i class="fe fe-edit"></i>
                  </router-link>
                  <button v-if="row.item.status === 'active'" @click="Delete(row.item.id)" type="button"
                          class="btn btn-outline-danger ml-2">
                    <i class="ti-close"></i>
                  </button>
                  <div v-else class="d-inline-block">
                    <button @click="restore(row.item.id)" type="button" class="btn btn-outline-success ml-2">
                      <i class="fe fe-rotate-ccw"></i>
                    </button>
                    <button @click="Delete(row.item.id)" type="button" class="btn btn-outline-danger ml-2">
                      <i class="fe fe-trash-2"></i>
                    </button>
                  </div>
                </template >
                <template #cell(example)="row">
                    <div v-html="row.item.example">

                    </div>
                </template>
              </b-table>
              <div class="d-flex justify-content-end  mt-3" v-if="pagination">
                <div>
                  <b-pagination
                    class="m-0"
                    @change="getEncyclopediaList"
                    v-model="paginationCurrentPage"
                    :total-rows="pagination.total"
                    :per-page="pagination.per_page"
                    first-text="<<"
                    prev-text="<"
                    next-text=">"
                    last-text=">>"
                  ></b-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Swal from 'sweetalert2'

export default {
  name: 'teachingLangCategories',
  computed: {
    ...mapGetters({
      encyclopediaList: 'encyclopedia/encyclopediaList',
      pagination: 'encyclopedia/pagination'
    }),
    tableFields () {
      return [
        {
          key: 'id',
          label: 'Id',
          sortable: false
        },
        {
          key: 'word',
          label: 'Слово',
          sortable: false
        },
        {
          key: 'translate',
          label: 'Перевод',
          sortable: false
        },
        {
          key: 'example',
          label: 'Пример',
          sortable: false
        },
        {
          key: 'transliteration',
          label: 'Транслитерация',
          sortable: false
        },
        {
          key: 'language.name.' + this.$i18n.locale,
          label: 'Язык',
          sortable: false
        },
        {
          key: 'actions',
          label: 'Действие',
          sortable: false
        }
      ]
    }
  },
  data () {
    return {
      paginationCurrentPage: 1,
    }
  },
  created () {
    this.getEncyclopediaList({ page: this.paginationCurrentPage }).catch(() => {
      this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
    })
  },
  methods: {
    ...mapActions({
      getEncyclopediaList: 'encyclopedia/getEncyclopediaList',
      deleteEncyclopedia: 'encyclopedia/deleteEncyclopedia',
      restoreEncyclopedia: 'encyclopedia/restoreEncyclopedia',
    }),
    restore (id) {
      Swal.fire({
        text: this.$t('message.warning.restore'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#748C41',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('buttons.yes'),
        cancelButtonText: this.$t('buttons.no')
      }).then((result) => {
        if (result.value) {
          this.restoreEncyclopedia(id).then(res => {
            this.$toasted.success(this.$t('messages.success.teachingLanguageUpdated')).goAway(1500)
          }).catch(() => {
            this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
          })
        }
      })
    },
    Delete (id) {
      Swal.fire({
        text: this.$t('messages.warning.delete'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#748C41',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('buttons.yes'),
        cancelButtonText: this.$t('buttons.no')
      }).then((result) => {
        if (result.value) {
          this.deleteEncyclopedia(id).then(res => {
            this.$toasted.success(this.$t('messages.success.languageDeleted')).goAway(1500)
          }).catch(() => {
            this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
          })
        }
      })
    }
  }
}
</script>
<style>

</style>
