import { http } from '../../../api'

const encyclopedia = {
  namespaced: true,
  state: {
    phones: [],
    phone: null,
    pagination: {}
  },
  actions: {
    get: ({ commit }, params) => {
      return http.get('/phones', params).then(res => {
        commit('setPhones', res.data.data)
        commit('setPagination', res.data)
        return res.data
      })
    },
    create: ({ commit }, params) => {
      return http.post('/phones', params).then(res => {
        return res.data
      })
    },
    show ({ commit }, id) {
      return http.get(`/phones/${id}`).then(res => {
        commit('setPhone', res.data)
      })
    },
    delete: ({ commit }, id) => {
      return http.delete('/phones/' + id).then(res => {
        commit('setPhonesAfterDelete', id)
      })
    },
    update: ({ commit }, params) => {
      return http.put('/phones/' + params.id, params.data).then(res => {
      })
    },
  },
  mutations: {
    setPhones (state, data) {
      state.phones = data
    },
    setPhonesAfterDelete (state, id) {
      state.phones = state.phones.filter(item => item.id !== id)
    },
    setPhone (state, data) {
      state.phone = data
    },
    setPagination (state, data) {
      state.pagination = data
    }

  },
  getters: {
    phones: state => state.phones,
    phone: state => state.phone,
    pagination: state => state.pagination
  }
}

export default encyclopedia
