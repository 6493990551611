export default {
  data () {
    return {
      audio: null
    }
  },
  methods: {
    audioChange (val) {
      this.audio = val
    },
    preUploadAudio (formData) {
      if (typeof this.audio === 'string') {
        formData.set('audio', this.audio)
      } else if (this.audio) {
        this.audio.forEach(audio => {
          formData.set('audio', audio)
        })
      }
    },
    setAudioData (data) {
      data = this.audio
    },
  }
}
