<template>
  <div>
    <div class="page-header">
      <div>
        <h2 class="main-content-title tx-24 mg-b-5">{{isCreate ? 'Добавить' : 'Редактировать'}} язык</h2>
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="#" @click="$router.go(-1)">Назад</a></li>
          <li class="breadcrumb-item active" aria-current="page">{{isCreate ? 'Добавить' : 'Редактировать'}} язык</li>
        </ol>
      </div>
      <div class="d-flex">
      </div>
    </div>
    <div class="row row-sm justify-content-center">
      <div class="col-lg-6 col-md-12">
        <div class="card custom-card">
          <div class="card-body">
            <div>
              <h6 class="main-content-label mb-1">Язык</h6>
            </div>
            <div>
              <ValidationObserver ref="languageAddForm" tag="form" class="d-flex flex-column" @submit.prevent="formSubmit()">
                <ValidationProvider v-slot="{ classes }" rules="required" tag="div" class="form-group">
                  <label for="name" class="form-label">Название:</label>
                  <input id="name" class="form-control" v-model="formData.name" :class="classes" placeholder="Название" type="text">
                </ValidationProvider>
                <ValidationProvider v-slot="{ classes }" rules="required" tag="div" class="form-group">
                  <label for="code" class="form-label">Код:</label>
                  <input id="code" class="form-control" v-model="formData.code" :class="classes" placeholder="Код" type="text">
                </ValidationProvider>
                <div class="form-group">
                  <label for="localizationJson" class="form-label">Локализация:</label>
                  <v-jsoneditor id="localizationJson" v-model="formData.localization_json" :options="options" name="localization_json" :plus="true" height="400px"/>
                </div>
                <button class="btn ripple btn-main-primary">{{isCreate?'Сохранить':'Обновить'}}</button>
              </ValidationObserver>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import VJsoneditor from 'v-jsoneditor/src/index'

export default {
  name: 'AddLanguage',
  data () {
    return {
      formData: {
        code: '',
        name: '',
        json: {}
      },
      options: {
        mode: 'code'
      },
      isCreate: false,
      languageId: null
    }
  },
  components: {
    VJsoneditor
  },
  created () {
    this.languageId = this.$route.params.id
    if (this.languageId === 'add') {
      this.isCreate = true
    } else {
      this.getLanguage(this.languageId).then(res => {
        this.formData = res.data
      })
    }
  },
  methods: {
    ...mapActions({
      createLanguage: 'language/createLanguage',
      updateLanguage: 'language/updateLanguage',
      getLanguage: 'language/getLanguage'
    }),
    formSubmit () {
      this.$refs.languageAddForm.validate().then(success => {
        if (success) {
          if (this.isCreate) {
            this.createLanguage(this.formData).then(() => {
              this.$router.push('/language')
            }).catch(() => {
              this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
            })
          } else {
            this.updateLanguage({ data: this.formData, id: this.languageId }).then(() => {
              this.$router.push('/language')
            }).catch(() => {
              this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
            })
          }
        } else {
          this.$toasted.error(this.$t('messages.error.validationError')).goAway(1500)
        }
      })
    }
  }
}
</script>
