<template>
  <TaskBase
    :data="formData"
    :update="updateTask"
    :create="createTask"
    :before-update-request="beforeUpdateRequest"
    :task-type-id="14"
    :help="help"
  >
    <FormCard>
      <b-tabs content-class="mt-3">
        <div class="d-flex justify-content-between">
          <button type="button" class="btn btn-dark mb-3" @click="hideWord">Опечатка</button>
          <div>
            <button type="button" class="btn btn-success mb-3" @click="addText">Добавить ещё</button>
            <button type="button" class="btn btn-danger mb-3 ml-3" v-if="activeQuestion > 0" @click="removeText">Удалить</button>
          </div>
        </div>
        <div v-for="(text, index) in texts" v-show="index === activeQuestion">
          <ValidationProvider v-slot="{ classes }" rules="required" tag="div" class="form-group">
                  <textarea
                    class="form-control" ref="texts" :name="`texts[${index}][text]`"
                    @input="changeTextarea"
                    placeholder="Missing Words" :class="classes"
                    v-model="text.text"
                    @keyup="keyUp"
                  >

                  </textarea>
          </ValidationProvider>
          <div class="row">
            <div class="col-md-6">
              Правильный вариант
            </div>
            <div class="col-md-6">
              Неправильный вариант
            </div>
          </div>
          <div class="row mt-2" v-for="(answer,answerIndex) in text.answers">
            <div class="col-md-6">
              <ValidationProvider v-slot="{ classes }" rules="required" tag="div" class="form-group">
                <input class="form-control"
                       v-model="answer.correct_answer"
                       :class="classes"
                       :name="`texts[${index}][answers][${answerIndex}][correct_answer]`" readonly>
              </ValidationProvider>
            </div>

            <div class="col-md-5">
              <ValidationProvider v-slot="{ classes }" rules="required" tag="div" class="form-group">
                <input
                  class="form-control"
                  v-model="answer.incorrect_answer"
                  :class="classes"
                  :name="`texts[${index}][answers][${answerIndex}][incorrect_answer]`">
              </ValidationProvider>
            </div>
            <div class="col-md-1 text-danger pt-2" style="cursor: pointer" @click="removeAnswer(answerIndex)">
              <i class="ti-close"></i>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center align-items-center">
          <button type="button" class="btn btn-outline-primary d-flex align-items-center rounded-circle p-3"
                  @click="prev"><i class="ti-arrow-left"></i></button>
          <span class="pl-2 pr-2 font-weight-bold">{{ activeQuestion + 1 }}/{{ texts.length }}</span>
          <button type="button" class="btn btn-outline-primary d-flex align-items-center rounded-circle p-3"
                  @click="next"><i class="ti-arrow-right"></i></button>
        </div>
      </b-tabs>
    </FormCard>
  </TaskBase>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import FileUpload from '../../../components/FileUpload'
import FormCard from '../../../components/FormCard'
import VideoForm from '../../../components/VideoForm'
import CkeditorMixin from '../../../../mixins/CkeditorMixin'
import TaskMixin from '../../../../mixins/TaskMixin'
import Breadcrumbs from '../../../components/Breadcrumbs'
import AudioMixin from '../../../../mixins/AudioMixin'
import TaskBase from '../../../components/tasks/TaskBase'

export default {
  mixins: [CkeditorMixin, TaskMixin, AudioMixin],
  data () {
    return {
      help: ' Заключая фрагмент текста в квадратные скобки - [ ] - вы скрываете его для экзаменуемого. И именно этот\n' +
        '        фрагмент должен будет угадать экзаменуемый. Также вы можете выделить фрагмент текста и и нажать кнопку\n' +
        '        "Опечатка."\n' +
        '        <br>\n' +
        '        Например:\n' +
        '        <br>\n' +
        '        Ich reise viel, ich [reise] gern.\n' +
        '        <br>\n' +
        '        В задании:\n' +
        '        <br>\n' +
        '        Ich reise viel, ich ... gern.',
      activeQuestion: 0,
      buttonDisable: false,
      videoType: 'video_iframe',
      fileErrorMessage: '',
      uploadedVideoFile: null,
      isCreate: false,
      taskId: null,
      wordsOrder: [],
      formData: {
        name: {},
        description: {},
        prompt: {},
      },
      texts: [{
        text: null,
        answers: []
      }],
      gallery: []
    }
  },
  components: {
    TaskBase,
    Breadcrumbs,
    VideoForm,
    FormCard,
    FileUpload
  },
  computed: {
    ...mapState({
      languages: state => state.language.languages,
      taskType: state => state.taskType.taskType
    }),
    ...mapGetters({
      task: 'taskTypos/task',
    })
  },
  created () {
    this.taskId = this.$route.params.id
    this.getTaskType(14).catch(() => {
      this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
    })
    if (this.taskId === 'add') {
      this.isCreate = true
    } else {
      this.isCreate = false
      this.getTask(this.taskId).then(res => {
        this.formData = this.task.task
        this.formData.prompt = this.task.task_typos.prompt
        this.texts = this.task.task_typos.texts
        if (this.formData.video_iframe || !this.formData.video) {
          this.videoType = 'video_iframe'
        } else {
          this.videoType = 'video'
          this.uploadedVideoFile = this.formData.video
        }
      })
    }
  },
  methods: {
    ...mapActions({
      createTask: 'taskTypos/createTask',
      updateTask: 'taskTypos/updateTask',
      getTask: 'taskTypos/getTask',
      getTaskType: 'taskType/getTaskType',
    }),
    addText () {
      this.texts.push({
        text: null,
        answers: []
      })
    },
    removeText(){
      this.texts.splice(this.activeQuestion, 1)
      this.activeQuestion--
    },
    changeTextarea (e) {
      return
      const words = e.target.value.split(' ')

      if (this.texts[this.activeQuestion].answers.length === 0) {
        return false
      }

      this.texts[this.activeQuestion].answers.forEach((answer, answerIndex) => {
        const index = words.findIndex(word => word === `[${answer.correct_answer}]`)
        if (index !== -1) {
          words.splice(index, 1)
        } else {
          this.texts[this.activeQuestion].answers.splice(answerIndex, 1)
        }
      })
    },
    keyUp (e) {
      return
      const allowedKeys = ['BracketLeft', 'BracketRight']

      if (!allowedKeys.includes(e.code)) {
        return false
      }
      const textarea = e.target
      const text = textarea.value
      const start = textarea.selectionStart
      if ('BracketLeft' === e.code) {
        if (start < text.length) {
          for (let i = start; i < text.length; i++) {
            if (text[i] === '[') {
              break
            }
            if (text[i] === ']') {
              if ((i + 1) !== text.length && text[i + 1] !== ' ') {
                continue
              }
              this.addAnswer(text.slice(start, i))
              break
            }
          }
        }
      } else if ('BracketRight' === e.code) {
        for (let i = start - 1; i > 0; i--) {
          if (text[i - 1] === ']') {
            break
          }
          if (text[i - 1] === '[') {
            if ((i - 2) !== 0 && text[i - 2] !== ' ') {
              continue
            }
            console.log('keyUpText', text[i - 2])
            this.addAnswer(text.slice(i, start - 1))
            break
          }
        }
      }

    },
    addAnswer (correct_answer) {
      this.texts[this.activeQuestion].answers.push({
        incorrect_answer: null,
        correct_answer,
      })
      this.sortAnswers()
    },
    sortAnswers () {
      const squareWords = this.texts[this.activeQuestion].text.match(/\[.*?\]/ig)
      const tempAnswers = this.texts[this.activeQuestion].answers.filter(answer => answer)
      squareWords.forEach((word, index) => {
        const tempIndex = tempAnswers.findIndex(answer => word.indexOf(answer.correct_answer) !== -1)

        if (tempIndex !== -1){
          this.texts[this.activeQuestion].answers.splice(index, 1, tempAnswers[tempIndex])
          tempAnswers.splice(tempIndex, 1)
        }
      })
    },
    removeAnswer (index) {
      const activeText = this.texts[this.activeQuestion]
      const { correct_answer } = activeText.answers[index],
        wordIndex = activeText.text.indexOf(correct_answer)

      if (wordIndex === -1) {
        return false
      }

      activeText.text = activeText.text.slice(0, wordIndex - 1) + correct_answer + activeText.text.slice(wordIndex + correct_answer.length + 1)

      activeText.answers.splice(index, 1)
    },
    hideWord () {
      const textarea = this.$refs.texts[this.activeQuestion]

      const start = textarea.selectionStart
      const end = textarea.selectionEnd

      if (start === end) {
        return false
      }

      const text = this.texts[this.activeQuestion].text

      const typoText = text.slice(start, end)
      this.texts[this.activeQuestion].text = `${text.substr(0, start)}[${typoText}]${text.substr(end)}`
      this.addAnswer(typoText)
    },
    customLabel ({
      name,
      desc
    }) {
      return `${name[this.$i18n.locale]}`
    },
    beforeUpdateRequest(formData){
      formData.append('_method', 'put')
    },
    prev () {
      if (this.activeQuestion > 0) {
        this.activeQuestion -= 1
      }
    },
    next () {
      if (this.activeQuestion < this.texts.length - 1) {
        this.activeQuestion += 1
      }
    },
  }
}
</script>
