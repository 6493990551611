<template>
  <div>
    <div class="page-header">
      <Breadcrumbs name="видео обучения"/>
      <div class="d-flex">
      </div>
    </div>
    <div class="row row-sm justify-content-center">
      <div class="col-xl-6 col-lg-8 col-md-12">
        <div class="card custom-card">
          <div class="card-body">
            <div>
              <h6 class="main-content-label mb-1">Видео обучения</h6>
            </div>
            <div v-if="showVideo === true">
              <ValidationObserver ref="teachingVideoAddForm" id="teachingVideoAddForm" tag="form"
                                  class="d-flex flex-column" @submit.prevent="formSubmit()">
                <b-tabs content-class="mt-3">
                  <b-tab :title="language.code" v-for="(language, i) in languages" :key="i">
                    <ValidationProvider v-slot="{ classes }" rules="required|max:120" tag="div" class="form-group">
                      <label :for="'title[' + language.code + ']'" class="form-label">Название:</label>
                      <input :id="'title[' + language.code + ']'" class="form-control"
                             :name="'title[' + language.code + ']'" v-model="formData.title[language.code]"
                             :class="checkValidation(classes, language.code)" placeholder="Название" type="text">
                    </ValidationProvider>
                    <ValidationProvider v-slot="{ classes }" rules="required|max:500" tag="div" class="form-group">
                      <label :for="'descriptions[' + language.code + ']'" class="form-label">Описание:</label>
                      <textarea class="form-control" :name="'description[' + language.code + ']'"
                                :id="'descriptions[' + language.code + ']'" placeholder="Описание"
                                :class="checkValidation(classes, language.code)"
                                v-model="formData.description[language.code]"></textarea>
                    </ValidationProvider>
                  </b-tab>
                  <FileUpload accept="image/x-png,image/jpeg,image/jpg" label="Фото:" name="image"
                               uploadType="oneImage" :validation="true" :filesData="formData.uploadedFiles"/>
                  <span class="text-danger" v-if="imageErrorMessage">{{ imageErrorMessage }}</span>
                  <ValidationProvider rules="required" tag="div" v-slot="{ classes }" class="form-group">
                    <label for="image_alt" class="form-label">Image alt:</label>
                    <input id="image_alt" class="form-control" name="image_alt" v-model="formData.image_alt"
                           placeholder="Image alt" :class="classes" type="text">
                  </ValidationProvider>
                  <div class="row mg-y-10 ">
                    <div class="col-lg-3 mg-t-20 mg-lg-t-0">
                      <label class="radiobox">
                        <input checked="" name="video_type" type="radio" v-model="videoType" value="video_iframe">
                        <span>Добавить iframe</span>
                      </label>
                    </div>
                    <div class="col-lg-3">
                      <label class="radiobox">
                        <input name="video_type" v-model="videoType" type="radio" value="video">
                        <span>Загрузить видео</span>
                      </label>
                    </div>
                  </div>
                  <ValidationProvider
                    :rules="{ required: true, regex: /(?:<iframe[^>]*)(?:(?:\/>)|(?:>.*?<\/iframe>))/ }" tag="div"
                    v-slot="{ classes }" v-if="videoType === 'video_iframe'" class="form-group">
                    <label for="video_iframe" class="form-label">Видео iframe:</label>
                    <input id="video_iframe" class="form-control" name="video_iframe" @input="createIframeVideo"
                           v-model="formData.video_iframe" placeholder="Iframe Video" :class="classes" type="text">
                  </ValidationProvider>
                  <ValidationProvider v-else tag="div" class="form-group">
                    <div>
                      <label class="form-label">Видео:</label>
                    </div>
                    <div class="custom-file">
                      <input name="video" accept=".mp4, .webm, .ogg" type="file" class="custom-file-input"
                             @change="uploadCoverFile($event.target.files)" id="inputGroupFile01"
                             aria-describedby="inputGroupFileAddon01">
                      <label class="custom-file-label" for="inputGroupFile01"
                             v-if="uploadedVideoFile && uploadedVideoFile.name">{{ isEdit && !uploadedVideoFile.name ? uploadedVideoFile : uploadedVideoFile.name }}</label>
                      <label class="custom-file-label" for="inputGroupFile01" v-else>Выбрать файл</label>
                    </div>
                  </ValidationProvider>
                  <ValidationProvider name="Курс" v-slot="{ classes, errors }" rules="required" tag="div" class="form-group">
                    <label for="course_id" class="form-label">Курс:</label>
                    <b-select
                      :class="classes"
                      id="course_id"
                      value-field="id"
                      :text-field="`name.${$i18n.locale}`"
                      :options="courses"
                      name="course_id"
                      v-model="formData.course_id"
                    />
                    <div class="invalid-feedback">{{errors[0]}}</div>
                  </ValidationProvider>
                  <span class="text-danger" v-if="fileErrorMessage">{{ fileErrorMessage }}</span>
                </b-tabs>
                <button class="btn ripple btn-main-primary mt-2" :disabled="buttonDisable"
                        :class="buttonDisable ? 'button-readonly' : ''"><ButtonName />
                </button>
              </ValidationObserver>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import FileUpload from '../../components/FileUpload'
import Breadcrumbs from '../../components/Breadcrumbs'
import ButtonName from '../../components/ButtonName'

export default {
  name: 'TeachingVideoAdd',
  data () {
    return {
      showVideo: false,
      isEdit: false,
      buttonDisable: false,
      videoType: 'video_iframe',
      formData: {
        title: {},
        description: {},
        video_iframe: null
      },
      isCreate: false,
      teachingVideoId: '',
      uploadedVideoFile: null,
      fileErrorMessage: '',
      imageErrorMessage: ''
    }
  },
  components: {
    ButtonName,
    Breadcrumbs,
    FileUpload
  },
  computed: {
    ...mapState({
      languages: state => state.language.languages,
      teachingVideo: state => state.teachingVideo.teachingVideo
    }),
    ...mapGetters({
      courses: 'course/activeCoursesList'
    })
  },
  created () {
    this.teachingVideoId = this.$route.params.id
    if (this.teachingVideoId === 'add') {
      this.isEdit = false
      this.isCreate = true
      this.showVideo = true
    } else {
      this.isEdit = true
      this.getTeachingVideo({ id: this.teachingVideoId }).then(res => {
        this.showVideo = true
        this.formData = this.teachingVideo
        if (this.formData.video_iframe) {
          this.videoType = 'video_iframe'
        } else {
          this.videoType = 'video'
        }
        if (this.teachingVideo.image_paths === null) {
          this.formData.uploadedFiles = []
        } else {
          this.formData.uploadedFiles = [this.teachingVideo.image_paths.small]
        }
        this.uploadedVideoFile.name = this.teachingVideo.video_path
      })
    }
    this.getCourses()
  },
  methods: {
    ...mapActions({
      createTeachingVideo: 'teachingVideo/createTeachingVideo',
      getTeachingVideo: 'teachingVideo/getTeachingVideo',
      updateTeachingVideo: 'teachingVideo/updateTeachingVideo',
      getCourses: 'course/getActiveCoursesList'
    }),

    checkValidation (data) {
      return data
    },
    formSubmit () {
      this.$refs.teachingVideoAddForm.validate().then(success => {
        if (success) {
          const form = document.getElementById('teachingVideoAddForm')
          const formData = new FormData(form)
          this.buttonDisable = true

          formData.delete('video_type')
          if (this.videoType === 'video_iframe') {
            formData.delete('video')
          } else {
            formData.delete('video_iframe')
          }
          if (!this.formData.video_iframe && !this.uploadedVideoFile) {
            this.fileErrorMessage = 'Please upload Video!'
            this.buttonDisable = false
            return false
          } else {
            this.fileErrorMessage = ''
          }
          if (formData.get('image').name === '' && !this.formData.uploadedFiles) {
            this.imageErrorMessage = 'Please upload Image!'
            this.buttonDisable = false
            return false
          } else {
            this.imageErrorMessage = ''
          }

          if (this.isCreate === true) {
            this.createTeachingVideo(formData).then(() => {
              this.$toasted.success(this.$t('messages.success.courseCreated')).goAway(1500)
              this.$router.push('/teaching-videos')
            }).catch(() => {
              this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
            }).finally(() => {
              this.buttonDisable = false
            })
          } else {
            this.updateTeachingVideo({
              data: formData,
              id: this.$route.params.id
            }).then(res => {
              this.$toasted.success(this.$t('messages.success.courseUpdated')).goAway(1500)
              this.$router.push('/teaching-videos')
            }).catch(() => {
              this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
            }).finally(() => {
              this.buttonDisable = false
            })
          }
        } else {
          this.$toasted.error(this.$t('messages.error.validationError')).goAway(1500)
        }
      })
    },
    uploadCoverFile (file) {
      this.fileErrorMessage = ''
      const uploadFile = file[0]
      this.uploadedVideoFile = uploadFile
    },
    createIframeVideo () {
      this.fileErrorMessage = ''
    }
  }
}
</script>
