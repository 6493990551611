import { http } from '../../../api'

const taskWheelFortune = {
  namespaced: true,
  state: {
    task: null
  },
  actions: {
    createTask: ({ commit }, params) => {
      return http.post('/task-wheel-fortune', params).then(res => {
        return res.data
      })
    },
    getTask: ({ commit }, params) => {
      return http.get('/task-wheel-fortune/' + params).then(res => {
        commit('setTask', res.data)
      })
    },
    updateTask: ({ commit }, params) => {
      return http.post('/task-wheel-fortune/' + params.id, params.data).then(res => {
      })
    },
    deleteAnswers: ({ commit }, params) => {
      return http.post('/task-wheel-fortune/delete-answers', params).then(res => {
      })
    },
    deleteQuestions({commit}, params){
      return http.post('/task-wheel-fortune/delete-questions', params).then(res => {

      })
    }
  },
  mutations: {
    setTask (state, data) {
      state.task = data
    }
  },
  getters: {
    task: state => state.task
  }
}

export default taskWheelFortune
