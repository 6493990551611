<template>
  <TaskBase
    :data="formData"
    :update="updateTask"
    :create="createTask"
    :after-validation="afterValidation"
    :before-update-request="beforeUpdateRequest"
    :task-type-id="1"
  >
    <FormCard>
      <div class="d-flex flex-row align-items-start justify-content-between">
        <div class="mb-3 font-weight-bold">Вопрос: {{ activeQuestion }}</div>
        <PresentationButtons
          :contentOutputMethod="formData.content_output_method"
          :contentOutputCount="formData.content_output_count"
          @changePresentationType="$event => (formData.content_output_method = $event)"
          @input="$event => (formData.content_output_count = $event)"
        />
      </div>
      <div class="col-md-2 pt-4"></div>
      <div class="d-flex flex-column">
        <b-tabs content-class="mt-3">
          <b-tab :title="language.code" v-for="(language, i) in languages" :key="i">
            <div v-for="(question, index) in formData.questions" :key="index" class="d-flex flex-column align-items-center">
              <div v-show="activeQuestion === index + 1" class="w-100">
                <div class="d-flex w-100 justify-content-end">
                  <div
                    class="text-danger pointer mr-2"
                    v-if="formData.questions && formData.questions.length > 1"
                    @click="deleteQuestion(index, question)"
                  >
                    Удалит вопрос
                  </div>
                  <div class="text-primary pointer" @click="addQuestion">
                    Добавить вопрос
                  </div>
                </div>
                <ValidationProvider v-slot="{ classes }" rules="required" tag="div" class="form-group">
                  <label class="form-label">Вопрос:</label>
                  <input
                    class="form-control"
                    :name="'questions[' + index + '][question][' + language.code + ']'"
                    v-model="question.question[language.code]"
                    :class="checkValidation(classes, language.code)"
                    placeholder="Еnter a question"
                    type="text"
                  />
                </ValidationProvider>
                <div class="d-flex flex-wrap">
                  <div class="w-50 pl-3 pr-3" v-for="(answer, i) in question.answers" :key="i">
                    <ValidationProvider v-slot="{ classes }" rules="required" tag="div" class="form-group">
                      <label class="form-label" :for="'questions[' + index + '][answers][' + i + '][answer][' + language.code + ']'">
                        Ответ:
                      </label>
                      <div class="d-flex align-items-center">
                        <label
                          :for="'questions[' + index + '][answers][' + i + '][correct_answer][' + language.code + ']'"
                          class="radiobox pr-2 mb-0"
                        >
                          <input
                            :value="answer.correct_answer"
                            @change="selectCorrectAnswer(index, i)"
                            :id="'questions[' + index + '][answers][' + i + '][correct_answer][' + language.code + ']'"
                            :name="'questions[' + index + '][answers][' + i + '][correct_answer]'"
                            type="checkbox"
                            v-model="answer.correct_answer"
                          />
                          <span></span>
                        </label>
                        <input
                          class="form-control"
                          :name="'questions[' + index + '][answers][' + i + '][answer][' + language.code + ']'"
                          :id="'questions[' + index + '][answers][' + i + '][answer][' + language.code + ']'"
                          v-model="answer.answer[language.code]"
                          :class="checkValidation(classes, language.code)"
                          placeholder="Possible answer"
                          type="text"
                        />
                        <span class="p-2 text-danger pointer" style="width: 32px;"
                          ><i v-if="question.answers.length > 2" @click="deleteTaskAnswer(answer, index, i)" class="ti-close"></i
                        ></span>
                      </div>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="pl-3 text-primary pointer" @click="addAnswer(index)">
                  Добавить вопрос
                </div>
              </div>
              <InsertMediaButtons
                v-show="activeQuestion === index + 1"
                :item="question"
                :imageName="`questions[${index}][image][${language.code}]`"
                :audioName="`questions[${index}][audio][${language.code}]`"
                :videoName="`questions[${index}][video][${language.code}]`"
                :iframeName="`questions[${index}][video_iframe][${language.code}]`"
              />
            </div>

            <div class="d-flex justify-content-center align-items-center">
              <button type="button" class="btn btn-outline-primary d-flex align-items-center rounded-circle p-3" @click="prevQuestion">
                <i class="ti-arrow-left"></i>
              </button>
              <span class="pl-2 pr-2 font-weight-bold">{{ activeQuestion }}/{{ formData.questions.length }}</span>
              <button class="btn btn-outline-primary d-flex align-items-center rounded-circle p-3" type="button" @click="nextQuestion">
                <i class="ti-arrow-right"></i>
              </button>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </FormCard>
  </TaskBase>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import InsertMediaButtons from '../../../components/InsertMediaButtons'
import FormCard from '../../../components/FormCard'
import CkeditorMixin from '../../../../mixins/CkeditorMixin'
import TaskMixin from '../../../../mixins/TaskMixin'
import AudioMixin from '../../../../mixins/AudioMixin'
import TaskBase from '../../../components/tasks/TaskBase'
import PresentationButtons from '../../../components/PresentationButtons.vue'

export default {
  name: 'TaskTest',
  mixins: [CkeditorMixin, TaskMixin, AudioMixin],
  data() {
    return {
      buttonDisable: false,
      removeAnswers: [],
      removeQuestion: [],
      formData: {
        name: {},
        content_output_method: 0,
        content_output_count: 3,
        description: {},
        questions: [
          {
            question: {},
            prompt: {},
            answers: [
              {
                answer: {},
                correct_answer: true
              },
              {
                answer: {},
                correct_answer: false
              }
            ]
          }
        ]
      },
      gallery: [],
      activeQuestion: 1,
      videoType: 'video_iframe',
      fileErrorMessage: '',
      uploadedVideoFile: null,
      isCreate: false,
      taskId: null
    }
  },
  components: {
    TaskBase,
    FormCard,
    PresentationButtons,
    InsertMediaButtons
  },
  computed: {
    ...mapState({
      languages: state => state.language.languages,
      task: state => state.taskTest.task,
      taskType: state => state.taskType.taskType
    })
  },
  created() {
    this.taskId = this.$route.params.id
    this.getTaskType(1).catch(() => {
      this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
    })
    if (this.taskId === 'add') {
      this.isCreate = true
    } else {
      this.isCreate = false
      this.getTask(this.taskId).then(() => {
        this.formData = this.task.task
        this.formData.questions = this.task.questions
      })
    }
  },
  methods: {
    ...mapActions({
      createTask: 'taskTest/createTask',
      updateTask: 'taskTest/updateTask',
      deleteAnswers: 'taskTest/deleteAnswers',
      deleteQuestions: 'taskTest/deleteQuestions',
      getTask: 'taskTest/getTask',
      getTaskType: 'taskType/getTaskType'
    }),
    selectCorrectAnswer(questionIndex, answerIndex) {
      this.formData.questions[questionIndex].answers.map((item, index) => {
        if (answerIndex === index) {
          item.correct_answer = true
        } else {
          item.correct_answer = false
        }
      })
    },
    addAnswer(questionIndex) {
      this.formData.questions[questionIndex].answers.push({
        answer: {},
        correct_answer: false
      })
    },
    addQuestion() {
      this.formData.questions.push({
        question: {},
        prompt: {},
        answers: [
          {
            answer: {},
            correct_answer: true
          },
          {
            answer: {},
            correct_answer: false
          }
        ]
      })
      this.activeQuestion = this.formData.questions.length
    },
    deleteQuestion(index, question) {
      if (question.id) {
        this.removeQuestion.push(question.id)
      }
      this.formData.questions = this.formData.questions.filter((item, i) => index !== i)
    },
    deleteTaskAnswer(answer, questionIndex, answerIndex) {
      let correctAnswer = false
      if (answer.id) {
        this.removeAnswers.push(answer.id)
        this.formData.questions[questionIndex].answers = this.formData.questions[questionIndex].answers.filter((item, index) => {
          if (index !== answerIndex) {
            return item
          } else {
            if (item.correct_answer) {
              correctAnswer = true
            }
          }
        })
      } else {
        this.formData.questions[questionIndex].answers = this.formData.questions[questionIndex].answers.filter((item, index) => {
          if (index !== answerIndex) {
            return item
          } else {
            if (item.correct_answer) {
              correctAnswer = true
            }
          }
        })
      }
      if (correctAnswer) {
        this.formData.questions[questionIndex].answers[0].correct_answer = true
      }
    },
    customLabel({ name }) {
      return `${name[this.$i18n.locale]}`
    },
    prevQuestion() {
      if (this.activeQuestion > 1) {
        this.activeQuestion -= 1
      }
    },
    nextQuestion() {
      if (this.activeQuestion < this.formData.questions.length) {
        this.activeQuestion += 1
      }
    },
    afterValidation(formData) {
      if (this.removeAnswers.length) {
        this.deleteAnswers({ answers_id: this.removeAnswers })
      }
      if (this.removeQuestion.length) {
        this.deleteQuestions({ questions_id: this.removeQuestion })
      }
    },
    beforeUpdateRequest(formData) {
      this.formData.questions.map((item, index) => {
        this.languages.map(language => {
          formData.set('questions[' + index + '][question][' + language.code + ']', item.question[language.code])
          formData.set('questions[' + index + '][prompt][' + language.code + ']', item.prompt[language.code])
        })
        if (item.id) {
          formData.append('questions[' + index + '][id]', item.id)
        }
        item.answers.map((answer, i) => {
          this.languages.map(language => {
            formData.set('questions[' + index + '][answers][' + i + '][answer][' + language.code + ']', answer.answer[language.code])
          })
          formData.set('questions[' + index + '][answers][' + i + '][correct_answer]', answer.correct_answer)
          if (item.id) {
            formData.append('questions[' + index + '][answers][' + i + '][id]', answer.id)
          }
        })
      })

      formData.append('content_output_method', this.formData.content_output_method)
      formData.append('content_output_count', this.formData.content_output_count)
    },
    checkValidation(data) {
      return data
    }
  }
}
</script>
