import { http } from '../../../api'

const supportCategory = {
  namespaced: true,
  state: {
    supportCategories: [],
    supportCategoriesList: [],
    supportCategory: {},
    paginationSupportCategory: {}
  },
  actions: {
    getAllSupportCategories: ({ commit }, params) => {
      let url = '/support-ticket-category'
      if (params) {
        const q = new URLSearchParams(params)
        url = url + '?' + q.toString()
      }
      return http.get(url).then(res => {
        commit('setSupportCategories', res.data)
        return res.data
      })
    },
    getSupportCategory: ({ commit }, id) => {
      return http.get('/support-ticket-category/' + id).then(res => {
        commit('setSupportCategory', res.data.data)
        return res.data
      })
    },
    getSupportCategoriesList: ({ commit }, id) => {
      return http.get('/support-ticket-category/get-list').then(res => {
        commit('setSupportCategoriesList', res.data.data)
        return res.data
      })
    },
    create: ({ commit }, params) => {
      return http.post('/support-ticket-category', params).then(res => {
        return res
      })
    },
    update: ({ commit }, params) => {
      return http.put('/support-ticket-category/' + params.id, params.data).then(res => {
        return res
      })
    },
    delete: ({ commit }, id) => {
      return http.delete('/support-ticket-category/' + id).then(res => {
        if (res.data.deleteType === 'forceDelete') {
          commit('removeSupportCategory', id)
        } else {
          commit('updateSupportCategoryStatus', { id: id, status: 'inactive' })
        }
      })
    },
    restore: ({ commit }, id) => {
      return http.get('/support-ticket-category/' + id + '/restore').then(res => {
        commit('updateSupportCategoryStatus', { id: id, status: 'active' })
      })
    }
  },
  mutations: {
    setSupportCategories (state, data) {
      state.supportCategories = data.data
      state.paginationSupportCategory = data.pagination
    },
    setSupportCategoriesList (state, data) {
      state.supportCategoriesList = data
    },
    updateSupportCategory (state, data) {
      state.supportCategories = state.supportCategories.map(supportCategory => {
        if (supportCategory.id === data.id) {
          supportCategory = data
        }
        return supportCategory
      })
    },
    updateSupportCategoryStatus (state, data) {
      state.supportCategories = state.supportCategories.map(supportCategory => {
        if (supportCategory.id === data.id) {
          supportCategory.status = data.status
        }
        return supportCategory
      })
    },
    removeSupportCategory (state, id) {
      state.supportCategories = state.supportCategories.filter(supportCategory => supportCategory.id !== id)
    },
    setSupportCategory (state, data) {
      state.supportCategories = data
    }
  },
  getters: {
    supportCategories: state => state.supportCategories,
    supportCategory: state => state.supportCategory,
    paginationSupportCategory: state => state.paginationSupportCategory,
    supportCategoriesList: state => state.supportCategoriesList
  }
}

export default supportCategory
