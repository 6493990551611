import { http } from '../../../api'

const taskType = {
  namespaced: true,
  state: {
    lessonBlockList: [],
    lessonBlock: null
  },
  actions: {
    getLessonBlocksByLesson: ({ commit }, params) => {
      return http.get('/lesson-block/' + params + '/lesson').then(res => {
        commit('setLessonBlocksByLesson', res.data.data)
      })
    },
    create: ({ commit }, params) => {
      return http.post('/lesson-block', params).then(res => {
        return res.data
      })
    },
    delete: ({ commit }, params) => {
      return http.delete('/lesson-block/' + params).then(res => {
        if (res.data.deleteType === 'forceDelete') {
          commit('setLessonBlockAfterDelete', params)
        } else {
          commit('setUpdateLessonBlockStatus', { id: params, status: 'inactive' })
        }
      })
    },
    restore: ({ commit }, params) => {
      return http.get('/lesson-block/' + params + '/restore').then(res => {
        commit('setUpdateLessonBlockStatus', { id: params, status: 'active' })
      })
    },
    getLessonBlock: ({ commit }, params) => {
      return http.get('/lesson-block/' + params).then(res => {
        commit('setLessonBlock', res.data.data)
      })
    },
    updateLessonBlock: ({ commit }, params) => {
      return http.post('/lesson-block/' + params.id, params.data).then(res => {
        return res.data
      })
    },
    deleteFileLessonBlock: ({ commit }, id) => {
      return http.delete('/lesson-block/' + id + '/delete-file')
    }
  },
  mutations: {
    setUpdateLessonBlockStatus (state, data) {
      state.lessonBlockList = state.lessonBlockList.map(item => {
        if (item.id === data.id) {
          item.status = data.status
        }
        return item
      })
    },
    setLessonBlocksByLesson (state, data) {
      state.lessonBlockList = data
    },
    setLessonBlockAfterDelete (state, id) {
      state.lessonBlockList = state.lessonBlockList.filter(item => item.id !== id)
    },
    setLessonBlock (state, data) {
      state.lessonBlock = data
    }
  },
  getters: {
    lessonBlockList: state => state.lessonBlockList,
    lessonBlock: state => state.lessonBlock
  }
}

export default taskType
