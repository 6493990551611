<template>
  <div>
    <div class="page-header">
      <div>
        <h2 class="main-content-title tx-24 mg-b-5">Отзывы</h2>
      </div>
      <div class="d-flex">
        <div class="justify-content-center">
        </div>
      </div>
    </div>
    <div class="row row-sm">
      <div class="col-lg-12">
        <div class="card custom-card">
          <div class="card-body">
            <div>
              <h6 class="main-content-label mb-1">Список отзывов</h6>
            </div>
            <div class="table-responsive">
              <b-table striped hover :items="reviews" :fields="tableFields">
                <template #cell(status_name)="row">
                  <span class="badge text-white text-uppercase"  :class="[row.item.status_name === 'На модерации' ? 'bg-dark' : row.item.status_name === 'Отклонять' ? 'bg-danger' : 'bg-success']">
                    {{row.item.status_name}}
                  </span>
                </template>
                <template #cell(status)="row">
                  <span class="badge text-white"  :class="[!row.item.deleted ? 'bg-success' : 'bg-danger']">
                    {{!row.item.deleted ? 'активный' : 'неактивный'}}
                  </span>
                </template>
                <template #cell(actions)="row">
                  <Actions :item="row.item"/>
                </template>
              </b-table>
              <div class="d-flex justify-content-end  mt-3">
                <div class="mr-3">
                  <b-form-select
                    @change="changePaginationPerPage"
                    style="width: 80px"
                    v-model="paginationPerPage"
                    :options="paginationOptions"
                  >
                  </b-form-select>
                </div>
                <div>
                  <b-pagination
                    class="m-0"
                    @change="changePagination"
                    v-model="paginationCurrentPage"
                    :total-rows="pagination.count"
                    :per-page="paginationPerPage"
                    first-text="<<"
                    prev-text="<"
                    next-text=">"
                    last-text=">>"
                  ></b-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Actions from '../../components/Actions'

export default {
  name: 'Review',
  data () {
    return {
      paginationOptions: [
        { value: 6, text: '6' },
        { value: 10, text: '10' },
        { value: 30, text: '30' },
        { value: 100, text: '100' }
      ],
      paginationCurrentPage: 1,
      paginationPerPage: 6
    }
  },
  components: {
    Actions
  },
  computed: {
    ...mapState({
      reviews: state => state.review.reviews,
      pagination: state => state.review.pagination
    }),
    tableFields () {
      return [
        {
          key: 'id',
          label: 'Id',
          sortable: true
        },
        {
          key: 'full_name',
          label: 'Имя',
          sortable: true
        },
        {
          key: 'email',
          label: 'Почта',
          sortable: true
        },
        {
          key: 'text',
          label: 'Текст',
          sortable: true
        },
        {
          key: 'answer',
          label: 'ответ',
          sortable: true
        },
        {
          key: 'status_name',
          label: 'Статус модерации',
          sortable: true
        },
        {
          key: 'rating',
          label: 'Рейтинг',
          sortable: true
        },
        {
          key: 'status',
          label: 'Статус',
          sortable: true
        },
        {
          key: 'actions',
          label: 'Действие',
          sortable: false
        }
      ]
    }
  },
  created () {
    this.getAllReviews().then(res => {
    }).catch(() => {
      this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
    })
  },
  methods: {
    ...mapActions({
      getAllReviews: 'review/getAllReviews'
    }),
    changePaginationPerPage (value) {
      this.getAllReviews({ page: this.paginationCurrentPage, count: value })
    },
    changePagination (page) {
      this.getAllReviews({ page: page, count: this.paginationPerPage })
    }
  }
}
</script>
