<template>
  <div>
    <div class="page-header">
      <div>
        <h2 class="main-content-title tx-24 mg-b-5">{{isCreate ? 'Добавить' : 'Редактировать'}} метод оплаты</h2>
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="#" @click="$router.go(-1)">Назад</a></li>
          <li class="breadcrumb-item active" aria-current="page">{{isCreate ? 'Добавить' : 'Редактировать'}} метод оплаты</li>
        </ol>
      </div>
      <div class="d-flex">
      </div>
    </div>
    <div class="row row-sm justify-content-center">
      <div class="col-lg-6 col-md-12">
        <div class="card custom-card">
          <div class="card-body">
            <div>
              <h6 class="main-content-label mb-1">Метод оплаты</h6>
            </div>
            <div>
              <ValidationObserver tag="form" id="createPaymentMethodForm" ref="createPaymentMethodForm" class="d-flex flex-column" @submit.prevent="formSubmit()">
                <b-tabs content-class="mt-3">
                  <b-tab :title="language.code" v-for="(language, i) in languages" :key="i">
                      <ValidationProvider v-slot="{ classes }" rules="required" tag="div" class="form-group">
                        <input class="form-control" :name="'name['+language.code+']'" :class="classes" v-model="formData.name[language.code]"  :placeholder="'Название'" type="text">
                      </ValidationProvider>
                      <ValidationProvider v-slot="{ classes }" rules="required" tag="div" class="form-group">
                        <textarea rows="3" class="form-control" :name="'description['+language.code+']'" :class="classes" v-model="formData.description[language.code]" :placeholder="'Описание'"></textarea>
                      </ValidationProvider>
                  </b-tab>
                  <FileUpload v-if="this.formData.footer_image || isCreate"  accept="image/png,image/jpeg,image/jpg" label="Лого в подвале:" name="footer_image" uploadType="oneImage" type="image" :validation="true" :filesData="formData.footer_image" />
                  <FileUpload v-if="this.formData.page_image || isCreate"  accept="image/png,image/jpeg,image/jpg" label="Лого на странице:" name="page_image" uploadType="oneImage" type="image" :validation="true" :filesData="formData.page_image" />
                </b-tabs>
                <button class="btn ripple btn-main-primary">{{isCreate?'Сохранить':'Обновить'}}</button>
              </ValidationObserver>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import FileUpload from '../../components/FileUpload'

export default {
  name: 'AddPaymentMethod',
  components: {
    FileUpload
  },
  data () {
    return {
      formData: {
        name: {},
        description: {}
      },
      paymentMethodId: '',
      isCreate: false
    }
  },
  created () {
    this.paymentMethodId = this.$route.params.id
    if (this.paymentMethodId === 'add') {
      this.isCreate = true
    } else {
      this.getPaymentMethod(this.paymentMethodId).then(res => {
        this.formData = res.data
        this.$set(this.formData, 'footer_image',this.setImage(this.formData.footer_image) )
        this.$set(this.formData, 'page_image',this.setImage(this.formData.page_image) )
      })
    }
  },
  computed: {
    ...mapState({
      languages: state => state.language.languages,
      paymentMethod: state => state.paymentMethod.paymentMethod
    })
  },
  methods: {
    ...mapActions({
      createPaymentMethod: 'paymentMethod/create',
      update: 'paymentMethod/update',
      getPaymentMethod: 'paymentMethod/getPaymentMethod'
    }),
    setImage(image){
      if (!image){
        return []
      }else {
        return  [image]
      }
    },
    customLabel ({ name, desc }) {
      return `${name[this.$i18n.locale]}`
    },
    formSubmit () {
      this.$refs.createPaymentMethodForm.validate().then(success => {
        if (success) {
          const form = document.getElementById('createPaymentMethodForm')
          const formData = new FormData(form)
          this.buttonDisable = true

          if (this.isCreate === true) {
            this.createPaymentMethod(formData).then(() => {
              this.$toasted.success(this.$t('messages.success.paymentMethodCreated')).goAway(1500)
              this.$router.push('/payment-method')
            }).catch(err => {
              if (err.response.status === 400 || err.response.status === 422) {
                const keys = Object.keys(err.response.data)
                this.$toasted.error(err.response.data[keys[0]]).goAway(1500)
              } else {
                this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
              }
            }).finally(() => {
              this.buttonDisable = false
            })
          } else {
            this.update({ data: formData, id: this.$route.params.id }).then(res => {
              this.$toasted.success(this.$t('messages.success.courseUpdated')).goAway(1500)
              this.$router.push('/payment-method')
            }).catch(err => {
              if (err.response.status === 400 || err.response.status === 422) {
                const keys = Object.keys(err.response.data)
                this.$toasted.error(err.response.data[keys[0]]).goAway(1500)
              } else {
                this.$toasted.error(this.$t('messages.error.paymentMethodCreated')).goAway(1500)
              }
            }).finally(() => {
              this.buttonDisable = false
            })
          }
        } else {
          this.$toasted.error(this.$t('messages.error.validationError')).goAway(1500)
        }
      })
    }
  }
}
</script>
