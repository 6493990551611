import { http } from '../../../api'

const courseLevel = {
  namespaced: true,
  state: {
    courseLevelsList: [],
    activeCourseLevelsList: [],
    courseLevel: null
  },
  actions: {
    getCourseLevels: ({ commit }, params) => {
      let url = '/course-level'
      if (params) {
        const q = new URLSearchParams(params)
        url = url + '?' + q.toString()
      }
      return http.get(url).then(res => {
        commit('setCourseLevels', res.data.data)
        return res.data
      })
    },
    getActiveCourseLevels: ({ commit }) => {
      return http.get('course-level/list').then(res => {
        commit('setActiveCourseLevels', res.data.data)
        return res.data
      })
    },
    createCourseLevel: ({ commit }, params) => {
      return http.post('/course-level', params).then(res => {
        return res.data
      })
    },
    delete: ({ commit }, params) => {
      return http.delete('/course-level/' + params).then(res => {
        if (res.data.deleteType === 'forceDelete') {
          commit('setCourseLevelAfterDelete', params)
        } else {
          commit('setUpdateCourseLevelStatus', { id: params, status: 'inactive' })
        }
      })
    },
    restore: ({ commit }, params) => {
      return http.get('/course-level/' + params + '/restore').then(res => {
        commit('setUpdateCourseLevelStatus', { id: params, status: 'active' })
      })
    },
    getCourseLevel: ({ commit }, params) => {
      return http.get('/course-level/' + params).then(res => {
        commit('setCourseLevel', res.data.data)
      })
    },
    updateCourseLevel: ({ commit }, params) => {
      return http.put('/course-level/' + params.id, params.data).then(res => {
      })
    }
  },
  mutations: {
    setUpdateCourseLevelStatus (state, data) {
      state.courseLevelsList = state.courseLevelsList.map(item => {
        if (item.id === data.id) {
          item.status = data.status
        }
        return item
      })
    },
    setCourseLevels (state, data) {
      state.courseLevelsList = data
    },
    setActiveCourseLevels (state, data) {
      state.activeCourseLevelsList = data
    },
    setCourseLevelAfterDelete (state, id) {
      state.courseLevelsList = state.courseLevelsList.filter(item => item.id !== id)
    },
    setCourseLevel (state, data) {
      state.courseLevel = data
    }
  },
  getters: {
    courseLevelsList: state => state.courseLevelsList,
    activeCourseLevelsList: state => state.activeCourseLevelsList,
    courseLevel: state => state.courseLevel
  }
}

export default courseLevel
