<template>
  <div>
    <div class="page-header">
      <div>
        <h2 class="main-content-title tx-24 mg-b-5">Курсы</h2>
      </div>
      <div class="d-flex">
        <div class="justify-content-center">
          <router-link to="/courses/add" type="button" class="btn btn-primary my-2 btn-icon-text">
            <i class="fe fe-plus-square mr-2"></i> Добавить курс
          </router-link>
        </div>
      </div>
    </div>

    <div class="row row-sm">
      <div class="col-lg-12">
        <div class="card custom-card">
          <div class="card-body">
            <div>
              <h6 class="main-content-label mb-1">Список курсов</h6>
            </div>

            <div class="table-responsive">
              <b-table striped hover :items="coursesList" :fields="tableFields">
                <template #cell(id)="row">
                  <router-link title="Course Detail" :to="'/courses/' + row.item.id + '/detail'">{{ row.item.id }} </router-link>
                </template>

                <template #cell(description)="row">
                  <div class="overflow-text-description">
                    {{ replaceHtml(row.item.description[$i18n.locale]) }}
                  </div>
                </template>

                <template #cell(tariffs)="row">
                  <div v-if="row.item.tariffs">
                    <div v-for="(tariff, index) in row.item.tariffs" :key="index">{{ index + 1 }}.{{ tariff.name[$i18n.locale] }}</div>
                  </div>
                </template>

                <template #cell(image_path)="row">
                  <div v-if="row.item.image_path">
                    <img class="img-thumbnail table-image" :src="row.item.image_path" alt="image" />
                  </div>
                  <div v-else>No logo.</div>
                </template>

                <template #cell(teaching_language.color)="row">
                  <span
                    class="badge w-25 px-5 py-3 d-inline-block"
                    v-bind:style="{ backgroundImage: row.item.teaching_language && row.item.teaching_language.color }"
                  ></span>
                </template>
                <template #cell(created_at)="row">
                  <div>{{ row.item.created_at | moment('DD.MM.YYYY') }}</div>
                </template>
                <template #cell(actions)="row">
                  <router-link title="Course Detail" :to="`/courses/${row.item.id}/detail/`" class="btn btn-outline-primary mr-2 mt-1 mb-1">
                    <i class="fe fe-file-text"></i>
                  </router-link>
                  <button type="button" title="Edit Course" class="btn btn-outline-primary mr-2 mt-1 mb-1" @click="editCourse(row.item)">
                    <i class="fe fe-edit"></i>
                  </button>
                  <button
                    v-if="row.item.status === 'active'"
                    @click="removeCourse(row.item.id)"
                    type="button"
                    class="btn btn-outline-danger mr-2 mt-1 mb-1"
                  >
                    <i class="ti-close"></i>
                  </button>
                  <div v-else class="d-inline-block">
                    <button
                      title="Rest Course"
                      @click="restCourse(row.item.id)"
                      type="button"
                      class="btn btn-outline-success mr-2 mt-1 mb-1"
                    >
                      <i class="fe fe-rotate-ccw"></i>
                    </button>
                    <button
                      title="Remove Course"
                      @click="removeCourse(row.item.id)"
                      type="button"
                      class="btn btn-outline-danger mr-2 mt-1 mb-1"
                    >
                      <i class="fe fe-trash-2"></i>
                    </button>
                  </div>
                </template>
              </b-table>
              <div class="d-flex justify-content-end  mt-3">
                <div class="mr-3">
                  <b-form-select
                    @change="changePaginationPerPage"
                    style="width: 80px"
                    v-model="paginationPerPage"
                    :options="paginationOptions"
                  >
                  </b-form-select>
                </div>
                <div>
                  <b-pagination
                    class="m-0"
                    @change="changePagination"
                    v-model="paginationCurrentPage"
                    :total-rows="pagination.count"
                    :per-page="paginationPerPage"
                    first-text="<<"
                    prev-text="<"
                    next-text=">"
                    last-text=">>"
                  ></b-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import Swal from 'sweetalert2'

export default {
  name: 'Courses',
  data() {
    return {
      paginationOptions: [
        {
          value: 6,
          text: '6'
        },
        {
          value: 10,
          text: '10'
        },
        {
          value: 30,
          text: '30'
        },
        {
          value: 100,
          text: '100'
        }
      ],
      paginationCurrentPage: 1,
      paginationPerPage: 6,
      pagination: {}
    }
  },
  computed: {
    ...mapState({
      coursesList: state => state.course.coursesList
    }),
    ...mapGetters({
      setting: 'settings/setting'
    }),
    tableFields() {
      return [
        {
          key: 'id',
          label: 'Id',
          sortable: true
        },
        {
          key: 'name.' + this.$i18n.locale,
          label: 'Название',
          sortable: true
        },
        {
          key: 'description',
          label: 'Описание',
          sortable: true
        },
        {
          key: 'tariffs',
          label: 'Тарифы',
          sortable: true
        },
        {
          key: 'image_path',
          label: 'Фото',
          sortable: true
        },
        {
          key: 'teaching_language.name.' + this.$i18n.locale,
          label: 'Язык',
          sortable: true
        },
        {
          key: 'course_level.name.' + this.$i18n.locale,
          label: 'Уровень курса',
          sortable: true
        },
        {
          key: 'teaching_language.color',
          label: 'Цвет',
          sortable: true
        },
        {
          key: 'created_at',
          label: 'Создан',
          sortable: true
        },
        {
          key: 'actions',
          label: 'Действие',
          sortable: false
        }
      ]
    }
  },
  created() {
    this.getCourses({})
    this.getSettingByKey('check_course_edit')
  },
  methods: {
    ...mapActions({
      getAllCourses: 'course/getAllCourses',
      deleteCourse: 'course/deleteCourse',
      restoreCourse: 'course/restoreCourse',
      getSettingByKey: 'settings/getSettingByKey'
    }),
    replaceHtml(string) {
      if (string) {
        return string.replace(/(<([^>]+)>)/gi, '')
      }
      return ''
    },
    editCourse(course) {
      if (course.users_count && this.setting.value === '1') {
        this.$toasted.error(this.$t('You cannot edit the course, it has already been purchased.')).goAway(1500)
      } else {
        this.$router.push('/courses/' + course.id)
      }
    },
    getCourses(params) {
      this.getAllCourses(params)
        .then(res => {
          this.pagination = res.pagination
        })
        .catch(() => {
          this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
        })
    },
    changePaginationPerPage(value) {
      this.getCourses({
        page: this.paginationCurrentPage,
        count: value
      })
    },
    changePagination(page) {
      this.getCourses({
        page: page,
        count: this.paginationPerPage
      })
    },
    removeCourse(id) {
      Swal.fire({
        text: this.$t('messages.warning.delete'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#748C41',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('buttons.yes'),
        cancelButtonText: this.$t('buttons.no')
      }).then(result => {
        if (result.value) {
          this.deleteCourse({ id })
            .then(res => {})
            .catch(() => {
              this.$toasted.error(this.$t('messages.error.somethingWentWrong')).goAway(1500)
            })
        }
      })
    },
    restCourse(id) {
      this.restoreCourse({ id }).then(res => {})
    }
  }
}
</script>
