import { http } from '../../../api'

const emailTemplate = {
  namespaced: true,
  state: {
    emailTemplates: null
  },
  actions: {
    getEmailTemplates: ({ commit }, params) => {
      const q = new URLSearchParams(params)
      return http.get('/email-template?' + q.toString()).then(res => {
        commit('setEmailTemplates', res.data)
        return res.data
      })
    },
    getEmailTemplate: ({ commit }, params) => {
      return http.get('/email-template/' + params.id).then(res => {
        return res.data
      })
    },
    create: ({ commit }, params) => {
      return http.post('/email-template', params).then(res => {
        return res.data
      })
    },
    delete: ({ commit }, params) => {
      return http.delete('/email-template/' + params).then(res => {
        if (res.data.deleteType === 'forceDelete') {
          commit('removeEmailTemplate', params)
        } else {
          commit('updateEmailTemplateStatus', { id: params, status: 'inactive' })
        }
        return res.data
      })
    },
    restore: ({ commit }, params) => {
      return http.get('/email-template/' + params + '/restore').then(res => {
        commit('updateEmailTemplateStatus', { id: params, status: 'active' })
        return res.data
      })
    },
    update: ({ commit }, params) => {
      return http.put('/email-template/' + params.id, params.data).then(res => {
        return res.data
      })
    }
  },
  mutations: {
    addEmailTemplate (state, data) {
      state.emailTemplates.push(data.data)
    },
    updateEmailTemplateStatus (state, data) {
      state.emailTemplates = state.emailTemplates.map(item => {
        if (item.id === data.id) {
          item.status = data.status
        }
        return item
      })
    },
    removeEmailTemplate (state, id) {
      state.emailTemplates = state.emailTemplates.filter(item => item.id !== id)
    },
    setEmailTemplates (state, data) {
      state.emailTemplates = data.data
    }
  },
  getters: {
    emailTemplates: state => state.emailTemplates
  }
}

export default emailTemplate
